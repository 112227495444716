import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import Radio from '@eg/elements/Radio';
import * as React from 'react';
import { PatientConfidentialityRelease } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementConsentToReleasePrivateData } from '../../../../tracking/tracking.types';
import { handleFormErrors } from '../../../../tracking/trackingFormating';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import './ConsentToReleasePrivateData.css';
import { consentToReleaseContent } from './ConsentToReleasePrivateData.data';
import {
  CheckboxType,
  ConsentToReleasePrivateDataProps,
} from './ConsentToReleasePrivateData.types';

const ConsentToReleasePrivateData = ({
  onSubmit,
  onNavigateNoSubmit,
  trackClickEvent,
  patientConfidentialityRelease,
  patientConfidentialityReleaseAfterDeath,
}: ConsentToReleasePrivateDataProps) => {
  const [selectedRadioGroupOption, setSelectedRadioGroupOption] =
    React.useState<PatientConfidentialityRelease>();
  const [selectedCheckboxOption, setSelectedCheckboxOption] = React.useState(false);
  const [showRadioGroupError, setShowRadioGroupError] = React.useState(false);
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);
  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackClickEvent(
      event.target.value === PatientConfidentialityRelease.GENERAL
        ? TrackingElementConsentToReleasePrivateData.RADIOBUTTON_GENERAL
        : TrackingElementConsentToReleasePrivateData.RADIOBUTTON_SINGULAR,
    );
    setSelectedRadioGroupOption(event.target.value as PatientConfidentialityRelease);
    setShowRadioGroupError(false);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackClickEvent(TrackingElementConsentToReleasePrivateData.CHECKBOX);
    setSelectedCheckboxOption(event.target.checked);
    setShowCheckboxError(false);
  };

  React.useEffect(() => {
    if (patientConfidentialityRelease) {
      setSelectedRadioGroupOption(patientConfidentialityRelease);
    }
    if (patientConfidentialityReleaseAfterDeath) {
      setSelectedCheckboxOption(patientConfidentialityReleaseAfterDeath);
    }
  }, []);

  if (showRadioGroupError || showCheckboxError) {
    handleFormErrors(consentToReleaseContent.sections[0]?.options?.errorMessage as string);
  }

  return (
    <div className="container">
      <div className="consent-to-release-private-data__body">
        <Headline text={consentToReleaseContent.titleText} />
        <div
          className="consent-to-release-private-data__sections-container"
          id="consent-to-release-private-data__sections-container"
        >
          {consentToReleaseContent.sections.map((section, idx) => (
            <div
              className="consent-to-release-private-data__section"
              key={`consent-to-release-private-data__section-${idx}`}
            >
              {section.subtitle}

              {section.options.type === CheckboxType.RADIO && (
                <ControlWithHint error={showRadioGroupError && section.options.errorMessage}>
                  <>
                    {section.options.checkboxes.map((checkbox, optionIdx) => (
                      <Radio
                        key={`consent-to-release-private-data__section-${idx}__option-${optionIdx}`}
                        onChange={handleRadioGroupChange}
                        value={checkbox.value as PatientConfidentialityRelease}
                        label={checkbox.content}
                        error={showRadioGroupError}
                        checked={selectedRadioGroupOption === checkbox.value}
                      />
                    ))}
                  </>
                </ControlWithHint>
              )}

              {section.options.type === CheckboxType.CHECKBOX &&
                section.options.checkboxes.map((checkbox, optionIdx) => (
                  <ControlWithHint
                    error={showCheckboxError && checkbox.errorMessage}
                    key={`consent-to-release-private-data__section-${idx}__option-${optionIdx}`}
                  >
                    <Checkbox
                      verticalAlign="top"
                      checked={selectedCheckboxOption}
                      label={checkbox.content}
                      onChange={handleCheckboxChange}
                      error={showCheckboxError}
                    />
                  </ControlWithHint>
                ))}

              {section.paragraph}
            </div>
          ))}
        </div>
        <div className="consent-to-release-private-data__virtual-assistant">
          <AemFragment name={AemFragmentCatalog.STEP19VA} useVA={true} />
        </div>
        <Footer
          showNext
          showPrevious
          labelNext="weiter"
          handleAction={(navigationAction: NavigationAction) => {
            const navigatingBack = navigationAction === NavigationAction.BACK;

            if (!selectedCheckboxOption && !navigatingBack) {
              setShowCheckboxError(true);
            } else {
              setShowCheckboxError(false);
            }

            if (!selectedRadioGroupOption && !navigatingBack) {
              setShowRadioGroupError(true);
            } else {
              setShowRadioGroupError(false);
            }

            if (selectedCheckboxOption && selectedRadioGroupOption) {
              // TODO: Update the CheckOutConsents interface and remove duplication from the state machine
              onSubmit(
                {
                  releaseFromConfidentiality: {
                    confidentialityDeliveranceType: selectedRadioGroupOption,
                    confidentialityDeath: selectedCheckboxOption,
                  },
                  patientConfidentialityRelease: selectedRadioGroupOption,
                  patientConfidentialityReleaseAfterDeath: selectedCheckboxOption,
                },
                navigationAction,
              );
            }

            if (navigatingBack) {
              onNavigateNoSubmit(navigationAction);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ConsentToReleasePrivateData;
