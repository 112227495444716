import * as React from 'react';
import { getOeNumber } from '../../../helpers/modeConfig';
import { ViewName } from '../../../helpers/ViewName';
import LegalQuestionsPageContainer, {
  CheckOutPageData,
} from '../../../pages/legalQuestions/legalQuestionsPageContainer';
import { getAngebot, updateCheckOutPageData, updateOeNumber } from '../../../services/api';
import { fetchTrackingDataAfterPersonalDataPage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const legalQuestionsMoneyLaundering: StateDefinition<CheckOutPageData> = {
  name: StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE,
  percentage: PagePercentage.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE,
  trackingViewName: ViewName.PRUEFENUNDSENDEN,
  validInboundStates: [
    StateName.SUBSCRIPTIONS,
    StateName.LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE,
  ],
  transitions: [
    {
      test: action => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.SUBSCRIPTIONS,
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE,
    },
  ],
  onEnter: async (_, inputData) => {
    await updateOeNumber(inputData.businessId, getOeNumber());
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData = fetchTrackingDataAfterPersonalDataPage(response);

    return {
      userInput: {
        ...response.checkoutModel,
        showMeldung: false,
      },
      valueRanges: response.valueRanges,
      varianten: response.varianten,
      fetchedTrackingData,
    };
  },
  onExit: async (_, inputData) => {
    await updateCheckOutPageData(inputData.businessId, inputData.userInput);

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { userInput, businessId, varianten } = inputData;
    return (
      <LegalQuestionsPageContainer
        businessId={businessId}
        varianten={varianten}
        storeState={{
          additionalInsuranceSettings: userInput.additionalInsuranceSettings,
          paymentMethod: userInput.paymentMethod,
          pdeId: userInput.pdeId,
          role: userInput.role,
          variante: userInput.variante,
          entitledPersons: userInput.entitledPersons,
          patientConfidentialityRelease: userInput.patientConfidentialityRelease,
          patientConfidentialityReleaseAfterDeath:
            userInput.patientConfidentialityReleaseAfterDeath,
          loanProtection: userInput.loanProtection,
          update: values => updateApp(values),
          oeNumber: userInput.oeNumber,
          additionalTimeWaiting: userInput.additionalTimeWaiting,
          showMeldung: userInput.showMeldung,
        }}
        onError={onError}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
        valueRanges={inputData.valueRanges}
      />
    );
  },
};
