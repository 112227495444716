import * as React from 'react';
import { ConsentToCreditCheckData } from './ConsentToCreditCheck.types';

export const consentToCreditCheckData: ConsentToCreditCheckData = {
  titleText: 'Einwilligung zur Nutzung von Bonitätsdaten',
  sections: [
    {
      subtitle: '',
      checkboxes: [
        {
          label: (
            <>
              <p className="consent-to-credit-check__checkbox-label">
                Ich willige ein, dass die ERGO Vorsorge Lebensversicherung AG vor dem
                Vertragsabschluss Informationen über mein allgemeines Zahlungsverhalten sowie über
                meine Bonität auf Basis mathematischer Verfahren einholt. Dafür darf ERGO meine
                Adressdaten verwenden. ERGO darf die Informationen auch von einer Auskunftei
                einholen lassen (z. B. Creditreform) und dazu meine Stammdaten an diese
                weiterleiten.
              </p>
            </>
          ),
        },
      ],
      errorMessage: 'Bitte bestätigen Sie Ihre Einwilligung.',
    },
  ],
};
