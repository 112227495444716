import * as React from 'react';
import { OrderResponse, ResponseCodes } from 'rlv-common';
import { getOeNumber } from '../../../helpers/modeConfig';
import * as downloadPDFUtils from '../../../helpers/downloadPDF.utils';
import { ViewName } from '../../../helpers/ViewName';
import LegalQuestionsPageContainer, {
  CheckOutPageData,
} from '../../../pages/legalQuestions/legalQuestionsPageContainer';
import {
  getAngebot,
  makeOrder,
  updateCheckOutPageData,
  updateOeNumber,
} from '../../../services/api';
import { fetchTrackingDataAfterPersonalDataPage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const legalQuestionsIddDownloadPdf: StateDefinition<CheckOutPageData> = {
  name: StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE,
  trackingViewName: ViewName.PRUEFENUNDSENDEN,
  validInboundStates: [
    StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK,
    StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE,
  ],
  percentage: PagePercentage.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE,
  transitions: [
    {
      test: (action, inputData) =>
        action === NavigationAction.BACK ||
        (action === NavigationAction.BROWSER_BACK && !downloadPDFUtils.isPdfDownloaded()),
      newState: StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK,
    },
    {
      test: (action, inputData) =>
        action === NavigationAction.BACK ||
        (action === NavigationAction.BROWSER_BACK && downloadPDFUtils.isPdfDownloaded()),
      newState: StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE,
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.FEEDBACK_PAGE,
    },
  ],
  onEnter: async (transition, inputData) => {
    await updateOeNumber(inputData.businessId, getOeNumber());
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData = fetchTrackingDataAfterPersonalDataPage(response);

    return {
      userInput: {
        ...response.checkoutModel,
        showMeldung: false,
      },
      valueRanges: response.valueRanges,
      varianten: response.varianten,
      fetchedTrackingData,
    };
  },
  onExit: async (transitionDetails, inputData) => {
    await updateCheckOutPageData(inputData.businessId, inputData.userInput);
    const oeNumber = getOeNumber();
    if (transitionDetails.action === NavigationAction.NEXT) {
      const response: OrderResponse = await makeOrder(inputData.businessId, oeNumber);
      if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
        return {
          skipTransition: true,
          payload: { showMeldung: response.showMeldung },
        };
      }
      if (response.status !== ResponseCodes.SUCCESS) {
        throw new Error('Internal server error');
      }
    }

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { userInput, businessId, varianten } = inputData;
    return (
      <LegalQuestionsPageContainer
        businessId={businessId}
        varianten={varianten}
        storeState={{
          vorname: userInput.vorname,
          nachname: userInput.nachname,
          adresse: userInput.adresse,
          abbuchungsTage: userInput.abbuchungsTage,
          additionalInsuranceSettings: userInput.additionalInsuranceSettings,
          bic: userInput.bic,
          biker: userInput.biker,
          birthdate: userInput.birthdate,
          duwToken: userInput.duwToken,
          email: userInput.email,
          employment: userInput.employment,
          geburtsort: userInput.geburtsort,
          geschlecht: userInput.geschlecht,
          height: userInput.height,
          iban: userInput.iban,
          inceptionDate: userInput.inceptionDate,
          need: userInput.need,
          paymentMethod: userInput.paymentMethod,
          pdeId: userInput.pdeId,
          periodInYears: userInput.periodInYears,
          insuranceEndSum: userInput.insuranceEndSum,
          professionKey: userInput.professionKey,
          professionLabel: userInput.professionLabel,
          propertyOwner: userInput.propertyOwner,
          role: userInput.role,
          rufnummer: userInput.rufnummer,
          smoker: userInput.smoker,
          staatsangehoerigkeit: userInput.staatsangehoerigkeitText,
          sumInsured: userInput.sumInsured,
          tagDerAbbuchung: userInput.tagDerAbbuchung,
          variante: userInput.variante,
          vorwahl: userInput.vorwahl,
          weight: userInput.weight,
          entitledPersons: userInput.entitledPersons,
          patientConfidentialityRelease: userInput.patientConfidentialityRelease,
          patientConfidentialityReleaseAfterDeath:
            userInput.patientConfidentialityReleaseAfterDeath,
          insuranceEndDate: userInput.insuranceEndDate,
          loanSum: userInput.loanSum,
          loanPeriodInYears: userInput.loanPeriodInYears,
          loanRate: userInput.loanRate,
          basisSum: userInput.basisSum,
          repaymentFreePeriodInYears: userInput.repaymentFreePeriodInYears,
          update: values => updateApp(values),
          oeNumber: userInput.oeNumber,
          additionalTimeWaiting: userInput.additionalTimeWaiting,
          extraExamination: userInput.extraExamination,
          showMeldung: userInput.showMeldung,
          pdfUrlDownloaded: downloadPDFUtils.isPdfDownloaded()
            ? userInput.pdfUrlDownloaded
            : undefined,
        }}
        onError={onError}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
        valueRanges={inputData.valueRanges}
      />
    );
  },
};
