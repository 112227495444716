import { FFlagList, iFeature, FEATURE_FLAG_LIST } from './FeatureFlag.types';

function mergeArraysWithoutDuplicates(array1: any[], array2: any[] = []): any[] {
  const arrayMerge = array1.concat(array2);
  return arrayMerge.filter(
    (obj, index) => index === arrayMerge.findIndex(o => obj.name === o.name),
  );
}

const validateFFlags = (featList: iFeature[]): void => {
  const notFound =
    featList?.filter(feat => !FEATURE_FLAG_LIST?.includes(feat.name?.toString())) || [];
  if (notFound.length > 0) {
    console.log(`Not allowed flags! ${JSON.stringify(notFound)}`);
  }
};

const getFlagsFromQueryString = (): iFeature[] => {
  const parsedQuery: FFlagList[] =
    (window.location.search.substring(1).split('&') as unknown as FFlagList[]) || [];
  const r = parsedQuery
    .filter(p => p.toString().toLocaleLowerCase().startsWith('ff'))
    .map(feature => {
      const parsedValue = feature.toString().split('=');
      return {
        name: FFlagList[parsedValue[0]] ?? parsedValue[0],
        active: JSON.parse(parsedValue[1] || ''),
      };
    });

  return r;
};

const mergeWithUrlParams = (featureList: iFeature[]): iFeature[] =>
  mergeArraysWithoutDuplicates(getFlagsFromQueryString(), featureList);

export const featureFlag = (featureList: iFeature[], isProduction: boolean) => {
  // Merge querystring params with given FF list only in test / dev mode
  const features = isProduction ? featureList : mergeWithUrlParams(featureList);

  if (!isProduction) {
    validateFFlags(features);
  }

  return {
    isActive: (name: FFlagList): boolean =>
      features.find(feat => feat.name === name)?.active || false,
  };
};
