import Button from '@eg/elements/Button';
import { DownloadIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import {
  Beitragsvariante,
  InsuranceSetting,
  InsuranceSettingKey,
  InsuranceSettingsOption,
  mapZahlweise,
  Need,
  Variante,
} from 'rlv-common';
import { Schema } from 'yup';
import AemFragment from '../../components/AemFragment/AemFragment';
import AppLoader from '../../components/AppLoader';
import Footer, { AuxButton } from '../../components/Footer';
import { Headline } from '../../components/Headline/Headline';
import { aemAssets } from '../../configuration/serviceConfiguration';
import { scrollToTop, shouldShowPublicView } from '../../Helper';
import Debouncer from '../../helpers/debouncer';
import {
  getNettoBeitragPerYearForVariant,
  getPaymentPerYear,
} from '../../helpers/getPaymentPerYear';
import { isOnline } from '../../helpers/isOnline';
import { getIsTiedAgent } from '../../helpers/modeConfig';
import { PaymentMethod } from '../../helpers/PaymentMethod';
import { Storage } from '../../helpers/Storage';
import { getBruttoBeitragForVariant, getNettoBeitragForVariant } from '../../helpers/variant.utils';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { updateFeePageData } from '../../services/api';
import { DTMSchutz, DTMSeitenname, DTMTrackingState } from '../../tracking/dtmTracking';
import {
  TrackingBoolean,
  TrackingConversionTypes,
  TrackingElementFeePage,
} from '../../tracking/tracking.types';
import { withTracker } from '../../tracking/withTracker';
import { AemFragmentCatalog } from '../../types/AemFragmentCatalog';
import { SessionStorageKeys } from '../../types/SessionStorage';
import { createAnnuityNeedSchema } from '../../validation/AnnuityNeedBlock';
import { createConstantNeedBlockSchema } from '../../validation/ConstantNeedBlock';
import { createDecreasingNeedSchema } from '../../validation/DecreasingNeedBlock';
import { SmokerServerValues } from '../livingConditions/forms/smokerForm/SmokerForm.types';
import './FeePage.css';
import { BaseFeePageProps, FeePageData, FeePageState, FeePageVariante } from './FeePage.types';
import { headline, paymentIntervalData, trackingTabData } from './FeePageData';
import UpdatingOfferForm from './forms/UpdatingOfferForm';
import RadioTab from './RadioTab/RadioTab';

const enum BeitragType {
  NETTO = 'netto',
  BRUTTO = 'brutto',
}

export class FeePage extends React.Component<BaseFeePageProps, FeePageState> {
  private readonly debouncer: Debouncer = new Debouncer();

  public constructor(props: BaseFeePageProps) {
    super(props);
    this.state = {
      showInitialSpinner: false,
      showUpdateSpinner: false,
      varianten: props.varianten,
      options: props.options,
      updatingOfferError: false,
      valueRanges: props.valueRanges,
    };

    const insuranceSettingsStoreState: InsuranceSetting[] =
      this.props.storeState.additionalInsuranceSettings;
    this.props.valueRanges.insuranceSettings.forEach((element: InsuranceSettingsOption) => {
      const elementIndexTobeUpdated = insuranceSettingsStoreState.find(
        existingElement => existingElement.insuranceSettingKey === element.key,
      );
      if (!elementIndexTobeUpdated) {
        insuranceSettingsStoreState.push({
          insuranceSettingKey: element.key,
          insuranceSettingValue: false,
        } as InsuranceSetting);
      }
    });

    let variante = this.state.varianten.find(
      element => element.variante === this.props.storeState.variante,
    );
    if (!variante) {
      variante = this.state.varianten.find(element => element.selected);
    }
    this.props.storeState.update({
      additionalInsuranceSettings: insuranceSettingsStoreState,
      variante: variante ? variante.variante : Beitragsvariante.KOMFORT,
    });
  }

  public async componentDidMount() {
    scrollToTop();
    this.props.updateDTMTracking({
      ...this.getDTMTrackingValuesFromSelection(
        this.props.storeState.variante || Beitragsvariante.KOMFORT,
      ),
      seitenName: DTMSeitenname.BEITRAGSSEITE,
    });

    this.props.tracker.trackPageLoad({
      conversionType: TrackingConversionTypes.SALE_FUNNEL_CALCULATION,
    });
  }

  public componentWillUnmount() {
    this.setState = () => undefined;
  }

  public render() {
    const nettobeitrag = Storage.readItem(SessionStorageKeys.PRICE_NETTO) || '0';

    const { insuranceEndSum, paymentMethod, periodInYears, sumInsured } = this.props.storeState;

    return (
      <div>
        <AppLoader show={this.state.showUpdateSpinner} viewport="relative">
          <Headline text={headline} />

          <RadioTab
            variant={this.props.storeState.variante}
            sumInsured={this.props.storeState.sumInsured}
            periodInYears={this.props.storeState.periodInYears}
            insuranceTerm={this.props.storeState.need}
            insuranceEndSum={this.props.storeState.insuranceEndSum}
            tariffTypeValues={this.props.valueRanges.tariffType}
            price={nettobeitrag}
            paymentMethod={this.props.storeState.paymentMethod}
            varianten={this.state.varianten}
            trackClick={trackingElement =>
              this.props.tracker.trackClickEvent({
                clickedElement: trackingElement,
              })
            }
            variantCallback={async (variant: Beitragsvariante) => {
              this.props.storeState.update({ variante: variant });
              const trackingVariant = trackingTabData.find(data => data.name === variant);
              if (trackingVariant) {
                this.setState({
                  lastClickedElement: trackingVariant.trackingName,
                  productCombination: trackingVariant.productCombination,
                });
              }
              this.onBlur();
            }}
            smoker={
              this.props.storeState.smoker !== SmokerServerValues.SMOKER &&
              this.props.storeState.smoker !== SmokerServerValues.NON_SMOKER_MORE_THAN_10
            }
            checkboxCallback={async (checked: boolean, version: InsuranceSettingKey) => {
              const updatedInsuranceSettings =
                this.props.storeState.additionalInsuranceSettings.map(setting => {
                  if (setting.insuranceSettingKey === version) {
                    setting.insuranceSettingValue = checked;
                  }
                  return setting;
                });
              this.props.storeState.update({
                additionalInsuranceSettings: updatedInsuranceSettings,
              });
              if (version === InsuranceSettingKey.BEITRAGSDYNAMIK) {
                this.setState({
                  lastClickedElement: TrackingElementFeePage.CHECKBOX_DYNAMIC_PAYMENT,
                });
              } else if (version === InsuranceSettingKey.SICHERHEIT_PLUS) {
                this.setState({
                  lastClickedElement: TrackingElementFeePage.CHECKBOX_SECURITY_PLUS,
                });
              }
              this.onBlur();
            }}
            securityPlusValue={this.props.storeState.additionalInsuranceSettings.find(
              setting => setting.insuranceSettingKey === InsuranceSettingKey.SICHERHEIT_PLUS,
            )}
            dynamicPaymentValue={this.props.storeState.additionalInsuranceSettings.find(
              setting => setting.insuranceSettingKey === InsuranceSettingKey.BEITRAGSDYNAMIK,
            )}
          />

          <div className="fee-page__updating-offer-form">
            <UpdatingOfferForm
              updateOfferValues={{
                paymentMethod: paymentMethod || PaymentMethod.MONATLICH,
                sumInsured: sumInsured || this.props.valueRanges.insuranceSum.min,
                insuranceEndSum: insuranceEndSum || this.props.valueRanges.insuranceEndSum.min,
                periodInYears: periodInYears || this.props.valueRanges.insuranceDuration.min,
              }}
              needSelection={this.props.storeState.need || Need.KONSTANT}
              valueRanges={this.state.valueRanges || this.props.valueRanges}
              onSubmit={async updateOfferValues => {
                await this.updateOffer({ updateOfferValues });
              }}
              trackClick={trackingElement =>
                this.props.tracker.trackClickEvent({
                  clickedElement: trackingElement,
                })
              }
              setErrorCallback={val => this.setState({ updatingOfferError: val })}
            />
          </div>

          {aemAssets && aemAssets[AemFragmentCatalog.PDF_DOWNLOAD] ? (
            <div className="fee-page_download">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={aemAssets[AemFragmentCatalog.PDF_DOWNLOAD]}
                className="fee-page__pdf-download-text"
              >
                <Button
                  variant="text-link"
                  className="download-button"
                  iconLeft={DownloadIcon}
                  onClick={() => {
                    this.props.tracker.trackClickEvent({
                      clickedElement: TrackingElementFeePage.DOWNLOAD_BENEFITS_PDF,
                    });
                  }}
                >
                  Leistungsübersicht als PDF herunterladen
                </Button>
              </a>
            </div>
          ) : (
            <></>
          )}
          <AemFragment
            name={
              shouldShowPublicView(getIsTiedAgent()) && this.props.requestOfferPersonalData
                ? AemFragmentCatalog.STEP12VA2
                : AemFragmentCatalog.STEP12VA
            }
            useVA={true}
          />
          <footer>
            <Footer
              showNext={Storage.readItem('divergingInsuredPerson') === 'false'}
              showPrevious={true}
              labelNext={'Weiter'}
              disableNext={this.state.updatingOfferError}
              handleAction={this.props.handleAction}
              handleNext={() => {
                this.props.tracker.trackClickEvent({
                  clickedElement: TrackingElementFeePage.BUTTON_ONLINE_CONTRACT,
                });
                Storage.writeItem(SessionStorageKeys.IS_ONLINE, 'true');
                this.goToNext();
              }}
              auxButtons={
                <>
                  {!getIsTiedAgent() && (
                    <AuxButton
                      label="Angebot anfordern"
                      buttonId="btn1"
                      key="1"
                      disabled={this.state.updatingOfferError}
                      onClick={() => {
                        // TODO: Add to constants file
                        this.props.tracker.trackClickEvent({
                          clickedElement: TrackingElementFeePage.BUTTON_REQUEST_OFFER,
                        });
                        Storage.removeItem(SessionStorageKeys.DUW_STATUS);
                        Storage.removeItem(SessionStorageKeys.DUW_STATE);
                        Storage.writeItem(SessionStorageKeys.IS_ONLINE, 'false');
                        this.props.handleAction(
                          NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA,
                        );
                      }}
                    />
                  )}
                </>
              }
            />
          </footer>
        </AppLoader>
      </div>
    );
  }

  private validateBeforeContinue(values: FeePageData): FeePageData {
    return {
      ...this.props.storeState,
      ...this.getSchema().cast(values),
    } as FeePageData;
  }

  private readonly goToNext = () => {
    const validatedValues = this.validateBeforeContinue({
      ...this.props.storeState,
    } as FeePageData);
    this.props.storeState.update(validatedValues);

    this.props.handleAction(
      Storage.readItem('divergingInsuredPerson') === 'true'
        ? NavigationAction.DIRECT_JUMP_PERSONAL_DATA
        : NavigationAction.NEXT,
    );
  };

  public onBlur = () => {
    const schema = this.getSchema();
    schema
      .validate(this.props.storeState)
      .then(async validatedInput => {
        await this.updateOffer({
          ...validatedInput,
          ...schema.cast(validatedInput),
        }).then(() => {
          const selectedVariante = this.state.varianten.find(
            variante => variante.variante === this.props.storeState.variante,
          );
          this.props.tracker.updateCalculatedValue(
            getNettoBeitragPerYearForVariant(
              selectedVariante as Variante,
              mapZahlweise(this.props.storeState.paymentMethod),
            ) || 0,
          );
          const paymentInterval = paymentIntervalData.find(
            interval => interval.name === this.props.storeState.paymentMethod,
          );
          if (paymentInterval) {
            this.props.tracker.updateTariffOptions({
              Zahlung: paymentInterval.trackingValue,
            });
          }
          const securityPlusValue = this.props.storeState.additionalInsuranceSettings.find(
            setting => setting.insuranceSettingKey === InsuranceSettingKey.SICHERHEIT_PLUS,
          );
          const dynamicValue = this.props.storeState.additionalInsuranceSettings.find(
            setting => setting.insuranceSettingKey === InsuranceSettingKey.BEITRAGSDYNAMIK,
          );
          this.props.tracker.updateTariffOptions({
            SiPlus:
              securityPlusValue && securityPlusValue.insuranceSettingValue
                ? TrackingBoolean.YES
                : TrackingBoolean.NO,
            Dynamik:
              dynamicValue && dynamicValue.insuranceSettingValue
                ? TrackingBoolean.YES
                : TrackingBoolean.NO,
          });

          if (this.state.productCombination) {
            this.props.tracker.updateProductCombination(this.state.productCombination);
          }
          if (this.state.lastClickedElement) {
            this.props.tracker.trackClickEvent({
              clickedElement: this.state.lastClickedElement,
            });
          }
        });
      })
      // TODO: Remove this catch
      // eslint-disable-next-line
      .catch(() => {
        return;
      });
  };

  private getSchema(): Schema<any> {
    // TODO: klären ob tabelle und PaymentMethod enthalten sein muss
    switch (this.props.storeState.need) {
      case Need.KONSTANT:
        return createConstantNeedBlockSchema(this.props.valueRanges);
      case Need.DECREASING:
        return createDecreasingNeedSchema(this.props.valueRanges);
      case Need.ANNUITY:
        return createAnnuityNeedSchema(this.props.valueRanges, this.props.storeState.loanSum || 0);
      default:
        throw new Error('No validation schema defined');
    }
  }

  private getDTMTrackingValuesFromSelection(
    selection: Beitragsvariante,
    varianten: FeePageVariante[] = this.state.varianten,
  ): Partial<DTMTrackingState> {
    let selectedVariante = varianten.find(variante => variante.variante === selection);
    if (!selectedVariante) {
      selectedVariante = varianten.find(variante => variante.selected);
    }

    if (selectedVariante) {
      switch (selectedVariante.variante) {
        case Beitragsvariante.BASIS:
          return {
            schutz: DTMSchutz.BASIS,
            beitrag: getPaymentPerYear(
              selectedVariante.nettobeitrag,
              mapZahlweise(this.props.storeState.paymentMethod),
            ),
          };
        case Beitragsvariante.KOMFORT:
          return {
            schutz: DTMSchutz.KOMFORT,
            beitrag: getPaymentPerYear(
              selectedVariante.nettobeitrag,
              mapZahlweise(this.props.storeState.paymentMethod),
            ),
          };
        case Beitragsvariante.PREMIUM:
          return {
            schutz: DTMSchutz.PREMIUM,
            beitrag: getPaymentPerYear(
              selectedVariante.nettobeitrag,
              mapZahlweise(this.props.storeState.paymentMethod),
            ),
          };
        default:
          break;
      }
    }
    return {};
  }

  private async updateOffer(userInput: Partial<FeePageData>) {
    if (!userInput) {
      return;
    }

    const { updateOfferValues } = userInput;

    const updatedUserInput = {
      ...this.props.storeState,
      sumInsured: Number(updateOfferValues?.sumInsured) || this.props.storeState.sumInsured,
      periodInYears:
        Number(updateOfferValues?.periodInYears) || this.props.storeState.periodInYears,
      insuranceEndSum:
        Number(updateOfferValues?.insuranceEndSum) || this.props.storeState.insuranceEndSum,
      loanSum: userInput.loanSum || this.props.storeState.loanSum,
      loanPeriodInYears: userInput.loanPeriodInYears || this.props.storeState.loanPeriodInYears,
      loanRate: userInput.loanRate || this.props.storeState.loanRate,
      basisSum: userInput.basisSum || this.props.storeState.basisSum,
      repaymentFreePeriodInYears:
        userInput.repaymentFreePeriodInYears || this.props.storeState.repaymentFreePeriodInYears,
      paymentMethod: updateOfferValues?.paymentMethod || this.props.storeState.paymentMethod,
      additionalInsuranceSettings:
        userInput.additionalInsuranceSettings || this.props.storeState.additionalInsuranceSettings,
    } as FeePageData;

    this.setState({
      showUpdateSpinner: true,
    });
    this.debouncer.debounce(async () => {
      try {
        const angebotResponse = await updateFeePageData(this.props.businessId, updatedUserInput);

        this.setState({ valueRanges: angebotResponse.valueRanges });

        const varianten: FeePageVariante[] = angebotResponse.varianten;
        const variantenInformation = varianten.find(
          element => element.variante === this.props.storeState.variante,
        );

        const getBeitragForVariant = (type: BeitragType): number | undefined => {
          const beitrag: number | undefined =
            type === BeitragType.NETTO
              ? getNettoBeitragForVariant(variantenInformation)
              : getBruttoBeitragForVariant(variantenInformation);
          return variantenInformation ? beitrag : undefined;
        };

        this.props.storeState.update({
          ...updatedUserInput,
          priceNetto: getBeitragForVariant(BeitragType.NETTO),
          priceBrutto: getBeitragForVariant(BeitragType.BRUTTO),
          variante: angebotResponse.varianten.find(
            element => element.variante === this.props.storeState.variante,
          )
            ? updatedUserInput.variante
            : undefined,
        });

        if (varianten) {
          varianten.forEach(variante => {
            const nettoBeitrag = isOnline()
              ? variante.nettoBeitragMitRisikozuschlag || variante.nettobeitragNachZahlweise
              : variante.nettobeitragNachZahlweise;
            const bruttoBeitrag = isOnline()
              ? variante.bruttoBeitragMitRisikozuschlag || variante.bruttobeitragNachZahlweise
              : variante.bruttobeitragNachZahlweise;

            variante.bruttobeitrag = bruttoBeitrag;
            variante.nettobeitrag = nettoBeitrag;
          });
        }
        this.props.updateDTMTracking(
          this.getDTMTrackingValuesFromSelection(this.props.storeState.variante, varianten),
        );
        this.setState({
          showUpdateSpinner: false,
          options: !angebotResponse.valueRanges
            ? []
            : angebotResponse.valueRanges.insuranceSettings,
          varianten,
        });
      } catch (error) {
        if (error instanceof Error) {
          this.props.onError(error);
        } else {
          console.log('Unexpected error', error);
        }
        const varianten = this.state.varianten;
        varianten.forEach(element => {
          element.bruttoBeitragMitRisikozuschlag = undefined;
          element.bruttobeitragNachZahlweise = undefined;
          element.nettoBeitragMitRisikozuschlag = undefined;
          element.nettobeitragNachZahlweise = undefined;
          element.bruttobeitrag = undefined;
          element.nettobeitrag = undefined;
        });

        this.setState({
          showUpdateSpinner: false,
          varianten,
        });
      }
    });
  }
}

export default withTracker(FeePage);
