import * as React from 'react';
import { Storage } from '../../../helpers/Storage';
import { ViewName } from '../../../helpers/ViewName';
import RequestOfferDivergingInsuredPersonPageNew, {
  RequestOfferDivergingInsuredPersonPageData
} from '../../../pages/requestOfferDiverging/RequestOfferDivergingInsuredPersonPage';
import {
  getOfferPageData,
  updateDivergingInsuredPersonPageData
} from '../../../services/api';
import { mapFetchedNeedData } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createRequestOfferDivergingInsuredPersonPageSchema } from '../../../validation/RequestOfferDivergingInsuredPersonPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const MYSELF = 'MYSELF';
export const DIFFERENT_PERSON = 'DIFFERENT_PERSON';

export const requestOfferDivergingInsuredPersonPage: StateDefinition<RequestOfferDivergingInsuredPersonPageData> =
{
  name: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
  percentage: PagePercentage.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
  trackingViewName: ViewName.DIVERGING_INSURED_PERSON,
  createValidationSchema: () => createRequestOfferDivergingInsuredPersonPageSchema(),
  validInboundStates: [
    StateName.NEED_PAGE,
    StateName.LIVING_CONDITIONS_BIRTHDATE
  ],
  transitions: [
    {
      test: (action, data) => {
        data.userInput.need = undefined;
        const backAction =
          action === NavigationAction.BACK ||
          action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.NEED_PAGE
    },
    {
      test: (action, data) => action === NavigationAction.NEXT,
      newState: StateName.LIVING_CONDITIONS_BIRTHDATE
    }
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getOfferPageData(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel)
      }
    };

    return {
      userInput: {
        ...response.requestOfferPersonalDataModel,
        ...response.needSelectionModel,
        divergingInsuredPerson: response.divergingInsuredPerson,
        paymentMethod: response.paymentMethod,
        messages: response.messages
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData
    };
  },
  onExit: async (transitionDetails, inputData) => {
    if (transitionDetails.action === NavigationAction.BACK) {
      return {};
    }
    const response = await updateDivergingInsuredPersonPageData(
      inputData.businessId,
      inputData.userInput
    );
    Storage.writeItem(
      'divergingInsuredPerson',
      String(inputData.userInput.divergingInsuredPerson)
    );

    return {
      payload: {
        userInput: {
          ...response.requestOfferPersonalDataModel,
          divergingInsuredPerson: response.divergingInsuredPerson,
          messages: response.messages
        },
        valueRanges: response.valueRanges
      }
    };
  },
  render: (
    inputData,
    handleAction,
    updateApp,
    onError,
    updateDTMTracking
  ) => (
    <RequestOfferDivergingInsuredPersonPageNew
      storeState={{
        ...inputData.userInput,
        insuredPerson: inputData.userInput.divergingInsuredPerson
          ? DIFFERENT_PERSON
          : MYSELF,
        divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
        birthdateVn: inputData.userInput.vn.birthdate,
        update: values => updateApp(values)
      }}
      onError={onError}
      businessId={inputData.businessId}
      handleAction={handleAction}
      valueRanges={inputData.valueRanges}
      updateDTMTracking={updateDTMTracking}
    />
  )
};
