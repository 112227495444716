export const DUW_MOUNT_POINT = 'root-duw';
export const DUW_RENDER_EVENT = 'duw_render';
export const DUW_UNMOUNT_EVENT = 'duw_unmount';
export const DUW_NEXT_CLICKED_EVENT = 'duw_nextClicked';

export enum DuwNumberingMode {
  HIDE = 'HIDE',
  SKIP = 'SKIP',
  UPDATE = 'UPDATE',
}

export enum DuwValues {
  MAGIC_DUW_VALUE_TARIF_RLV = 'RLV',
  MAGIC_DUW_VALUE_MANDANT_ERGO = 'ERGO',
  MAGIC_OE_VALUE_SMOKER = 'RAUCHER',
}

export enum DuwSituation {
  Worker = 'ARBEITER',
  Employee = 'ANGESTELLTER',
  Official = 'BEAMTER',
  SelfEmployed = 'SELBSTSTAENDIGER',
  Freelancer = 'FREIBERUFLER',
  ExecutiveEmployee = 'LEITEND_ANGESTELLTER',
  Unemployed = 'ARBEITSLOSER',
  Student = 'STUDENT',
  Pensioner = 'RENTNER_PENSIONAER',
  Miscellaneous = 'SONSTIGES',
  Apprentice = 'AUSZUBILDENDER',
  Beneficiary = 'VERSORGUNGSEMPFAENGER',
  IndependentCraftsman = 'SELBSTAENDIGER_HANDWERKER',
}

export enum OESituation {
  Worker = 'ARBEITER',
  Employee = 'ANGESTELLTER',
  Official = 'BEAMTER',
  SelfEmployed = 'SELBSTSTAENDIGER',
  Freelancer = 'SONST_SELBST_FREIBERUFLER',
  ExecutiveEmployee = 'GESELLSCHAFTER_VORSTAND',
  Unemployed = 'ARBEITSLOS',
  Student = 'STUDENT',
  Pensioner = 'RENTNER',
  Miscellaneous = 'SONSTIGE',
  Apprentice = 'AZUBI',
  Beneficiary = 'VERSORGUNGSEMPFAENGER',
  IndependentCraftsman = 'SELBST_HANDWERKER',
}

export const situationMap = new Map<string, DuwSituation>([
  [OESituation.Worker, DuwSituation.Worker],
  [OESituation.Employee, DuwSituation.Employee],
  [OESituation.Official, DuwSituation.Official],
  [OESituation.SelfEmployed, DuwSituation.SelfEmployed],
  [OESituation.Freelancer, DuwSituation.Freelancer],
  [OESituation.ExecutiveEmployee, DuwSituation.ExecutiveEmployee],
  [OESituation.Unemployed, DuwSituation.Unemployed],
  [OESituation.Student, DuwSituation.Student],
  [OESituation.Pensioner, DuwSituation.Pensioner],
  [OESituation.Miscellaneous, DuwSituation.Miscellaneous],
  [OESituation.Apprentice, DuwSituation.Apprentice],
  [OESituation.Beneficiary, DuwSituation.Beneficiary],
  [OESituation.IndependentCraftsman, DuwSituation.IndependentCraftsman],
]);
