import Button, { ButtonProps } from '@eg/elements/Button';
import StepButton from '@eg/elements/StepButton';
import * as React from 'react';

import './AuxButton.css';

const DEFAULT_LABEL = 'Weiter';

export interface AuxButtonProps extends ButtonProps {
  label?: string;
  disabled?: boolean;
  buttonId?: string;
  key: string | number;
  step?: number;
}

const AuxButton = (props: AuxButtonProps) => {
  if (props.step && !props.iconRight) {
    return (
      <StepButton
        type="button"
        onClick={props.onClick}
        disabled={props.disabled === undefined ? false : props.disabled}
        text={props.label || DEFAULT_LABEL}
        step={props.step}
      />
    );
  }

  const renderButtonContent = () => {
    if (props.step) {
      return (
        <span className="ee_step-button__wrapper">
          <span className="ee_step-button__step">{props.step}</span>
          <span className="ee_step-button__text">{props.label}</span>
        </span>
      );
    }
    return props.label;
  };

  return (
    <Button
      data-component-id={props.buttonId}
      type="button"
      variant="secondary"
      size="normal"
      onClick={props.onClick}
      disabled={props.disabled === undefined ? false : props.disabled}
      iconRight={props.iconRight}
      data-step={props.step}
    >
      {renderButtonContent()}
    </Button>
  );
};

export default AuxButton;
