import MessageBox from '@eg/elements/MessageBox';
import * as React from 'react';
import { FeedbackModel, Geschlecht } from 'rlv-common';
import { CONFIG_BACKEND_BASE_URL } from '../config';
import { scrollToTop } from '../Helper';
import { Storage } from '../helpers/Storage';
import { DTMSeitenname } from '../tracking/dtmTracking';
import { InjectedTrackerProps, withTracker } from '../tracking/withTracker';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface RequestOfferFeedbackPageData
  extends FeedbackModel,
    StoreStateUpdater<RequestOfferFeedbackPageData> {
  shouldDownloadPdf: boolean;
  variante: string;
  inceptionDate: string;
  priceNetto: number;
  paymentMethod: string;
}

interface RequestOfferFeedbackPageProps
  extends PagePropsWithValues<RequestOfferFeedbackPageData>,
    InjectedTrackerProps {
  businessId: string;
}

interface RequestOfferFeedbackPageState {}

class RequestOfferFeedbackPage extends React.Component<
  RequestOfferFeedbackPageProps,
  RequestOfferFeedbackPageState
> {
  private readonly downloadLinkAnchor: React.RefObject<HTMLAnchorElement>;

  public constructor(props: RequestOfferFeedbackPageProps) {
    super(props);
    this.isChrome = this.isChrome.bind(this);
    this.downloadLinkAnchor = React.createRef();
  }

  public componentDidMount() {
    scrollToTop();
    Storage.clear();
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.FEEDBACK_ANGEBOT });
    this.props.tracker.trackPageLoad({});
    if (this.downloadLinkAnchor.current) {
      this.downloadLinkAnchor.current.click();
    }
  }

  public render() {
    const input = this.props.storeState;
    return (
      <div className="feedback-page" data-component-id="FeedbackPage">
        <div>
          <h3>Feedback</h3>
          <br />
        </div>
        {this.props.storeState.shouldDownloadPdf && (
          <MessageBox>
            Sie interessieren sich für eine unserer Versicherungen – das freut uns. Jetzt können
            auch Sie sich freuen: über Ihr Angebot für eine richtig gute Absicherung. Bitte schicken
            Sie uns die Seiten eins bis acht unterschrieben und vollständig ausgefüllt zurück, um
            schnellstmöglich Ihren Versicherungsschutz genießen zu können. <br />
            Sie können Ihr Angebot{' '}
            <a
              ref={this.downloadLinkAnchor}
              href={`${CONFIG_BACKEND_BASE_URL}${CONFIG_BACKEND_API_PREFIX}/documents/${this.props.businessId}/vollangebot`}
              data-component-id="offer-download-link"
              target="_blank"
              className="esc_text-link"
              download={this.isChrome()}
            >
              hier herunterladen
            </a>
          </MessageBox>
        )}
        {!this.props.storeState.shouldDownloadPdf && (
          <MessageBox>
            <b>{this.getSalutation(input.vorname, input.nachname, input.geschlecht)}</b>
            <br />
            <br />
            Sie interessieren sich für eine unserer Versicherungen – das freut uns. Jetzt können
            auch Sie sich freuen: über Ihr Angebot für eine richtig gute Absicherung. Darin erfahren
            Sie auch, wie es weitergeht.
            <br />
            Haben Sie ein Angebot per Post angefordert? Dann kann es einige Tage dauern, bis es in
            Ihrem Briefkasten liegt.
          </MessageBox>
        )}
      </div>
    );
  }

  private readonly getSalutation = (vorname: string, nachname: string, geschlecht?: Geschlecht) => {
    switch (geschlecht) {
      case Geschlecht.WEIBLICH:
        return `Sehr geehrte Frau ${vorname} ${nachname},`;
      case Geschlecht.MAENNLICH:
        return `Sehr geehrter Herr ${vorname} ${nachname},`;
      default:
        return `Sehr geehrte/geehrter ${vorname} ${nachname},`;
    }
  };

  private readonly isChrome = (): boolean =>
    // @ts-ignore
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
}

export default withTracker(RequestOfferFeedbackPage);
