import MessageWithAvatar from '@eg/elements/MessageWithAvatar';
import * as React from 'react';
import { getServiceConfiguration } from '../../configuration/serviceConfiguration';
import AssistantImg from '../../images/assistant.png';
import './VirtualAssistant.css';
import { VirtualAssistantProps } from './VirtualAssistant.types';

export const VirtualAssistant = ({ text }: VirtualAssistantProps) => (
  <MessageWithAvatar imgSrc={`${getServiceConfiguration().iconsPath}${AssistantImg}`} text={text} />
);
