import { BeneficiaryDetails, BeneficiaryDetailsFormTexts } from './BeneficiaryDetailsForm.types';

export const FormTexts: BeneficiaryDetailsFormTexts = {
  title: 'Personendaten des Bezugsberechtigten',
  mobileTitle: 'Bezugsberechtigte Person',
  linkCopyAddress: 'Anschrift von versicherter Person übernehmen',
  errorMessage: 'Fehler beim Speichern der bezugsberechtigten Person',
  savePerson: 'Person speichern',
  cancel: 'abbrechen',
};

export const FormLabels: Partial<BeneficiaryDetails> = {
  addresses: 'Anrede',
  name: 'Name',
  birthdate: 'Geburtsdatum',
  address: 'Adresse',
  street: 'Straße',
  streetNumber: 'Hausnummer',
  zip: 'Postleitzahl',
  city: 'Wohnort',
  country: 'Land',
  relationship: 'Verwandtschaftsverhältnis',
  entitlementPercentage: 'Prozentualer Anteil des Bezugsrechts',
};

export const FormPlaceholders: Partial<BeneficiaryDetails> = {
  firstName: 'Vorname',
  surname: 'Nachname',
  street: 'Straße',
  streetNumber: 'Nr.',
  zip: 'PLZ',
  city: 'wird automatisch ermittelt',
  relationship: 'bitte auswählen',
  relationshipFreeText: 'bitte angeben',
  entitlementPercentage: 'z. B. 50 %',
};

export const FormTooltips: Partial<BeneficiaryDetails> = {
  country:
    'Bezugsberechtigte müssen in Deutschland wohnen. Ansonsten bitte an den Außendienstmitarbeiter wenden.',
};

export const FormErrorMessages: Partial<BeneficiaryDetails> = {
  addresses: 'Bitte wählen Sie eine Anrede aus.',
  firstName: 'Bitte geben Sie einen Vornamen ein.',
  surname: 'Bitte geben Sie einen Nachnamen ein.',
  birthdate: 'Bitte geben Sie das Geburtsdatum der bezugsberechtigten Person an.',
  address: 'Bitte geben Sie eine Adresse ein.',
  street: 'Bitte geben Sie eine Straße ein.',
  streetNumber: 'Bitte geben Sie eine Hausnummer ein.',
  zip: 'Bitte geben Sie eine Postleitzahl ein.',
  wrongZip: 'Bitte geben Sie eine gültige Postleitzahl an.',
  relationship: 'Bitte wählen Sie ein Verwandtschaftsverhältnis aus.',
  relationshipFreeText: 'Bitte geben Sie ein Verwandtschaftsverhältnis ein.',
  entitlementPercentage: 'Bitte geben Sie ein prozentuales Bezugsrecht ein.',
  percentageMinMax: 'Das prozentuale Bezugsrecht muss zwischen 1 % und 100 % liegen.',
};
