import { Variante, Zahlweise } from 'rlv-common';

export const getPaymentPerYear = (
  beitrag: number | undefined, paymentMethod?: Zahlweise
): number | undefined => {
  const ZWEISTELLIG: number = 2;

  const MONATLICH_MULTIPLIER = 12;
  const VIERTELJAEHRLICH_MULTIPLIER = 4;
  const HALBJAEHRLICH_MULTIPLIER = 2;
  const JAEHRLICH_MULTIPLIER = 1;

  let paymentPerYear;

  if (beitrag) {
    switch (paymentMethod) {
      case Zahlweise.MONATLICH:
        paymentPerYear = beitrag * MONATLICH_MULTIPLIER;
        break;
      case Zahlweise.VIERTELJAEHRLICH:
        paymentPerYear = beitrag * VIERTELJAEHRLICH_MULTIPLIER;
        break;
      case Zahlweise.HALBJAEHRLICH:
        paymentPerYear = beitrag * HALBJAEHRLICH_MULTIPLIER;
        break;
      case Zahlweise.JAEHRLICH:
        paymentPerYear = beitrag * JAEHRLICH_MULTIPLIER;
        break;
      default:
        paymentPerYear = undefined;
        break;
    }
  }

  return paymentPerYear
    ? Number(paymentPerYear.toFixed(ZWEISTELLIG))
    : undefined;
};

export const getNettoBeitragPerYearForVariant = (selectedVariante: Variante, paymentMethod?: Zahlweise): number | undefined => {
  if (selectedVariante) {
    return selectedVariante.nettoBeitragMitRisikozuschlag ? getPaymentPerYear(
      selectedVariante.nettoBeitragMitRisikozuschlag,
      paymentMethod
    ) : getPaymentPerYear(
      selectedVariante.nettobeitragNachZahlweise,
      paymentMethod
    );
  }

  return undefined;
};
