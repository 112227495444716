import { AlertIcon } from '@eg/elements/components/Icons';
import React, { FC } from 'react';

interface ErrorsListProps {
  errorMessages: string[];
}

export const ErrorsList: FC<ErrorsListProps> = ({ errorMessages }) => (
  <div style={{ color: '#8e0038', fontSize: '14px' }}>
    <AlertIcon width={24} height={24} style={{ position: 'relative', top: '5px' }} /> Bitte beheben
    Sie die Fehler bei der Erfassung der Personendaten:
    <ul>
      {errorMessages.map(error => (
        <li key={error}>{error}</li>
      ))}
    </ul>
    <br />
  </div>
);
