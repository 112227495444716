import { mixed, number as yupNumber, object, Schema, string as yupString } from 'yup';
import {
  Addresses,
  IsoDateString,
  MAX_SIZE_ENTITELMENT_PERCENTAGE,
  MIN_SIZE_ENTITLEMENT_PERCENTAGE,
  Relationship,
} from 'rlv-common';
import { FormErrorMessages } from '../pages/subscriptions/components/BeneficiaryDetails/BeneficiaryDetailsForm/BeneficiaryDetailsForm.data';
import { DATE_ERROR_MESSAGES, isFuture } from './date';
import { CustomMixedSchema as CustomMixedNumberSchema } from './validation';
require('./date.ts'); // required for CustomMixedSchema to work
require('./validation.ts'); // required for CustomMixedNumberSchema to work

export function createBeneficiarySchema(resCity: boolean): Schema<any> {
  return object().shape({
    person: object().shape({
      addresses: yupString()
        .required()
        .oneOf([Addresses.MRS, Addresses.MR], FormErrorMessages.addresses),
      firstName: yupString().trim().required(FormErrorMessages.firstName),
      surname: yupString().trim().required(FormErrorMessages.surname),
      birthdate: (mixed() as any)
        .required(FormErrorMessages.birthdate)
        .isValidIsoDateFormat()
        .test('futureDate', DATE_ERROR_MESSAGES.futureDate, (birthday: IsoDateString) => {
          const today: Date = new Date();
          return isFuture(today, birthday);
        }),
      address: object().shape({
        street: yupString().trim().required(FormErrorMessages.street),
        streetNumber: yupString().trim().required(FormErrorMessages.streetNumber),
        zip: yupString()
          .trim()
          .min(5, FormErrorMessages.wrongZip)
          .required(FormErrorMessages.zip)
          .test('zipMatchWithSomeCity', FormErrorMessages.wrongZip as string, () => resCity),
      }),
    }),
    relationship: yupString().trim().required(FormErrorMessages.relationship),
    relationshipFreeText: yupString()
      .trim()
      .when('relationship', {
        is: value => value === Relationship.OTHER,
        then: yupString().trim().required(FormErrorMessages.relationshipFreeText),
      }),
    entitlementPercentage: (yupNumber() as CustomMixedNumberSchema)
      .validateNumber()
      .required(FormErrorMessages.entitlementPercentage)
      .min(MIN_SIZE_ENTITLEMENT_PERCENTAGE, FormErrorMessages.percentageMinMax)
      .max(MAX_SIZE_ENTITELMENT_PERCENTAGE, FormErrorMessages.percentageMinMax),
  });
}
