export interface Variante {
  bruttobeitragNachZahlweise?: number;
  nettobeitragNachZahlweise?: number;
  bruttoBeitragMitRisikozuschlag?: number;
  nettoBeitragMitRisikozuschlag?: number;
  bruttoRiskSurcharge?: number;
  nettoRiskSurcharge?: number;
  variante: Beitragsvariante;
  selected: boolean;
}

export enum Beitragsvariante {
  BASIS = 'basis',
  KOMFORT = 'komfort',
  PREMIUM = 'premium'
}

export interface AdditionalInsuranceSettings {
  beitragsdynamik?: boolean;
  sicherheitplus?: boolean;
  beitragsuebernahmebu?: boolean;
}
