import * as React from 'react';

import { MapDuwValues } from '../../pages/legalQuestions/forms/Summary/Summary.types';

import {

  removeNumbersFromLabels
} from '../../pages/legalQuestions/forms/Summary/Summary.utils';

import { useDuwAnswers } from '../useDuwAnswers/useDuwAnswers';

export const useDuwAccordionData = (duwToken?: string) => {
  const { duwAnswers, loadingAnswers } = useDuwAnswers(duwToken);

  const duwAccordionData: Array<[string, string]> = React.useMemo<Array<[string, string]>>(() => {
    if (duwAnswers) {
      const duwAnswersWithoutNumbers = duwAnswers.map(({ question, answer }) =>
        ({ question: removeNumbersFromLabels(question), answer })
      );
      return mapHealthIssues(duwAnswersWithoutNumbers);
    }
    return [];
  }, [duwToken, duwAnswers]);

  return {
    duwAccordionData,
    loadingAnswers
  };
};

/**
 * -------------------
 * ##### MAPPERS #####
 * -------------------
 * These mappers functions manage the data that should be displayed in each section of the Summary page.
 * They look pretty similar, and is tempting to simplify them by using a common "mapData" function,
 * but each section have edge cases and we are no sure yet about how we should treat each piece of data on it.
 * So lets wait until business response to simplify this.
 */

// ***** Gesundheitsfragen ( Health Issues - DUW? ) *****
// TODO: Should be tested
const mapHealthIssues: MapDuwValues = (questionsWithAnswers): Array<[string, string]> => {
  const formatQuestions: Array<[string, string]> = questionsWithAnswers.map(({ question, answer }) => [question, answer || '-']);
  return formatQuestions;
};
