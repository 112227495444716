import { Button } from '@eg/elements/Button';
import { CheckColorIcon, DownloadIcon, LoaderIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import { OfferType } from 'rlv-common';
import AemFragment from '../../../components/AemFragment/AemFragment';
import { CONFIG_BACKEND_BASE_URL } from '../../../config';
import { buildPdfs } from '../../../helpers/downloadPDF.utils';
import { TrackingElementAfterSalesFeedback } from '../../../tracking/tracking.types';
import { AemFragmentCatalog } from '../../../types/AemFragmentCatalog';
import AfterSalesFeedbackContent from '../AfterSalesFeedback.data';
import {
  AfterSalesFeedbackContainerProps,
  AfterSalesFeedbackTypes,
} from '../AfterSalesFeedback.types';
import '../AfterSalesFeedbackPage.css';
import TariffInformationComponent from './TariffInformationComponent';

const AfterSalesFeedbackContainer = ({
  vorname,
  nachname,
  variante,
  inceptionDate,
  priceNetto,
  paymentMethod,
  businessId,
  isOnline,
  offerType,
  handleError,
  trackClick,
}: AfterSalesFeedbackContainerProps): React.ReactElement => {
  // eslint-disable-next-line no-null/no-null
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const firstUpdate = React.useRef(true);
  const [isLoadingPdf, setIsLoadingPdf] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState<string | undefined>();

  React.useEffect(() => {
    // Don't want to call the 'downloadLoadedPdf' on initial render but after 'pdfUrl' has been set (https://dev.to/calebbenjin/how-to-prevent-useeffect-from-running-on-initial-render-in-react-22a8)
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    downloadLoadedPdf();
  }, [pdfUrl]);

  const afterSalesFeedbackType = (): AfterSalesFeedbackTypes => {
    if (isOnline === 'false') {
      if (offerType === OfferType.WRITTEN_EMAIL) {
        return AfterSalesFeedbackTypes.OFFER_EMAIL;
      }
      if (offerType === OfferType.DIRECT_ONLINE) {
        return AfterSalesFeedbackTypes.OFFER_PDF;
      }
      return AfterSalesFeedbackTypes.OFFER_POSTAL;
    }

    return AfterSalesFeedbackTypes.ONLINE_APPLICATION;
  };

  const aemFeedbackType = (): AemFragmentCatalog => {
    if (isOnline === 'false') {
      if (offerType === OfferType.WRITTEN_EMAIL) {
        return AemFragmentCatalog.FEEDBACK_EMAIL;
      }
      if (offerType === OfferType.DIRECT_ONLINE) {
        return AemFragmentCatalog.FEEDBACK_PDF;
      }
      return AemFragmentCatalog.FEEDBACK_POST;
    }

    return AemFragmentCatalog.FEEDBACK_ONLINE;
  };

  const downloadLoadedPdf = (): void => {
    linkRef.current!.click();
  };

  const loadAndStoreDocument = async (): Promise<void> => {
    try {
      setIsLoadingPdf(true);

      const dinamicPdfUrl = `${CONFIG_BACKEND_BASE_URL}${CONFIG_BACKEND_API_PREFIX}/documents/${businessId}/vollangebot`;

      // Could be strange using this "merger" for just one document but, as far as we know, this library makes all the "dirty job" just by providing it the "route" to the document.
      // Otherwise we will need to create the new endpoint, transform that response to Blob, create an "objectUrl" and serve it to be handle for our anchor tag
      const blobUrl = await buildPdfs([dinamicPdfUrl]);

      setPdfUrl(blobUrl);
    } catch (error) {
      handleError(error as Error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handlePdf = async (): Promise<void> => {
    trackClick(TrackingElementAfterSalesFeedback.BUTTON_DOWNLOAD_PDF);
    if (linkRef && linkRef.current && pdfUrl) {
      downloadLoadedPdf();
      return;
    }
    await loadAndStoreDocument();
  };

  const iconSize = 40;

  const content = AfterSalesFeedbackContent[afterSalesFeedbackType()];

  return (
    <div className="after-sales-feedback">
      <div className="after-sales-feedback__hero">
        <div className="after-sales-feedback__hero-title">
          <CheckColorIcon width={iconSize} height={iconSize} />
          <br />
          <span>
            {vorname && nachname ? `Vielen Dank, ${vorname} ${nachname}!` : 'Vielen Dank!'}
          </span>
        </div>
        <h3 className="after-sales-feedback__hero-subtitle">{content.header.subtitle}</h3>
      </div>

      <div className="after-sales-feedback__body">
        <p className="after-sales-feedback__paragraph1">{content.body.paragraph1}</p>

        {offerType && offerType === OfferType.DIRECT_ONLINE && (
          <div className="after-sales-feedback__button-wrapper">
            <Button
              data-component-id="after-sales-feedback__download-button"
              type="button"
              variant="secondary"
              size="normal"
              iconLeft={isLoadingPdf ? LoaderIcon : DownloadIcon}
              onClick={handlePdf}
            >
              Ihr persönliches Angebot (PDF)
            </Button>
          </div>
        )}

        <TariffInformationComponent
          variante={variante}
          inceptionDate={inceptionDate}
          priceNetto={priceNetto}
          paymentMethod={paymentMethod}
          cardTitle={content.body.cardTitle}
        />

        <a
          data-component-id="after-sales-feedback__anchor-tag"
          href={pdfUrl || '#'}
          download
          ref={linkRef}
        />
      </div>
      <div className="after-sales-feedback__fragment-container">
        <AemFragment name={aemFeedbackType()} />
      </div>
    </div>
  );
};

export default AfterSalesFeedbackContainer;
