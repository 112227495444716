import Button from '@eg/elements/Button';
import * as React from 'react';
import { FormTexts } from '../BeneficiaryDetailsForm.data';

export interface CustomButtonProps {
  label: string;
  loading: boolean;
  variant: 'disabled' | 'primary' | 'secondary' | 'tertiary' | 'text-link' | undefined;
  onClick: () => void;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ label, loading, variant, onClick }) => (
  <div className="esc_grid">
    <div className="esc_grid__wrapper">
      <div className="esc_col esc_col-8">
        <Button
          type="button"
          variant={variant}
          loading={loading}
          data-component-id={
            label === FormTexts.savePerson
              ? 'edit-entitled-person-save'
              : 'edit-entitled-person-cancel'
          }
          onClick={onClick}
        >
          {label}
        </Button>
      </div>
    </div>
  </div>
);
