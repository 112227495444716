import { Addresses, EntitledPerson } from 'rlv-common';

export const BeneficiarySummaryTexts = {
  header: 'Personendaten des Bezugsberechtigten',
  mobileHeader: 'Bezugsberechtigte Person',
  delete: 'löschen',
  edit: 'bearbeiten',
  add: 'Weitere Person hinzufügen',
  percentage: 'Prozentualer Anteil: ',
};

export const MOCK_ENTITLED_PERSONS: EntitledPerson[] = [
  {
    person: {
      pdeId: 'id-123',
      addresses: Addresses.MR,
      firstName: 'John',
      surname: 'Doe',
      birthdate: '1975-01-14',
      address: {
        street: 'Hauptstrasse',
        streetNumber: '1a',
        zip: '12850',
        city: 'Berlin',
        country: 'Deutschland',
      },
    },
    entitlementPercentage: 60,
    relationship: 'ONKEL',
    relationshipFreeText: 'Onkel',
  },
  {
    person: {
      pdeId: 'id-456',
      addresses: Addresses.MRS,
      firstName: 'Jane',
      surname: 'Doe',
      birthdate: '1999-04-01',
      address: {
        street: 'Philipp-Scheidemann-Str.',
        streetNumber: '29',
        zip: '28340',
        city: 'Bremen',
        country: 'Deutschland',
      },
    },
    entitlementPercentage: 40,
    relationship: 'SONSTIGES_VERHAELTNIS',
    relationshipFreeText: 'other relationship',
  },
];
