import * as React from 'react';
import { aemAssets } from '../configuration/serviceConfiguration';

export enum DTMSeitenname {
  BEDARFSANALYSE = 'rlv_Bedarfsanalyse',
  BEDARFSANALYSE_AUSWAHL = 'rlv_BedarfsanalyseAuswahl',
  BASISDATEN = 'rlv_Basisdaten',
  BASISDATEN_VP_AUSWAHL = 'rlv_BasisdatenVpAuswahl',
  BEITRAGSSEITE = 'rlv_Beitragseite',
  GESUNDHEITSFRAGEN = 'rlv_Gesundheitsfragen',
  PERSONENDATEN_ANGEBOT = 'rlv_PersonendatenAngebot',
  FEEDBACK_ANGEBOT = 'rlv_Feedback',
  PERSONENDATEN = 'rlv_Personendatenerfassung',
  PRUEFEN_UND_SENDEN = 'rlv_PruefenUndSenden',
  FEEDBACK = 'rlv_Feedback',
}

export enum DTMAuswahl {
  LINEAR = 'Linear',
  KONSTANT = 'Konstant',
  ANNUITAETISCH = 'Annuitaetisch',
}

export enum DTMSchutz {
  BASIS = 'Grundschutz',
  KOMFORT = 'Komfort',
  PREMIUM = 'Premium',
}

export enum DTMTarifmodus {
  PDF = 'PDF',
  POST = 'POST',
  EMAIL = 'EMAIL',
  OnlineAbschluss = 'OAB',
}

export interface DTMTrackingState {
  vertriebsprodukt: 'TARIF_RLV';
  seitenName: DTMSeitenname;
  auswahl?: DTMAuswahl;
  beitrag?: number;
  schutz?: DTMSchutz;
  tarifmodus?: DTMTarifmodus;
  orderId?: string;
}

export function updateDTMTrackingState(
  oldState: DTMTrackingState,
  update: Partial<DTMTrackingState>,
): DTMTrackingState {
  return {
    ...oldState,
    ...update,
  };
}

const NUM_DIGITS = 2;

export function renderDTMTrackingElements(trackingState: DTMTrackingState) {
  return (
    <>
      <div className="DataElementVertriebsprodukt" style={{ display: 'none' }}>
        {trackingState.vertriebsprodukt}
      </div>
      <div className="DataElementSeitenName" style={{ display: 'none' }}>
        {resolveSeitenNameFromAEMAssets(trackingState.seitenName)}
      </div>
      {trackingState.auswahl && (
        <div className="DataElementAuswahl" style={{ display: 'none' }}>
          {trackingState.auswahl}
        </div>
      )}
      {trackingState.beitrag && (
        <div className="DataElementBeitrag" style={{ display: 'none' }}>
          {trackingState.beitrag.toFixed(NUM_DIGITS)}
        </div>
      )}
      {trackingState.schutz && (
        <div className="DataElementSchutz" style={{ display: 'none' }}>
          {trackingState.schutz}
        </div>
      )}
      {trackingState.tarifmodus && (
        <div className="DataElementTarifmodus" style={{ display: 'none' }}>
          {trackingState.tarifmodus}
        </div>
      )}
      {trackingState.orderId && (
        <div className="DataElementOrderID" style={{ display: 'none' }}>
          {trackingState.orderId}
        </div>
      )}
    </>
  );
}

function resolveSeitenNameFromAEMAssets(seitenName: DTMSeitenname): string {
  if (aemAssets) {
    switch (seitenName) {
      case DTMSeitenname.BEDARFSANALYSE:
        return aemAssets['dtmTrackingNameBedarfsanalyse'] || seitenName;
      case DTMSeitenname.BEDARFSANALYSE_AUSWAHL:
        return aemAssets['dtmTrackingNameBedarfsanalyseAuswahl'] || seitenName;
      case DTMSeitenname.BASISDATEN:
        return aemAssets['dtmTrackingNameBasisdaten'] || seitenName;
      case DTMSeitenname.BASISDATEN_VP_AUSWAHL:
        return aemAssets['dtmTrackingNameBasisdatenVpAuswahl'] || seitenName;
      case DTMSeitenname.BEITRAGSSEITE:
        return aemAssets['dtmTrackingNameBeitragseite'] || seitenName;
      case DTMSeitenname.GESUNDHEITSFRAGEN:
        return aemAssets['dtmTrackingNameGesundheitsfragen'] || seitenName;
      case DTMSeitenname.PERSONENDATEN_ANGEBOT:
        return aemAssets['dtmTrackingNamePersonendatenAngebot'] || seitenName;
      case DTMSeitenname.FEEDBACK_ANGEBOT:
        return aemAssets['dtmTrackingNameFeedbackAngebot'] || seitenName;
      case DTMSeitenname.PERSONENDATEN:
        return aemAssets['dtmTrackingNamePersonendaten'] || seitenName;
      case DTMSeitenname.PRUEFEN_UND_SENDEN:
        return aemAssets['dtmTrackingNamePruefenUndSenden'] || seitenName;
      case DTMSeitenname.FEEDBACK:
        return aemAssets['dtmTrackingNameFeedback'] || seitenName;
      default:
        break;
    }
  }
  return seitenName;
}
