import * as React from 'react';

import { CaretDownIcon, CaretUpIcon, EditIcon } from '@eg/elements/components/Icons';
import Details from '@eg/elements/Details';

import './Accordion.css';

interface AccordionProps {
  id: string;
  sectionTitle: string;
  isInitiallyOpen?: boolean;
  children: JSX.Element;
  onClickHandler?: () => void;
  trackClick: () => void;
}

export const Accordion = ({
  id,
  sectionTitle,
  isInitiallyOpen = false,
  onClickHandler,
  trackClick,
  children,
}: AccordionProps) => (
  <Details
    summary={<h3 className="accordion__title">{sectionTitle}</h3>}
    isInitiallyOpen={isInitiallyOpen}
    summaryClassName={id}
    onToggledOpen={trackClick}
  >
    {children}
    <button className="accordion__button" type="button" onClick={onClickHandler}>
      <EditIcon />
      <span>Angaben ändern</span>
    </button>
  </Details>
);

interface CommonSectionDetailsProps<T> {
  values: Array<[T, string]>;
}

export const CommonSectionDetails = <T,>({ values }: CommonSectionDetailsProps<T>): JSX.Element => (
  <dl className="accordion__list">
    {values.map((val: [T, string], index: number) => (
      <React.Fragment key={index}>
        <dt className="accordion__element-title">{val[0]}</dt>
        <dd className="accordion__element-description">{val[1]}</dd>
      </React.Fragment>
    ))}
  </dl>
);

interface DuwSectionDetailsProps<T> {
  values?: Array<[T, string]>;
  maxInitialValues?: number;
}

const defaultMaxInitialValues = 2;

export const DuwSectionDetails = <T,>({
  values,
  maxInitialValues = defaultMaxInitialValues,
}: DuwSectionDetailsProps<T>): JSX.Element => {
  const [displayAllData, setDisplayAllData] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    const targetNode = document.getElementsByClassName('healthIssues')[0];
    let observer: MutationObserver;

    if (targetNode) {
      const config = { attributes: true };

      observer = new MutationObserver(() => setDisplayAllData(false));

      observer.observe(targetNode, config);
    }

    return () => observer && observer.disconnect();
  }, []);

  return (
    <dl className="accordion__list accordion__list--duw">
      {values &&
        (displayAllData ? values : values.slice(0, maxInitialValues)).map(
          (val: [T, string], index: number) => (
            <React.Fragment key={index}>
              <dt className="accordion__element-title">{val[0]}</dt>
              <dd className="accordion__element-description accordion__element-description--duw">
                {val[1]}
              </dd>
            </React.Fragment>
          ),
        )}
      <button
        className="duw-section-details__button"
        type="button"
        onClick={() => setDisplayAllData(prevValue => !prevValue)}
      >
        {displayAllData ? (
          <CaretUpIcon className="duw-section-details__icon" />
        ) : (
          <>
            <span>Mehr sehen</span>
            <CaretDownIcon className="duw-section-details__icon" />
          </>
        )}
      </button>
    </dl>
  );
};
