import * as React from 'react';
import { ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import LivingConditionsSwitcher, { LivingConditionsPageData } from '../../../pages/livingConditions/LivingConditionsPageContainer';
import { getAngebot, updateLivingConditionsPageData } from '../../../services/api';
import {
  getTrackingBirthdate,
  mapFetchedNeedData,
  mapFetchedPricingData,
  mapTrackingEmployment,
  mapTrackingInsuredPerson
} from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createLivingConditionsPageSchema } from '../../../validation/LivingConditionsPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const livingConditionSmokerState: StateDefinition<LivingConditionsPageData> = {
  name: StateName.LIVING_CONDITIONS_SMOKER,
  percentage: PagePercentage.LIVING_CONDITIONS_SMOKER,
  trackingViewName: ViewName.BASISDATEN,
  createValidationSchema: (valueRanges: ValueRanges) => createLivingConditionsPageSchema(valueRanges),

  validInboundStates: [
    StateName.LIVING_CONDITIONS_EMPLOYMENT,
    StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT
  ],

  transitions: [
    {
      test: action => {
        const backAction =
            action === NavigationAction.BACK ||
            action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.LIVING_CONDITIONS_EMPLOYMENT
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT
    }
  ],

  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
        ...getTrackingBirthdate(response.livingConditionsModel),
        ...mapTrackingEmployment(response.livingConditionsModel)
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
        ...mapFetchedPricingData(
          response.needSelectionModel,
          response.feeCalculationModel
        )
      }
    };

    return {
      userInput: response.livingConditionsModel,
      valueRanges: response.valueRanges,
      fetchedTrackingData
    };
  },

  onExit: async (_, inputData) => {
    const userInput = inputData.userInput;

    await updateLivingConditionsPageData(inputData.businessId, userInput);
    return {};
  },

  render: (
    inputData,
    handleAction,
    updateApp,
    _unused,
    updateDTMTracking
  ) => {
    const { userInput, valueRanges, businessId } = inputData;
    return (
      <LivingConditionsSwitcher
        storeState={{
          smoker: userInput.smoker,
          update: values => updateApp(values)
        }}
        businessId={businessId}
        valueRanges={valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  }
};
