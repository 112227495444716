import Button, { ButtonProps } from '@eg/elements/Button';
import * as React from 'react';
import { DataIdFooter } from '../../types/DataId';

export interface BackButtonProps extends ButtonProps {
  onClick: () => void;
  label?: string;
}

const BackButton = (props: BackButtonProps) => (
  <Button
    data-component-id={DataIdFooter.BUTTON_BACKBUTTON}
    type="button"
    variant="tertiary"
    size="normal"
    onClick={props.onClick}
  >
    {props.label || 'zurück'}
  </Button>
);

export default BackButton;
