import React, { CSSProperties, FC } from 'react';

interface DataSummaryItemProps {
  label: string;
  extraStyle?: CSSProperties;
  text?: string;
}

export const DataSummaryItem: FC<DataSummaryItemProps> = ({ label, extraStyle, text }) => (
  <div
    className="esc_col esc_col-12 esc_col-s-12 esc_col-m-6"
    style={{ borderTopWidth: '0px', borderBottomWidth: '0px', ...extraStyle }}
  >
    <small>
      <b>{label}</b> {text}
    </small>
  </div>
);
