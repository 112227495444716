
export enum DataIdNeedPage {
  INPUT_RADIO_CONSTANT = 'NeedPage-RadioTile-Constant',
  INPUT_RADIO_DECREASING = 'NeedPage-RadioTile-Decreasing',
  INPUT_RADIO_ANNUITY = 'NeedPage-RadioTile-Annuity'
}

export enum DataIdConstantNeed {
  INPUT_PERIOD_IN_YEARS = 'ConstantNeed-Input-PeriodInYears',
  INPUT_SUM_INSURED = 'ConstantNeed-Input-SumInsured'
}

export enum DataIdDecreasingNeed {
  INPUT_RADIO_CONSTANT = 'NeedSelectionDecreasingPage-RadioTile-Constant',
  INPUT_RADIO_DECREASING = 'NeedSelectionDecreasingPage-RadioTile-Decreasing',
  INPUT_RADIO_ANNUITY = 'NeedSelectionDecreasingPage-RadioTile-Annuity',
  INPUT_PERIOD_IN_YEARS = 'NeedSelectionDecreasingPage-Input-PeriodInYears',
  INPUT_SUM_INSURED = 'NeedSelectionDecreasingPage-Input-SumInsured',
  INPUT_INSURANCE_END_SUM = 'NeedSelectionDecreasingPage-Input-InsuranceEndSum'
}

export enum DataIdAnnuityNeed{
  INPUT_PERIOD_IN_YEARS = 'AnnuityNeed-Input-PeriodInYears',
  INPUT_LOAN_PERIOD_IN_YEARS = 'AnnuityNeed-Input-LoanPeriodInYears',
  INPUT_LOAN_SUM = 'AnnuityNeed-Input-LoanSum',
  INPUT_LOAN_RATE = 'AnnuityNeed-Input-LoanRate',
  INPUT_BASIS_SUM = 'AnnuityNeed-Input-BasisSum',
  INPUT_REPAYMENT_FREE_PERIOD_IN_YEARS = 'AnnuityNeed-Input-RepaymentFreePeriodInYears',
}

export enum DataIdRequestOfferDivergingInsuredPersonPage {
  PAGE = 'RequestOfferDivergingInsuredPersonPage',
  INPUT_BIRTHDATE_VN = 'RequestOfferDivergingInsuredPersonPage-Input-Birthdate-VN'
}

export enum DataIdLivingConditionsPage {
  INPUT_BIRTHDATE = 'LivingConditionsPage-Input-Birthdate',
  INPUT_EMPLOYMENT = 'LivingConditionsPage-Input-Employment',
  INPUT_PROFESSION = 'LivingConditionsPage-Input-Profession',
  INPUT_SMOKER = 'LivingConditionsPage-Input-Smoker',
  INPUT_HEIGHT = 'LivingConditionsPage-Input-Height',
  INPUT_WEIGHT = 'LivingConditionsPage-Input-Weight',
  INPUT_BIKER_YES = 'LivingConditionsPage-Input-Biker-Yes',
  INPUT_BIKER_NO = 'LivingConditionsPage-Input-Biker-No',
  INPUT_PROPERTY_OWNER_YES = 'LivingConditionsPage-Input-Property-Owner-Yes',
  INPUT_PROPERTY_OWNER_NO = 'LivingConditionsPage-Input-Property-Owner-No',
  INPUT_INCEPTION_DATE = 'LivingConditionsPage-Input-Inception-Date',
}

export enum DataIdFooter {
  BUTTON_BACKBUTTON = 'Footer-BackButton',
  BUTTON_NEXTBUTTON = 'Footer-NextButton',
}

export enum DataIdFeePage {
  INPUT_PAYMENT_INTERVAL = 'FeePage-Input-Payment-Interval',
  COMPARISON_TABLE = 'FeePage-ComparisonTable',
  PRICE_VARIANT = 'FeePage-Price-Variant-',
  PRICE_VARIANT_KOMFORT = 'FeePage-Price-Variant-Komfort',
  PRICE_VARIANT_PREMIUM = 'FeePage-Price-Variant-Premium',
  INPUT_RADIO_VARIANT = 'FeePage-Input-Radio-Variant-',
  INPUT_CHECKBOX = 'FeePage-Input-Checkbox-',
}
