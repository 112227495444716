import React from 'react';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { InputType } from '@eg/elements/Input';
import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps } from 'formik';
import { filterNumberInputField, getErrorMessage, limitMaxLength } from '../../../Helper';

import './FieldInputRow.css';

export interface FieldInputRowProps {
  name: string;
  label?: string | undefined;
  className?: string;
  placeholder?: string;
  tooltip?: string | JSX.Element;
  value?: number | string;
  type?: InputType;
  adornmentRight?: string;
  step?: number;
  max?: number;
  min?: number;
  pattern?: string;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: number | string) => void;
  onBlur?: () => void;
}

const FieldInputRow = (props: FieldInputRowProps) => (
  <Field
    name={props.name}
    render={({ field, form }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
      const { setFieldValue, setFieldTouched } = form;
      return (
        <div className={props.className || 'field-input-row__body'}>
          <InputRow
            value={field.value}
            id={field.name}
            name={field.name}
            label={props.label || ''}
            placeholder={props.placeholder}
            tooltip={props.tooltip}
            type={props.type}
            max={props.max}
            min={props.min}
            pattern={props.pattern}
            step={props.step}
            adornmentRight={props.adornmentRight}
            onKeyDown={props.type === 'number' ? filterNumberInputField : undefined}
            onBlur={() => {
              setFieldTouched(field.name);
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const targetValue = props.maxLength
                ? limitMaxLength(event.target.value, props.maxLength)
                : event.target.value;
              setFieldValue(field.name, targetValue);
              if (props.onChange) {
                props.onChange(targetValue);
              }
            }}
            disabled={props.disabled}
            readOnly={props.readOnly}
          />
          <ControlWithHint
            className="field-input-row__body__error"
            error={getErrorMessage(form, field)}
          />
        </div>
      );
    }}
  />
);

export default FieldInputRow;
