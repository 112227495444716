import axios, { AxiosError, AxiosResponse } from 'axios';

import { Tracker } from '../tracking/tracker';
import { handleNetworkErrors } from '../tracking/trackingFormating';

export const createAxiosInterceptor = (): void => {
  axios.interceptors.response.use((res: AxiosResponse) => res, (rej: AxiosError) => {
    handleNetworkErrors(rej, Tracker.getInstance(), window.location.href);
  }
  );
};
