import TooltipIcon from '@eg/elements/TooltipIcon';
import * as React from 'react';
import { PatientConfidentialityRelease } from 'rlv-common';
import { CheckboxType, ConsentToReleaseContent } from './ConsentToReleasePrivateData.types';

export const consentToReleaseContent: ConsentToReleaseContent = {
  titleText: <>Einwilligungs- und Schweigepflichtent&shy;bindungs&shy;erklärungen</>,
  sections: [
    {
      subtitle: (
        <div>
          Bitte wählen Sie aus, in welcher Form Sie die{' '}
          <b>Einwilligungs- und Schweigepflichtentbindungserklärung zur Vertragsdurchführung</b> für
          die Abfrage bei Dritten erteilen wollen.
          <TooltipIcon isModal>
            <h3>Einwilligungs- und Schweigepflichtentbindungserklärung zur Vertragsdurchführung</h3>
            <p>
              Die ERGO Vorsorge Lebensversicherung AG beurteilt vor der Antragsannahme die zu
              versichernden Risiken. Im Rahmen dessen kann es notwendig sein, z. B. bei Ärzten Ihre
              Gesundheitsdaten abzufragen.
            </p>
            <p>
              Zur Prüfung der Leistungspflicht kann es zudem notwendig sein, Angaben zu Ihrem
              Gesundheitszustand zu prüfen, die Sie selbst gemacht haben oder die sich aus weiteren
              Unterlagen ergeben.
            </p>
            <p>
              Diese Überprüfung erfolgt nur, soweit es zur Erfüllung der Pflichten des Versicherers
              erforderlich ist. Dafür benötigt die ERGO Vorsorge Lebensversicherung AG Ihre
              Einwilligung zur Erhebung und Verwendung von Daten einschließlich einer
              Schweigepflichtentbindung. Eine solche ist auch für die entsprechenden Stellen
              erforderlich, falls im Rahmen der Abfragen Gesundheitsdaten oder weitere nach § 203
              StGB (Strafgesetzbuch) geschützte Informationen weitergegeben werden müssen.
            </p>
            <p>
              Es steht Ihnen frei, die Einwilligung/Schweigepflichtentbindung nicht abzugeben oder
              jederzeit später mit Wirkung für die Zukunft zu widerrufen. Wir weisen jedoch darauf
              hin, dass ohne Verarbeitung von Gesundheitsdaten der Abschluss oder die Durchführung
              des Versicherungsvertrages nicht möglich sein wird.
            </p>
            <h4>Allgemein</h4>
            <p>
              Soweit es für die Risikobeurteilung oder für die Leistungsfallprüfung erforderlich
              ist, darf die ERGO Vorsorge Lebensversicherung AG meine Gesundheitsdaten verwenden.
              Dazu darf sie sie erheben bei Ärzten, Pflegepersonen sowie bei Bediensteten von
              Krankenhäusern, sonstigen Krankenanstalten, Pflegeheimen, Personenversicherern,
              gesetzlichen Krankenkassen, Berufsgenossenschaften und Behörden.
            </p>
            <p>
              Diese befreie ich von ihrer Schweigepflicht, soweit meine Gesundheitsdaten aus
              Untersuchungen, Beratungen, Behandlungen sowie Versicherungsanträgen und -verträgen
              aus einem Zeitraum von bis zu 10 Jahren vor der Antragstellung an die ERGO Vorsorge
              Lebensversicherung AG weitergeleitet werden.
            </p>
            <p>
              Ich bin darüber hinaus damit einverstanden, dass in diesem Zusammenhang - soweit
              erforderlich - meine Gesundheitsdaten durch die ERGO Vorsorge Lebensversicherung AG an
              diese Stellen weitergegeben werden und befreie auch insoweit die für die ERGO Vorsorge
              Lebensversicherung AG tätigen Personen von ihrer Schweigepflicht.
            </p>
            <p>
              Vor jeder Datenerhebung werde ich informiert, von wem und zu welchem Zweck die Daten
              erhoben werden sollen. Außerdem werde ich darauf hingewiesen, dass ich widersprechen
              und die erforderlichen Unterlagen selbst besorgen kann.
            </p>
            <p>
              Soweit sich diese Erklärung auf meine Angaben bei der Antragstellung beziehen, gilt
              sie für einen Zeitraum von 5 Jahren nach Vertragsschluss.
            </p>
            <p>
              Sie gelten bis zu 10 Jahre nach Vertragsschluss, falls sich nach Vertragsschluss für
              die ERGO Vorsorge Lebensversicherung AG konkrete Anhaltspunkte dafür ergeben, dass ich
              bei der Antragstellung vorsätzlich unrichtige oder unvollständige Angaben gemacht
              habe, die die Risikobeurteilung beeinflusst haben.
            </p>
            <h4>Individuell</h4>
            <p>
              Die ERGO Vorsorge Lebensversicherung AG soll mich in jedem Einzelfall informieren, von
              wem sie zu welchem Zweck eine Auskunft benötigt. Ich entscheide dann jeweils, ob ich
            </p>
            <ul>
              <li>
                in die Erhebung und Verwendung meiner Gesundheitsdaten durch die ERGO Vorsorge
                Lebensversicherung AG einwillige, die genannten Personen oder Einrichtungen sowie
                deren Mitarbeiter von ihrer Schweigepflicht entbinde und in die Übermittlung meiner
                Gesundheitsdaten an die ERGO Vorsorge Lebensversicherung AG einwillige.
              </li>
              <li>die erforderlichen Unterlagen selbst beibringe.</li>
            </ul>
            <p>
              Wenn nicht, besorge ich die Daten selbst. Mir ist bewusst, dass das zu einer
              Verzögerung der Antragsbearbeitung führen kann.
            </p>
            <p>
              Soweit sich diese Erklärung auf meine Angaben bei der Antragstellung beziehen, gilt
              sie für einen Zeitraum von 5 Jahren nach Vertragsschluss.
            </p>
            <p>
              Sie gelten bis zu 10 Jahre nach Vertragsschluss, falls sich nach Vertragsschluss für
              die ERGO Vorsorge Lebensversicherung AG konkrete Anhaltspunkte dafür ergeben, dass ich
              bei der Antragstellung vorsätzlich unrichtige oder unvollständige Angaben gemacht
              habe, die die Risikobeurteilung beeinflusst haben.
            </p>
          </TooltipIcon>
        </div>
      ),
      options: {
        type: CheckboxType.RADIO,
        checkboxes: [
          {
            value: PatientConfidentialityRelease.GENERAL,
            content: (
              <span>
                Ich willige in die Erhebung meiner Gesundheitsdaten und deren Weitergabe an Dritte
                ein und gebe <b>einmalig</b> eine
                <b> allgemeine</b> Schweigepflichtentbindungserklärung ab. Ich werde in jedem Fall
                vor jeder Datenerhebung informiert.
              </span>
            ),
          },
          {
            value: PatientConfidentialityRelease.INDIVIDUAL,
            content: (
              <span>
                Ich möchte <b>in jedem Einzelfall individuell</b> entscheiden, ob ich die ERGO
                Vorsorge Lebensversicherung AG zur Erhebung von Gesundheitsdaten bei Dritten
                berechtige oder ob ich die Unterlagen selbst beibringe.
              </span>
            ),
          },
        ],
        errorMessage: 'Bitte bestätigen Sie Ihre Einwilligung.',
      },
      paragraph: (
        <p className="consent-to-release-private-data__section-paragraph">
          Für die Beurteilung der zu versichernden Risiken kann es notwendig sein, während der
          Antragsbearbeitung Informationen von Stellen abzufragen, die über Ihre Gesundheitsdaten
          verfügen. Dafür ist Ihre Einwilligung und Entbindung von der Schweigepflicht erforderlich.
          <br />
          Diese können Sie auch jederzeit wieder ändern.
        </p>
      ),
    },
    {
      subtitle: (
        <div>
          Bitte erteilen Sie ERGO die <b>Schweigepflichtentbindung für den Todesfall</b>:
          <TooltipIcon isModal>
            <h3>Schweigepflichtentbindung für den Todesfall</h3>
            <p>
              Zur Prüfung der Leistungspflicht kann es auch nach Ihrem Tod erforderlich sein,
              gesundheitliche Angaben zu prüfen. Eine Prüfung kann auch erforderlich sein, wenn sich
              bis zu zehn Jahre nach Vertragsschluss für die ERGO Vorsorge Lebensversicherung AG
              konkrete Anhaltspunkte dafür ergeben, dass bei der Anfrage-/Antragstellung unrichtige
              oder unvollständige Angaben gemacht wurden und damit die Risikobeurteilung beeinflusst
              wurde. Auch dafür bedürfen wir einer Einwilligung und Schweigepflichtentbindung.
            </p>
          </TooltipIcon>
        </div>
      ),
      options: {
        type: CheckboxType.CHECKBOX,
        checkboxes: [
          {
            content: (
              <span>
                Für den Fall meines Todes willige ich in die Erhebung meiner Gesundheitsdaten und
                deren Weitergabe an Dritte ein und gebe die <b>allgemeine</b>{' '}
                Schweigepflichtentbindung ab.
              </span>
            ),
            errorMessage: 'Bitte bestätigen Sie Ihre Einwilligung.',
          },
        ],
      },
    },
  ],
};
