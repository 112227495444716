import { TrackingElementAfterSalesFeedback } from '../../tracking/tracking.types';

export enum AfterSalesFeedbackTypes {
  ONLINE_APPLICATION = 'onlineApplication',
  OFFER_POSTAL = 'postal',
  OFFER_EMAIL = 'email',
  OFFER_PDF = 'pdf'
}

interface AfterSalesFeedbackContentStructure {
  header: {
    subtitle: string;
  };
  body: {
    paragraph1: JSX.Element | string;
    button1?: JSX.Element;
    cardTitle: string;
  };
}

export type AfterSalesFeedbackContent = {
  [key in AfterSalesFeedbackTypes]: AfterSalesFeedbackContentStructure;
};

export interface AfterSalesFeedbackContainerProps {
  vorname: string;
  nachname: string;
  variante: string;
  inceptionDate: string;
  priceNetto: number;
  paymentMethod: string;
  businessId: string;
  isOnline: string;
  offerType: string;
  handleError: (e: Error) => void;
  trackClick: (element: TrackingElementAfterSalesFeedback) => void;
}

export interface TariffInformationComponentProps {
  variante: string;
  inceptionDate: string;
  priceNetto: number;
  paymentMethod: string;
  cardTitle: string;
  annotation?: string;
}
