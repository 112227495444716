import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import InfoText from '../../../../components/InfoText/infoText';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioTileValue } from '../../../../components/RadioGroup/RadioTile/RadioTile.types';
import { getServiceConfiguration } from '../../../../configuration/serviceConfiguration';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { withTracker } from '../../../../tracking/withTracker';
import HouseIcon from '../../../../images/HouseGroundIcon.svg';
import NoHouseIcon from '../../../../images/NoHouseGroundIcon.svg';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import {
  PropertyOwnerFormProps,
  PropertyOwnerSelection,
  PropertyOwnerText,
} from './propertyOwner.types';
import './propertyOwnerForm.css';

const PropertyOwnerForm = ({
  propertyOwner,
  onNavigateNoSubmit,
  onSubmit,
  trackClick,
}: PropertyOwnerFormProps): React.ReactElement => {
  const propertyOwnerOptions = [
    {
      title: PropertyOwnerSelection.YES,
      value: PropertyOwnerSelection.YES,
      icon: (
        <img
          className="property-owner__icon"
          src={`${getServiceConfiguration().iconsPath}${HouseIcon}`}
          alt="house owner"
        />
      ),
    },
    {
      title: PropertyOwnerSelection.NO,
      value: PropertyOwnerSelection.NO,
      icon: (
        <img
          className="property-owner__icon"
          src={`${getServiceConfiguration().iconsPath}${NoHouseIcon}`}
          alt="no house owner"
        />
      ),
    },
  ];
  const footerInfoSection = <InfoText textBeforeLink={PropertyOwnerText.footer} />;
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);
  const [selectedOption, setSelectedOption] = React.useState<RadioTileValue>('');

  React.useEffect(() => {
    if (propertyOwner) {
      setSelectedOption(PropertyOwnerSelection.YES);
    }
    if (propertyOwner === false) {
      setSelectedOption(PropertyOwnerSelection.NO);
    }
  }, []);

  return (
    <div className="container">
      <Formik
        initialValues={{ propertyOwner }}
        onSubmit={() => onSubmit(selectedOption === PropertyOwnerSelection.YES, navigationRequest)}
      >
        {form => (
          <Form>
            <Headline text={PropertyOwnerText.title} />
            <Field
              name="propertyOwnerField"
              render={() => (
                <>
                  <RadioGroup
                    options={propertyOwnerOptions}
                    name="propertyOwner"
                    selectedValue={selectedOption}
                    onChangeSelection={(_, value) => {
                      setSelectedOption(String(value));
                      if (value === 'Ja') {
                        trackClick(true);
                      } else if (value === 'Nein') {
                        trackClick(false);
                      }
                    }}
                  />
                  <AemFragment name={AemFragmentCatalog.STEP10VA} useVA={true} />
                  <Footer
                    showNext={true}
                    showPrevious={true}
                    labelNext={'weiter'}
                    labelPrevious={'zurück'}
                    infoSection={footerInfoSection}
                    disableNext={selectedOption === ''}
                    handleAction={(navigationAction: NavigationAction) => {
                      setNavigationRequest(navigationAction);
                      if (navigationAction === NavigationAction.NEXT) {
                        form.submitForm();
                      } else {
                        onNavigateNoSubmit(navigationAction);
                      }
                    }}
                  />
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withTracker(PropertyOwnerForm);
