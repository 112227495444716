import { bool, mixed, object, Schema } from 'yup';
import { IsoDateString } from 'rlv-common';
import { DATE_ERROR_MESSAGES, isDateOlderThan18, isDateYoungerThan75, isFuture } from './date';
require('./date.ts'); // required for CustomMixedSchema to work

export function createRequestOfferDivergingInsuredPersonPageSchema(): Schema<any> {
  return object().shape({
    divergingInsuredPerson: bool().required(
      'Bitte wählen Sie aus, für wen die Versicherung abgeschlossen werden soll.',
    ),
    birthdate: (mixed() as any)
      .required(DATE_ERROR_MESSAGES.required)
      .isValidIsoDateFormat()
      .test('futureDate', DATE_ERROR_MESSAGES.futureDate, (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isFuture(today, birthday);
      })
      .test('olderThan18', DATE_ERROR_MESSAGES.olderThan18, (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isDateOlderThan18(today, birthday);
      })
      .test('youngerThan75', DATE_ERROR_MESSAGES.youngerThan75, (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isDateYoungerThan75(today, birthday);
      }),
  });
}
