export enum Zahlweise {
  MONATLICH = 'MONATLICH',
  VIERTELJAEHRLICH = 'VIERTELJÄHRLICH',
  HALBJAEHRLICH = 'HALBJÄHRLICH',
  JAEHRLICH = 'JÄHRLICH',

  EINMALZAHLUNG = 'EINMALZAHLUNG',
  SAISONBEITRAG = 'SAISONBEITRAG'
}

export const mapZahlweise = (zahlweise: string): Zahlweise | undefined => {
  switch (zahlweise) {
    case 'VIERTELJAEHRLICH':
      return Zahlweise.VIERTELJAEHRLICH;
    case 'HALBJAEHRLICH':
      return Zahlweise.HALBJAEHRLICH;
    case 'JAEHRLICH':
      return Zahlweise.JAEHRLICH;
    case 'MONATLICH':
      return Zahlweise.MONATLICH;
    case 'EINMALZAHLUNG':
      return Zahlweise.EINMALZAHLUNG;
    case 'SAISONBEITRAG':
      return Zahlweise.SAISONBEITRAG;
    default:
      return undefined;
  }
};
