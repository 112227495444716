import * as React from 'react';

import { isOnline } from '../../../helpers/isOnline';
import { ViewName } from '../../../helpers/ViewName';
import SubscriptionsPageContainer from '../../../pages/subscriptions/SubscriptionsPageContainer';
import { getAngebot, updatePersonalPageData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

import { SubscriptionsPageContainerData } from '../../../pages/subscriptions/SubscriptionsPageContainer.types';

export const subscriptionsRoute: StateDefinition<SubscriptionsPageContainerData> = {
  name: StateName.SUBSCRIPTIONS,
  percentage: PagePercentage.SUBSCRIPTIONS,
  trackingViewName: ViewName.SUBSCRIPTIONS,
  validInboundStates: [
    StateName.PERSONAL_DATA_PAGE,
    StateName.REQUEST_OFFER_PAGE,
    StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],
  transitions: [
    {
      test: action =>
        action === (NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) &&
        isOnline(),
      newState: StateName.PERSONAL_DATA_PAGE,
    },
    {
      test: action =>
        action === (NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) &&
        !isOnline(),
      newState: StateName.REQUEST_OFFER_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_SUMMARY,
      newState: StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
    },
    {
      test: action =>
        action === NavigationAction.NEXT || action === NavigationAction.NAVIGATE_NO_UPDATE,
      newState: StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE,
    },
  ],

  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};

    return {
      userInput: {
        ...response.personalDataModel,
      },
      fetchedTrackingData,
      valueRanges: response.valueRanges,
    };
  },
  onExit: async (navigationInfo, inputData) => {
    if (navigationInfo.action === NavigationAction.NAVIGATE_NO_UPDATE) {
      return {};
    }

    await updatePersonalPageData(inputData.businessId, inputData.userInput);
    return {};
  },

  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { businessId, userInput, valueRanges } = inputData;

    return (
      <SubscriptionsPageContainer
        handleAction={handleAction}
        businessId={businessId}
        storeState={{
          iban: userInput.iban,
          bic: userInput.bic,
          pdeId: userInput.pdeId,
          adresse: userInput.adresse,
          entitledPersons: userInput.entitledPersons,
          update: (values, callback) => updateApp(values, callback),
        }}
        updateDTMTracking={updateDTMTracking}
        valueRanges={valueRanges}
      />
    );
  },
};
