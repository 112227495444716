export const FEATURE_FLAG_LIST = ['FFIsExampleFeature'];

export enum FFlagList {
  FFIsExampleFeature,
}

export interface FeatureFlagProps {
  renderOn: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  renderOff: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  active?: boolean;
  name: FFlagList;
}

export interface iFeature {
  name: FFlagList;
  active: boolean;
}
