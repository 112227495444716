import { ValueRanges } from 'rlv-common';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';

export interface InsuranceStartDateFormProps extends SharedSubmitCallBack<string> {
  insuranceStartDate?: string;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: string, navigationRequest: NavigationAction) => void;
  valueRanges: ValueRanges;
  trackClick: (date: string, position: number) => void;
}

export enum InsuranceStartDateText {
  title = 'Wann soll Ihre Versicherung beginnen?',
}
