import * as React from 'react';
import { ViewName } from '../../../helpers/ViewName';
import LegalQuestionsPageContainer, {
  CheckOutPageData
} from '../../../pages/legalQuestions/legalQuestionsPageContainer';
import { getAngebot, updateCheckOutPageData } from '../../../services/api';
import { fetchTrackingDataAfterPersonalDataPage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const legalQuestionsConsentToDataProcessing: StateDefinition<CheckOutPageData> =
  {
    name: StateName.LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE,
    percentage: PagePercentage.LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE,
    trackingViewName: ViewName.PRUEFENUNDSENDEN,
    validInboundStates: [
      StateName.LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE,
      StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE
    ],
    transitions: [
      {
        test: action =>
          action === NavigationAction.BACK ||
          action === NavigationAction.BROWSER_BACK,
        newState: StateName.LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE
      },
      {
        test: action => action === NavigationAction.NEXT,
        newState:
          StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE
      }
    ],
    onEnter: async (_, inputData) => {
      const response = await getAngebot(inputData.businessId);

      const fetchedTrackingData =
        fetchTrackingDataAfterPersonalDataPage(response);

      return {
        userInput: {
          ...response.checkoutModel,
          showMeldung: false
        },
        valueRanges: response.valueRanges,
        varianten: response.varianten,
        fetchedTrackingData
      };
      return {};
    },
    onExit: async (_, inputData) => {
      await updateCheckOutPageData(inputData.businessId, inputData.userInput);

      return {};
    },
    render: (
      inputData,
      handleAction,
      updateApp,
      onError,
      updateDTMTracking
    ) => {
      const { userInput, businessId, varianten } = inputData;
      return (
        <LegalQuestionsPageContainer
          businessId={businessId}
          varianten={varianten}
          storeState={{
            additionalInsuranceSettings: userInput.additionalInsuranceSettings,
            paymentMethod: userInput.paymentMethod,
            pdeId: userInput.pdeId,
            role: userInput.role,
            variante: userInput.variante,
            entitledPersons: userInput.entitledPersons,
            patientConfidentialityRelease:
              userInput.patientConfidentialityRelease,
            patientConfidentialityReleaseAfterDeath:
              userInput.patientConfidentialityReleaseAfterDeath,
            loanProtection: userInput.loanProtection,
            update: values => updateApp(values),
            oeNumber: userInput.oeNumber,
            additionalTimeWaiting: userInput.additionalTimeWaiting,
            showMeldung: userInput.showMeldung
          }}
          onError={onError}
          handleAction={handleAction}
          updateDTMTracking={updateDTMTracking}
          valueRanges={inputData.valueRanges}
        />
      );
    }
  };
