import { Button } from '@eg/elements/Button';
import { EditIcon } from '@eg/elements/components/Icons';
import React, { FC, useEffect } from 'react';
import { Geschlecht, Honorific, RequestOfferPerson, mapToGermanDate } from 'rlv-common';
import { scrollToTop } from '../../Helper';
import {
  getAdresse,
  getNationalityForKey,
  getNationalityText,
} from '../../helpers/personalDataPage';
import {
  PersonalPageData,
  SetStateHandlerArg,
} from '../../pages/personalData/PersonalDataPage.types';
import { DataSummaryItem } from './DataSummaryItem';

interface ExtendRequestOfferPerson extends RequestOfferPerson {
  bic?: string;
  iban?: string;
}

interface DataSummaryProps {
  isAddressCorrectedInPds: boolean;
  storeState: PersonalPageData | ExtendRequestOfferPerson;
  renderPDE: () => void;
  setStateHandler: (newState: SetStateHandlerArg, callback?: () => void) => void;
}

export const DataSummary: FC<DataSummaryProps> = ({
  isAddressCorrectedInPds,
  storeState,
  renderPDE,
  setStateHandler,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    adresse: storeAdresse,
    bic,
    birthdate,
    email,
    geburtsort,
    geschlecht,
    iban,
    nachname,
    nationalities,
    rufnummer,
    staatsangehoerigkeit,
    vorname,
    vorwahl,
  } = storeState;
  const address = getAdresse(storeAdresse);
  const nationalityForKey = getNationalityForKey(nationalities, staatsangehoerigkeit);
  const nationalityText = getNationalityText(nationalityForKey, staatsangehoerigkeit);
  const gender =
    geschlecht === Geschlecht.MAENNLICH ? Honorific.MALE_ADULT : Honorific.FEMALE_ADULT;

  const onClickNext = () => {
    setStateHandler(
      {
        vpEditable: true,
      },
      renderPDE,
    );
  };

  return (
    <>
      <p>
        <b>{`${gender} ${vorname} ${nachname}`}</b>
      </p>
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div
            className="esc_col esc_col-12"
            style={{ borderTopWidth: '15px', borderBottomWidth: '0px' }}
          >
            <small>
              <b>Adresse:</b> {address}
            </small>
          </div>
          {isAddressCorrectedInPds && (
            <DataSummaryItem
              label={'Adresse wurde korrigiert.'}
              extraStyle={{ color: '#BF1528' }}
            />
          )}
          <DataSummaryItem
            label={'Geburtsdatum:'}
            text={`${mapToGermanDate(birthdate)}, ${geburtsort}`}
          />
          <DataSummaryItem label={'Staatsangehörigkeit:'} text={`${nationalityText}`} />
          {vorwahl && rufnummer && (
            <DataSummaryItem label={'Telefon:'} text={`${vorwahl} / ${rufnummer}`} />
          )}
          {email && <DataSummaryItem label={'E-Mail-Adresse:'} text={`${email}`} />}
          {iban && <DataSummaryItem label={'IBAN:'} text={`${iban}`} />}
          {bic && <DataSummaryItem label={'BIC:'} text={`${bic}`} />}
          <div
            className="esc_col esc_col-12"
            style={{
              borderTopWidth: '0px',
              borderBottomWidth: '0px',
              textAlign: 'right',
            }}
          >
            <Button variant="text-link" iconLeft={EditIcon} onClick={onClickNext}>
              bearbeiten
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
