import { Beitragsvariante } from 'rlv-common';
import {
  TrackingElementFeePage,
  TrackingPaymentInterval,
  TrackingProductCombination,
} from '../../tracking/tracking.types';

export const headline = 'Wählen Sie Ihren Versicherungsschutz';

export const paymentIntervalData = [
  { name: 'MONATLICH', trackingValue: TrackingPaymentInterval.MONTHLY },
  {
    name: 'VIERTELJAEHRLICH',
    trackingValue: TrackingPaymentInterval.QUARTERLY,
  },
  {
    name: 'HALBJAEHRLICH',
    trackingValue: TrackingPaymentInterval.BIANNUAL,
  },
  { name: 'JAEHRLICH', trackingValue: TrackingPaymentInterval.YEARLY },
];

export const trackingTabData = [
  {
    name: Beitragsvariante.BASIS,
    trackingName: TrackingElementFeePage.RADIOBUTTON_BASIC,
    productCombination: TrackingProductCombination.basic,
  },
  {
    name: Beitragsvariante.KOMFORT,
    trackingName: TrackingElementFeePage.RADIOBUTTON_KOMFORT,
    productCombination: TrackingProductCombination.comfort,
  },
  {
    name: Beitragsvariante.PREMIUM,
    trackingName: TrackingElementFeePage.RADIOBUTTON_PREMIUM,
    productCombination: TrackingProductCombination.premium,
  },
];
