export enum StateName {
  SUBSCRIPTIONS = 'SubscriptionsPage',
  NEED_PAGE = 'NeedPage',
  LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE = 'LegalQuestionsMoneyLaunderingPage',
  LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE = 'LegalQuestionsPurposeLoanProtectionPage',
  LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE = 'LegalQuestionsConsentToDataPage',
  LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE = 'LegalQuestionsConsentToRealeasePrivateDataPage',
  LEGAL_QUESTIONS_SUMMARY_PAGE = 'LegalQuestionsSummaryPage',
  LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK = 'LegalQuestionsConsentToCreditCheckPage',
  LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE = 'LegalQuestionsIDDDownloadPdfPage',
  LIVING_CONDITIONS_PAGE = 'LivingConditionsPage',
  LIVING_CONDITIONS_BIRTHDATE = 'LivingConditionsBirthdatePage',
  LIVING_CONDITIONS_EMPLOYMENT = 'LivingConditionsEmploymentPage',
  LIVING_CONDITIONS_OCCUPATION = 'LivingConditionsOccupationPage',
  LIVING_CONDITIONS_SMOKER = 'LivingConditionsSmokerPage',
  LIVING_CONDITIONS_HEIGHT_WEIGHT = 'LivingConditionsHeight&WeightPage',
  LIVING_CONDITIONS_BIKER = 'LivingConditionsMotorcyclistPage',
  LIVING_CONDITIONS_PROPERTY_OWNER = 'LivingConditionsOwnerHousingPage',
  LIVING_CONDITIONS_INCEPTION_DATE = 'LivingConditionsInceptionDatePage',
  CONSTANT_SUM_INSURED = 'ConstantSumInsuredPage',
  CONSTANT_YEARS_TERM = 'ConstantPeriodInYearsPage',
  DECREASING_SUM_INSURED = 'DecreasingSumInsuredPage',
  DECREASING_YEARS_TERM = 'DecreasingPeriodInYearsPage',
  DECREASING_INSURANCE_END_SUM = 'DecreasingEndSumInsuredPage',
  FEE_BEFORE_DUW_PAGE = 'FeeBeforeDuwPage',
  DUW_DISCLOSURE_PAGE = 'DuwDisclosurePage',
  DUW_QUESTIONNAIRE_PAGE = 'DuwQuestionnairePage',
  DUW_ASSESSMENT_PAGE = 'DuwAssessmentPage',
  PERSONAL_DATA_PAGE = 'PersonalDataPage',
  CHECKOUT_PAGE = 'CheckoutPage',
  FEEDBACK_PAGE = 'FeedbackPage',
  INSURANCE_DOCUMENTS_PAGE = 'InsuranceDocumentsPage',
  INSURANCE_SUMMARY_PAGE = 'InsuranceSummaryPage',
  CONFIDENTIALITY_RELEASE_PAGE = 'ConfidentialityReleasePage',
  REQUEST_OFFER_PAGE = 'RequestOfferPage',
  REQUEST_OFFER_FEEDBACK_PAGE = 'RequestOfferFeedbackPage',
  REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE = 'RequestOfferDivergingInsuredPersonPage',
  REQUEST_OFFER_POLICY_HOLDER_PAGE = 'RequestOfferPolicyHolderPage',
  REQUEST_OFFER_INSURED_PERSON_PAGE = 'RequestOfferInsuredPersonPage',
  REQUEST_OFFER_CHECKOUT_PAGE = 'RequestOfferCheckoutPage',
  POLICY_HOLDER_PAGE = 'PolicyHolderPage',
  INSURED_PERSON_PAGE = 'InsuredPersonPage',
}

export const enum NavigationAction {
  INTERCEPTION_MODAL_OPEN = 'duw_modal_open',
  INTERCEPTION_MODAL_DISMISS = 'duw_modal_dismiss',
  DUW_MODAL_CONFIRM = 'duw_modal_confirm',
  BROWSER_BACK = 'browser_back',
  BROWSER_NEXT = 'browser_next',
  DIRECT_JUMP_DUW = 'directjump_duw',
  DIRECT_JUMP_PDE = 'directjump_pde',
  DIRECT_JUMP_TARIFDATEN = 'directjump_needpage',
  DIRECT_JUMP_REQUEST_OFFER = 'directjump_requestofferpage',
  DIRECT_JUMP_PERSONAL_DATA = 'directjump_personalData',
  DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA = 'directjump_request_offer_personalData',
  DIRECT_JUMP_SUMMARY = 'directjump_summary',
  DIRECT_JUMP_FEE_PAGE_AFTER_DUW = 'directjump_fee_page_after_duw',
  DIRECT_JUMP_FEE_PAGE_BEFORE_DUW = 'directjump_fee_page_before_duw',
  DIRECT_JUMP_RISK_ASSESMENT = 'directjump_risk_assesment',
  DIRECT_JUMP_EMPLOYMENT = 'directjump_living_conditions_employment',
  DIRECT_JUMP_SUBSCRIPTIONS = 'directjump_subscriptions',
  BREADCRUMB_NEEDPAGE = 'breadcrumb_needpage',
  START = 'start',
  NEXT = 'next',
  BACK = 'back',
  NAVIGATE_NO_UPDATE = 'navigate_no_update',
  NEED_CONSTANT = 'need_constant',
  NEED_DECREASING = 'need_decreasing',
  NEED_ANNUITY = 'need_annuity',
  REPEAT_CALL = 'repeat_call',
  OFFER_DIRECT_ONLINE = 'offer_direct_online',
  OFFER_WRITTEN_POSTAL = 'offer_written_postal',
  OFFER_WRITTEN_EMAIL = 'offer_written_email',
}

export const URLState = {
  [StateName.NEED_PAGE]: 'absicherungsbedarf',
  [StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE]: 'versicherte-person',
  [StateName.LIVING_CONDITIONS_BIRTHDATE]: 'geburtsdatum',
  [StateName.CONSTANT_SUM_INSURED]: 'versicherungssumme',
  [StateName.DECREASING_SUM_INSURED]: 'versicherungssumme-start',
  [StateName.DECREASING_INSURANCE_END_SUM]: 'versicherungssumme-ende',
  [StateName.CONSTANT_YEARS_TERM]: 'versicherungslaufzeit',
  [StateName.DECREASING_YEARS_TERM]: 'versicherungslaufzeit',
  [StateName.LIVING_CONDITIONS_EMPLOYMENT]: 'stellung-beruf',
  [StateName.LIVING_CONDITIONS_SMOKER]: 'raucher',
  [StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT]: 'geschlecht-grosse-gewicht',
  [StateName.LIVING_CONDITIONS_PROPERTY_OWNER]: 'wohneigentum',
  [StateName.LIVING_CONDITIONS_INCEPTION_DATE]: 'versicherungsbeginn',
  [StateName.FEE_BEFORE_DUW_PAGE]: 'vorlaufiger-beitrag',
  [StateName.DUW_DISCLOSURE_PAGE]: 'vorvertragliche-anzeigepflicht',
  [StateName.DUW_QUESTIONNAIRE_PAGE]: 'gesundheitsfragen',
  [StateName.LIVING_CONDITIONS_BIKER]: 'motorradfahrer',
  [StateName.DUW_ASSESSMENT_PAGE]: 'votum-risikozuschlag',
  [StateName.PERSONAL_DATA_PAGE]: 'personendaten',
  [StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE]: 'gwg-betreuer',
  [StateName.LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE]: 'einwilligung-datenverarbeitung',
  [StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE]: 'einwilligung-todesfall',
  [StateName.LEGAL_QUESTIONS_SUMMARY_PAGE]: 'zusammenfassung-antrag',
  [StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK]: 'einwilligung-bonitat',
  [StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE]: 'idd-antrag-senden',
  [StateName.FEEDBACK_PAGE]: 'vielen-dank',

  // TODO: Should be expecify by the client
  [StateName.SUBSCRIPTIONS]: 'bezugsrecht',
};
