import * as React from 'react';
import { Need, TextValue, ValueList } from 'rlv-common/types';
import {
  PaymentMethod,
  PaymentMethodText
} from '../../../helpers/PaymentMethod';
import { NeedInputLabels, NeedInputTooltips } from './UpdatingOfferForm.types';

export const paymentMethodOptions: ValueList<TextValue> = {
  defaultValue: {
    key: PaymentMethod.MONATLICH,
    text: PaymentMethodText.MONATLICH
  },
  possibleValues: [
    { key: PaymentMethod.MONATLICH, text: PaymentMethodText.MONATLICH },
    {
      key: PaymentMethod.VIERTELJAEHRLICH,
      text: PaymentMethodText.VIERTELJAEHRLICH
    },
    { key: PaymentMethod.HALBJAEHRLICH, text: PaymentMethodText.HALBJAEHRLICH },
    { key: PaymentMethod.JAEHRLICH, text: PaymentMethodText.JAEHRLICH }
  ]
};

const PAYMENT_METHOD_LABEL = 'Zahlweise';
const PERIOD_IN_YEARS_LABEL = 'Laufzeit';

export const UpdatingOfferInput: {
  [Need.KONSTANT]: NeedInputLabels;
  [Need.DECREASING]: NeedInputLabels;
} = {
  KONSTANT: {
    paymentMethod: { key: 'paymentMethod', text: PAYMENT_METHOD_LABEL },
    sumInsured: { key: 'sumInsured', text: 'Todesfallsumme' },
    periodInYears: { key: 'periodInYears', text: PERIOD_IN_YEARS_LABEL }
  },
  LINEAR_FALLEND: {
    paymentMethod: { key: 'paymentMethod', text: PAYMENT_METHOD_LABEL },
    sumInsured: { key: 'sumInsured', text: 'Todesfallsumme im ersten Jahr' },
    insuranceEndSum: {
      key: 'insuranceEndSum',
      text: 'Todesfallsumme im letzten Jahr'
    },
    periodInYears: { key: 'periodInYears', text: PERIOD_IN_YEARS_LABEL }
  }
};

const PERIOD_IN_YEARS_TOOLTIP = (
  <div>
    <p>
      <b>Wie lang muss die Absicherung laufen?</b>
    </p>
    <p>
      Überlegen Sie, bis zu welchem Alter Ihr Partner und/oder Ihre Kinder ganz
      oder zum Teil auf Ihr Einkommen oder Ihre Arbeit zuhause angewiesen sind,
      bis wann zusätzliche Belastungen wie Kredite laufen und wann die Kinder voraussichtlich finanziell selbstständig sind.
    </p>
  </div>
);

export const UpdatingOfferTooltips: {
  [Need.KONSTANT]: NeedInputTooltips;
  [Need.DECREASING]: NeedInputTooltips;
} = {
  KONSTANT: {
    sumInsured: (
      <div>
        <p>
          <b>Wie hoch muss die Absicherung sein?</b>
        </p>
        <p>
          Überlegen Sie, welche finanziellen Verpflichtungen im Todesfall von
          Ihren Hinterbliebenen getragen werden müssen. Im
          Allgemeinen rechnet man mit dem 3- bis 5-fachen des
          Jahresbruttoeinkommens.
        </p>
        <p>
          <b>Alleinverdiener: </b>Die Summe muss ausreichen, dass die
          Familie den größten Teil des Lebensunterhalts ohne Sie
          bestreiten kann. Witwen- und Waisenrente reichen nicht aus.
        </p>
        <p>
          <b>Zwei Einkommen: </b>Welche Kosten und finanziellen Belastungen muss
          Ihr Partner weiterhin tragen, wenn Ihr Einkommen wegfällt?
        </p>
        <p>
          <b>Care-Arbeit: </b>Übernehmen Sie z. B. Hausarbeit und Kinderbetreuung,
          fallen jahrelang laufende Kosten an, falls Sie sterben.
        </p>
      </div>
    ),
    periodInYears: PERIOD_IN_YEARS_TOOLTIP
  },
  LINEAR_FALLEND: {
    sumInsured: (
      <div>
        <p>
          <b>
            Wie hoch muss die fallende Absicherung für Ihre Angehörigen sein?
          </b>
        </p>
        <p>
          Überlegen Sie, welche finanziellen Verpflichtungen im Todesfall von
          Ihrem Partner und/oder den Kindern heute getragen werden müssen und
          wie hoch der finanzielle Bedarf zum Ende der Laufzeit ist.
        </p>
        <p>
          Im Allgemeinen rechnet man mit dem 3- bis 5-fachen des
          Jahresbruttoeinkommens. Oder Sie sichern die Höhe eines offenen
          Kredits oder Darlehens ab, den Ihre Angehörigen abbezahlen müssen.
        </p>
      </div>
    ),
    periodInYears: PERIOD_IN_YEARS_TOOLTIP
  }
};
