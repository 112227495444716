import { ErrorLogKey } from 'rlv-common';
import { ROOT_DUW, ROOT_PDE } from '../constants';

export const CLASS_NAME = 'LoadingScriptError';
export class LoadingScriptError extends Error {
  public readonly errorKey: ErrorLogKey;
  public constructor(errorKey: ErrorLogKey, message: string) {
    super(message);
    this.errorKey = errorKey;
    this.name = CLASS_NAME;
  }
}

const getErrorLogKey = (scriptTagId: string): ErrorLogKey => {
  const rootMatch = scriptTagId.match(/ecsp-[-a-zA-Z]*\//g);
  let root = '';
  if (rootMatch && rootMatch.length === 1) {
    root = rootMatch[0].replace('/', '');
  }
  switch (root) {
    case ROOT_PDE:
      return ErrorLogKey.LOAD_PDE_SCRIPT_ERROR;
    case ROOT_DUW:
      return ErrorLogKey.LOAD_DUW_SCRIPT_ERROR;
    default:
      return ErrorLogKey.UNKNOWN;
  }
};

export const createScriptError = (error: Event): LoadingScriptError => {
  const scriptTagSrc = (error.target as HTMLScriptElement).src;
  const errorLogKey = getErrorLogKey(scriptTagSrc);
  return new LoadingScriptError(errorLogKey, `can not load script from ${scriptTagSrc}`);
};
