import { FormikContext } from 'formik';
import * as React from 'react';

interface ScrollToErrorProps {
  formik: FormikContext<object>;
}

class ScrollToError extends React.Component<ScrollToErrorProps> {
  public componentDidUpdate(prevProps: ScrollToErrorProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement;
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'center',
        });
      }
    }
  }

  public render() {
    return false;
  }
}

const scrollToErrorByQuery = (query: string) => {
  const errorElement = document.querySelector(query) as HTMLElement;
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  }
};

export { ScrollToError, scrollToErrorByQuery };
