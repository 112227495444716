import { StateDefinition } from '../StateMachine';
import { afterSalesFeedback } from './afterSalesFeedback/afterSalesFeedback';
import { duwAssessment } from './duw/duwAssessment';
import { duwDisclosure } from './duw/duwDisclosure';
import { duwQuestionnaire } from './duw/duwQuestionnaire';
import { feeBeforeDuwPage } from './feeBeforeDUW/feeBeforeDuwPage';
import { legalQuestionsConsentReleasePrivateDataInDeath } from './legalQuestions/legalQuestionsConsentReleasePrivateData';
import { legalQuestionsConsentToCreditCheck } from './legalQuestions/legalQuestionsConsentToCreditCheck';
import { legalQuestionsConsentToDataProcessing } from './legalQuestions/legalQuestionsConsentToDataProcessing';
import { legalQuestionsIddDownloadPdf } from './legalQuestions/legalQuestionsIDDDownload&PDF';
import { legalQuestionsPurposeLoan } from './legalQuestions/legalQuestionsLoanPurposes';
import { legalQuestionsMoneyLaundering } from './legalQuestions/legalQuestionsMoneyLaundering';
import { legalQuestionsSummary } from './legalQuestions/legalQuestionsSummary';
import { livingConditionsBikerState } from './livingConditions/livingConditionsBiker';
import { livingConditionsBirthdateState } from './livingConditions/livingConditionsBirthdate';
import { livingConditionsEmploymentState } from './livingConditions/livingConditionsEmployment';
import { livingConditionsHeightWeightState } from './livingConditions/livingConditionsHeight&Weight';
import { livingConditionsInceptionDateState } from './livingConditions/livingConditionsInceptionDate';
import { livingConditionsPropertyOwnerState } from './livingConditions/livingConditionsPropertyOwner';
import { livingConditionSmokerState } from './livingConditions/livingConditionsSmoker';
import { needPageState } from './needPage/needPage';
import { constantSumInsuredState } from './needs/constantNeeds/constantSumInsured';
import { constantYearsTermState } from './needs/constantNeeds/constantYearsTerm';
import { decreasingInsuranceEndSumState } from './needs/decreasingNeeds/decreasingInsuranceEndSum';
import { decreasingSumInsuredState } from './needs/decreasingNeeds/decreasingSumInsured';
import { decreasingYearsTermState } from './needs/decreasingNeeds/decreasingYearsTerm';
import { personalDataPage } from './personalData/personalDataPage';
import { requestOfferDivergingInsuredPersonPage } from './requestOfferDivergingInsuredPerson/requestOfferDivergingInsuredPersonPage';
import { requestOfferFeedbackPage } from './requestOfferFeedback/requestOfferFeedbackPage';
import { requestOfferPersonalDataPage } from './requestOfferPersonalDataPage';
import { subscriptionsRoute } from './subscriptions/subscriptionsRoute';

export const stateMachineStates: Array<StateDefinition<any>> = [
  constantSumInsuredState,
  constantYearsTermState,
  decreasingInsuranceEndSumState,
  decreasingSumInsuredState,
  decreasingYearsTermState,
  duwAssessment,
  duwDisclosure,
  duwQuestionnaire,
  feeBeforeDuwPage,
  afterSalesFeedback,
  legalQuestionsConsentReleasePrivateDataInDeath,
  legalQuestionsConsentToCreditCheck,
  legalQuestionsConsentToDataProcessing,
  legalQuestionsIddDownloadPdf,
  legalQuestionsMoneyLaundering,
  legalQuestionsSummary,
  legalQuestionsPurposeLoan,
  livingConditionsBikerState,
  livingConditionsBirthdateState,
  livingConditionsEmploymentState,
  livingConditionsHeightWeightState,
  livingConditionsInceptionDateState,
  livingConditionSmokerState,
  livingConditionsPropertyOwnerState,
  needPageState,
  personalDataPage,
  requestOfferDivergingInsuredPersonPage,
  requestOfferFeedbackPage,
  requestOfferPersonalDataPage,
  subscriptionsRoute,
];
