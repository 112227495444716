export const SESSION_STORAGE_OFFER_BACK_STATE = 'RLVOfferBackState';

export class Storage {
  public static writeItem = (key: string, value?: string) => {
    try {
      if (!value) {
        return;
      }

      window.sessionStorage.setItem(key, value);
    } catch (e) {
      return;
    }
  };

  public static removeItem = (key: string) => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (e) {
      return;
    }
  };

  public static readItem = (key: string): string | undefined => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? item : undefined;
    } catch (e) {
      return undefined;
    }
  };

  public static clear = (): void => {
    try {
      window.sessionStorage.clear();
    } catch (e) {
      return;
    }
  };
}
