import * as React from 'react';
import { FeedbackModel, OfferType } from 'rlv-common';
import { scrollToTop } from '../../Helper';
import { Storage } from '../../helpers/Storage';
import { StateName } from '../../routing/StateMachineTypes';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import { TrackingConversionTypes } from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { PagePropsWithoutCallbacks, StoreStateUpdater } from '../../types/PageProps';
import AfterSalesFeedbackContainer from './components/AfterSalesFeedbackContainer';

export interface AfterSalesFeedbackData
  extends FeedbackModel,
    StoreStateUpdater<AfterSalesFeedbackData> {
  geschlecht: any;
  vorname: any;
  nachname: any;
  additionalTimeWaiting: any;
  vnPdsId: any;
  businessId: any;
  isOnline: string;
  offerType: string;
  variante: string;
  inceptionDate: string;
  priceNetto: number;
  paymentMethod: string;
}

interface AfterSalesFeedbackPageProps
  extends PagePropsWithoutCallbacks<AfterSalesFeedbackData>,
    InjectedTrackerProps {
  onError: (e: Error) => void;
}

class AfterSalesFeedbackPage extends React.Component<AfterSalesFeedbackPageProps> {
  public componentDidMount() {
    scrollToTop();
    this.updateTracker();
    Storage.clear();
  }

  public render() {
    return (
      <AfterSalesFeedbackContainer
        trackClick={element => {
          this.props.tracker.trackClickEvent({
            clickedElement: element,
          });
        }}
        {...this.props.storeState}
        handleError={this.props.onError}
      />
    );
  }

  private readonly updateTracker = () => {
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.FEEDBACK });
    switch (this.props.storeState.offerType) {
      case OfferType.DIRECT_ONLINE:
        this.props.tracker.trackPageLoad({
          pageName: StateName.REQUEST_OFFER_FEEDBACK_PAGE,
          conversionType: TrackingConversionTypes.PDF_DOWNLOAD,
        });
        break;
      case OfferType.WRITTEN_EMAIL:
        this.props.tracker.trackPageLoad({
          pageName: StateName.REQUEST_OFFER_FEEDBACK_PAGE,
          conversionType: TrackingConversionTypes.EMAIL,
        });
        break;
      case OfferType.WRITTEN_POSTAL:
        this.props.tracker.trackPageLoad({
          pageName: StateName.REQUEST_OFFER_FEEDBACK_PAGE,
          conversionType: TrackingConversionTypes.POSTAL,
        });
        break;
      default:
        this.props.tracker.trackPageLoad({ conversionType: TrackingConversionTypes.SALE });
        break;
    }
  };
}

export default withTracker(AfterSalesFeedbackPage);
