import * as React from 'react';
import { DecreasingNeedModel } from 'rlv-common';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { NeedSubPageProps } from '../Needs.types';
import { insuranceEndSumValidator } from '../../../validation/DecreasingNeedBlock';
import { SliderForm, SliderFormProps } from '../forms/SliderForm/SliderForm';
import {
  ComponentFieldNames,
  DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT,
  SliderFormValues
} from '../NeedsPageContainer.data';

export const DecreasingEndSumInsuredPage = ({
  valueRanges,
  storeState,
  handleAction,
  tracker
}: NeedSubPageProps) => {
  const SETUP = {
    componentFieldName: ComponentFieldNames.InsuranceEndSum,
    sliderConfig: {
      min: valueRanges.insuranceEndSum.min,
      max: valueRanges.insuranceEndSum.max,
      step: valueRanges.insuranceEndSum.increment,
      text: '€',
      trackerUpdate: (value: number) =>
        tracker.updateTariffOptions({
          VersSumLJ: value
        })
    },
    templateLayout: DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT.insuranceEndSum,
    formValues: {
      insuranceEndSum:
        storeState.insuranceEndSum ||
        SliderFormValues.DefaultInsuranceEndSum
    },
    validationSchema: insuranceEndSumValidator(valueRanges),
    onHandleSliderFormActions: (
      { insuranceEndSum }: { insuranceEndSum: number },
      navigationAction: NavigationAction
    ) => {
      if (navigationAction === NavigationAction.NEXT) {
        storeState.update({ insuranceEndSum });
      }

      handleAction(navigationAction);
    },
    handleNavigateNoSubmitAction: () => {
      handleAction(NavigationAction.BACK);
    }
  };

  // TODO: type aren't well defined to support some optional params not passed here, thus the unknown
  return <SliderForm<DecreasingNeedModel> {...SETUP as unknown as SliderFormProps<DecreasingNeedModel>} />;
};
