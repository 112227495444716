import * as React from 'react';
import { isMobile } from '../../../helpers/mediaMatcher';
import Price from '../../Price/Price';
import { PriceSize } from '../../Price/Price.types';
import { StateHeaderProps } from '../StatusRibbon.types';
import './StateHeader.css';

const StateHeader = ({ amount, subline, payment }: StateHeaderProps) => {
  const title: string = 'ERGO Risikolebensversicherung';

  return (
    <div data-testid="state-header" className={!!amount ? 'state-bar' : 'state-bar-only-title'}>
      <div className={!!amount ? 'state-bar__wrapper' : ''}>
        <div className={!!amount ? 'state-bar__container' : 'state-bar__container-only-title'}>
          <span
            data-testid="state-bar__title"
            className={!!amount ? 'state-bar__title' : 'state-bar__title-only-title'}
          >
            {title}
          </span>
          {!!amount && subline && (
            <span data-testid="state-bar__subline" className="state-bar__subline">
              {subline}
            </span>
          )}
        </div>
        {!!amount && (
          <div className="state-bar__price">
            <Price
              amount={amount}
              paymentMethod={payment}
              size={isMobile() ? PriceSize.Small : PriceSize.Medium}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(StateHeader);
