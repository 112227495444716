import { Button } from '@eg/elements/Button';
import { CloseIcon, EditIcon } from '@eg/elements/components/Icons';
import React, { FC } from 'react';
import { Addresses, EntitledPerson, Honorific } from 'rlv-common';
import { isMobile } from '../../../../../helpers/mediaMatcher';
import { TrackingElementSubscriptions } from '../../../../../tracking/tracking.types';
import './BeneficiaryDetailsSummary.css';
import { BeneficiarySummaryTexts } from './BeneficiaryDetailsSummary.data';
import { BeneficiaryDetailsSummaryProps } from './SubscriptionsDetailsSummary.types';

const getWrongAddressField = (person: EntitledPerson) => {
  if (person.addressValidation && person.addressValidation.isInvalid) {
    if (person.addressValidation.errorHousenumber) {
      return 'Hausnummer';
    }
    if (person.addressValidation.errorStreet) {
      return 'Strasse';
    }
    if (person.addressValidation.errorPostalCity) {
      return 'PLZ / Ort';
    }
  }
  return '';
};

export const BeneficiaryDetailsSummary: FC<BeneficiaryDetailsSummaryProps> = ({
  beneficiaries,
  isTotalPercentageCompleted,
  createNewBeneficiaryCallback,
  editBeneficiaryCallback,
  deleteBeneficiaryCallback,
  trackClickEvent,
}) => {
  const onClickLnkPersonLoeschen = (id: string | undefined) => {
    deleteBeneficiaryCallback(id);
    trackClickEvent(TrackingElementSubscriptions.LNK_PERSON_LOESCHEN);
  };
  const onClickLnkPersonBearbeiten = (id: string | undefined) => {
    editBeneficiaryCallback(id);
    trackClickEvent(TrackingElementSubscriptions.LNK_PERSON_BEARBEITEN);
  };
  return (
    <>
      {beneficiaries.map((el: EntitledPerson) => {
        const title =
          el.person.addresses === Addresses.MR ? Honorific.MALE_ADULT : Honorific.FEMALE_ADULT;
        const id = el.person.pdeId;

        return (
          <div key={id}>
            <hr className="beneficiary__separator" />
            <div className="beneficiary__container">
              <span className="beneficiary__header">
                {isMobile() ? BeneficiarySummaryTexts.mobileHeader : BeneficiarySummaryTexts.header}
              </span>
              <section className="beneficiary__top">
                <div className="beneficiary__personalInfo">
                  <span className="beneficiary__personalInfo--name">{`${title} ${el.person.firstName} ${el.person.surname}`}</span>
                  <span className="beneficiary__personalInfo--birthday">{el.person.birthdate}</span>
                </div>
                <span>{el.relationshipFreeText}</span>
              </section>
              <section className="beneficiary__center">
                <div className="beneficiary__address">
                  <span>{`${el.person.address.street} ${el.person.address.streetNumber}`}</span>
                  <span>{`${el.person.address.zip} ${el.person.address.city}`}</span>
                </div>
                <div className="beneficiary__statistics">
                  <span>{BeneficiarySummaryTexts.percentage}</span>
                  <span className="beneficiary__statistics--percentage">{`${el.entitlementPercentage} %`}</span>
                </div>
              </section>
              <div className="beneficiary__error--container esc_col esc_col-12 esc_col-s-12 esc_col-m-12">
                {el.addressValidation && el.addressValidation.isInvalid && (
                  <div
                    className="beneficiary__error--message"
                    data-component-id="personal-data-address-invalid-message"
                  >
                    <small>
                      {' '}
                      {`Adresse ist ungültig. Bitte korrigieren Sie die ${getWrongAddressField(
                        el,
                      )}`}{' '}
                    </small>
                    <br />
                    <small>
                      {' '}
                      {'Online können nur Adressen innerhalb Deutschlands angegeben werden'}.{' '}
                    </small>
                    <br />
                    <small> {'Sie können die Adresse im Nachhinein ändern lassen.'} </small>
                  </div>
                )}
              </div>
              <section className="beneficiary__buttons">
                <div className="beneficiary__buttons--delete">
                  <Button
                    variant="text-link"
                    iconLeft={CloseIcon}
                    data-component-id="view-entitled-person-delete"
                    loading={false}
                    onClick={() => onClickLnkPersonLoeschen(id!)}
                  >
                    {BeneficiarySummaryTexts.delete}
                  </Button>
                </div>
                <Button
                  variant="text-link"
                  iconLeft={EditIcon}
                  data-component-id="view-entitled-person-edit"
                  loading={false}
                  onClick={() => onClickLnkPersonBearbeiten(id)}
                >
                  {BeneficiarySummaryTexts.edit}
                </Button>
              </section>
            </div>
          </div>
        );
      })}
      <hr className="beneficiary__separator" />
      {!isTotalPercentageCompleted && (
        <section className="beneficiary__addButton">
          <Button
            variant="secondary"
            data-component-id="view-entitled-person-add"
            loading={false}
            onClick={() => {
              createNewBeneficiaryCallback();
              trackClickEvent(TrackingElementSubscriptions.BTN_PERSON_HINZUFUEGEN);
            }}
          >
            {BeneficiarySummaryTexts.add}
          </Button>
        </section>
      )}
    </>
  );
};
