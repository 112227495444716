import { IsoDateString, RequestOfferPersonalDataModel } from './index';

export interface CreateRequest {
  oeNumber?: string; // Kann bei Gelegenheit raus, da auf eine andere Schnittstelle verschoben (add: 30.01.2020)
  aktionsnummer: string;
  quellsystem?: AemQuellsystem;
}

export const BUSINESS_ID_KEY = 'businessId';
export const OE_NUMBER_KEY = 'oeNumber';
export const OFFER_TYPE = 'offerType';
export const PDEID = 'pdeId';

export interface OrderRequest {
  [BUSINESS_ID_KEY]: string;
  [OFFER_TYPE]?: OfferType;
  [OE_NUMBER_KEY]?: string;
  [PDEID]?: string;
}

export interface AgencyRequest {
  [BUSINESS_ID_KEY]: string;
  [OE_NUMBER_KEY]: string;
}

export interface UpdateRequest {
  businessId: string;
  oeNumber?: string;
  need?: string;
  birthdate?: IsoDateString;
  professionLabel?: string;
  employment?: string;
  professionKey?: number;
  smoker?: string;
  height?: number;
  weight?: number;
  biker?: boolean;
  propertyOwner?: boolean;
  inceptionDate?: IsoDateString;
  persons?: PersonRolle[];
  iban?: string;
  bic?: string;
  paymentMethod?: string;
  sumInsured?: number;
  periodInYears?: number;
  insuranceEndSum?: number;
  loanProtection?: boolean;
  loanPeriodInYears?: number;
  loanRate?: number;
  loanSum?: number;
  basisSum?: number;
  repaymentFreePeriodInYears?: number;
  variante?: string;
  additionalInsuranceSettings?: InsuranceSetting[];
  duwToken?: string;
  patientConfidentialityRelease?: PatientConfidentialityRelease;
  patientConfidentialityReleaseAfterDeath?: boolean;
  isOnline?: boolean;
}

export interface UpdateDivergingInsuredPersonalDataRequest {
  businessId: string;
  divergingInsuredPerson: boolean;
  requestOfferPersonalDataModel: RequestOfferPersonalDataModel;
}

export interface PDERequest {
  businessId: string;
  iban?: string;
  bic?: string;
  persons: PersonRolle[];
  entitledPersons?: EntitledPerson[];
}

export interface EntitledPerson {
  person: Person;
  entitlementPercentage: number;
  relationship: string;
  relationshipFreeText?: string;
  addressValidation?: AddressValidationResults;
}

export interface Person {
  pdeId?: string;
  addresses: Addresses;
  firstName: string;
  surname: string;
  birthdate: IsoDateString;
  address: Address;
}

export enum Addresses {
  MRS = '0',
  MR = '1',
}

export enum OfferType {
  DIRECT_ONLINE = 'online',
  WRITTEN_POSTAL = 'post',
  WRITTEN_EMAIL = 'email',
}

export enum AemQuellsystem {
  ERGO_AGENTHOMEPAGE = 'ERGO_VERMITTLERHOMEPAGE',
  ERGO_INTERNET = 'ERGO_INTERNET',
}

export interface Address {
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  country: string;
}

export interface InsuranceSetting {
  insuranceSettingKey: InsuranceSettingKey;
  insuranceSettingValue: boolean;
}

export interface PersonRolle {
  pdeId: string | null;
  rollentyp: PersonRollenTyp;
}

export enum PersonRollenTyp {
  VERSICHERUNGSNEHMER = 'VERSICHERUNGSNEHMER',
  VERSICHERTE_PERSON = 'VERSICHERTE_PERSON',
}

export enum InsuranceSettingKey {
  BEITRAGSDYNAMIK = 'beitragsdynamik',
  SICHERHEIT_PLUS = 'sicherheitplus',
}

export enum PatientConfidentialityRelease {
  GENERAL = 'PAUSCHAL',
  INDIVIDUAL = 'EINZELFALL',
}

export enum ErrorLogKey {
  UNKNOWN = 'UNKNOWN',
  LOAD_PDE_SCRIPT_ERROR = 'LOAD_PDE_SCRIPT_ERROR',
  LOAD_DUW_SCRIPT_ERROR = 'LOAD_DUW_SCRIPT_ERROR',
  LOAD_CHECKOUT_SCRIPT_ERROR = 'LOAD_CHECKOUT_SCRIPT_ERROR',
  CHECKOUT_PAGE_ERROR = 'CHECKOUT_PAGE_ERROR',
  PDE_PAGE_ERROR = 'PDE_PAGE_ERROR',
  DUW_PAGE_ERROR = 'DUW_PAGE_ERROR',
  NO_PRICES = 'NO_PRICES',
}

export const ERROR_LOG_KEY = 'errorLogKey';
export const MESSAGE_KEY = 'message';
export const MESSAGE_MAX_STRING_SIZE = 2000;

export interface ErrorRequest {
  [ERROR_LOG_KEY]: ErrorLogKey;
  [MESSAGE_KEY]?: string;
}

export interface AddressValidationResults {
  isCorrectedAddress?: boolean;
  isInvalid?: boolean;
  errorStreet?: boolean;
  errorHousenumber?: boolean;
  errorPostalCity?: boolean;
}
