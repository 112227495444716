import * as React from 'react';
import { AngebotResponse, Need, ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import LivingConditionsSwitcher, {
  LivingConditionsPageData,
} from '../../../pages/livingConditions/LivingConditionsPageContainer';
import { getAngebot, getProfessions, updateLivingConditionsPageData } from '../../../services/api';
import {
  getTrackingBirthdate,
  mapFetchedNeedData,
  mapFetchedPricingData,
  mapTrackingInsuredPerson,
} from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createLivingConditionsPageSchema } from '../../../validation/LivingConditionsPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const livingConditionsEmploymentState: StateDefinition<LivingConditionsPageData> = {
  name: StateName.LIVING_CONDITIONS_EMPLOYMENT,
  percentage: PagePercentage.LIVING_CONDITIONS_EMPLOYMENT,
  trackingViewName: ViewName.BASISDATEN,
  createValidationSchema: (valueRanges: ValueRanges) =>
    createLivingConditionsPageSchema(valueRanges),

  validInboundStates: [
    StateName.CONSTANT_YEARS_TERM,
    StateName.DECREASING_INSURANCE_END_SUM,
    StateName.LIVING_CONDITIONS_SMOKER,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],

  transitions: [
    {
      test: (action, data) => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction && data.userInput.need === Need.KONSTANT;
      },
      newState: StateName.CONSTANT_YEARS_TERM,
    },
    {
      test: (action, data) => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction && data.userInput.need === Need.DECREASING;
      },
      newState: StateName.DECREASING_INSURANCE_END_SUM,
    },
    {
      test: (action, data) =>
        // TODO, remove comments in here when app is handling real data
        // const isValid = createLivingConditionsPageSchema(data.valueRanges).isValidSync(data.userInput);
        action === NavigationAction.NEXT,
      newState: StateName.LIVING_CONDITIONS_SMOKER,
    },
  ],

  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
        ...getTrackingBirthdate(response.livingConditionsModel),
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
        ...mapFetchedPricingData(response.needSelectionModel, response.feeCalculationModel),
      },
    };

    return {
      userInput: {
        ...response.needSelectionModel,
        ...response.livingConditionsModel,
        professionKey: response.livingConditionsModel!.professionKey
          ? response.livingConditionsModel!.professionKey
          : undefined,
        professionLabel: response.livingConditionsModel!.professionLabel
          ? response.livingConditionsModel!.professionLabel
          : '',
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },

  onExit: async (transitionDetails, inputData) => {
    if (transitionDetails.action === NavigationAction.BACK) {
      return {};
    }
    const userInput = inputData.userInput;

    const response: AngebotResponse = await updateLivingConditionsPageData(
      inputData.businessId,
      userInput,
    );
    return {
      payload: { insurancePeriodAdjusted: response.insurancePeriodAdjusted },
    };
  },

  render: (inputData, handleAction, updateApp, _unused, updateDTMTracking) => {
    const { userInput, valueRanges, businessId } = inputData;
    return (
      <LivingConditionsSwitcher
        getProfessions={getProfessions}
        storeState={{
          employment: userInput.employment,
          professionLabel: userInput.professionLabel,
          professionKey: userInput.professionKey,
          update: values => updateApp(values),
        }}
        businessId={businessId}
        valueRanges={valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
