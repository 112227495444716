
export enum PagePercentage {
  DUW_QUESTIONAIRE = 34.15,
  DUW_ASSESSMENT = 78.05,

  // TARIFF OPTIONS (11 [constant flow] - 12 [decreasing flow] pages)
  NEED_PAGE = 0,
  REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE = 2.44,
  LIVING_CONDITIONS_BIRTHDATE = 4.88,
  DECREASING_SUM_INSURED = 7.32,
  DECREASING_YEARS_TERM = 9.76,
  DECREASING_INSURANCE_END_SUM = 12.2,
  LIVING_CONDITIONS_EMPLOYMENT = 14.63,
  LIVING_CONDITIONS_SMOKER = 17.07,
  LIVING_CONDITIONS_HEIGHT_WEIGHT = 19.51,
  LIVING_CONDITIONS_BIKER = 21.95,
  LIVING_CONDITIONS_PROPERTY_OWNER = 24.39,
  LIVING_CONDITIONS_INCEPTION_DATE = 26.83,

  CONSTANT_SUM_INSURED = 8.54,
  CONSTANT_YEARS_TERM = 10.98,

  // CONTRIBUTIONS (2 pages)
  FEE_BEFORE_DUW_PAGE = 29.27,
  DUW_DISCLOSURE_PAGE = 31.71,

  // DUW (17 pages)
  DUW_QUESTIONNAIRE_PAGE = 34.15,

  // RISK SURCHARGES (1 page)
  DUW_ASSESSMENT_PAGE = 78.05,

  // PDS (1 page)
  PERSONAL_DATA_PAGE = 80.49,

  // SUBSCRIPTIONS (1 page)
  SUBSCRIPTIONS = 82.93,

  // LEGAL QUESIONS (7 pages)
  LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE = 85.37,
  LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE = 87.8,
  LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE = 90.24,
  LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE = 92.68,
  LEGAL_QUESTIONS_SUMMARY_PAGE = 95.12,
  LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK = 97.56,
  LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE = 100,
}
