import { TextValue, Variante } from 'rlv-common';
import { isOnline } from '../helpers/isOnline';

export const getNettoBeitragForVariant = (selectedVariant?: Variante) => {
  if (selectedVariant) {
    return isOnline()
      ? selectedVariant.nettoBeitragMitRisikozuschlag || selectedVariant.nettobeitragNachZahlweise
      : selectedVariant.nettobeitragNachZahlweise;
  }
  return undefined;
};

export const getBruttoBeitragForVariant = (selectedVariant?: Variante) => {
  if (selectedVariant) {
    return isOnline()
      ? selectedVariant.bruttoBeitragMitRisikozuschlag || selectedVariant.bruttobeitragNachZahlweise
      : selectedVariant.bruttobeitragNachZahlweise;
  }
  return undefined;
};

export const getVarianteFromPossibleValues = (
  keyValue: string,
  possibleValues?: TextValue[],
): string => {
  if (!possibleValues) {
    /**
     * In case of no values come from the server in the ValueRange object, we will throw an error
     */
    const error = 'No variant received';
    console.error(error);
    throw new Error(error);
  }

  const tariffType = possibleValues.find(
    possibleValue => possibleValue.key.toLowerCase() === keyValue.toLowerCase(),
  );

  if (!tariffType) {
    return 'NO_TARIF';
  }

  return tariffType.text;
};
