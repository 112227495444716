import { DateInputValue } from '@eg/elements/DateInput';
import { differenceInYears } from 'date-fns';
import { IsoDateString, isValidIsoDateFormat, mapDateInputToIsoDateString } from 'rlv-common';
import { addMethod, mixed, MixedSchema } from 'yup';

export interface CustomMixedSchema extends MixedSchema {
  mapDateInputToIsoDateString(): this;

  isValidIsoDateFormat(message?: string): this;
}

export const DATE_ERROR_MESSAGES = {
  format: 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ an.',
  required: 'Bitte geben Sie das Geburtsdatum der zu versichernden Person an.',
  olderThan18: 'Sie müssen bei Vertragsabschluss mindestens 18 Jahre alt sein.',
  youngerThan75: 'Wir können Ihnen diese Versicherung derzeit online nicht anbieten.',
  futureDate: 'Bitte geben Sie ein gültiges Datum ein.',
};

addMethod(mixed, 'mapDateInputToIsoDateString', function () {
  return this.transform(mapDateInputToIsoDateString);
});

addMethod(mixed, 'isValidIsoDateFormat', function (message?: string) {
  return this.test({
    name: 'isValidIsoDateFormat',
    exclusive: true,
    message: message || DATE_ERROR_MESSAGES.format,
    test: isValidIsoDateFormat,
  });
});

addMethod(mixed, 'isDateOlderThan18', function (referenceDate: IsoDateString, message: string) {
  return this.test({
    name: 'isDateOlderThan18',
    exclusive: true,
    message,
    test: (value: IsoDateString) => isDateOlderThan18(new Date(), value),
  });
});

addMethod(mixed, 'isDateYoungerThan75', function (referenceDate: IsoDateString, message: string) {
  return this.test({
    name: 'isDateYoungerThan75',
    exclusive: true,
    message,
    test: (value: IsoDateString) => isDateYoungerThan75(new Date(), value),
  });
});

addMethod(mixed, 'isFutureDate', function (referenceDate: IsoDateString, message: string) {
  return this.test({
    name: 'isFutureDate',
    exclusive: true,
    message,
    test: (value: IsoDateString) => isFuture(new Date(), value),
  });
});

export function isDateOlderThan18(today: Date, birthday: IsoDateString): boolean {
  return evaluateBirthday(today, birthday, deltaTime => deltaTime >= 18);
}

export function isDateYoungerThan75(today: Date, birthday: IsoDateString): boolean {
  return evaluateBirthday(today, birthday, deltaTime => deltaTime < 75);
}

export function isFuture(today: Date, birthday: IsoDateString): boolean {
  return new Date(birthday) < today;
}

function evaluateBirthday(
  today: Date,
  birthday: IsoDateString,
  evaluationFunction: (deltaTime: number) => boolean,
): boolean {
  if (!birthday || !isValidIsoDateFormat(birthday)) {
    return false;
  }

  return evaluationFunction(differenceInYears(today, new Date(birthday)));
}

function isValidDay(date: DateInputValue): boolean {
  const MAX_DAYS_IN_MONTH = 31;
  const dayInt = (date.day && parseInt(date.day, 10)) || 0;
  return dayInt > 0 && dayInt <= MAX_DAYS_IN_MONTH;
}

function isValidMonth(date: DateInputValue): boolean {
  const MAX_MONTHS_IN_YEAR = 12;
  const monthInt = (date.month && parseInt(date.month, 10)) || 0;
  return monthInt > 0 && monthInt <= MAX_MONTHS_IN_YEAR;
}

function isValidYear(date: DateInputValue): boolean {
  const yearInt = (date.year && parseInt(date.year, 10)) || 0;
  return yearInt > 0;
}

/**
 * Check for valid (logical/sensible) ERGO ELements DateInput
 * Allow undefined to permit continued input
 */
export const isValidDateInput = (date: DateInputValue): boolean =>
  (date.day === undefined || isValidDay(date)) &&
  (date.month === undefined || isValidMonth(date)) &&
  (date.year === undefined || isValidYear(date));
