import MessageBox from '@eg/elements/MessageBox';
import * as React from 'react';
import { CheckoutModel, IsoDateString, Variante } from 'rlv-common';
import { scrollToTop } from '../../Helper';
import { Storage } from '../../helpers/Storage';
import { isOnline } from '../../helpers/isOnline';
import { NavigationAction, StateName } from '../../routing/StateMachineTypes';
import { DTMSeitenname, DTMTarifmodus } from '../../tracking/dtmTracking';
import {
  TrackingConversionTypes,
  TrackingElement,
  TrackingPagename,
} from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { ConsentsPageContainer } from '../../types/PageContainer';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { SessionStorageKeys } from '../../types/SessionStorage';
import { ConsentToCreditCheck } from './forms/ConsentToCreditCheck/ConsentToCreditCheck';
import { ConsentToDataProcessing } from './forms/ConsentToDataProcessing/ConsentToDataProcessing';
import ConsentToReleasePrivateData from './forms/ConsentToReleasePrivateDataInDeath/ConsentToReleasePrivateData';
import { IddDownloadPdfSubmit } from './forms/IDDDownloadPdfSubmit/IddDownloadPdfSubmit';
import { LoanProtection } from './forms/LoanProtection/LoanProtection';
import { MoneyLaundering } from './forms/MoneyLaundering/MoneyLaundering';
import { Summary } from './forms/Summary/Summary';
import { CheckOutConsents, ReleaseFromConfidentialityData } from './legalQuestions.types';
export interface CheckOutPageData extends CheckoutModel, StoreStateUpdater<CheckOutPageData> {
  oeNumber?: string;
  showMeldung: boolean;
  pdfUrlDownloaded?: string;
}

interface CheckOutPageProps extends PagePropsWithValues<CheckOutPageData>, InjectedTrackerProps {
  onError: (e: Error) => void;
  varianten?: Variante[];
  businessId: string;
  insuranceEndDate?: IsoDateString;
}

class LegalQuestionsPageContainer
  extends React.Component<CheckOutPageProps>
  implements ConsentsPageContainer
{
  public constructor(props: CheckOutPageProps) {
    super(props);
    this.state = {
      isReportValid: false,
      isOrdering: false,
    };
  }

  public async componentDidMount() {
    scrollToTop();
    this.props.updateDTMTracking({
      seitenName: DTMSeitenname.PRUEFEN_UND_SENDEN,
      tarifmodus: DTMTarifmodus.OnlineAbschluss,
    });
    if (
      Storage.readItem(SessionStorageKeys.CURRENT_STATE) === StateName.LEGAL_QUESTIONS_SUMMARY_PAGE
    ) {
      this.props.tracker.trackPageLoad({
        pageName: isOnline()
          ? StateName.LEGAL_QUESTIONS_SUMMARY_PAGE
          : TrackingPagename.LEGAL_QUESTIONS_SUMMARY_OFFLINE_PAGE,
        conversionType: TrackingConversionTypes.SALE_FUNNEL_FINAL_CHECK,
      });
    } else {
      this.props.tracker.trackPageLoad({});
    }
  }

  public trackClick(clickedElement: TrackingElement) {
    this.props.tracker.trackClickEvent({
      clickedElement,
    });
  }

  public render() {
    const legalQuestionsContent = () => {
      switch (Storage.readItem('currentState')) {
        case StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE:
          return (
            <MoneyLaundering
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={(_, navigationAction) => {
                this.props.handleAction(navigationAction);
              }}
            />
          );

        case StateName.LEGAL_QUESTIONS_PURPOSE_LOAN_PROTECTION_PAGE:
          return (
            <LoanProtection
              loanProtection={this.props.storeState.loanProtection}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={async (loanProtection: boolean, navigationRequest: NavigationAction) =>
                this.submitChild('loanProtection', loanProtection, navigationRequest)
              }
            />
          );

        case StateName.LEGAL_QUESTIONS_CONSENT_TO_DATA_PROCESSING_PAGE:
          return (
            <ConsentToDataProcessing
              bussisnesId={this.props.businessId}
              onNavigateNoSubmit={this.props.handleAction}
              onSubmit={(_, navigationAction) => {
                this.props.handleAction(navigationAction);
              }}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
            />
          );

        case StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE:
          return (
            <ConsentToReleasePrivateData
              patientConfidentialityRelease={this.props.storeState.patientConfidentialityRelease}
              patientConfidentialityReleaseAfterDeath={
                this.props.storeState.patientConfidentialityReleaseAfterDeath
              }
              onNavigateNoSubmit={this.props.handleAction}
              onSubmit={async (
                checkOutConsents: CheckOutConsents,
                navigationRequest: NavigationAction,
              ) => this.submitConsent(checkOutConsents, navigationRequest)}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
            />
          );

        case StateName.LEGAL_QUESTIONS_SUMMARY_PAGE:
          return (
            <Summary
              checkoutData={this.props.storeState}
              valueRanges={this.props.valueRanges}
              varianten={this.props.varianten}
              onNavigateNoSubmit={this.props.handleAction}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
            />
          );

        case StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK:
          return (
            <ConsentToCreditCheck
              onChange={(consent: boolean) => {
                Storage.writeItem('consentToCreditCheck', consent.toString());
              }}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={(_, navigationAction) => {
                this.props.handleAction(navigationAction);
              }}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
            />
          );

        case StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE:
          return (
            <IddDownloadPdfSubmit
              // TODO, implement new tracker logic. This remains here as reference
              businessId={this.props.businessId}
              onNavigateNoSubmit={() => this.props.handleAction(NavigationAction.BACK)}
              onSubmit={() => this.props.handleAction(NavigationAction.NEXT)}
              onError={this.props.onError}
              trackClickEvent={clickedElement => {
                this.trackClick(clickedElement);
              }}
              pdfUrlDownloaded={this.props.storeState.pdfUrlDownloaded}
              onPdfUrl={url => this.updateStateMachine('pdfUrlDownloaded', url || '')}
            />
          );

        default:
          return <h1>No project match</h1>;
      }
    };

    const currentState = Storage.readItem('currentState');

    return (
      <>
        <div data-component-id={currentState}>
          {legalQuestionsContent()}
          {this.props.storeState.showMeldung && (
            <MessageBox type="error">
              Online können wir Ihnen den Tarif leider nicht anbieten. Bitte rufen Sie uns an -
              natürlich gebührenfrei!
            </MessageBox>
          )}
        </div>
        <div id="annotation-text" />
      </>
    );
  }

  private async updateStateMachine(field: string, value: string | boolean | number) {
    this.props.storeState.update({
      [field]: value,
    });
  }

  public readonly submitChild = async (
    field: string,
    value: string | boolean | number,
    navigationRequest: NavigationAction,
  ) => {
    await this.updateStateMachine(field, value).then(() => this.navigate(navigationRequest));
  };

  private async updateStateMachineConsents(consents: CheckOutConsents) {
    const updatedCheckOutConsentsData = {
      ...this.props.storeState,
      ...this.mapToPatientConfidentialityRelease(consents.releaseFromConfidentiality),
    } as CheckOutPageData;
    this.props.storeState.update(updatedCheckOutConsentsData);
  }

  public readonly submitConsent = async (
    consent: CheckOutConsents,
    navigationRequest: NavigationAction,
  ) => {
    await this.updateStateMachineConsents(consent).then(() => this.navigate(navigationRequest));
  };

  public navigate(navigationRequest: NavigationAction) {
    this.props.handleAction(navigationRequest);
  }

  private mapToPatientConfidentialityRelease(
    input: ReleaseFromConfidentialityData,
  ): CheckOutConsents {
    return {
      releaseFromConfidentiality: input,
      patientConfidentialityReleaseAfterDeath: input.confidentialityDeath,
      patientConfidentialityRelease: input.confidentialityDeliveranceType,
    };
  }
}

export default withTracker(LegalQuestionsPageContainer);
