export interface ValidationResponse {
  personId?: string;
  responseType?: ValidationResponseType;
  errorStreet?: boolean;
  errorHousenumber?: boolean;
  errorPostalCity?: boolean;
  correctedStreet?: string;
  correctedHousenumber?: string;
  correctedPostal?: string;
  correctedCity?: string;
}

export enum ValidationResponseType {
  ADRESSE_UNGUELTIG = 'ADRESSE_UNGUELTIG',
  ADRESSE_KORRIGIERT = 'ADRESSE_KORRIGIERT'
}

export interface PdsAddressValidationResponse {
  meldungen: [{
    meldungscode?: ValidationResponseType,
    validierung?: string,
    personId?: string,
    text?: string,
    eigenschaften?: string[]
  }];
}
