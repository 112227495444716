export enum ConsentSelection {
  YES = 'Ja',
  NO = 'Nein',
}

export const LoanProtectionText = {
  title: 'Absicherung von Darlehen und Leasingraten',
  subtitle:
    'Wird diese Risikolebensversicherung zur Absicherung einer Finanzierung (z. B. eines Darlehens oder für Leasingraten) abgeschlossen?',
};
