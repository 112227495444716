import { PatientConfidentialityRelease } from 'rlv-common';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementConsentToReleasePrivateData } from '../../../../tracking/tracking.types';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { CheckOutConsents } from '../../legalQuestions.types';

export interface ConsentToReleasePrivateDataProps extends SharedSubmitCallBack<CheckOutConsents> {
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: CheckOutConsents, navigationRequest: NavigationAction) => void;
  patientConfidentialityRelease?: PatientConfidentialityRelease;
  patientConfidentialityReleaseAfterDeath?: boolean;
  trackClickEvent: (clickedElement: TrackingElementConsentToReleasePrivateData) => void;
}
export interface ConsentToReleaseContent {
  titleText: JSX.Element | string;
  sections: Array<{
    subtitle: JSX.Element | string;
    options: {
      type: CheckboxType;
      checkboxes: Array<{
        value?: unknown;
        content: JSX.Element;
        errorMessage?: string;
      }>;
      errorMessage?: string;
    };
    paragraph?: JSX.Element;
  }>;
}

export enum CheckboxType {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
}
