import * as React from 'react';
import './BeneficiaryDetailsStatus.css';
import { BeneficiaryDetailsStatusDescription } from './BeneficiaryDetailsStatus.data';

interface BeneficiaryDetailsStatusProps {
  numBeneficiaries?: number;
  currentPercentage?: number;
}

const BeneficiaryDetailsStatus = ({ numBeneficiaries= 0, currentPercentage= 0 }: BeneficiaryDetailsStatusProps): JSX.Element => (
  <div className="beneficiary-status__container">
    <div className="beneficiary-status__item">
      <div>
        <span><b data-testid="beneficiaries-status__count">{numBeneficiaries} </b>
          {BeneficiaryDetailsStatusDescription.FROM} <b>{BeneficiaryDetailsStatusDescription.MAX_COUNT}</b>
        </span><br/>
        <span>{BeneficiaryDetailsStatusDescription.COUNT_DESC}</span>
      </div>
    </div>
    <div className="beneficiary-status__item">
      <div>
        <span> <b data-testid="beneficiaries-status__percentage">{currentPercentage} </b>
          {BeneficiaryDetailsStatusDescription.FROM} <b>{BeneficiaryDetailsStatusDescription.MAX_PERCENTAGE}</b>
        </span><br/>
        <span>{BeneficiaryDetailsStatusDescription.PERCENTAGE_DESC}</span>
      </div>
    </div>
  </div>
);

export default BeneficiaryDetailsStatus;
