import Checkbox from '@eg/elements/Checkbox';
import { Form, Formik } from 'formik';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import { Storage } from '../../../../helpers/Storage';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementCreditCheck } from '../../../../tracking/tracking.types';
import { handleFormErrors } from '../../../../tracking/trackingFormating';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { SessionStorageKeys } from '../../../../types/SessionStorage';
import { CheckboxData, ConsentToCreditCheckProps } from './ConsentToCreditCheck.types';

import './ConsentToCreditCheck.css';
import { consentToCreditCheckData } from './ConsentToCreditCheck.data';

export const ConsentToCreditCheck = ({
  onSubmit,
  onNavigateNoSubmit,
  trackClickEvent,
}: ConsentToCreditCheckProps) => {
  const storedConsent: boolean =
    Storage.readItem(SessionStorageKeys.CONSENT_TO_CREDIT_CHECK) === 'true';
  const [checkedCheckboxes, setCheckedCheckboxes] = React.useState(
    storedConsent ? [true] : [false],
  );
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);

  const handleCheckboxChange =
    (optionIdx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      trackClickEvent(TrackingElementCreditCheck.CHECKBOX);
      setShowCheckboxError(false);
      const updatedCheckedCheckboxes = checkedCheckboxes.map((item, index) =>
        index === optionIdx ? !item : item,
      );
      setCheckedCheckboxes(updatedCheckedCheckboxes);
      setConsent(updatedCheckedCheckboxes);
    };

  if (showCheckboxError) {
    handleFormErrors(consentToCreditCheckData.sections[0].errorMessage as string);
  }

  const setConsent = (checkboxes: boolean[]) => {
    const consent = checkboxes.every(v => v);
    Storage.writeItem(SessionStorageKeys.CONSENT_TO_CREDIT_CHECK, consent.toString());
    return consent;
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        const consent = setConsent(checkedCheckboxes);
        setShowCheckboxError(!consent);
        if (consent) {
          onNavigateNoSubmit(NavigationAction.NEXT);
        }
      }}
      validationSchema={undefined}
    >
      {form => (
        <Form className="container consent-to-credit-check">
          <Headline text={consentToCreditCheckData.titleText} />
          <div
            className="consent-to-credit-check__sections-container"
            id="consent-to-credit-check__sections-container"
          >
            {consentToCreditCheckData.sections.map((section, idx) => (
              <div
                className="consent-to-credit-check__section"
                key={`consent-to-credit-check__section-${idx}`}
              >
                {section.subtitle && (
                  <h3 className="consent-to-credit-check__section-subtitle">{section.subtitle}</h3>
                )}
                <div className={`section consent-to-credit-check__section-${idx}`}>
                  {section.checkboxes &&
                    section.checkboxes.map((checkbox: CheckboxData, optionIdx: number) => (
                      <Checkbox
                        verticalAlign="top"
                        key={`consent-to-credit-check__section-${idx}__option-${optionIdx}`}
                        checked={checkedCheckboxes[optionIdx]}
                        label={checkbox.label}
                        onChange={handleCheckboxChange(optionIdx)}
                        error={showCheckboxError}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div className="consent-to-credit-check__error">
            {showCheckboxError && (
              <p className="error-message">{consentToCreditCheckData.sections[0].errorMessage}</p>
            )}
          </div>
          <AemFragment name={AemFragmentCatalog.STEP21VA} useVA={true} />
          <Footer
            showNext
            showPrevious
            handleAction={(navigationAction: NavigationAction) => {
              if (navigationAction === NavigationAction.NEXT) {
                form.submitForm();
              } else {
                onNavigateNoSubmit(navigationAction);
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
