import * as React from 'react';

import { getServiceConfiguration } from '../../configuration/serviceConfiguration';

import { duwLoaded } from '../../helpers/externalScripts';
import { DUW_RENDER_EVENT, DUW_UNMOUNT_EVENT } from '../../types/external/duw/DuwTypes';

import { DuwAnswers } from '../../pages/legalQuestions/forms/Summary/Summary.types';

const createHiddenDUWAnchor = () => {
  const duwAnchor = document.createElement('div');
  duwAnchor.id = 'root-duw';
  duwAnchor.style.display = 'none';

  document.body.appendChild(duwAnchor);
};

const removeDUWAnchor = () => {
  const duwAnchor = document.getElementById('root-duw');

  if (duwAnchor) {
    document.body.removeChild(duwAnchor);
  }
};

/**
 * TODO: Instead of using pop-drilling to reach the "configuration" it would be better
 * to import it directly from 'index', but, by importing 'configuration' from there you
 * will launch ReacDOM.render method, and  it causes problems in test suite
 */
export const useDuwAnswers = (
  duwToken: string | undefined,
): { duwAnswers: DuwAnswers[] | undefined; loadingAnswers: boolean; error: Event | undefined } => {
  const [duwAnswers, setDuwAnswers] = React.useState<DuwAnswers[]>();
  const [loadingAnswers, setLoadingAnswers] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Event>();

  const initHiddenDuw = (): void => {
    duwLoaded
      .then(() => {
        document.dispatchEvent(
          new CustomEvent(DUW_RENDER_EVENT, {
            detail: {
              apiBaseUrl: getServiceConfiguration().duw.baseUrl,
              token: duwToken,
              readonlyMode: {
                callbacks: {
                  onRender: (answers: DuwAnswers[]) => {
                    setDuwAnswers(answers);
                    setLoadingAnswers(false);
                  },
                  onError: setError,
                },
              },
            },
          }),
        );
      })
      .catch(setError);
  };

  React.useEffect(() => {
    if (duwToken) {
      createHiddenDUWAnchor();
      initHiddenDuw();
    } else {
      setLoadingAnswers(false);
    }

    return () => {
      document.dispatchEvent(new Event(DUW_UNMOUNT_EVENT));
      removeDUWAnchor();
    };
  }, []);

  return { duwAnswers, loadingAnswers, error };
};
