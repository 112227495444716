import * as React from 'react';
import './Text.css';

export interface TextProps {
  /**
   * Child Elements
   */
  children?: React.ReactNode;
  /**
   * [optional] Aditional classNames
   */
  styles?: string;
}

const Text = ({ styles = '', children }: TextProps) => <p className={`text_content_p ${styles}`}>{children}</p>;

export default Text;
