/**
 * This StateDefinition-Machinery is an adaptation of React-Machinery from francisrstokes
 * https://github.com/francisrstokes/React-Machinery
 */
import FormFooter from '@eg/elements/FormFooter';
import * as React from 'react';
import { mapToGermanDate, ValueRanges, Variante, Zahlweise } from 'rlv-common';
import { Schema } from 'yup';
import AppLoader from '../components/AppLoader';
import { PageWrapper } from '../components/PageWrapper';
import StatusRibbon from '../components/StatusRibbon/StatusRibbon';
import { ViewName } from '../helpers/ViewName';
import {
  DTMSeitenname,
  DTMTrackingState,
  renderDTMTrackingElements,
  updateDTMTrackingState,
} from '../tracking/dtmTracking';
import { Tracker } from '../tracking/tracker';
import {
  TrackingAdditionalTariffOptions,
  TrackingProductCombination,
  TrackingTariffOptions,
  TrackingUserAttributes,
  TrackingUserContact,
} from '../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../tracking/withTracker';
import { NavigationAction, StateName, URLState } from './StateMachineTypes';

export type UpdateFunction<T> = (userInput: Partial<T>, callback?: () => void) => void;
export type UpdateDTMTrackingFunction = (update: Partial<DTMTrackingState>) => void;
export type OnEnterCallback<T> = (
  transitionDetails: TransitionDetails,
  inputData: TransitionInput<T>,
) => Promise<Partial<TransitionOutput<T>>>;
export type OnExitCallback<T> = (
  transitionDetails: TransitionDetails,
  inputData: TransitionInput<T>,
) => Promise<Optional<{ payload?: any; skipTransition?: boolean }>> | undefined;
export type HandleActionCallback = (
  action: NavigationAction,
  interceptedAction?: NavigationAction,
) => void;

export type ValidationSchemaCreator = (valueRanges: ValueRanges) => Schema<any>;

export interface StateDefinition<T> {
  name: StateName;
  percentage?: number;
  trackingViewName: ViewName;
  createValidationSchema?: ValidationSchemaCreator;
  /**
   * The States from which this State can be reached.
   */
  validInboundStates: StateName[];
  /**
   * The allowed transitions away from this state into another one.
   */
  transitions: Array<Transition<T>>;
  onEnter?: OnEnterCallback<T>;
  onExit?: OnExitCallback<T>;
  render?: (
    inputData: StateData<T>,
    handleAction: HandleActionCallback,
    updateApp: UpdateFunction<T>,
    onError: (e: Error) => void,
    updateDTMTracking: UpdateDTMTrackingFunction,
  ) => React.ReactNode;
}

export interface StateData<T> extends TransitionInput<T> {
  valueRanges: ValueRanges;
  varianten?: Variante[];
  paymentMethod?: Zahlweise;
}

export interface Transition<T> {
  test: (action: NavigationAction, inputData: StateData<T>) => boolean;
  newState: StateName;
}

export interface TransitionInput<T> {
  businessId: string;
  userInput: T;
}

export interface TransitionOutput<T> {
  userInput: Partial<T>;
  valueRanges: ValueRanges;
  fetchedTrackingData?: FetchedTrackingData;
  paymentMethod?: Zahlweise;
  varianten?: Variante[];
}

export interface FetchedTrackingData {
  tariffOptions?: Partial<TrackingTariffOptions>;
  userAttributes?: TrackingUserAttributes;
  userContactInfo?: TrackingUserContact;
  additionalTariffOptions?: Partial<TrackingAdditionalTariffOptions>;
  insuranceStart?: string;
  productCombination?: TrackingProductCombination;
  calculatedValue?: number;
  transactionTotal?: number;
}

export interface TransitionDetails {
  sourceStateName?: StateName;
  targetStateName?: StateName;
  action?: NavigationAction;
  payload?: any;
}

export interface StateMachineProps extends InjectedTrackerProps {
  stateDefinitions: Array<StateDefinition<object>>;
  inputData: {
    businessId: string;
  };
  tracker: Tracker;
}

export interface StateMachineInternalState {
  currentStateName: StateName;
  transitionCompleted: boolean;
  userInput: any;
  fetchedTrackingData?: FetchedTrackingData;
  valueRanges: ValueRanges;
  paymentMethod?: Zahlweise;
  varianten?: Variante[];
  updateCalling: boolean;
  interceptedAction?: NavigationAction;
  lastFailedAction?: NavigationAction;
  dtmTracking: DTMTrackingState;
  frontendURL?: string;
  variantError: boolean;
}

export const updateURL = (stateName: StateName, baseUrl: string = ''): void => {
  const href = baseUrl && baseUrl !== '' ? baseUrl : window.location.href;
  const url = href.replace(/#.*/, '');
  const path = URLState[stateName] || '';

  if (path === '') {
    return;
  }
  const updatedUrl = `${url}#${path}`.replace(/([https|http]?:\/\/)|(\/){2,}/g, '$1$2');
  window.history.pushState({}, '', updatedUrl);
};

export class StateMachine extends React.Component<StateMachineProps, StateMachineInternalState> {
  public constructor(props: StateMachineProps) {
    super(props);

    const currentStateFromSessionStorage: string | null =
      window.sessionStorage.getItem('currentState');
    const keyName: string | undefined = Object.keys(StateName).find(
      element => StateName[element] === currentStateFromSessionStorage,
    );
    let currentStateName: StateName = keyName ? StateName[keyName] : StateName.NEED_PAGE;
    if (!currentStateName) {
      currentStateName = StateName.NEED_PAGE;
    }
    const frontendURL = window.location.href;

    window.onpopstate = this.handleBrowserBack;

    this.state = {
      currentStateName,
      transitionCompleted: false,
      userInput: {},
      valueRanges: {} as ValueRanges,
      paymentMethod: '' as Zahlweise,
      varianten: [] as Variante[],
      updateCalling: false,
      interceptedAction: undefined,
      lastFailedAction: undefined,
      dtmTracking: {
        vertriebsprodukt: 'TARIF_RLV',
        seitenName: DTMSeitenname.BEDARFSANALYSE,
      },
      frontendURL,
      variantError: false,
    };

    updateURL(currentStateName, frontendURL);
  }

  public updateDTMTracking = (update: Partial<DTMTrackingState>) => {
    const newTrackingState = updateDTMTrackingState(this.state.dtmTracking, {
      ...update,
      orderId: this.props.inputData.businessId,
    });
    this.setState({
      dtmTracking: newTrackingState,
    });
  };

  public handleBrowserBack = (event: PopStateEvent) => {
    this.handleAction(NavigationAction.BROWSER_BACK);
  };

  public async componentDidMount() {
    const state = this.props.stateDefinitions.find(s => s.name === this.state.currentStateName);
    if (state === undefined) {
      return;
    }

    await this.handleOnEnter(state, {
      action: NavigationAction.START,
      sourceStateName: undefined,
      targetStateName: this.state.currentStateName,
    });
  }

  public render() {
    const currentStateName: string = this.state.currentStateName;
    const currentState: StateDefinition<object> | undefined = this.props.stateDefinitions.find(
      state => state.name === currentStateName,
    );

    if (currentState) {
      if (this.state.fetchedTrackingData) {
        if (this.state.fetchedTrackingData.tariffOptions) {
          this.props.tracker.updateTariffOptions(this.state.fetchedTrackingData.tariffOptions);
        }
        if (this.state.fetchedTrackingData.userAttributes) {
          this.props.tracker.updateUserAttributes(this.state.fetchedTrackingData.userAttributes);
        }
        if (this.state.fetchedTrackingData?.userContactInfo) {
          const { address, profileInfo } = this.state.fetchedTrackingData.userContactInfo;
          this.props.tracker.setUserContactInfo(address, profileInfo);
        }
        if (this.state.fetchedTrackingData.additionalTariffOptions) {
          this.props.tracker.updateAdditionalTariffOptions(
            this.state.fetchedTrackingData.additionalTariffOptions,
          );
        }
        if (this.state.fetchedTrackingData.insuranceStart) {
          this.props.tracker.updateInsuranceStartDate(
            mapToGermanDate(this.state.fetchedTrackingData.insuranceStart) || '',
          );
        }
        if (this.state.fetchedTrackingData.productCombination) {
          this.props.tracker.updateProductCombination(
            this.state.fetchedTrackingData.productCombination,
          );
        }
        if (this.state.fetchedTrackingData.calculatedValue) {
          this.props.tracker.updateCalculatedValue(this.state.fetchedTrackingData.calculatedValue);
        }
        if (this.state.fetchedTrackingData.transactionTotal) {
          this.props.tracker.updateTransactionTotal(
            this.state.fetchedTrackingData.transactionTotal,
          );
        }
      }

      if (currentState.render) {
        return (
          <>
            {renderDTMTrackingElements(this.state.dtmTracking)}
            {currentState.name !== StateName.FEEDBACK_PAGE && !this.state.variantError && (
              <StatusRibbon
                tariffTypes={this.state.valueRanges.tariffType?.possibleValues}
                onError={() => {
                  this.updateVariantError();
                }}
                stateName={currentState.name}
                progress={currentState.percentage}
                duwProgress={this.state.userInput.duwProgress}
              />
            )}
            <AppLoader show={this.state.updateCalling} viewport="relative">
              {this.state.transitionCompleted && !this.state.updateCalling && (
                <PageWrapper
                  currentState={currentState}
                  inputData={{
                    ...this.props.inputData,
                    userInput: this.state.userInput,
                    valueRanges: this.state.valueRanges,
                    paymentMethod: this.state.paymentMethod,
                    varianten: this.state.varianten,
                  }}
                  openErrorModal={!!this.state.lastFailedAction}
                  handleAction={this.handleAction}
                  updateApp={(userInput: any, callback?: () => void) => {
                    this.setState(
                      {
                        userInput: {
                          ...this.state.userInput,
                          ...(currentState.createValidationSchema
                            ? currentState
                                .createValidationSchema(this.state.valueRanges)
                                .cast(userInput)
                            : userInput),
                        },
                      },
                      callback,
                    );
                  }}
                  updateDTMTracking={this.updateDTMTracking}
                  updateCalling={this.state.updateCalling}
                  interceptedActionPending={!!this.state.interceptedAction}
                />
              )}
            </AppLoader>
            <div className="footer__form-footer">
              <FormFooter />
            </div>
          </>
        );
      }
    }

    throw new Error(
      `Neither a valid render or component property was found for state '${currentStateName}'`,
    );
  }

  private async update(action: NavigationAction) {
    const { stateDefinitions, inputData } = this.props;
    const currentStateName: StateName = this.state.currentStateName;
    const currentState = stateDefinitions.find(state => state.name === currentStateName);
    if (currentState && action) {
      for (const transition of currentState.transitions) {
        if (
          transition.test(action, {
            ...inputData,
            userInput: this.state.userInput,
            valueRanges: this.state.valueRanges,
            paymentMethod: this.state.paymentMethod,
            varianten: this.state.varianten,
          })
        ) {
          await this.transition(currentStateName, transition.newState, action);
          return;
        }
      }
    }
  }

  private async transition(
    oldStateName: StateName,
    newStateName: StateName,
    action: NavigationAction,
  ) {
    const nextState: StateDefinition<object> | undefined = this.props.stateDefinitions.find(
      state => state.name === newStateName,
    );
    if (!nextState) {
      const validStates: string = this.props.stateDefinitions.map(state => state.name).join(', ');
      throw new Error(
        `Tried to transition from state '${oldStateName}' to '${newStateName}'. Valid states are: [${validStates}]`,
      );
    } else {
      const validTransition =
        nextState.validInboundStates.filter(validEntryState => validEntryState === oldStateName)
          .length > 0;
      if (!validTransition) {
        const validStates: string = nextState.validInboundStates.join(', ');
        throw new Error(
          `Tried forbidden transition from state '${oldStateName}' to '${newStateName}'. Valid entry states are: [${validStates}]`,
        );
      }
    }
    this.setState({
      updateCalling: true,
    });

    const transitionDetails: TransitionDetails = {
      action,
      sourceStateName: oldStateName,
      targetStateName: newStateName,
    };
    let payload: any;
    const currentState: StateDefinition<object> | undefined = this.props.stateDefinitions.find(
      state => state.name === oldStateName,
    );
    try {
      if (currentState) {
        if (currentState.onExit) {
          try {
            const onExitReturn = await currentState.onExit(transitionDetails, {
              ...this.props.inputData,
              userInput: this.state.userInput,
            });
            if (onExitReturn) {
              payload = onExitReturn.payload;
              if (onExitReturn.skipTransition) {
                this.setState({
                  updateCalling: false,
                  userInput: {
                    ...this.state.userInput,
                    ...payload,
                  },
                });
                return;
              }
            }
          } catch (e) {
            this.setState({
              lastFailedAction: action,
              updateCalling: false,
            });
            return;
          }
        }
      }

      window.sessionStorage.setItem('currentState', newStateName);
      updateURL(newStateName, this.state.frontendURL);

      this.setState(
        {
          currentStateName: newStateName,
          transitionCompleted: false,
          lastFailedAction: undefined,
        },
        async () => {
          await this.handleOnEnter(nextState, {
            ...transitionDetails,
            payload,
          });
        },
      );
    } catch (e) {
      window.sessionStorage.setItem('currentState', newStateName);
      this.setState({
        currentStateName: oldStateName,
        updateCalling: false,
        transitionCompleted: true,
      });
    }
  }

  private readonly handleAction: HandleActionCallback = async (
    action: NavigationAction,
    interceptedAction?: NavigationAction,
  ) => {
    if (action === NavigationAction.INTERCEPTION_MODAL_OPEN && interceptedAction) {
      this.setState({
        interceptedAction,
      });
    } else if (action === NavigationAction.INTERCEPTION_MODAL_DISMISS) {
      this.setState({
        interceptedAction: undefined,
      });
    } else if (action === NavigationAction.REPEAT_CALL) {
      if (this.state.lastFailedAction) {
        this.setState({
          lastFailedAction: undefined,
        });
        await this.update(this.state.lastFailedAction);
      }
    } else {
      if (this.state.interceptedAction) {
        this.setState({
          interceptedAction: undefined,
        });
        await this.update(this.state.interceptedAction);
      } else {
        await this.update(action);
      }
    }
  };

  private async handleOnEnter(
    state: StateDefinition<object>,
    transitionDetails: TransitionDetails,
  ) {
    if (state === undefined || !state.onEnter) {
      this.setState({
        transitionCompleted: true,
        updateCalling: false,
      });

      return;
    }

    try {
      const output = await state.onEnter(transitionDetails, {
        ...this.props.inputData,
        userInput: this.state.userInput,
      });

      this.setState({
        transitionCompleted: true,
        userInput: {
          ...this.state.userInput,
          ...output.userInput,
        },
        valueRanges: {
          ...this.state.valueRanges,
          ...output.valueRanges,
        },
        fetchedTrackingData: {
          ...output.fetchedTrackingData,
        },
        paymentMethod: output.paymentMethod,
        varianten: output.varianten,
        updateCalling: false,
      });
    } catch (e) {
      this.setState({
        lastFailedAction: transitionDetails.action,
        transitionCompleted: true,
        updateCalling: false,
      });
      return;
    }
  }

  public updateVariantError() {
    this.setState({ variantError: true });
  }
}

export default withTracker(StateMachine);
