import { Form, Formik } from 'formik';
import * as React from 'react';
import { ValueRanges } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import FieldDateInputRow from '../../../../components/FormComponents/FieldDateInputRow/FieldDateInputRow';
import { Headline } from '../../../../components/Headline/Headline';
import InfoText from '../../../../components/InfoText/infoText';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { createLivingConditionsBirthdateSchema } from '../../../../validation/LivingConditionsPage';

export interface BirthdateFormProps extends SharedSubmitCallBack<string> {
  birthdate?: string;
  disabled: boolean;
  valueRanges: ValueRanges;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: string, navigationRequest: NavigationAction) => void;
  updateTrackingBirthdate: (date: string) => void;
}

const BirthdateForm = ({
  birthdate,
  valueRanges,
  onNavigateNoSubmit,
  onSubmit,
}: BirthdateFormProps): React.ReactElement => {
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);
  const footerInfoSection = (
    <InfoText textBeforeLink="Wenn Sie jemand anders absichern möchten, geben Sie bitte dessen Geburtsdatum an."></InfoText>
  );

  return (
    <>
      <Formik
        initialValues={{ birthdate, valueRanges }}
        onSubmit={values => onSubmit(values.birthdate || '', navigationRequest)}
        validationSchema={createLivingConditionsBirthdateSchema(valueRanges)}
      >
        {form => (
          <Form className="birthdate-form__body">
            <Headline text="Geben Sie Ihr Geburtsdatum an" />
            <FieldDateInputRow
              name="birthdate"
              date={birthdate}
              label={''}
              formError={form.errors.birthdate}
              onError={(error: string | undefined) => {
                form.setStatus(error);
              }}
            />
            <AemFragment name={AemFragmentCatalog.STEP3VA} useVA={true} />
            <Footer
              showNext
              showPrevious
              labelNext={'weiter'}
              labelPrevious={'zurück'}
              infoSection={footerInfoSection}
              disableNext={!!form.status || !!form.errors.birthdate || !form.values.birthdate}
              handleAction={(navigationAction: NavigationAction) => {
                setNavigationRequest(navigationAction);
                if (navigationAction === NavigationAction.BACK) {
                  onNavigateNoSubmit(navigationAction);
                } else {
                  form.submitForm();
                }
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BirthdateForm;
