/**
 * Takes the input word and makes the first letter uppercase and all the other
 * letters lowercase. An empty string is return if the input word is undefined or null.
 *
 * @param word The word which should be transformed.
 */

export const toReadableWord = (word?: string): string => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase().replace(/_/g, ' ');
  }

  return '';
};

export const getQueryValue = (key: string): string | undefined => {
  const parameters: string[] = window.location.search.substring(1).split('&');
  const parameterMatcher = new RegExp(`${key}=.+`);
  const parameter = parameters.find(element => parameterMatcher.test(element)) || '';
  const queryValue = parameter.split('=')[1];

  return queryValue || undefined;
};
