import {
  bool,
  mixed,
  number as yupNumber,
  object,
  ObjectSchema,
  Schema,
  string as yupString,
} from 'yup';
import { IsoDateString, TextValue, ValueRanges } from 'rlv-common/types';
import { DATE_ERROR_MESSAGES, isDateOlderThan18, isDateYoungerThan75, isFuture } from './date';
import { CustomMixedSchema as CustomMixedNumberSchema } from './validation';
require('./date.ts'); // required for CustomMixedSchema to work
require('./validation.ts'); // required for CustomMixedNumberSchema to work

// TODO: remove this complete living conditions validation schema after splitting screens process is done (keeps preserved as reference in the meantime)
export function createLivingConditionsPageSchema(valueRanges: ValueRanges): Schema<any> {
  return object().shape({
    employment: yupString()
      .required('Bitte wählen Sie das Beschäftigungsverhältnis.')
      .test(
        'isValid',
        'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
        (employment: string) =>
          !!valueRanges.employments.possibleValues.filter(
            (entry: TextValue) => entry.key === employment,
          ),
      ),
    birthdate: (mixed() as any)
      .required(DATE_ERROR_MESSAGES.required)
      .isValidIsoDateFormat()
      .test('olderThan18', DATE_ERROR_MESSAGES.olderThan18, (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isDateOlderThan18(today, birthday);
      })
      .test('youngerThan75', DATE_ERROR_MESSAGES.youngerThan75, (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isDateYoungerThan75(today, birthday);
      }),
    professionLabel: yupString().required(),
    professionKey: yupNumber()
      .required(`Bitte geben Sie den Beruf der zu versichernden Person an. Sollte der Beruf nicht in der Liste sein,
       verwenden Sie bitte eine allgemeinere Bezeichnung (z.B. „Angestellte/r“).`),
    smoker: yupString()
      .nullable(true)
      .required(
        `Bitte geben Sie an, ob die zu versichernde Person Raucher/in ist
       oder seit wann sie Nichtraucher/in ist.`,
      )
      .test(
        'isValid',
        'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
        (smoker: string) =>
          !!valueRanges.smoker.possibleValues.filter((entry: TextValue) => entry.key === smoker),
      ),
    height: (yupNumber() as CustomMixedNumberSchema)
      .validateNumber()
      .required('Bitte geben Sie die Körpergröße der zu versichernden Person an.')
      .min(30, 'Die Körpergröße der zu versichernden Person muss mindestens 30 cm sein.')
      .max(250, 'Die Körpergröße der zu versichernden Person darf maximal 250 cm sein.'),
    weight: (yupNumber() as CustomMixedNumberSchema)
      .validateNumber()
      .required('Bitte geben Sie das Gewicht der zu versichernden Person an.')
      .min(10, 'Das Gewicht der zu versichernden Person muss mindestens 10 Kg sein.')
      .max(300, 'Das Gewicht der zu versichernden Person darf maximal 300 Kg sein.'),
    biker: bool().nullable(true)
      .required(`Bitte geben Sie an, ob die zu versichernde Person ein Zweirad, Quad oder Trike jeweils ab Hubraum 80 ccm fährt oder ein 
        solches auf sie zugelassen ist. Dieses Risiko ist damit gegen einen Beitragszuschlag abgesichert.`),
    propertyOwner: bool().nullable(true)
      .required(`Bitte geben Sie an, ob die zu versichernde Person über Wohneigentum (Wohnung, Haus) verfügt,
       das sie selbst bewohnt.`),
    inceptionDate: (mixed() as any)
      .required('Bitte geben Sie Ihr gewünschtes Versicherungsbeginn-Datum an.')
      .isValidIsoDateFormat('Bitte geben Sie Ihr gewünschtes Versicherungsbeginn-Datum an.')
      .test(
        'isValid',
        'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
        (inceptionDate: string) =>
          !!valueRanges.insuranceStart.possibleValues.filter(
            (entry: string) => entry === inceptionDate,
          ),
      ),
  });
}

// ** Is needed to split living conditions validation schema in order to make isValid property in splitted Formik forms working properly.
export function createLivingConditionsBirthdateSchema(valueRanges: ValueRanges): Schema<any> {
  return object().shape({
    birthdate: (mixed() as any)
      .required('Bitte geben Sie ein gültiges Datum ein.')
      .isValidIsoDateFormat()
      .test('futureDate', 'Bitte geben Sie ein gültiges Datum ein.', (birthday: IsoDateString) => {
        const today: Date = new Date();
        return isFuture(today, birthday);
      })
      .test(
        'olderThan18',
        'Sie müssen bei Vertragsabschluss mindestens 18 Jahre alt sein.',
        (birthday: IsoDateString) => {
          const today: Date = new Date();
          return isDateOlderThan18(today, birthday);
        },
      )
      .test(
        'youngerThan75',
        'Wir können Ihnen diese Versicherung derzeit online nicht anbieten.',
        (birthday: IsoDateString) => {
          const today: Date = new Date();
          return isDateYoungerThan75(today, birthday);
        },
      ),
  });
}

export function createLivingConditionsProfessionSchema(valueRanges: ValueRanges): Schema<any> {
  return object().shape({
    employment: yupString()
      .required('Bitte wählen Sie das Beschäftigungsverhältnis.')
      .test(
        'isValid',
        'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
        (employment: string) =>
          valueRanges.employments.possibleValues.some(
            (entry: TextValue) => entry.key === employment,
          ),
      ),
    professionLabel: yupString().required(),
    professionKey: yupNumber()
      .required(`Bitte geben Sie den Beruf der zu versichernden Person an. Sollte der Beruf nicht in der Liste sein,
         verwenden Sie bitte eine allgemeinere Bezeichnung (z.B. „Angestellte/r“).`),
  });
}

export function createLivingConditionsPhysicalAspectSchema(): ObjectSchema {
  return object().shape({
    genre: yupString().required(),
    height: (yupNumber() as CustomMixedNumberSchema)
      .validateNumber()
      .required('Bitte geben Sie die Körpergröße der zu versichernden Person an.')
      .min(50, 'Die Körpergröße der zu versichernden Person muss mindestens 50 cm sein.')
      .max(250, 'Die Körpergröße der zu versichernden Person darf maximal 250 cm sein.'),
    weight: (yupNumber() as CustomMixedNumberSchema)
      .validateNumber()
      .required('Bitte geben Sie das Gewicht der zu versichernden Person an.')
      .min(10, 'Das Gewicht der zu versichernden Person muss mindestens 10 Kg sein.')
      .max(300, 'Das Gewicht der zu versichernden Person darf maximal 300 Kg sein.'),
  });
}

export function createLivingConditionsSmokerSchema() {
  return object().shape({
    isSmoker: yupString().required(),
  });
}
