import * as React from 'react';
import { AngebotResponse, ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import LivingConditionsSwitcher, {
  LivingConditionsPageData,
} from '../../../pages/livingConditions/LivingConditionsPageContainer';
import { getAngebot, updateLivingConditionsPageData } from '../../../services/api';
import {
  getTrackingBirthdate,
  mapFetchedNeedData,
  mapFetchedPricingData,
  mapTariffOptionsSmokerValues,
  mapTrackingEmployment,
  mapTrackingGender,
  mapTrackingHeightAndWeight,
  mapTrackingInsuredPerson,
  mapUserAttributesSmokerValues,
} from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createLivingConditionsPageSchema } from '../../../validation/LivingConditionsPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const livingConditionsBikerState: StateDefinition<LivingConditionsPageData> = {
  name: StateName.LIVING_CONDITIONS_BIKER,
  percentage: PagePercentage.LIVING_CONDITIONS_BIKER,
  trackingViewName: ViewName.BASISDATEN,
  createValidationSchema: (valueRanges: ValueRanges) =>
    createLivingConditionsPageSchema(valueRanges),

  validInboundStates: [
    StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT,
    StateName.LIVING_CONDITIONS_PROPERTY_OWNER,
  ],

  transitions: [
    {
      test: action => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT,
    },
    {
      test: (action, data) =>
        // TODO, remove comments in here when app is handling real data
        // const isValid = createLivingConditionsPageSchema(data.valueRanges).isValidSync(data.userInput);
        action === NavigationAction.NEXT,
      newState: StateName.LIVING_CONDITIONS_PROPERTY_OWNER,
    },
  ],

  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
        ...getTrackingBirthdate(response.livingConditionsModel),
        ...mapTrackingGender(),
        ...mapTrackingEmployment(response.livingConditionsModel),
        ...mapUserAttributesSmokerValues(response.livingConditionsModel),
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
        ...mapFetchedPricingData(response.needSelectionModel, response.feeCalculationModel),
      },
      additionalTariffOptions: {
        ...mapTariffOptionsSmokerValues(response.livingConditionsModel),
        ...mapTrackingHeightAndWeight(response.livingConditionsModel),
      },
    };

    return {
      userInput: {
        ...response.needSelectionModel,
        ...response.livingConditionsModel,
        professionKey: response.livingConditionsModel!.professionKey
          ? response.livingConditionsModel!.professionKey
          : undefined,
        professionLabel: response.livingConditionsModel!.professionLabel
          ? response.livingConditionsModel!.professionLabel
          : undefined,
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },

  onExit: async (transitionDetails, inputData) => {
    let userInput = inputData.userInput;
    if (!userInput.professionKey && transitionDetails.action === NavigationAction.BACK) {
      userInput = {
        ...userInput,
        professionLabel: '',
      };
    }

    const response: AngebotResponse = await updateLivingConditionsPageData(
      inputData.businessId,
      userInput,
    );
    return {
      payload: { insurancePeriodAdjusted: response.insurancePeriodAdjusted },
    };
  },

  render: (inputData, handleAction, updateApp, _unused, updateDTMTracking) => {
    const { userInput, valueRanges, businessId } = inputData;
    return (
      <LivingConditionsSwitcher
        storeState={{
          biker: userInput.biker,
          update: values => updateApp(values),
        }}
        businessId={businessId}
        valueRanges={valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
