import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { RequestOfferDivergingInsuredPersonModel } from 'rlv-common';
import AemFragment from '../../components/AemFragment/AemFragment';
import Footer from '../../components/Footer';
import FieldDateInputRow from '../../components/FormComponents/FieldDateInputRow/FieldDateInputRow';
import { Headline } from '../../components/Headline/Headline';
import InfoText from '../../components/InfoText/infoText';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import { RadioTileValue } from '../../components/RadioGroup/RadioTile/RadioTile.types';
import '../../helpers/NoLabelRadioGroupRow.css';
import { scrollToTop } from '../../helpers/scrolling';
import * as requestOfferPersonalDataPage from '../../routing/routes/requestOfferDivergingInsuredPerson/requestOfferDivergingInsuredPersonPage';
import { NavigationAction } from '../../routing/StateMachineTypes';
import {
  TrackingBoolean,
  TrackingElementDivergingInsuredPerson,
} from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { AemFragmentCatalog } from '../../types/AemFragmentCatalog';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { createRequestOfferDivergingInsuredPersonPageSchema } from '../../validation/RequestOfferDivergingInsuredPersonPage';
import './RequestOfferDivergingInsuredPersonPage.css';

export interface RequestOfferDivergingInsuredPersonPageData
  extends RequestOfferDivergingInsuredPersonModel,
    StoreStateUpdater<RequestOfferDivergingInsuredPersonPageData> {
  insuredPerson: string;
  birthdate?: string;
}

interface RequestOfferDivergingInsuredPersonPageProps
  extends PagePropsWithValues<RequestOfferDivergingInsuredPersonPageData>,
    InjectedTrackerProps {
  onError: (e: Error) => void;
  businessId: string;
}

interface RequestOfferDivergingInsuredPersonPageState {
  virtualAssistantFragment: AemFragmentCatalog;
}

export class RequestOfferDivergingInsuredPersonPageNew extends React.Component<
  RequestOfferDivergingInsuredPersonPageProps,
  RequestOfferDivergingInsuredPersonPageState
> {
  private readonly radioGroupOptions = [
    { title: 'Mich selbst', value: requestOfferPersonalDataPage.MYSELF },
    {
      title: 'Jemand anders',
      value: requestOfferPersonalDataPage.DIFFERENT_PERSON,
    },
  ];

  private readonly InfoText = (
    <InfoText textBeforeLink="Sie möchten Ihren Partner oder eine andere Person absichern? Dann geben Sie auf den folgenden Seiten bitte die Daten dieser Person an." />
  );

  public constructor(props: Readonly<RequestOfferDivergingInsuredPersonPageProps>) {
    super(props);
    this.state = {
      virtualAssistantFragment: AemFragmentCatalog.STEP2VA,
    };
  }

  public async componentDidMount() {
    scrollToTop();
    this.props.tracker.updateUserAttributes({
      insuredIsInsuredPerson: this.props.storeState.divergingInsuredPerson
        ? TrackingBoolean.NO
        : TrackingBoolean.YES,
    });
    this.props.tracker.trackPageLoad({});
  }

  public render() {
    return (
      <div data-component-id="RequestOfferDivergingInsuredPersonPage">
        <Formik
          initialValues={{
            ...this.props.storeState,
            birthdate: this.props.storeState.vn.birthdate,
          }}
          isInitialValid={true}
          onSubmit={values => {
            this.handleBirthdate(values.birthdate);
            this.props.handleAction(NavigationAction.NEXT);
          }}
          validationSchema={createRequestOfferDivergingInsuredPersonPageSchema()}
        >
          {(form: FormikProps<RequestOfferDivergingInsuredPersonPageData>) => (
            <Form className="insured-person__container">
              <Headline text="Wen möchten Sie versichern?" />
              <div className="insured-person__radio-group">
                <Field
                  name="insuredPerson"
                  render={({ field }: FieldProps<RequestOfferDivergingInsuredPersonPageData>) => (
                    <RadioGroup
                      name={field.name}
                      selectedValue={field.value || requestOfferPersonalDataPage.MYSELF}
                      options={this.radioGroupOptions}
                      onChangeSelection={(name: string, value: RadioTileValue) => {
                        form.setFieldValue('birthdateField', undefined);
                        this.handleSelectOption(name, String(value), form);
                        this.updateTracker(value);
                      }}
                    ></RadioGroup>
                  )}
                />
              </div>
              {form.values.insuredPerson === requestOfferPersonalDataPage.DIFFERENT_PERSON && (
                <div className="insured-person__other-birthdate">
                  <InfoText textBeforeLink="Um den Beitrag zu errechnen, ist das Geburtsdatum des Versicherungsnehmers erforderlich." />
                  <div className="birthdate-form__body">
                    <FieldDateInputRow
                      name="birthdate"
                      date={form.values.birthdate}
                      label={''}
                      formError={(form.touched.birthdate && form.errors.birthdate) || undefined}
                      onError={(error: string | undefined) =>
                        form.touched.birthdate && form.setStatus(error)
                      }
                    />
                  </div>
                </div>
              )}
              <AemFragment name={this.state.virtualAssistantFragment} useVA={true} />
              <footer data-component-id="request-offer-diverging-insured-person">
                <Footer
                  showNext={true}
                  showPrevious={true}
                  labelNext="weiter"
                  labelPrevious="zurück"
                  disableNext={!this.isNextButtonEnabled(form)}
                  handleAction={(action: NavigationAction) => {
                    if (action === NavigationAction.NEXT) {
                      this.handleNextAction(form);
                    } else {
                      this.props.handleAction(action);
                    }
                  }}
                  infoSection={this.InfoText}
                />
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  private handleNextAction(form: FormikProps<RequestOfferDivergingInsuredPersonPageData>) {
    if (form.values.insuredPerson === requestOfferPersonalDataPage.MYSELF) {
      this.props.handleAction(NavigationAction.NEXT);
    } else {
      form.submitForm();
    }
  }

  private updateTracker(value: string | number | boolean | undefined) {
    if (value === requestOfferPersonalDataPage.MYSELF) {
      this.props.tracker.updateUserAttributes({
        insuredIsInsuredPerson: TrackingBoolean.YES,
      });
      this.props.tracker.trackClickEvent({
        clickedElement: TrackingElementDivergingInsuredPerson.RADIOBUTTON_SELF,
      });
    } else if (value === requestOfferPersonalDataPage.DIFFERENT_PERSON) {
      this.props.tracker.updateUserAttributes({
        insuredIsInsuredPerson: TrackingBoolean.NO,
      });
      this.props.tracker.trackClickEvent({
        clickedElement: TrackingElementDivergingInsuredPerson.RADIOBUTTON_DIVERGING,
      });
    }
  }

  private isNextButtonEnabled(
    form: FormikProps<RequestOfferDivergingInsuredPersonPageData>,
  ): boolean {
    if (form.values.insuredPerson === requestOfferPersonalDataPage.MYSELF) {
      return true;
    } else if (form.values.insuredPerson === requestOfferPersonalDataPage.DIFFERENT_PERSON) {
      return (
        (!form.dirty && !!this.props.storeState.vn.birthdate) ||
        (form.dirty &&
          form.isValid &&
          !form.errors.birthdate &&
          !!form.values.birthdate &&
          !form.status)
      );
    }
    return true;
  }

  private readonly handleSelectOption = (
    name: string,
    value: string,
    form: FormikProps<RequestOfferDivergingInsuredPersonPageData>,
  ) => {
    const { setFieldValue } = form;
    setFieldValue(name, value);
    setFieldValue(
      'divergingInsuredPerson',
      value === requestOfferPersonalDataPage.DIFFERENT_PERSON,
    );
    this.props.storeState.update({
      divergingInsuredPerson: value === requestOfferPersonalDataPage.DIFFERENT_PERSON,
      insuredPerson: value,
    });
    this.setVirtualAssistantFragment(value === requestOfferPersonalDataPage.DIFFERENT_PERSON);
  };

  private readonly handleBirthdate = (value: string | undefined) => {
    this.props.storeState.update({
      birthdateVn: value,
    });
  };

  private readonly setVirtualAssistantFragment = (isDifferentPerson: boolean): void => {
    const fragment = isDifferentPerson ? AemFragmentCatalog.STEP2VA2 : AemFragmentCatalog.STEP2VA;
    this.setState({ virtualAssistantFragment: fragment });
  };
}

export default withTracker(RequestOfferDivergingInsuredPersonPageNew);
