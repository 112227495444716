import * as React from 'react';
import { Storage } from '../../helpers/Storage';
import { getVarianteFromPossibleValues } from '../../helpers/variant.utils';
import { useHeaderHeight } from '../../hooks/useHeaderHeight/useHeaderHeight';
import { StateName } from '../../routing/StateMachineTypes';
import { PagePercentage } from '../../types/PagePercentage';
import { SessionStorageKeys } from '../../types/SessionStorage';
import ProgressBar from './ProgressBar/ProgressBar';
import StateHeader from './StateHeader/StateHeader';
import { StatusRibbonProps } from './StatusRibbon.types';

import './StatusRibbon.css';

const StatusRibbon = ({
  tariffTypes,
  onError,
  stateName,
  progress,
  duwProgress,
}: StatusRibbonProps): React.ReactElement => {
  const { customSizes } = useHeaderHeight();

  const mapVariante = () => {
    try {
      const variante = Storage.readItem(SessionStorageKeys.VARIANTE);
      return variante && tariffTypes && getVarianteFromPossibleValues(variante, tariffTypes);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
        return error.message;
      }
      throw error;
    }
  };

  const getPercentage = (): number => {
    const percentage = progress || 0;
    if (stateName === StateName.DUW_QUESTIONNAIRE_PAGE) {
      return (
        percentage +
        ((PagePercentage.DUW_ASSESSMENT - PagePercentage.DUW_QUESTIONAIRE) * duwProgress) / 100
      );
    }
    return percentage;
  };

  return (
    <div
      className="status-ribbon__sticky-box"
      style={{ top: customSizes.topSize, marginBottom: customSizes.marginSize }}
    >
      <div data-testid="status_ribbon" className="status-ribbon">
        <ProgressBar percentage={getPercentage()} />
        <div className="status-ribbon__header">
          <StateHeader
            subline={mapVariante()}
            payment={Storage.readItem(SessionStorageKeys.PAYMENT_METHOD)}
            amount={Storage.readItem(SessionStorageKeys.PRICE_NETTO)}
          ></StateHeader>
        </div>
      </div>
    </div>
  );
};

export default StatusRibbon;
