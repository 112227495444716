import Checkbox from '@eg/elements/Checkbox';
import TooltipIcon from '@eg/elements/TooltipIcon';
import * as React from 'react';
import { Beitragsvariante, InsuranceSettingKey, Need } from 'rlv-common';
import { TrackingElementFeePage } from '../../../../tracking/tracking.types';
import { RadioTabCheckboxProps } from '../RadioTab.types';
import {
  DYNAMIC_PAYMENT_TOOLTIP,
  SECURITY_PLUS_TOOLTIP
} from '../RadoTabTooltips';

const RadioTabCheckbox = (props: RadioTabCheckboxProps) => {
  const basisSelection: boolean = props.variant === Beitragsvariante.BASIS;

  return (
    <div className="tab-content-checkboxes" data-testid="radio-tab-checkout">
      <div className="tab-content-title">Weitere Optionen</div>

      <div className="tab-content-checkbox">
        <Checkbox
          disabled={basisSelection}
          verticalAlign="top"
          checked={!basisSelection && props.securityPlusValue && props.securityPlusValue.insuranceSettingValue}
          onChange={e => {
            props.checkboxCallback(
              e.target.checked,
              InsuranceSettingKey.SICHERHEIT_PLUS
            );
          }}
          label={
            <div>
              <div className="tab-content-checkbox-header">Sicherheit Plus</div>
              <div className="tab-content-checkbox-subtext">
                Garantiert gleichbleibende Nettobeiträge
              </div>
            </div>
          }
        />
        <TooltipIcon
          className="tab-checkbox-tooltip-icon"
          isModal
          onToggledOpen={isOpen => {
            if (isOpen) {
              props.trackClick(TrackingElementFeePage.INFOICON_SECRUITY_PLUS);
            }
          }}
        >
          {SECURITY_PLUS_TOOLTIP}
        </TooltipIcon>
      </div>
      {props.insuranceTerm === Need.KONSTANT ? (
        <div className="tab-content-checkbox">
          <Checkbox
            verticalAlign="top"
            checked={
              props.dynamicPaymentValue
                ? props.dynamicPaymentValue.insuranceSettingValue
                : false
            }
            label={
              <div>
                <div className="tab-content-checkbox-header">
                  Beitragsdynamik
                </div>
                <div className="tab-content-checkbox-subtext tab-content-checkbox-subtext__dynamics">
                  Dynamische Erhöhung um 3 % jährl.
                </div>
              </div>
            }
            onChange={e => {
              props.checkboxCallback(
                e.target.checked,
                InsuranceSettingKey.BEITRAGSDYNAMIK
              );
            }}
          />
          <TooltipIcon
            className="tab-checkbox-tooltip-icon"
            isModal
            onToggledOpen={isOpen => {
              if (isOpen) {
                props.trackClick(TrackingElementFeePage.INFOICON_DYNAMIC_PAYMENT);
              }
            }}
          >
            {DYNAMIC_PAYMENT_TOOLTIP}
          </TooltipIcon>
        </div>
      ) : undefined}
    </div>
  );
};

export default RadioTabCheckbox;
