import { NavigationAction } from '../../../routing/StateMachineTypes';

export interface DuwAssessmentProps {
  duwStatus?: DuwStatus;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  paymentMethod?: string;
  nettoRiskSurcharge?: number;
  price?: number;
  tariff: string;
}

export interface DuwAssessmentDetail {
  text: (price: JSX.Element) => JSX.Element;
  icon: JSX.Element;
}

export interface DuwAssessmentData {
  details: DuwAssessmentDetail[];
  tariff: (tariff: string) => JSX.Element;
}

export enum DuwStatus {
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_WITH_DIFFICULTIES = 'ACCEPTED_WITH_DIFFICULTIES',
  MANUAL_RISKCHECK = 'MANUAL_RISKCHECK',
  REQUIRE_FURTHER_DOCUMENTS = 'REQUIRE_FURTHER_DOCUMENTS',
  DEFERRED = 'DEFERRED',
  REJECTED = 'REJECTED',
}

export type AssessementResults = {
  [key in DuwStatus]: DuwAssessmentData;
};
