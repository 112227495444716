import * as React from 'react';
import './InfoText.css';

import { IInfoText } from './InfoText.types';

const InfoText: IInfoText = ({
  linkUrl,
  linkText,
  textBeforeLink,
  textAfterLink,
  onClick,
  styles = '',
}) => (
  <p className={`infoText__text ${styles}`}>
    {textBeforeLink}&nbsp;
    {linkUrl !== '' ? (
      <a className="infoText__hyperlink" href={linkUrl} target="_blank" onClick={onClick}>
        {linkText}
      </a>
    ) : (
      `${linkText} `
    )}
    {textAfterLink}
  </p>
);

export default InfoText;
