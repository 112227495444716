import React, { FC, useEffect } from 'react';
import { useKeyPress } from '../../hooks/useKeyPress/useKeyPress';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { TrackingElementBasic } from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { BackButton, NextButton } from './index';
import './Footer.css';

export interface FooterProps extends InjectedTrackerProps {
  showNext: boolean;
  showPrevious: boolean;
  auxButtons?: JSX.Element | JSX.Element[];
  disableNext?: boolean;
  infoSection?: JSX.Element;
  labelNext?: string;
  labelPrevious?: string;
  stepNext?: number;
  handleAction: HandleActionCallback;
  handleBack?: () => void;
  handleNext?: () => void;
}

const Footer: FC<FooterProps> = ({
  showNext,
  showPrevious,
  auxButtons,
  disableNext,
  infoSection,
  labelNext,
  labelPrevious,
  stepNext,
  tracker,
  handleAction,
  handleBack,
  handleNext,
}) => {
  const enterPressed: boolean = useKeyPress('Enter');

  const handleSubmit = () => {
    if (handleNext) {
      handleNext();
    } else {
      tracker.trackClickEvent({ clickedElement: TrackingElementBasic.BUTTON_NEXT });
      handleAction(NavigationAction.NEXT);
    }
  };

  const goBack = () => {
    tracker.trackClickEvent({ clickedElement: TrackingElementBasic.BUTTON_BACK });
    if (handleBack) {
      handleBack();
    } else {
      handleAction(NavigationAction.BACK);
    }
  };

  useEffect(() => {
    if (enterPressed && !disableNext) {
      handleSubmit();
    }
  }, [enterPressed]);

  return (
    <div className="footer__container">
      <div className="footer__buttons">
        {showNext && (
          <NextButton
            className="footer__button"
            type="button"
            label={labelNext}
            step={stepNext}
            onClick={handleSubmit}
            disableNext={disableNext}
          />
        )}
        {auxButtons}
        {showPrevious && (
          <BackButton className="footer__button" label={labelPrevious} onClick={goBack} />
        )}
      </div>
      {infoSection}
    </div>
  );
};

export default withTracker(Footer);
