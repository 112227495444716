import * as React from 'react';
import { AemQuellsystem, CreateRequest } from 'rlv-common';
import { shouldShowPublicView } from '../Helper';
import { Storage } from '../helpers/Storage';
import { extractAktionsNummerFromCookie } from '../helpers/cookieExtractor';
import { getIsTiedAgent, modeConfig } from '../helpers/modeConfig';
import StateMachine from '../routing/StateMachine';
import { stateMachineStates } from '../routing/routes';
import { createAngebot } from '../services/api';
import { InjectedTrackerProps, withTracker } from '../tracking/withTracker';
import { SessionStorageKeys } from '../types/SessionStorage';
import AppLoader from './AppLoader';
import { ErrorModal } from './ErrorModal/ErrorModal';

export interface AppProps extends InjectedTrackerProps {}

export interface AppState {
  businessId?: string;
  isUpdateCalling: boolean;
  isInitFetchError: boolean;
  debugLogs?: boolean;
}

export class App extends React.Component<AppProps, AppState> {
  public constructor(props: AppProps) {
    super(props);
    this.state = {
      isUpdateCalling: false,
      isInitFetchError: false,
    };
  }

  public async componentDidMount() {
    await this.initFromSessionStorage();
    const appDataFromStorage = Storage.readItem(SessionStorageKeys.APP_DATA);

    if (!appDataFromStorage) {
      const appData = await modeConfig();
      Storage.writeItem(SessionStorageKeys.APP_DATA, JSON.stringify(appData));
    }
  }

  public componentDidUpdate(_: AppProps, prevState: AppState) {
    if (this.state.businessId && prevState.businessId !== this.state.businessId) {
      Storage.writeItem(SessionStorageKeys.BUSINESS_ID, this.state.businessId);
    }

    if (prevState.debugLogs !== this.state.debugLogs) {
      Storage.writeItem('debugLogs', String(this.state.debugLogs));
    }
  }

  public render() {
    return (
      <>
        <AppLoader show={this.state.isUpdateCalling} />
        <ErrorModal
          onClick={this.initAngebot}
          open={this.state.isInitFetchError}
          businessId={this.state.businessId || ''}
        />
        {this.renderMainComponent()}
      </>
    );
  }

  private async initFromSessionStorage() {
    const businessId = Storage.readItem(SessionStorageKeys.BUSINESS_ID);
    if (businessId && businessId !== 'undefined') {
      this.setState({
        businessId,
      });
      this.props.tracker.init(businessId);
    } else {
      await this.initAngebot();
    }
  }

  private readonly initAngebot = async () => {
    try {
      this.setState({
        isUpdateCalling: true,
        isInitFetchError: false,
      });

      const req: CreateRequest | { aktionsnummer: string } = {
        aktionsnummer: extractAktionsNummerFromCookie(),
      };
      const reqWithQuellSystem: CreateRequest | { aktionsnummer: string } = {
        aktionsnummer: extractAktionsNummerFromCookie(),
        quellsystem: shouldShowPublicView(getIsTiedAgent())
          ? AemQuellsystem.ERGO_AGENTHOMEPAGE
          : AemQuellsystem.ERGO_INTERNET,
      };

      const data = await createAngebot(reqWithQuellSystem);

      if (data) {
        this.setState({
          businessId: data.businessId,
          debugLogs: data.debugLogs,
        });
        this.props.tracker.init(data.businessId);
      }
    } catch (error) {
      this.setState({
        isUpdateCalling: false,
        isInitFetchError: true,
      });
    } finally {
      this.setState({
        isUpdateCalling: false,
      });
    }
  };

  private renderMainComponent(): JSX.Element | undefined {
    const { businessId } = this.state;
    if (!businessId) {
      return undefined;
    }

    return (
      <div data-cat-id={this.state.businessId} data-version="2.9.0">
        <StateMachine
          stateDefinitions={stateMachineStates}
          inputData={{
            businessId,
          }}
        />
      </div>
    );
  }
}

export default withTracker(App);
