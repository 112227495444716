import { TextValue, ValueRanges, Variante } from 'rlv-common';
import { CheckOutPageData } from '../../legalQuestionsPageContainer';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';

// -------------------------------
// ##### FUNCTION INTERFACES #####
// -------------------------------
export type MapCheckoutValues = (
  checkouData: CheckOutPageData,
  valueRanges?: ValueRanges,
  varianten?: Variante[],
) => Array<[any, string]>;
export type MapBeneficiariesValues = (
  checkouData: CheckOutPageData,
  valueRanges?: ValueRanges,
) => Array<Array<[any, string]>>;
export type MapDuwValues = (questionsWithAnswers: DuwAnswers[]) => Array<[any, string]>;
export type FormatFunction = (value: any, options?: any) => string;

// -----------------------------
// ##### BASE TYPES #####
// -----------------------------
export type PossibleValues = TextValue[] | undefined;
export type ValuesFromServerI = string | number | boolean;
export interface DuwAnswers {
  question: string;
  answer: string | undefined;
}

// -----------------
// ##### ENUMS #####
// -----------------
export enum SummaryPageStates {
  ONLINE = 'online',
  OFFLINE_SELF = 'offline_self',
  OFFLINE_OTHER = 'offline_other',
}

/**
 * These enums control the Text that should be displayed in every description block
 *
 * @enum [XXX]Properties - Controls which value should be picked from CheckOutPageData model (dd)
 * @enum [XXX]UIOptions - Allows to change the UI value which is going to be displayed as a title of the block (dt)
 */

// ***** Tarif und Beitrag ( Tariff and Contribution ) *****
export enum TariffAndContributionProperties {
  TARIF = 'variante',
  // Next tariff values don't come directly from server and should be mapped
  DYNAMIK = 'DYNAMIK',
  SAFETY_PLUS = 'SAFETY_PLUS',
  GROSS_CONTRIBUTION = 'GROSS_CONTRIBUTION',
  GROSS_CONTRIBUTION_RISK = 'GROSS_CONTRIBUTION_RISK',
  PAYMENT_CONTRIBUTION = 'PAYMENT_CONTRIBUTION',
  PAYMENT_CONTRIBUTION_RISK = 'PAYMENT_CONTRIBUTION_RISK',
}

export enum TariffAndContributionUIOptions {
  TARIF = 'Tarif',
  DYNAMIK = 'Dynamik', // optional
  SAFETY_PLUS = 'Sicherheit Plus', // optional
  GROSS_CONTRIBUTION = 'Bruttobeitrag',
  GROSS_CONTRIBUTION_RISK = 'Brutto-Risikozuschlag',
  PAYMENT_CONTRIBUTION = 'Zahlbeitrag (netto)*',
  PAYMENT_CONTRIBUTION_RISK = 'Netto-Risikozuschlag',
}

// ***** Absicherungsbedarf ( Hedging Needs ) *****
export enum HedgingNeedsProperties {
  INSURANCE_HISTORY = 'need',
  SUM_INSURED = 'sumInsured',
  SUM_INSURED_FIRST_YEAR = 'sumInsuredStart',
  INSURANCE_TERM = 'periodInYears',
  SUM_INSURED_LAST_YEAR = 'insuranceEndSum',
  START_OF_INSURANCE = 'inceptionDate',
  END_OF_INSURANCE = 'insuranceEndDate',
}

export enum HedgingNeedsUIOptions {
  INSURANCE_HISTORY = 'Versicherungsverlauf fallend/konstant',
  SUM_INSURED = 'Versicherungssumme',
  SUM_INSURED_FIRST_YEAR = 'Versicherungssumme (1. Jahr)', // alternative
  INSURANCE_TERM = 'Versicherungslaufzeit',
  SUM_INSURED_LAST_YEAR = 'Versicherungssumme (letztes Jahr)', // alternative
  START_OF_INSURANCE = 'Versicherungsbeginn',
  END_OF_INSURANCE = 'Versicherungsende',
}

// ***** Risikoeinschätzung ( Risk Assessment ) *****
export enum RiskAssessmentProperties {
  EMPLOYMENT_RELATIONSHIP = 'professionLabel',
  PROFESSION_PRACTISED = 'employment',
  SMOKER = 'smoker',
  // Next RiskAssesment value doesn't come directly from server and should be mapped
  GENDER = 'GENDER',
  SIZE = 'height',
  WEIGHT = 'weight',
  OWNER_OCCUPIED_RESIDENTIAL_PROPERTY = 'propertyOwner',
  MOTORCYCLIST = 'biker',
}

export enum RiskAssessmentUIOptions {
  EMPLOYMENT_RELATIONSHIP = 'Beschäftigungsverhältnis',
  PROFESSION_PRACTISED = 'Ausgeübter Beruf',
  SMOKER = 'Raucher',
  GENDER = 'Geschlecht',
  SIZE = 'Größe',
  WEIGHT = 'Gewicht',
  OWNER_OCCUPIED_RESIDENTIAL_PROPERTY = 'Selbstgenutztes Wohneigentum',
  MOTORCYCLIST = 'Motorradfahrer',
}

// ***** Persönliche Daten des Versicherungsnehmers ( Personal Data of the Policy Holder -- ONLINE ) *****
export enum PersonalDataOfThePolicyHolderOnlineProperties {
  SALUTATION = 'GENRE',
  FIRST_NAME = 'vorname',
  LAST_NAME = 'nachname',
  DATE_OF_BIRTH = 'birthdate',
  STREET_HOUSE_NUMBER = 'hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'plz',
  NATIONALITY = 'staatsangehoerigkeit',
  BIRTHPLACE = 'geburtsort',
  EMAIL_ADDRESS = 'email',
  TELEPHONE_NUMBER = 'rufnummer',
}

export enum PersonalDataOfThePolicyHolderOnlineUIOptions {
  SALUTATION = 'Anrede',
  FIRST_NAME = 'Vorname',
  LAST_NAME = 'Nachname',
  DATE_OF_BIRTH = 'Geburtsdatum',
  STREET_HOUSE_NUMBER = 'Straße/Hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'PLZ/Wohnort',
  NATIONALITY = 'Staatsangehörigkeit',
  BIRTHPLACE = 'Geburtsort',
  EMAIL_ADDRESS = 'E-Mail-Adresse',
  TELEPHONE_NUMBER = 'Telefonnummer',
}

// ***** Persönliche Daten des Versicherungsnehmers ( Personal Data of the Policy Holder -- OFFLINE ) *****
export enum PersonalDataOfThePolicyHolderOfflineProperties {
  SALUTATION = 'GENRE',
  FIRST_NAME = 'vorname',
  LAST_NAME = 'nachname',
  DATE_OF_BIRTH = 'birthdate',
  STREET_HOUSE_NUMBER = 'hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'plz',
  NATIONALITY = 'staatsangehoerigkeit',
  BIRTHPLACE = 'geburtsort',
  EMAIL_ADDRESS = 'email',
  TELEPHONE_NUMBER = 'rufnummer',
}

export enum PersonalDataOfThePolicyHolderOfflineUIOptions {
  SALUTATION = 'Anrede',
  FIRST_NAME = 'Vorname',
  LAST_NAME = 'Nachname',
  DATE_OF_BIRTH = 'Geburtsdatum',
  STREET_HOUSE_NUMBER = 'Straße/Hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'PLZ/Wohnort',
  NATIONALITY = 'Staatsangehörigkeit',
  BIRTHPLACE = 'Geburtsort',
  EMAIL_ADDRESS = 'E-Mail-Adresse',
  TELEPHONE_NUMBER = 'Telefonnummer',
}

// ***** Persönliche Daten der versicherten Person - optional ( Personal Data of the Insured Person ) *****
export enum PersonalDataOfTheInsuredPersonProperties {
  SALUTATION = 'GENRE',
  FIRST_NAME = 'vorname',
  LAST_NAME = 'nachname',
  DATE_OF_BIRTH = 'birthdate',
  STREET_HOUSE_NUMBER = 'hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'plz',
  NATIONALITY = 'vpNationalityText',
  BIRTHPLACE = 'geburtsort',
}

export enum PersonalDataOfTheInsuredPersonUIOptions {
  SALUTATION = 'Anrede',
  FIRST_NAME = 'Vorname',
  LAST_NAME = 'Nachname',
  DATE_OF_BIRTH = 'Geburtsdatum',
  STREET_HOUSE_NUMBER = 'Straße/Hausnummer',
  POSTCODE_PLACE_OF_RESIDENCE = 'PLZ/Wohnort',
  NATIONALITY = 'Staatsangehörigkeit',
  BIRTHPLACE = 'Geburtsort',
}

// ***** Bankverbindung ( Bank details ) *****
export enum BankDetailsProperties {
  ACCOUNT_HOLDER = 'kontoinhaber',
  IBAN = 'iban',
  BIC = 'bic',
  FINANCIAL_INSTITUTION = 'geldinstitut',
}

export enum BankDetailsUIOptions {
  ACCOUNT_HOLDER = 'Kontoinhaber',
  IBAN = 'IBAN',
  BIC = 'BIC',
  FINANCIAL_INSTITUTION = 'Geldinstitut',
}

// ***** Bezugsberechtigte Person(en) ( Beneficiary ) *****
export enum BeneficiaryProperties {
  SALUTATION = 'need',
  FIRST_NAME = 'need',
  LAST_NAME = 'need',
  RELATIONSHIP = 'need',
  PERCENTAGE_SUBSCRIPTION_RIGHT = 'need',
}

export enum BeneficiaryUIOptions {
  SALUTATION = 'Anrede',
  FIRST_NAME = 'Vorname',
  LAST_NAME = 'Nachname',
  RELATIONSHIP = 'Verwandtschaftsverhältnis',
  PERCENTAGE_SUBSCRIPTION_RIGHT = 'Prozentuales Bezugsrecht',
}

// -----------------
// ##### TEXTS #####
// -----------------
export enum SummaryTexts {
  TITLE = 'Sind Ihre Angaben richtig und vollständig?',

  VIRTUAL_ASSISTANT = 'Fast geschafft! Übrigens: Sie gehen kein Risiko ein. Denn Sie haben nach dem Abschluss die Möglichkeit, den Vertrag innerhalb von 30 Tagen zu widerrufen.',

  INFO_TEXT = 'Durch die Gewinn-Beteiligung reduziert sich Ihr Brutto-Beitrag, so dass Sie nur den genannten Netto-Betrag zahlen. Dieser Netto-Betrag kann nicht für die gesamte Vertragsdauer garantiert werden. Er gilt, solange die Gewinn-Anteile unverändert bleiben.',
}

export enum SummaryTextOfflineSelf {
  TITLE = 'Zusammenfassung Personendaten',

  VIRTUAL_ASSISTANT = 'Mein Tipp: Laden Sie sich doch einfach Ihr Angebot sofort als PDF-Datei herunter. Es ist unverbindlich und Sie gehen dabei kein Risiko ein.',

  INFO_TEXT = 'Durch die Gewinn-Beteiligung reduziert sich Ihr Brutto-Beitrag, so dass Sie nur den genannten Netto-Betrag zahlen. Dieser Netto-Betrag kann nicht für die gesamte Vertragsdauer garantiert werden. Er gilt, solange die Gewinn-Anteile unverändert bleiben.Dieser Beitrag ist vorläufig: Nach der Gesundheitsprüfung können noch Risikozuschläge anfallen.',
}

export enum SummaryTextOfflineOther {
  TITLE = 'Zusammenfassung Personendaten',

  VIRTUAL_ASSISTANT = 'Mein Tipp: Laden Sie sich doch einfach Ihr Angebot sofort als PDF-Datei herunter. Es ist unverbindlich und Sie gehen dabei kein Risiko ein.',

  INFO_TEXT = 'Durch die Gewinn-Beteiligung reduziert sich Ihr Brutto-Beitrag, so dass Sie nur den genannten Netto-Betrag zahlen. Dieser Netto-Betrag kann nicht für die gesamte Vertragsdauer garantiert werden. Er gilt, solange die Gewinn-Anteile unverändert bleiben.Dieser Beitrag ist vorläufig: Nach der Gesundheitsprüfung können noch Risikozuschläge anfallen. ',
}

export const TEXT_CONFIG = {
  [SummaryPageStates.ONLINE]: SummaryTexts,
  [SummaryPageStates.OFFLINE_SELF]: SummaryTextOfflineSelf,
  [SummaryPageStates.OFFLINE_OTHER]: SummaryTextOfflineOther,
};

export const TEXT_CONFIG_FRAGMENT = {
  [SummaryPageStates.ONLINE]: AemFragmentCatalog.STEP20VA1,
  [SummaryPageStates.OFFLINE_SELF]: AemFragmentCatalog.STEP20VA2,
  [SummaryPageStates.OFFLINE_OTHER]: AemFragmentCatalog.STEP20VA2,
};
