import { PeriodInYears } from '../types/PeriodInYears';

export const EURO_WITHOUT_CENT: number = 0;
export const EURO_WITH_CENT: number = 2;

export const formatCurrency = (fractionDigits: number) => new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: fractionDigits
});

export const removeCurrency = (valueWithCurrency: string): string => valueWithCurrency ? valueWithCurrency.replace(/\./g, '').replace('€', '').trim() : '';

export const yearFormatter = (periodInYears: number): string => periodInYears > 1 ? PeriodInYears.YEARS : PeriodInYears.YEAR;
