import { ValueRanges } from 'rlv-common/types';
import { number as yupNumber, object, ref, Schema } from 'yup';
import { EURO_WITHOUT_CENT, formatCurrency } from '../helpers/formattingHelper';
import { CustomMixedSchema } from './validation';
require('./validation.ts'); // required for CustomMixedSchema to work

export const createAnnuityNeedSchema = (
  valueRanges: ValueRanges,
  selectedLoanSum: number,
): Schema<any> => {
  const currencyFormatter = formatCurrency(EURO_WITHOUT_CENT);
  const STEP_RANGE_SUM_INSURED = 1000;
  const DARLEHENSSUMME = 15000;
  const maxBasisSum = selectedLoanSum - DARLEHENSSUMME;

  const loanRate = valueRanges.loanRate;
  const loanRateMinMaxError = `Der Zinssatz liegt online bei ${loanRate.min} bis ${loanRate.max}. Bitte korrigieren Sie die Angabe.`;

  const repaymentFreePeriodInYears = valueRanges.repaymentFreePeriodInYears;
  const repaymentFreePeriodInYearsMinMaxError = `Die tilgungsfreie Zeit muss ${repaymentFreePeriodInYears.min} bis ${repaymentFreePeriodInYears.max}
   Jahre betragen. Bitte korrigieren Sie die Angabe.`;

  const sum = valueRanges.insuranceSum;
  const sumInsuredMinMaxError = `Die Versicherungssumme liegt online bei ${currencyFormatter.format(
    sum.min,
  )} bis ${currencyFormatter.format(sum.max)}.
  Bitte korrigieren Sie die Angabe.`;

  const duration = valueRanges.insuranceDuration;
  const periodInYearsMinMaxError = `Die Laufzeit beträgt mindestens ${duration.min}, maximal ${duration.max} Jahre.
  Bitte korrigieren Sie die Angabe.`;

  return object().shape({
    periodInYears: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie die gewünschte Laufzeit an.')
      .min(duration.min, periodInYearsMinMaxError)
      .max(duration.max, periodInYearsMinMaxError),
    loanSum: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie die Darlehenssumme an.')
      .min(sum.min, sumInsuredMinMaxError)
      .max(sum.max, sumInsuredMinMaxError)
      .test(
        'isFullThousandEuro',
        'Es können nur ganze Tausend Eurobeträge als Darlehenssumme eingegeben werden.',
        value => value % STEP_RANGE_SUM_INSURED === 0,
      ),
    loanRate: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie einen Zinssatz ein.')
      .min(loanRate.min, loanRateMinMaxError)
      .max(loanRate.max, loanRateMinMaxError)
      .test('isLoanRate', loanRateMinMaxError, value => {
        const input: number = value as number;
        return input >= loanRate.min && input <= loanRate.max;
      }),
    loanPeriodInYears: (yupNumber() as any)
      .validateNumber()
      .required('Bitte geben Sie die Darlehenslaufzeit an.')
      .isBiggerThan(
        ref('repaymentFreePeriodInYears'),
        'Bitte beachten Sie, dass die Laufzeit nicht kleiner als die tilgungsfreie Zeit sein darf.',
      )
      .min(duration.min, periodInYearsMinMaxError)
      .max(duration.max, periodInYearsMinMaxError),
    repaymentFreePeriodInYears: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie eine tilgungsfreie Startphase an.')
      .min(repaymentFreePeriodInYears.min, repaymentFreePeriodInYearsMinMaxError)
      .max(repaymentFreePeriodInYears.max, repaymentFreePeriodInYearsMinMaxError),
    basisSum: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .isSmallerThanDarlehnssumme(maxBasisSum)
      .isNotNullWhenPeriodBiggerThanloanPeriod(
        ref('periodInYears'),
        ref('repaymentFreePeriodInYears'),
        ref('loanPeriodInYears'),
      )
      .test(
        'isFullThousandEuro',
        'Es können nur ganze Tausend Eurobeträge als Grundsumme eingegeben werden.',
        value => value % STEP_RANGE_SUM_INSURED === 0 || value === undefined,
      ),
  });
};
