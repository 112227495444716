import * as React from 'react';
import { isProduction } from '../../constants';
import { Storage } from '../../helpers/Storage';
import { SessionStorageKeys } from '../../types/SessionStorage';
import AemLogo from '../../images/aem-logo.png';
import './AemMockWrapper.css';

const AemMockWrapper = ({ children }: any): JSX.Element => {
  const [wrapContentinAem, setWrapContentinAem] = React.useState(true);
  const toggleWrapper = () => {
    if (wrapContentinAem) {
      setWrapContentinAem(false);
    }
    if (!wrapContentinAem) {
      setWrapContentinAem(true);
    }
  };

  React.useEffect(() => {
    Storage.writeItem(SessionStorageKeys.AEM_WRAPPER, JSON.stringify(wrapContentinAem));
  }, [wrapContentinAem]);

  const shouldShowAemWrapper = !isProduction && !window.params;

  return shouldShowAemWrapper ? (
    <>
      {wrapContentinAem ? (
        <div className="aem__esc_container">
          <div className="aem__full-grid">
            <div className="aem__GridColumn">{children}</div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}

      <div className="aem__fab-container" onClick={toggleWrapper}>
        <img
          src={AemLogo}
          alt="aem-logo"
          className="aem__fab"
          style={wrapContentinAem ? { opacity: 1 } : { opacity: 0.5 }}
        />
        {wrapContentinAem ? <span>AEM is On</span> : <span>AEM is Off</span>}
      </div>
    </>
  ) : (
    <>{children}</>
  );
};

export default AemMockWrapper;
