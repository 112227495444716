export enum Geschlecht {
  WEIBLICH = 'WEIBLICH',
  MAENNLICH = 'MAENNLICH'
}

interface BasisData {
  geschlecht: Geschlecht;
  gewicht: number;
  groesse: number;
}

export default BasisData;
