import * as React from 'react';
import { handleFormErrors } from '../../tracking/trackingFormating';

export const usePreviousAndTrackError = <T extends object>(initialState: T) => {
  const errors = React.useRef(initialState);

  const compareErrors = (field: string, newError: string | undefined) => {
    if (!newError && errors.current[field]) {
      errors.current = { ...errors.current, [field]: '' };
    } else if (newError && errors.current[field] !== newError) {
      handleFormErrors(newError);
      errors.current = { ...errors.current, [field]: newError };
    }
  };

  return { compareErrors };
};
