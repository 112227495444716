import Checkbox from '@eg/elements/Checkbox';
import PdfIcon from '@eg/elements/Icons/PdfIcon';
import { Form, Formik } from 'formik';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import { getLocalDocRoutes } from '../../../../configuration/serviceConfiguration';
import { Storage } from '../../../../helpers/Storage';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementConsentDataProcessing } from '../../../../tracking/tracking.types';
import { handleFormErrors } from '../../../../tracking/trackingFormating';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { SessionStorageKeys } from '../../../../types/SessionStorage';
import './ConsentToDataProcessing.css';
import { consentToDataProcessingData } from './ConsentToDataProcessing.data';
import { CheckboxData, ConsentToDataProcessingProps } from './ConsentToDataProcessing.types';

export const ConsentToDataProcessing = ({
  onSubmit,
  onNavigateNoSubmit,
  trackClickEvent,
  bussisnesId,
}: ConsentToDataProcessingProps) => {
  const storedConsent: boolean =
    Storage.readItem(SessionStorageKeys.CONSENT_TO_DATA_PROCESSING) === 'true';
  const [checkedCheckboxes, setCheckedCheckboxes] = React.useState(
    storedConsent ? [true, true] : [false, false],
  );
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);

  const staticDocuments = ['Datenschutz_Infopflichten und Boni SEE.pdf'];

  const pdfLinkHref: string = getLocalDocRoutes(staticDocuments)[0];

  const handleCheckboxChange =
    (optionIdx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      trackClickEvent(
        optionIdx === 0
          ? TrackingElementConsentDataProcessing.CHECKBOX_DATAPROCESSING
          : TrackingElementConsentDataProcessing.CHECKBOX_HEALTHDATA,
      );
      setShowCheckboxError(false);
      const updatedCheckedCheckboxes = checkedCheckboxes.map((item, index) =>
        index === optionIdx ? !item : item,
      );
      setCheckedCheckboxes(updatedCheckedCheckboxes);
      setConsent(updatedCheckedCheckboxes);
    };

  const setConsent = (checkboxes: boolean[]) => {
    const consent = checkboxes.every(v => v);
    Storage.writeItem(SessionStorageKeys.CONSENT_TO_DATA_PROCESSING, consent.toString());
    return consent;
  };

  if (showCheckboxError) {
    handleFormErrors(consentToDataProcessingData.sections[1]?.errorMessage as string);
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        const consent = setConsent(checkedCheckboxes);
        setShowCheckboxError(!consent);
        if (consent) {
          onNavigateNoSubmit(NavigationAction.NEXT);
        }
      }}
      validationSchema={undefined}
    >
      {form => (
        <Form className="container consent-to-data-processing">
          <Headline text={consentToDataProcessingData.titleText} />
          <div
            className="consent-to-data-processing__sections-container"
            id="consent-to-data-processing__sections-container"
          >
            {consentToDataProcessingData.sections.map((section, idx) => (
              <div
                className="consent-to-data-processing__section"
                key={`consent-to-data-processing__section-${idx}`}
              >
                <h3 className="consent-to-data-processing__section-subtitle">{section.subtitle}</h3>
                <div className={`section consent-to-data-processing__section-${idx}`}>
                  {section.pdfLabel ? (
                    <div
                      className="consent-to-data-processing__pdf-link"
                      key={`consent-to-data-processing__section-${idx}__option-0`}
                    >
                      <div className="consent-to-data-processing__pdf-icon">
                        <PdfIcon width="100%" height="100%" />
                      </div>
                      <a
                        href={pdfLinkHref}
                        target="_blank"
                        className="consent-to-data-processing__pdf-label"
                      >
                        {consentToDataProcessingData.sections[0].pdfLabel}
                      </a>
                    </div>
                  ) : (
                    <>
                      {section.checkboxIntro && (
                        <p className="consent-to-data-processing__section-checkboxintro">
                          {section.checkboxIntro}
                        </p>
                      )}
                      {section.checkboxes &&
                        section.checkboxes.map((checkbox: CheckboxData, optionIdx: number) => (
                          <Checkbox
                            verticalAlign="top"
                            key={`consent-to-data-processing__section-${idx}__option-${optionIdx}`}
                            checked={checkedCheckboxes[optionIdx]}
                            label={checkbox.label}
                            onChange={handleCheckboxChange(optionIdx)}
                            error={showCheckboxError}
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="consent-to-data-processing__error">
            {showCheckboxError && (
              <p className="error-message">
                {consentToDataProcessingData.sections[1].errorMessage}
              </p>
            )}
          </div>
          <AemFragment name={AemFragmentCatalog.STEP18VA} useVA={true} />
          <Footer
            showNext
            showPrevious
            handleAction={(navigationAction: NavigationAction) => {
              if (navigationAction === NavigationAction.NEXT) {
                form.submitForm();
              } else {
                onNavigateNoSubmit(navigationAction);
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
