import * as React from 'react';
import { DecreasingNeedModel, NumericRange } from 'rlv-common';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { TemplateLayout } from '../../../types/FormTemplate.types';
import { NeedSubPageProps } from '../Needs.types';
import { sumInsuredValidator } from '../../../validation/DecreasingNeedBlock';
import { SliderForm, SliderFormProps } from '../forms/SliderForm/SliderForm';
import {
  ComponentFieldNames,
  DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT,
  SliderFormValues
} from '../NeedsPageContainer.data';

export const DecreasingSumInsuredPage = ({
  valueRanges,
  storeState,
  handleAction,
  tracker
}: NeedSubPageProps) => {
  const decreasingSumInsured: TemplateLayout<NumericRange> =
    DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT.sumInsured;
  const SETUP = {
    componentFieldName: ComponentFieldNames.SumInsured,
    sliderConfig: {
      min: valueRanges.insuranceSum.min,
      max: valueRanges.insuranceSum.max,
      step: SliderFormValues.DefaultInsuranceSumInterval,
      text: '€',
      trackerUpdate: (value: number) =>
        tracker.updateTariffOptions({
          VersSumEJ: value
        })
    },
    templateLayout: {
      ...decreasingSumInsured,
      footerInfoSection: () =>
        decreasingSumInsured.footerInfoSection &&
        decreasingSumInsured.footerInfoSection(valueRanges.insuranceSum)
    },
    formValues: {
      sumInsured:
        storeState.sumInsured || valueRanges.insuranceSum.defaultValue
    },
    validationSchema: sumInsuredValidator(valueRanges),
    onHandleSliderFormActions: (
      { sumInsured }: { sumInsured: number },
      navigationAction: NavigationAction
    ) => {
      if (
        navigationAction === NavigationAction.NEXT &&
        sumInsured !== storeState.sumInsured
      ) {
        storeState.update({ sumInsured, insuranceEndSum: 5000 });
      }

      handleAction(navigationAction);
    },
    handleNavigateNoSubmitAction: () => {
      handleAction(NavigationAction.BACK);
    }
  };

  return <SliderForm<DecreasingNeedModel> {...SETUP as SliderFormProps<DecreasingNeedModel>} />;
};
