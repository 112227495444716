
import { Geschlecht } from 'rlv-common';
import * as DuwBasisData from '../types/external/duw/BasisData';
import { SessionStorageKeys } from '../types/SessionStorage';
import { Storage } from './Storage';

export const getDuwGenre = (): DuwBasisData.Geschlecht => {
  if (Storage.readItem(SessionStorageKeys.GENRE) === String(Geschlecht.MAENNLICH)) {
    return DuwBasisData.Geschlecht.MAENNLICH;
  } else if (Storage.readItem(SessionStorageKeys.GENRE) === String(Geschlecht.WEIBLICH)) {
    return DuwBasisData.Geschlecht.WEIBLICH;
  } else {
    const error = 'Genre must be set';
    throw new Error(error);
  }
};

export const getGenre = () => Storage.readItem(SessionStorageKeys.GENRE) === Geschlecht.MAENNLICH.toString() ? Geschlecht.MAENNLICH : Geschlecht.WEIBLICH;
