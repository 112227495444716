import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioTileValue } from '../../../../components/RadioGroup/RadioTile/RadioTile.types';
import { getServiceConfiguration } from '../../../../configuration/serviceConfiguration';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import MBIcon from '../../../../images/MotorbikeIcon.svg';
import NoMBIcon from '../../../../images/NoMotorbikeIcon.svg';
import { BikerFormProps, BikerSelection, BikerText } from './motorBiker.types';
import './motorBikerForm.css';

const BikerForm = ({
  biker,
  onNavigateNoSubmit,
  onSubmit,
  trackClick,
}: BikerFormProps): React.ReactElement => {
  const bikerOptions = [
    {
      title: BikerSelection.YES,
      value: BikerSelection.YES,
      icon: (
        <div className="radio_groups--icon">
          <img
            className="motorbike-form__icon"
            src={`${getServiceConfiguration().iconsPath}${MBIcon}`}
            alt="motorbike"
          />
        </div>
      ),
    },
    {
      title: BikerSelection.NO,
      value: BikerSelection.NO,
      icon: (
        <div className="radio_groups--icon">
          <img
            className="motorbike-form__icon"
            src={`${getServiceConfiguration().iconsPath}${NoMBIcon}`}
            alt="no motorbike"
          />
        </div>
      ),
    },
  ];

  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);
  const [selectedOption, setSelectedOption] = React.useState<RadioTileValue>('');

  React.useEffect(() => {
    if (biker) {
      setSelectedOption(BikerSelection.YES);
    }
    if (biker === false) {
      setSelectedOption(BikerSelection.NO);
    }
  }, []);

  return (
    <div className="container">
      <Formik
        initialValues={{ biker }}
        onSubmit={() => onSubmit(selectedOption === BikerSelection.YES, navigationRequest)}
      >
        {form => (
          <Form>
            <Headline text={BikerText.title} />
            <Field
              name="bikerField"
              render={() => (
                <>
                  <div className="motorbike-form__body__radio_tiles">
                    <RadioGroup
                      options={bikerOptions}
                      name="biker"
                      selectedValue={selectedOption}
                      onChangeSelection={(name: string, value: RadioTileValue) => {
                        setSelectedOption(value);
                        if (value === 'Ja') {
                          trackClick(true);
                        } else if (value === 'Nein') {
                          trackClick(false);
                        }
                      }}
                    />
                  </div>
                  <AemFragment name={AemFragmentCatalog.STEP9VA} useVA={true} />
                  <Footer
                    showNext={true}
                    showPrevious={true}
                    labelNext={'weiter'}
                    labelPrevious={'zurück'}
                    disableNext={selectedOption === ''}
                    handleAction={(navigationAction: NavigationAction) => {
                      setNavigationRequest(navigationAction);
                      if (navigationAction === NavigationAction.NEXT) {
                        form.submitForm();
                      } else {
                        onNavigateNoSubmit(navigationAction);
                      }
                    }}
                  />
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BikerForm;
