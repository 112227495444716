export enum PaymentMethod {
  MONATLICH = 'MONATLICH', // monthly
  VIERTELJAEHRLICH = 'VIERTELJAEHRLICH', // quarterly
  HALBJAEHRLICH = 'HALBJAEHRLICH', // bi-annually
  JAEHRLICH = 'JAEHRLICH' // yearly
}

export enum PaymentMethodText {
  MONATLICH = 'monatlich',
  VIERTELJAEHRLICH = 'vierteljährlich',
  HALBJAEHRLICH = 'halbjährlich',
  JAEHRLICH = 'jährlich'
}

export enum PaymentMethodAbbr {
  MONATLICH = 'mtl.',
  VIERTELJAEHRLICH = 'viertelj.',
  HALBJAEHRLICH = 'halbj.',
  JAEHRLICH = 'jährl.'
}

export enum PaymentMethodSentenceText {
  MONATLICH = 'monatlicher',
  VIERTELJAEHRLICH = 'vierteljährlicher',
  HALBJAEHRLICH = 'halbjährlicher',
  JAEHRLICH = 'jährlicher'
}
