import * as React from 'react';
import { OfferType, OrderResponse, ResponseCodes } from 'rlv-common';
import { getOeNumber } from '../../../helpers/modeConfig';
import { isOnline } from '../../../helpers/isOnline';
import { Storage } from '../../../helpers/Storage';
import { ViewName } from '../../../helpers/ViewName';
import LegalQuestionsPageContainer, {
  CheckOutPageData,
} from '../../../pages/legalQuestions/legalQuestionsPageContainer';
import {
  getAngebot,
  getNationalities,
  makeOffer,
  updateRequestOfferPageData,
} from '../../../services/api';
import { fetchTrackingDataAfterPersonalDataPage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { SessionStorageKeys } from '../../../types/SessionStorage';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const legalQuestionsSummary: StateDefinition<CheckOutPageData> = {
  name: StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  percentage: PagePercentage.LEGAL_QUESTIONS_SUMMARY_PAGE,
  trackingViewName: ViewName.PRUEFENUNDSENDEN,
  validInboundStates: [
    StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE,
    StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK,
    StateName.PERSONAL_DATA_PAGE,
    StateName.REQUEST_OFFER_PAGE,
    StateName.SUBSCRIPTIONS,
  ],
  transitions: [
    // DirectJumps for editing feature
    {
      test: action => action === NavigationAction.DIRECT_JUMP_TARIFDATEN,
      newState: StateName.NEED_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW,
      newState: StateName.FEE_BEFORE_DUW_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_DUW,
      newState: StateName.DUW_QUESTIONNAIRE_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_FEE_PAGE_AFTER_DUW,
      newState: StateName.PERSONAL_DATA_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_RISK_ASSESMENT,
      newState: StateName.DUW_ASSESSMENT_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_PDE,
      newState: StateName.PERSONAL_DATA_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_SUBSCRIPTIONS,
      newState: StateName.SUBSCRIPTIONS,
    },
    {
      test: action =>
        action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA && !isOnline(),
      newState: StateName.REQUEST_OFFER_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_EMPLOYMENT,
      newState: StateName.LIVING_CONDITIONS_EMPLOYMENT,
    },
    // Navigation
    {
      test: action => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.LEGAL_QUESTIONS_CONSENT_TO_RELEASE_OF_PRIVATE_DATA_PAGE,
    },
    // Navigation of "offline" process
    {
      test: action => action === NavigationAction.OFFER_WRITTEN_POSTAL,
      newState: StateName.FEEDBACK_PAGE,
    },
    {
      test: action => action === NavigationAction.OFFER_WRITTEN_EMAIL,
      newState: StateName.FEEDBACK_PAGE,
    },
    {
      test: action => action === NavigationAction.OFFER_DIRECT_ONLINE,
      newState: StateName.FEEDBACK_PAGE,
    },
    // ----
    {
      test: action => action === NavigationAction.DIRECT_JUMP_PERSONAL_DATA,
      newState: StateName.PERSONAL_DATA_PAGE,
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.LEGAL_QUESTIONS_CONSENT_TO_CREDIT_CHECK,
    },
  ],
  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);
    const nationalities = await getNationalities();
    const fetchedTrackingData = fetchTrackingDataAfterPersonalDataPage(response);

    return {
      userInput: {
        ...response.checkoutModel,
        ...response.requestOfferPersonalDataModel,
        showMeldung: false,
        nationalities,
      },
      valueRanges: response.valueRanges,
      varianten: response.varianten,
      fetchedTrackingData,
    };
  },
  onExit: async (transitionDetails, inputData) => {
    if (transitionDetails.targetStateName === StateName.FEEDBACK_PAGE) {
      await updateRequestOfferPageData(inputData.businessId, inputData.userInput);
      const oeNumber = getOeNumber();

      let offerType;
      switch (transitionDetails.action) {
        case NavigationAction.OFFER_WRITTEN_POSTAL:
          Storage.writeItem(SessionStorageKeys.OFFER_TYPE, OfferType.WRITTEN_POSTAL);
          offerType = OfferType.WRITTEN_POSTAL;
          break;
        case NavigationAction.OFFER_WRITTEN_EMAIL:
          Storage.writeItem(SessionStorageKeys.OFFER_TYPE, OfferType.WRITTEN_EMAIL);
          offerType = OfferType.WRITTEN_EMAIL;
          break;
        default:
          Storage.writeItem(SessionStorageKeys.OFFER_TYPE, OfferType.DIRECT_ONLINE);
          offerType = OfferType.DIRECT_ONLINE;
          break;
      }
      const response: OrderResponse = await makeOffer(
        inputData.businessId,
        oeNumber,
        inputData.userInput.vn!.pdeId,
        inputData.userInput.vp!.pdeId,
        offerType,
      );
      if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
        return { skipTransition: true, payload: { showMeldung: response.showMeldung } };
      }
      if (response.status !== ResponseCodes.SUCCESS) {
        throw new Error('Internal server error');
      }
    }

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { userInput, businessId, varianten } = inputData;
    return (
      <LegalQuestionsPageContainer
        businessId={businessId}
        varianten={varianten}
        storeState={{
          vp: userInput.vp,
          nationalities: userInput.nationalities,
          vorname: userInput.vorname,
          nachname: userInput.nachname,
          adresse: userInput.adresse,
          abbuchungsTage: userInput.abbuchungsTage,
          additionalInsuranceSettings: userInput.additionalInsuranceSettings,
          bic: userInput.bic,
          biker: userInput.biker,
          birthdate: userInput.birthdate,
          duwToken: userInput.duwToken,
          email: userInput.email,
          employment: userInput.employment,
          geburtsort: userInput.geburtsort,
          geschlecht: userInput.geschlecht,
          height: userInput.height,
          iban: userInput.iban,
          inceptionDate: userInput.inceptionDate,
          need: userInput.need,
          paymentMethod: userInput.paymentMethod,
          pdeId: userInput.pdeId,
          periodInYears: userInput.periodInYears,
          insuranceEndSum: userInput.insuranceEndSum,
          professionKey: userInput.professionKey,
          professionLabel: userInput.professionLabel,
          propertyOwner: userInput.propertyOwner,
          role: userInput.role,
          rufnummer: userInput.rufnummer,
          smoker: userInput.smoker,
          staatsangehoerigkeit: userInput.staatsangehoerigkeitText,
          sumInsured: userInput.sumInsured,
          tagDerAbbuchung: userInput.tagDerAbbuchung,
          variante: userInput.variante,
          vorwahl: userInput.vorwahl,
          weight: userInput.weight,
          entitledPersons: userInput.entitledPersons,
          patientConfidentialityRelease: userInput.patientConfidentialityRelease,
          patientConfidentialityReleaseAfterDeath:
            userInput.patientConfidentialityReleaseAfterDeath,
          insuranceEndDate: userInput.insuranceEndDate,
          loanProtection: userInput.loanProtection,
          loanSum: userInput.loanSum,
          loanPeriodInYears: userInput.loanPeriodInYears,
          loanRate: userInput.loanRate,
          basisSum: userInput.basisSum,
          repaymentFreePeriodInYears: userInput.repaymentFreePeriodInYears,
          update: values => updateApp(values),
          oeNumber: userInput.oeNumber,
          additionalTimeWaiting: userInput.additionalTimeWaiting,
          extraExamination: userInput.extraExamination,
          showMeldung: userInput.showMeldung,
        }}
        onError={onError}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
        valueRanges={inputData.valueRanges}
      />
    );
  },
};
