import Button, { ButtonProps } from '@eg/elements/Button';
import StepButton from '@eg/elements/StepButton';
import * as React from 'react';
import { DataIdFooter } from '../../types/DataId';

export interface NextButtonProps extends ButtonProps {
  label?: string;
  disableNext?: boolean;
  step?: number;
}

const NextButton = (props: NextButtonProps) => {
  if (props.step) {
    return (
      <StepButton
        data-component-id={DataIdFooter.BUTTON_NEXTBUTTON}
        type="button"
        onClick={props.onClick}
        disabled={props.disableNext === undefined ? false : props.disableNext}
        text={props.label || 'weiter'}
        step={props.step}
      />
    );
  }
  return (
    <Button
      data-component-id={DataIdFooter.BUTTON_NEXTBUTTON}
      type="button"
      variant="primary"
      size="normal"
      onClick={props.onClick}
      disabled={props.disableNext === undefined ? false : props.disableNext}
    >
      {props.label || 'weiter'}
    </Button>
  );
};

export default NextButton;
