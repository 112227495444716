import { Card } from '@eg/elements/Card';
import * as React from 'react';
import AemFragment from '../../../components/AemFragment/AemFragment';
import Footer from '../../../components/Footer';
import { Headline } from '../../../components/Headline/Headline';
import Price from '../../../components/Price/Price';
import { PriceSize } from '../../../components/Price/Price.types';
import { isMobile } from '../../../helpers/mediaMatcher';
import { Storage } from '../../../helpers/Storage';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { AemFragmentCatalog } from '../../../types/AemFragmentCatalog';
import { SessionStorageKeys } from '../../../types/SessionStorage';
import './DuwAssessment.css';
import { assessmentResultsContent, DuwAssessmentText } from './DuwAssessment.data';
import { DuwAssessmentData, DuwAssessmentProps, DuwStatus } from './DuwAssessment.types';

const DuwAssessment = ({
  duwStatus,
  onNavigateNoSubmit,
  paymentMethod,
  nettoRiskSurcharge,
  price,
  tariff,
}: DuwAssessmentProps): React.ReactElement => {
  const status = Storage.readItem(SessionStorageKeys.DUW_STATUS);
  const duwAssessmentData: DuwAssessmentData = assessmentResultsContent[status as DuwStatus];

  React.useEffect(() => {
    if (duwStatus) {
      Storage.writeItem(SessionStorageKeys.DUW_STATUS, duwStatus);
    }
  }, []);

  return (
    <div className="duw-assessment__body">
      <Headline text={DuwAssessmentText.title} />
      <div className="duw-assessment__card-container">
        <Card className="duw-assessment__card">
          <div className="duw-assessment__detail-container">
            {duwAssessmentData?.details.map((detail, idx) => (
              <div
                className="duw-assessment__detail-container-results"
                key={`duw-assessment-detail-${Object.values(DuwStatus)[idx]}`}
                id={`duw-assessment-detail-${Object.values(DuwStatus)[idx]}`}
              >
                {detail.icon}
                {detail.text(<Price amount={String(nettoRiskSurcharge)} unstyled />)}
              </div>
            ))}
          </div>
          <div className="duw-assessment__detail-container-price">
            {duwAssessmentData?.tariff(tariff)}
            <Price
              amount={String(price)}
              paymentMethod={paymentMethod}
              size={isMobile() ? PriceSize.Small : PriceSize.Medium}
            />
          </div>
        </Card>
      </div>
      <AemFragment name={AemFragmentCatalog.STEP15VA} useVA={true} />
      <Footer
        showNext
        showPrevious
        labelNext="weiter"
        handleAction={(navigationAction: NavigationAction) => onNavigateNoSubmit(navigationAction)}
      />
    </div>
  );
};

export default DuwAssessment;
