import { ManIcon, WomanIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import { FieldNumberInput } from '../../../../components/FormComponents';
import FormTemplate from '../../../../components/FormComponents/FormTemplate/FormTemplate';
import InfoText from '../../../../components/InfoText/infoText';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioGroupOption } from '../../../../components/RadioGroup/RadioGroup.types';
import { Storage } from '../../../../helpers/Storage';
import { usePreviousAndTrackError } from '../../../../hooks/usePreviousAndTrackError/usePreviousAndTrackError';
import { TrackingElementGender, TrackingGender } from '../../../../tracking/tracking.types';
import { withTracker } from '../../../../tracking/withTracker';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { TemplateLayout } from '../../../../types/FormTemplate.types';
import { SessionStorageKeys } from '../../../../types/SessionStorage';
import { createLivingConditionsPhysicalAspectSchema } from '../../../../validation/LivingConditionsPage';
import {
  PhysicalAspectFormNames,
  PhysicalAspectFormProps,
  PhysicalAspectFormRadioTitle,
  PhysicalAspectFormText,
  PhysicalAspectFormValues,
} from './PhysicalAspect.types';
import './PhysicalAspectForm.css';

const physicalAspectFormTemplateLayout: TemplateLayout<object> = {
  title: PhysicalAspectFormText.TITLE,
  virtualAssistantFragment: AemFragmentCatalog.STEP8VA,
  footerInfoSection: () => <InfoText textBeforeLink={PhysicalAspectFormText.FOOTER} />,
};

const RADIO_GROUP_OPTIONS: RadioGroupOption[] = [
  {
    title: PhysicalAspectFormRadioTitle.MAN,
    value: '2',
    icon: <ManIcon className="physical-aspect-form__icon" />,
  },
  {
    title: PhysicalAspectFormRadioTitle.WOMAN,
    value: '1',
    icon: <WomanIcon className="physical-aspect-form__icon" />,
  },
];

const PhysicalAspectForm: React.FC<PhysicalAspectFormProps> = ({
  height,
  weight,
  disabled,
  onSubmit,
  onNavigateNoSubmit,
  tracker,
}): React.ReactElement => {
  const currentStoredGenre = Storage.readItem(SessionStorageKeys.GENRE);
  const [hiddenInputs, setHiddenInputs] = React.useState<boolean>(!currentStoredGenre);
  const { compareErrors } = usePreviousAndTrackError({ height: '', weight: '' });

  return (
    <div className="container">
      <FormTemplate<PhysicalAspectFormValues>
        templateLayout={physicalAspectFormTemplateLayout}
        formValues={{ genre: currentStoredGenre, height, weight }}
        validationSchema={createLivingConditionsPhysicalAspectSchema()}
        handleNavigateNoSubmitAction={onNavigateNoSubmit}
        handleOnSubmitAction={onSubmit}
        render={[
          {
            name: PhysicalAspectFormNames.GENRE,
            component: (form, field) => (
              <div className="physical-aspect-form__radio-buttons">
                <RadioGroup
                  options={RADIO_GROUP_OPTIONS}
                  name={PhysicalAspectFormNames.GENRE}
                  selectedValue={field.value}
                  disabled={disabled}
                  onChangeSelection={(_, genre) => {
                    Storage.writeItem(SessionStorageKeys.GENRE, genre as string);
                    if (hiddenInputs) {
                      setHiddenInputs(false);
                    }
                    const genderOption = RADIO_GROUP_OPTIONS.find(option => option.value === genre);
                    if (genderOption && genderOption.title === PhysicalAspectFormRadioTitle.MAN) {
                      tracker.updateUserAttributes({ gender: TrackingGender.MALE });
                      tracker.trackClickEvent({
                        clickedElement: TrackingElementGender.RADIOBUTTOM_MALE,
                      });
                    } else if (
                      genderOption &&
                      genderOption.title === PhysicalAspectFormRadioTitle.WOMAN
                    ) {
                      tracker.updateUserAttributes({ gender: TrackingGender.FEMALE });
                      tracker.trackClickEvent({
                        clickedElement: TrackingElementGender.RADIOBUTTION_FEMALE,
                      });
                    }
                    form.setFieldValue(field.name, genre);
                  }}
                />
              </div>
            ),
          },
          {
            name: PhysicalAspectFormNames.HEIGHT,
            shouldBeHidden: hiddenInputs,
            component: (form, field) => (
              <div className="physical-aspect-form__input-wrapper">
                <div className="physical-aspect-form__input-container">
                  <FieldNumberInput
                    name={field.name}
                    label="Größe"
                    placeholder="z.B. 180"
                    adornmentRight="cm"
                    onBlur={() => compareErrors('height', form.errors[field.name])}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      tracker.updateAdditionalTariffOptions({ KGroesse: value });
                      form.setFieldValue(field.name, Number(value));
                    }}
                  />
                </div>
                {form.errors[field.name] && form.touched[field.name] && (
                  <p className="error-message">{form.errors[field.name]}</p>
                )}
              </div>
            ),
          },
          {
            name: PhysicalAspectFormNames.WEIGHT,
            shouldBeHidden: hiddenInputs,
            component: (form, field) => (
              <div className="physical-aspect-form__input-wrapper">
                <div className="physical-aspect-form__input-container">
                  <FieldNumberInput
                    name={field.name}
                    label="Gewicht"
                    placeholder="z.B. 80"
                    adornmentRight="kg"
                    onBlur={() => compareErrors('weight', form.errors[field.name])}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      tracker.updateAdditionalTariffOptions({ KGewicht: value });
                      form.setFieldValue(field.name, Number(value));
                    }}
                  />
                </div>
                {form.errors[field.name] && form.touched[field.name] && (
                  <p className="error-message">{form.errors[field.name]}</p>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default withTracker(PhysicalAspectForm);
