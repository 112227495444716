import { AemQuellsystem } from 'rlv-common';

const FF_DEFAULTS = {
  // FFIsExampleFeature: 'false',
};

export const DEFAULT_AEM_PARAMS = {
  ProductGroup: 'RisikoLeben',
  step11VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step11',
  step7VA1:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step7-raucher',
  step7VA2:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step7-kein-raucher',
  'sling:resourceType': 'ergo/carbon/base/components/appcatalog/applicationembed',
  step17VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step17',
  envs: [
    {
      name: 'otrUrl',
      url: 'https://b2c-sales-rlv-otr-dvit.aws-0518.cloudfirst.digital',
    },
  ],
  LivingConditionsSmokerPage: 'BasisdatenRaucher',
  ShortName: 'risikolv',
  step19VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step19',
  step13VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step13',
  step20VA1:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step20-daten-vollstaendig',
  step20VA2:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step20-angebot',
  step15VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step15',
  LegalQuestionsConsentToDataPage: 'Einwilligung Datenverarbeitung',
  NeedPage: 'Bedarfsanalyse',
  LivingConditionsEmploymentPage: 'BasisdatenBeruf',
  id: 'mDDsx',
  LegalQuestionsIDDDownloadPdfPage: 'PruefenUndSendenIDD',
  DecreasingEndSumInsuredPage: 'BasisdatenFallendVersSumLJ',
  'cq:styleIds': ['applicationembed--full-width'],
  pageProperties: {},
  LegalQuestionsPurposeLoanProtectionPage: 'Immobiliendarlehen',
  LegalQuestionsMoneyLaunderingPage: 'GWG',
  LegalQuestionsSummaryOfflinePage: 'PruefenUndSendenAngebot',
  DuwQuestionnairePage: 'DUW:Questions',
  DecreasingPeriodInYearsPage: 'BasisdatenFallendLZ',
  step2VA:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step2-mich-selbst',
  step16VAaltb:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step16-wbr-100',
  step16VAalta:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step16-wbr-bis-100',
  step5VA1alt:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step5-laufzeit-fallende-vs',
  step16VAaltc:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step16-wbr',
  step6VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step6',
  ConstantPeriodInYearsPage: 'BasisdatenKonstLZ',
  step8VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step8',
  LegalQuestionsSummaryPage: 'PruefenUndSenden',
  RequestOfferFeedbackPage: 'Angebot Feedback',
  FeedbackPage: 'Abschluss Feedback',
  RequestOfferDivergingInsuredPersonPage: 'BasisdatenVpAuswahl',
  LegalQuestionsConsentToCreditCheckPage: 'Einwilligung Bonität',
  LivingConditionsBirthdatePage: 'BasisdatenGebDatum',
  step10VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step10',
  step16VA:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step16-ohne-wbr',
  productTable: '/content/ergo/ergo-one/ergo-one-portal/fragments/produkttabelle/leben/master',
  step18VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step18',
  step5VA1:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step5-laufzeit-konstante-vs',
  step12VA:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step12-beitrag',
  feedbackEmail:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/feedback/abschluss-content/angebot-mail-content',
  DuwDisclosurePage: 'DUW:Gesundheits',
  step2VA2:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step2-jemand-anders',
  step4VA1alt:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step4-vs-fallend-erstes-jahr',
  LegalQuestionsConsentToRealeasePrivateDataPage: 'Einwilligung Schweigepflichtentbindung',
  DuwAssessmentPage: 'DUW:Votum',
  feedbackOnline:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/feedback/abschluss-content/rlv-antrag',
  RequestOfferPage: 'PersonendatenAngebot',
  LivingConditionsOwnerHousingPage: 'BasisdatenWohneigentum',
  step21VA:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step21-einwilligung-boni',
  feedbackPost:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/feedback/abschluss-content/angebot-post-content',
  step6VAalt:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step4-vs-fallend-letztes-jahr',
  PersonalDataPage: 'Personendaten',
  ViewNameForProdEinstieg: 'ProdEinstieg',
  step4VA1:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step4-vs-konstant',
  FeeBeforeDuwPage: 'Berechnungsergebnis',
  DecreasingSumInsuredPage: 'BasisdatenFallendVersSumEJ',
  ConstantSumInsuredPage: 'BasisdatenKonstVersSum',
  pdfDownload: '/content/dam/ergo/pdf/leben/risikolebensvresicherung-leistungsuebersicht.pdf',
  LivingConditionsInceptionDatePage: 'BasisdatenVersBeginn',
  dataProtection:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/kleingedrucktes/datenschutzbereich/master',
  SubscriptionsPage: 'Bezugsrecht',
  step3VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step3',
  'LivingConditionsHeight&WeightPage': 'BasisdatenZurPerson',
  step1VA1:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step1-konstante-vs',
  feedbackPdf:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/feedback/abschluss-content/promo-abschluss-content',
  step7VA:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step7-raucher',
  OENumber: '001280200',
  PNRNumber: '',
  step1VA2:
    '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step1-fallende-vs',
  step9VA: '/content/ergo/ergo-one/ergo-one-portal/fragments/virtueller-assistent/rlv/step9',
  LivingConditionsMotorcyclistPage: 'BasisdatenMotorrad',
  quellsystem: AemQuellsystem.ERGO_INTERNET,
  vva: 'http://',
  telephone: '12345689',
  ...FF_DEFAULTS,
};
