import {
  CheckIcon,
  ChecklistIcon,
  ContributionIcon,
  CurrencyEuroIcon,
  RightBorderIcon,
} from '@eg/elements/components/Icons';
import * as React from 'react';
import { AssessementResults, DuwStatus } from './DuwAssessment.types';

export enum DuwAssessmentText {
  title = 'Ergebnis Ihrer Gesundheitsprüfung',
}

const priceText = (price: JSX.Element): JSX.Element => (
  <p>
    {'Dadurch erhöht sich der vorläufige Beitrag um '}
    <b>{price}</b>
    {'. Dieser Zuschlag wird in Ihrem Zahlbeitrag berücksichtigt.'}
  </p>
);

const defaultText = (tariff: string): JSX.Element => (
  <div>
    <div>
      <b>Sie zahlen</b>
    </div>
    <div>im Tarif {tariff}</div>
  </div>
);

const surchargeText = (tariff: string): JSX.Element => (
  <div>
    <div>
      <b>Sie zahlen inkl. Risikozuschlag</b>
    </div>
    <div>im Tarif {tariff}</div>
  </div>
);

export const assessmentResultsContent: AssessementResults = {
  [DuwStatus.ACCEPTED]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <p>
            <b>Gute Nachrichten:</b> Es bleibt bei dem angegebenen Beitrag.
          </p>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <ContributionIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => <p>Sie können die Versicherung jetzt online beantragen.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: defaultText,
  },
  [DuwStatus.ACCEPTED_WITH_DIFFICULTIES]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: (price: JSX.Element) => <div>{priceText(price)}</div>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CurrencyEuroIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => <p>Sie können die Versicherung jetzt online beantragen.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: surchargeText,
  },
  [DuwStatus.MANUAL_RISKCHECK]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <div>
            <p>Als Nächstes prüft ERGO diese sorgfältig.</p>
          </div>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <ChecklistIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <p>
            Sie können die Versicherung jetzt online beantragen. Sobald das Ergebnis der Prüfung
            feststeht, bekommen Sie eine Nachricht.
          </p>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: defaultText,
  },
  [DuwStatus.REQUIRE_FURTHER_DOCUMENTS]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: (price: JSX.Element) => <div>{priceText(price)}</div>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CurrencyEuroIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <div>
            <p>Als Nächstes prüft ERGO Ihre Angaben sorgfältig.</p>
          </div>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <ChecklistIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <p>
            Sie können die Versicherung jetzt online beantragen. Sobald das Ergebnis der Prüfung
            feststeht, bekommen Sie eine Nachricht.
          </p>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: surchargeText,
  },
  [DuwStatus.DEFERRED]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <p>
            Sie können die Versicherung jetzt online beantragen. Sobald das Ergebnis der Prüfung
            feststeht, bekommen Sie eine Nachricht.
          </p>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: defaultText,
  },
  [DuwStatus.REJECTED]: {
    details: [
      {
        text: () => <p>Vielen Dank für Ihre Angaben.</p>,
        icon: (
          <div className="duw-assessment__detail-icon">
            <CheckIcon width="100%" height="100%" />
          </div>
        ),
      },
      {
        text: () => (
          <p>
            Sie können die Versicherung jetzt online beantragen. Sobald das Ergebnis der Prüfung
            feststeht, bekommen Sie eine Nachricht.
          </p>
        ),
        icon: (
          <div className="duw-assessment__detail-icon">
            <RightBorderIcon width="100%" height="100%" />
          </div>
        ),
      },
    ],
    tariff: defaultText,
  },
};
