import * as React from 'react';
import { ConstantNeedModel, NumericRange } from 'rlv-common';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { TemplateLayout } from '../../../types/FormTemplate.types';
import { NeedSubPageProps } from '../Needs.types';
import { constantSumInsuredValidator } from '../../../validation/ConstantNeedBlock';
import { SliderForm, SliderFormProps } from '../forms/SliderForm/SliderForm';
import {
  ComponentFieldNames,
  CONSTANT_SLIDER_FORM_TEMPLATES_LAYOUT,
  SliderFormValues,
} from '../NeedsPageContainer.data';

export const ConstantSumInsuredPage = ({
  valueRanges,
  storeState,
  handleAction,
  tracker,
}: NeedSubPageProps) => {
  const constantSumInsured: TemplateLayout<NumericRange> =
    CONSTANT_SLIDER_FORM_TEMPLATES_LAYOUT.sumInsured;

  const SETUP = {
    componentFieldName: ComponentFieldNames.SumInsured,
    sliderConfig: {
      min: valueRanges.insuranceSum.min,
      max: valueRanges.insuranceSum.max,
      step: SliderFormValues.DefaultInsuranceSumInterval,
      text: '€',
      trackerUpdate: (value: number) =>
        tracker.updateTariffOptions({
          VersSum: value,
        }),
    },
    templateLayout: {
      ...constantSumInsured,
      footerInfoSection: () =>
        constantSumInsured.footerInfoSection &&
        constantSumInsured.footerInfoSection(valueRanges.insuranceSum),
    },
    formValues: {
      sumInsured: storeState.sumInsured || valueRanges.insuranceSum.defaultValue,
    },
    validationSchema: constantSumInsuredValidator(valueRanges),
    onHandleSliderFormActions: (
      { sumInsured }: { sumInsured: number },
      navigationAction: NavigationAction,
    ) => {
      if (navigationAction === NavigationAction.NEXT) {
        storeState.update({ sumInsured });
      }

      handleAction(navigationAction);
    },
    handleNavigateNoSubmitAction: () => {
      handleAction(NavigationAction.BACK);
    },
  };

  return <SliderForm<ConstantNeedModel> {...(SETUP as SliderFormProps<ConstantNeedModel>)} />;
};
