import * as React from 'react';
import { AemFragmentCatalog } from '../../../types/AemFragmentCatalog';
import { FragmentHTMLProps } from './FragmentHTML.types';

function FragmentHTML({name, content}: FragmentHTMLProps) {
  return (
    (name === AemFragmentCatalog.PDF_DOWNLOAD.valueOf()) ? (
      <a href={content}
        id={`XF_url-${name}`}
        data-testid={`XF_url-${name}`}
        target="_blank"
      />
    ) : (
      <div
        id={`XF_content-${name}`}
        data-testid={`XF_content-${name}`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    )
  );
}

export default FragmentHTML;
