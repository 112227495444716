import ControlWithHint from '@eg/elements/ControlWithHint';
import { DateInputValue } from '@eg/elements/DateInput';
import DateInputRow from '@eg/elements/DateInputRow';
import { DateErrors } from '@eg/elements/utils/validation/date';
import { Field, FieldProps, FormikValues } from 'formik';
import * as React from 'react';
import {
  IsoDateString,
  mapDateInputToIsoDateString,
  mapIsoDateStringToDateInput,
} from 'rlv-common';
import { filterNumberInputField } from '../../../Helper';

import './FieldDateInputRow.css';

export interface FieldDateInputRowProps {
  name: string;
  label: string | undefined;
  date: IsoDateString | undefined;
  disabled?: boolean;
  formError?: string;
  onError?: (error: string | undefined) => void;
}

const FieldDateInputRow = (props: FieldDateInputRowProps) => {
  const [error, setError] = React.useState<string | undefined>(props.formError);
  const [date, setDate] = React.useState<DateInputValue>(mapIsoDateStringToDateInput(props.date));
  React.useEffect(() => {
    if (!!props.onError) {
      props.onError(error);
    }
  }, [error]);

  const handleComponentError = (componentError: DateErrors): void => {
    if (!componentError.valid) {
      setError(
        componentError.badInput ||
          componentError.rangeOverflow ||
          componentError.rangeUnderflow ||
          componentError.valueMissing,
      );
    } else {
      setError(undefined);
    }
  };

  return (
    <Field
      name={`${props.name}Field`}
      render={({ field, form }: FieldProps<FormikValues>) => {
        const { setFieldValue, setFieldTouched } = form;
        return (
          <div className="field-date-input__body">
            <div
              className={
                !props.label
                  ? 'field-date-input__body__dateInput'
                  : 'field-date-input__body__dateInput_with_label'
              }
            >
              <DateInputRow
                id={field.name}
                autoTab
                label={props.label || ''}
                onBlur={() => {
                  setFieldTouched(props.name);
                }}
                onKeyDown={filterNumberInputField}
                onChange={(value: DateInputValue, componentError: DateErrors) => {
                  handleComponentError(componentError);
                  setDate(value);
                  setFieldValue(props.name, mapDateInputToIsoDateString(value));
                }}
                value={date}
                disabled={props.disabled}
              />
            </div>
            <ControlWithHint className="field-date-input__errors" error={props.formError || error}>
              <></>
            </ControlWithHint>
          </div>
        );
      }}
    />
  );
};

export default FieldDateInputRow;
