import { LoaderIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import {
  carbonParams,
  getServiceConfiguration,
  rlvKey,
} from '../../configuration/serviceConfiguration';
import { VirtualAssistant } from '../VirtualAssistant/VirtualAssistant';
import { AemFragmentProps } from './AemFragment.types';
import FragmentHTML from './FragmentHTML/FragmentHTML';

const AemFragment = ({ name, useVA = false }: AemFragmentProps) => {
  const [fragmentContent, setFragmentContent] = React.useState('');
  const [isLoadingContent, setLoadingContent] = React.useState(false);
  const [fragmentUrl, setFragmentUrl] = React.useState('');

  React.useEffect(() => {
    const url = carbonParams && getXfUrlByAemProperties(name);
    if (!!url) {
      setFragmentUrl(url);
    } else {
      setLoadingContent(false);
    }
  }, [name]);

  React.useEffect(() => {
    if (fragmentUrl && carbonParams && rlvKey && carbonParams[rlvKey][name]) {
      setLoadingContent(true);
      fetch(fragmentUrl)
        .then(res => res.text())
        .then(res => {
          setFragmentContent(loadExternalJs(res));
        })
        .catch(e => {
          console.error('Unable to load Experience Fragment', fragmentUrl);
          if (!useVA) {
            setFragmentContent('<div><strong>LOAD ERROR</strong></div>');
          }
        })
        .finally(() => {
          setLoadingContent(false);
        })
        .catch(e => console.log('Unable to load content:', e));
    }
  }, [fragmentUrl]);

  const loadExternalJs = (content: string): string => {
    const aemWindow: Window = window;

    if (aemWindow.loadFetchedAemJs) {
      return aemWindow.loadFetchedAemJs(content);
    }
    return content;
  };

  const getXfUrlByAemProperties = (fragmentName: string): string => {
    const pathToXf = (carbonParams && rlvKey && carbonParams[rlvKey][fragmentName]) || '';
    const fullPathToXF = pathToXf.endsWith('.html') ? pathToXf : `${pathToXf}.html`;

    // It is necessary to load fragments in Carbon Edit mode
    const { isEditMode } = window as Window;
    if (!isEditMode) {
      return getServiceConfiguration().fragmentPath + fullPathToXF;
    }

    const newPathToFile = fullPathToXF.replace('content/experience-fragments', 'xf');
    return getServiceConfiguration().fragmentPath + newPathToFile;
  };

  return (
    <>
      {isLoadingContent && (
        <div className="aem-fragment-loader_container">
          <LoaderIcon />
        </div>
      )}
      {!isLoadingContent && fragmentContent && !useVA && (
        <FragmentHTML name={name} content={fragmentContent} />
      )}
      {!isLoadingContent && useVA && rlvKey && !!carbonParams[rlvKey][name] && (
        <div className="va-with-fragment">
          <VirtualAssistant text={fragmentContent} />
        </div>
      )}
      {!isLoadingContent && useVA && rlvKey && !carbonParams[rlvKey][name] && (
        <div className="va-with-fragment--no-va" />
      )}
    </>
  );
};

export default AemFragment;
