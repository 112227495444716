/*
    It must mismatch aem carbon manifest.
*/
export enum AemFragmentCatalog {
  DATA_PROTECTION = 'dataProtection',
  PRODUCT_TABLE = 'productTable',
  PDF_DOWNLOAD = 'pdfDownload',
  FEEDBACK_ONLINE = 'feedbackOnline',
  FEEDBACK_EMAIL = 'feedbackEmail',
  FEEDBACK_POST = 'feedbackPost',
  FEEDBACK_PDF = 'feedbackPdf',
  STEP1VA1 = 'step1VA1',
  STEP1VA2 = 'step1VA2',
  STEP2VA = 'step2VA',
  STEP2VA2 = 'step2VA2',
  STEP3VA = 'step3VA',
  STEP4VA1 = 'step4VA1',
  STEP4VA1ALT = 'step4VA1alt',
  STEP5VA1 = 'step5VA1',
  STEP5VA1ALT = 'step5VA1alt',
  STEP6VA = 'step6VA',
  STEP6VAALT = 'step6VAalt',
  STEP7VA = 'step7VA',
  STEP7VA1 = 'step7VA1',
  STEP7VA2 = 'step7VA2',
  STEP8VA = 'step8VA',
  STEP9VA = 'step9VA',
  STEP10VA = 'step10VA',
  STEP11VA = 'step11VA',
  STEP12VA = 'step12VA',
  STEP12VA2 = 'step12VA2',
  STEP13VA = 'step13VA',
  // STEP14VA is inside DUW project, that is not updatable
  STEP15VA = 'step15VA',
  STEP16VA = 'step16VA',
  STEP16VAALTA = 'step16VAalta',
  STEP16VAALTB = 'step16VAaltb',
  STEP16VAALTC = 'step16VAaltc',
  STEP17VA = 'step17VA',
  STEP18VA = 'step18VA',
  STEP19VA = 'step19VA',
  STEP20VA1 = 'step20VA1',
  STEP20VA2 = 'step20VA2',
  STEP21VA = 'step21VA',
}
