import { Provider as EEProvider } from '@eg/elements/Provider';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AemMockWrapper from './components/AemMockWrapper/AemMockWrapper';
import ApplicationRoot from './components/App';
import trackJsConfig, { TrackjsConfig } from './configuration/trackjs';
import { egElementsTheme } from './Helper';
import { trackJs } from './helpers/externalScripts';
import { createAxiosInterceptor } from './interceptors/trackerInterceptor';
import './styles/global.css';
import './styles/typography.css';
import { Configuration } from './types/Configuration';

declare global {
  interface Window {
    trackJs: TrackjsConfig | undefined;
  }
}

// Testing Prettier and Husky flow in Git commit Test 2
const rootElement: HTMLElement = document.getElementById('rlv-root') as HTMLElement;

// Disable AEM Spinner
const spinner = document.getElementById('ed_tarifspinner');
if (spinner && spinner.parentNode) {
  spinner.parentNode.removeChild(spinner);
}

// needed e.g. in the personal data page, this could be handled by the context api
export const configuration: Configuration = {
  theme: egElementsTheme,
};

if (trackJsConfig) {
  (window as Window).trackJs = trackJsConfig;
  // TODO: Remove this catch
  // eslint-disable-next-line
  trackJs.catch(() => {});
}

// Import axios interceptor
createAxiosInterceptor();

ReactDOM.render(
  <AemMockWrapper>
    <EEProvider theme={configuration.theme}>
      <ApplicationRoot />
    </EEProvider>
  </AemMockWrapper>,
  rootElement,
);
