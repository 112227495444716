import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { Honorific } from 'rlv-common';
import { getErrorMessage } from '../../../../../../Helper';

export interface FieldRadioGroupRowProps {
  disabled?: boolean;
  label: string | undefined;
  name: string;
}

export const FieldRadioGroupRow: React.FC<FieldRadioGroupRowProps> = ({
  disabled,
  label,
  name,
}) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
      const { setFieldValue, setFieldTouched } = form;

      return (
        <RadioGroupRow
          id={field.name}
          name={field.name}
          label={label}
          onBlur={() => {
            setFieldTouched(field.name);
          }}
          defaultValue=""
          value={field.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field.name, event.target.value);
          }}
          disabled={disabled}
          error={getErrorMessage(form, field)}
        >
          <Radio value="1" label={Honorific.MALE_ADULT} />
          <Radio value="0" label={Honorific.FEMALE_ADULT} />
        </RadioGroupRow>
      );
    }}
  />
);
