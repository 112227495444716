import { Environment } from 'rlv-common';
import { TRACKING_VERSION } from '../releaseVersion';

export interface TrackjsConfig {
  token: string;
  application: string;
  version: string;
}

let trackJsConfig: TrackjsConfig | undefined;

switch (process.env.NODE_ENV) {
  case Environment.development:
  case Environment.dvit:
  case Environment.fait:
    trackJsConfig = undefined;
    break;
  default:
    trackJsConfig = {
      token: 'ac5193cbf5714332be98f2334a1552dd',
      application: 'rlv-dive',
      version: TRACKING_VERSION,
    };
    break;
}

export default trackJsConfig;
