import * as React from 'react';
import { ValueRanges, Variante } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import AppLoader from '../../../../components/AppLoader';
import Footer, { AuxButton } from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import InfoText from '../../../../components/InfoText/infoText';
import { CheckOutPageData } from '../../../../pages/legalQuestions/legalQuestionsPageContainer';

import { useDuwAccordionData } from '../../../../hooks/useDuwAccordionData/useDuwAccordionData';
import { useGlobalAccordionData } from '../../../../hooks/useGlobalAccordionData/useGlobalAccordionData';
import { isOnline } from '../../../../helpers/isOnline';
import { Storage } from '../../../../helpers/Storage';
import { NavigationAction } from '../../../../routing/StateMachineTypes';

import { SessionStorageKeys } from '../../../../types/SessionStorage';

import { TrackingElementSummary } from '../../../../tracking/tracking.types';
import {
  Accordion,
  CommonSectionDetails,
  DuwSectionDetails,
} from './components/Accordion/Accordion';
import {
  BankDetailsUIOptions,
  BeneficiaryUIOptions,
  HedgingNeedsUIOptions,
  PersonalDataOfTheInsuredPersonUIOptions,
  PersonalDataOfThePolicyHolderOnlineUIOptions,
  RiskAssessmentUIOptions,
  SummaryPageStates,
  TariffAndContributionUIOptions,
  TEXT_CONFIG,
  TEXT_CONFIG_FRAGMENT,
} from './Summary.types';

import './Summary.css';

export interface SummmaryProps {
  checkoutData: CheckOutPageData;
  valueRanges: ValueRanges;
  varianten?: Variante[];
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  trackClickEvent: (clickedElement: TrackingElementSummary) => void;
}

export const Summary = ({
  checkoutData,
  valueRanges,
  varianten,
  onNavigateNoSubmit,
  trackClickEvent,
}: SummmaryProps) => {
  const [summaryState, setSummaryState] = React.useState<SummaryPageStates>(
    SummaryPageStates.ONLINE,
  );
  const {
    tariffAndContributionValues,
    hedgingNeedsValues,
    riskAssessmentValues,
    personalDataOfTheInsuredPersonValues,
    personalDataOfThePolicyHolderOnlineValues,
    personalDataOfThePolicyHolderOfflineValues,
    bankDetailsValues,
    beneficiaryValues,
  } = useGlobalAccordionData(checkoutData, valueRanges, varianten);

  const { duwAccordionData, loadingAnswers } = useDuwAccordionData(checkoutData.duwToken);

  React.useEffect(() => {
    const isSelfInsured = Storage.readItem(SessionStorageKeys.DIVERGING_INSURED_PERSON) === 'false';

    if (isOnline()) {
      return setSummaryState(SummaryPageStates.ONLINE);
    }

    if (isSelfInsured) {
      return setSummaryState(SummaryPageStates.OFFLINE_SELF);
    }
    setSummaryState(SummaryPageStates.OFFLINE_OTHER);
  }, []);

  React.useEffect(() => {
    const ACCORDIONS_CONFIG = {
      [SummaryPageStates.ONLINE]: ACCORDIONS_ONLINE_CONFIG,
      [SummaryPageStates.OFFLINE_SELF]: ACCORDIONS_OFFLINE_SELF_CONFIG,
      [SummaryPageStates.OFFLINE_OTHER]: ACCORDIONS_OFFLINE_OTHER_CONFIG,
    };

    setAccordionsConfig(ACCORDIONS_CONFIG[summaryState]);
  }, [summaryState, loadingAnswers]);

  // Next array controls the configuration of each accordion
  const ACCORDIONS_ONLINE_CONFIG = [
    {
      id: 'tariffAndContribution',
      values: tariffAndContributionValues,
      isOpen: true,
      title: 'Tarif und Beitrag',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW),
      component: (
        <CommonSectionDetails<TariffAndContributionUIOptions>
          values={tariffAndContributionValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_TARIFF),
    },
    {
      id: 'hedgingNeeds',
      values: hedgingNeedsValues,
      title: 'Absicherungsbedarf',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_TARIFDATEN),
      component: <CommonSectionDetails<HedgingNeedsUIOptions> values={hedgingNeedsValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_INSURANCE),
    },
    {
      id: 'riskAssessment',
      values: riskAssessmentValues,
      title: 'Risikoeinschätzung',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_EMPLOYMENT),
      component: <CommonSectionDetails<RiskAssessmentUIOptions> values={riskAssessmentValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_RISK),
    },
    {
      id: 'healthIssues',
      values: duwAccordionData,
      title: 'Gesundheitsfragen',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_DUW),
      component: <DuwSectionDetails values={duwAccordionData} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_HEALTH),
    },
    {
      id: 'personalDataOfThePolicyHolder',
      values: personalDataOfThePolicyHolderOnlineValues,
      title: 'Persönliche Daten des Versicherungsnehmers',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_PERSONAL_DATA),
      component: (
        <CommonSectionDetails<PersonalDataOfThePolicyHolderOnlineUIOptions>
          values={personalDataOfThePolicyHolderOnlineValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_PERSONAL_DATA),
    },
    {
      id: 'bankDetails',
      values: bankDetailsValues,
      title: 'Bankverbindung',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_PERSONAL_DATA),
      component: <CommonSectionDetails<BankDetailsUIOptions> values={bankDetailsValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_PAYMENT),
    },
    {
      id: 'beneficiary',
      values: beneficiaryValues,
      title: 'Bezugsberechtigte Person(en)',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_SUBSCRIPTIONS),
      component: (
        <>
          {beneficiaryValues &&
            beneficiaryValues.map((beneficiary, index) => (
              <React.Fragment key={index}>
                <CommonSectionDetails<BeneficiaryUIOptions> values={beneficiary} />
                <hr className="beneficiary__divider" />
              </React.Fragment>
            ))}
        </>
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_INHERITANCE),
    },
  ];

  const ACCORDIONS_OFFLINE_SELF_CONFIG = [
    {
      id: 'tariffAndContribution',
      values: tariffAndContributionValues,
      isOpen: true,
      title: 'Tarif und Beitrag',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW),
      component: (
        <CommonSectionDetails<TariffAndContributionUIOptions>
          values={tariffAndContributionValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_TARIFF),
    },
    {
      id: 'hedgingNeeds',
      values: hedgingNeedsValues,
      title: 'Absicherungsbedarf',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_TARIFDATEN),
      component: <CommonSectionDetails<HedgingNeedsUIOptions> values={hedgingNeedsValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_INSURANCE),
    },
    {
      id: 'riskAssessment',
      values: riskAssessmentValues,
      title: 'Risikoeinschätzung',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_EMPLOYMENT),
      component: <CommonSectionDetails<RiskAssessmentUIOptions> values={riskAssessmentValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_RISK),
    },
    {
      id: 'personalDataOfThePolicyHolder',
      values: personalDataOfThePolicyHolderOfflineValues,
      title: 'Versicherte Person / Versicherungsnehmer',
      clickEvent: () =>
        onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA),
      component: (
        <CommonSectionDetails<PersonalDataOfThePolicyHolderOnlineUIOptions>
          values={personalDataOfThePolicyHolderOfflineValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_PERSONAL_DATA),
    },
  ];

  const ACCORDIONS_OFFLINE_OTHER_CONFIG = [
    {
      id: 'tariffAndContribution',
      values: tariffAndContributionValues,
      isOpen: true,
      title: 'Tarif und Beitrag',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW),
      component: (
        <CommonSectionDetails<TariffAndContributionUIOptions>
          values={tariffAndContributionValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_TARIFF),
    },
    {
      id: 'hedgingNeeds',
      values: hedgingNeedsValues,
      title: 'Absicherungsbedarf',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_TARIFDATEN),
      component: <CommonSectionDetails<HedgingNeedsUIOptions> values={hedgingNeedsValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_INSURANCE),
    },
    {
      id: 'riskAssessment',
      values: riskAssessmentValues,
      title: 'Risikoeinschätzung',
      clickEvent: () => onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_EMPLOYMENT),
      component: <CommonSectionDetails<RiskAssessmentUIOptions> values={riskAssessmentValues} />,
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_RISK),
    },
    {
      id: 'personalDataOfThePolicyHolder',
      values: personalDataOfThePolicyHolderOfflineValues,
      title: 'Persönliche Daten des Versicherungsnehmers',
      clickEvent: () =>
        onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA),
      component: (
        <CommonSectionDetails<PersonalDataOfThePolicyHolderOnlineUIOptions>
          values={personalDataOfThePolicyHolderOfflineValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_PERSONAL_DATA),
    },
    {
      id: 'personalDataOfTheInsuredPerson',
      values: personalDataOfTheInsuredPersonValues,
      title: 'Persönliche Daten der versicherten Person',
      clickEvent: () =>
        onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA),
      component: (
        <CommonSectionDetails<PersonalDataOfTheInsuredPersonUIOptions>
          values={personalDataOfTheInsuredPersonValues}
        />
      ),
      trackClick: () => trackClickEvent(TrackingElementSummary.ACCORDEON_INSURED_PERSON_DATA),
    },
  ];

  const [accordionsConfig, setAccordionsConfig] = React.useState(ACCORDIONS_ONLINE_CONFIG);

  if (loadingAnswers) {
    return <AppLoader show />;
  }

  return (
    // TODO: Content Wrapper is going to be checked, so maybe this wrapper is no longer needed
    <div className="container ">
      <div className="summary__content-wrapper">
        <Headline text={TEXT_CONFIG[summaryState].TITLE} />

        <section className="summary__accordion-wrapper">
          {accordionsConfig.map(
            ({ id, values, isOpen, title, clickEvent, component, trackClick }) =>
              !!values.length && (
                <Accordion
                  key={id}
                  id={id}
                  isInitiallyOpen={isOpen}
                  sectionTitle={title}
                  onClickHandler={clickEvent}
                  trackClick={trackClick}
                >
                  {component}
                </Accordion>
              ),
          )}
        </section>
        <AemFragment name={TEXT_CONFIG_FRAGMENT[summaryState]} useVA={true} />
        {summaryState === SummaryPageStates.ONLINE ? (
          <Footer
            showNext={true}
            showPrevious={true}
            labelNext="weiter"
            labelPrevious="zurück"
            infoSection={
              <InfoText textBeforeLink={TEXT_CONFIG[SummaryPageStates.ONLINE].INFO_TEXT}></InfoText>
            }
            handleAction={(navigationAction: NavigationAction) => {
              onNavigateNoSubmit(navigationAction);
            }}
          />
        ) : (
          <Footer
            showNext={true}
            showPrevious={true}
            labelNext="Sofort-Download"
            labelPrevious="zurück"
            infoSection={<InfoText textBeforeLink={TEXT_CONFIG[summaryState].INFO_TEXT}></InfoText>}
            handleNext={() => {
              onNavigateNoSubmit(NavigationAction.OFFER_DIRECT_ONLINE);
              trackClickEvent(TrackingElementSummary.BUTTON_DOWNLOAD);
            }}
            handleAction={(navigationAction: NavigationAction) => {
              if (navigationAction === NavigationAction.BACK) {
                if (Storage.readItem(SessionStorageKeys.DIVERGING_INSURED_PERSON) === 'false') {
                  if (isOnline()) {
                    onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_SUBSCRIPTIONS);
                  } else {
                    onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA);
                  }
                } else {
                  onNavigateNoSubmit(NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA);
                }
                return;
              }
            }}
            auxButtons={[
              <AuxButton
                label="Angebot per Post"
                buttonId="btn1"
                key="1"
                onClick={() => {
                  trackClickEvent(TrackingElementSummary.BUTTON_POSTAL);
                  onNavigateNoSubmit(NavigationAction.OFFER_WRITTEN_POSTAL);
                }}
              />,
              <AuxButton
                label="Angebot per E-Mail"
                buttonId="btn2"
                key="2"
                onClick={() => {
                  trackClickEvent(TrackingElementSummary.BUTTON_EMAIL);
                  onNavigateNoSubmit(NavigationAction.OFFER_WRITTEN_EMAIL);
                }}
              />,
            ]}
          />
        )}
      </div>
    </div>
  );
};
