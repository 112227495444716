import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import * as React from 'react';
import { Need, ValueRanges } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import InfoText from '../../../../components/InfoText/infoText';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { ScrollToError } from '../../../../components/ScrollToError';
import { getServiceConfiguration } from '../../../../configuration/serviceConfiguration';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { createNeedPageSchema } from '../../../../validation/NeedPage';
import ConstantIcon from '../../../../images/SumIsuredLinearIcon.svg';
import FallIcon from '../../../../images/SumInsuredFallingIcon.svg';
import './NeedSelection.css';
import { getIsTiedAgent } from '../../../../helpers/modeConfig';

interface NeedSelectionProps extends SharedSubmitCallBack<Need> {
  need?: Need;
  valueRanges: ValueRanges;
  onSubmit: (needSelection: Need, navigationRequest: NavigationAction) => void;
  onSelect: (needSelection: Need) => void;
}

const NeedSelection = ({
  need,
  valueRanges,
  onSubmit,
  onSelect,
}: NeedSelectionProps): React.ReactElement => {
  const [needSelection, setNeedSelection] = React.useState(need);
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);

  const headline = 'Wählen Sie Ihre Absicherungsform aus';
  const footerSectionText =
    'Sie haben Fragen oder möchten sich beraten lassen? Dann rufen Sie einfach an.';

  const footerInfoSection = (
    <InfoText textBeforeLink={getIsTiedAgent() ? '' : footerSectionText}></InfoText>
  );

  const RADIO_OPTIONS = [
    {
      title: 'Konstante Versicherungssumme',
      value: Need.KONSTANT,
      description: 'z. B. zur Absicherung von Partner und/oder Kindern',
      icon: (
        <div className="radio_groups--icon">
          <img src={`${getServiceConfiguration().iconsPath}${ConstantIcon}`} alt="constant" />
        </div>
      ),
    },
    {
      title: 'Fallende Versicherungssumme',
      value: Need.DECREASING,
      description: 'z. B. zur Absicherung von Immobiliendarlehen und/oder Verbraucherkrediten',
      icon: (
        <div className="radio_groups--icon">
          <img src={`${getServiceConfiguration().iconsPath}${FallIcon}`} alt="falling" />
        </div>
      ),
    },
  ];

  const fragmentName =
    needSelection?.toString() === 'LINEAR_FALLEND'
      ? AemFragmentCatalog.STEP1VA2
      : AemFragmentCatalog.STEP1VA1;

  return (
    <Formik
      initialValues={{ need, valueRanges }}
      onSubmit={() => onSubmit(needSelection!, navigationRequest)}
      validationSchema={createNeedPageSchema(valueRanges)}
    >
      {form => (
        <Form className="need-selection__container" data-testid="need-selection">
          <Headline text={headline} />

          <Field
            name="need"
            render={({ field }: FieldProps<FormikValues>) => {
              const { setFieldValue } = form;
              return (
                <RadioGroup
                  options={RADIO_OPTIONS}
                  selectedValue={field.value || needSelection}
                  name="need"
                  onChangeSelection={(_, value) => {
                    const selectedNeed = value as Need;
                    setFieldValue(field.name, value);
                    setNeedSelection(selectedNeed);
                    onSelect(selectedNeed);
                  }}
                />
              );
            }}
          />
          {form.errors.need && (
            <div style={{ color: 'red', clear: 'both', float: 'left' }}>
              <p>{form.errors.need}</p>
            </div>
          )}
          <AemFragment name={fragmentName} useVA={true} />
          <Footer
            showNext
            showPrevious={false}
            labelNext="weiter"
            handleAction={(navigationAction: NavigationAction) => {
              setNavigationRequest(navigationAction);
              form.submitForm();
            }}
            infoSection={footerInfoSection}
          />
          <ScrollToError formik={form} />
        </Form>
      )}
    </Formik>
  );
};

export default NeedSelection;
