import * as React from 'react';
import { getVarianteFromPossibleValues } from '../../../../helpers/variant.utils';
import '../RadioTab.css';
import { RadioTabContentProps } from '../RadioTab.types';
import { RadioTabContentData } from '../RadioTabData';
import RadioTabBenefit from './RadioTabBenefit';
import RadioTabCheckbox from './RadioTabCheckbox';

const RadioTabContent = (props: RadioTabContentProps) => {
  const variantData =
    RadioTabContentData
      .map(data => ({ ...data, title: `${getVarianteFromPossibleValues(data.variant, props.tariffTypeValues?.possibleValues)} ${data.title}` }))
      .filter(data => data.variant === props.variant)
      [0];

  return <div className="tab-content">
    <RadioTabBenefit {...props} {...variantData} />
    <RadioTabCheckbox {...props} />
  </div>;
};

export default RadioTabContent;
