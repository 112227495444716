import { AemQuellsystem } from 'rlv-common';
import { getOeNumberFromErgo } from '../Helper';
import { aemAssets } from '../configuration/serviceConfiguration';
import { CarbonParamName } from '../types/AemCarbonEnv';
import { SessionStorageKeys } from '../types/SessionStorage';
import { Storage } from './Storage';

enum AppMode {
  TIED_AGENT = 'TIED_AGENT',
  ERGO_INTERNET = 'ERGO_INTERNET',
}

const getAPPMode = () => {
  // check if app tiedAgent
  if (aemAssets[CarbonParamName.QUELLSYSTEM] === AemQuellsystem.ERGO_AGENTHOMEPAGE) {
    return AppMode.TIED_AGENT;
  }

  return AppMode.ERGO_INTERNET;
};

export interface ModeConfig {
  mode: AppMode;
  data: ModData;
}

interface ModData {
  email: string;
  telephone: string;
  oeNumber: string;
}

const dataMapper = (
  oeNumber: string = '',
  telephone: string = '',
  email: string = '',
): ModData => ({
  email,
  telephone,
  oeNumber,
});

export const modeConfig = async (): Promise<ModeConfig> => {
  const businessId = Storage.readItem(SessionStorageKeys.BUSINESS_ID) || '';
  const mode = getAPPMode();
  let oeNumber = aemAssets[CarbonParamName.OE_NUMBER];
  let data: ModData;

  switch (mode) {
    case AppMode.TIED_AGENT:
      data = dataMapper(oeNumber, aemAssets[CarbonParamName.TELEPHONE]);
      break;

    default:
      oeNumber = getOeNumberFromErgo() || '';
      data = dataMapper(oeNumber);
      break;
  }

  return {
    mode,
    data,
  };
};

const getAppStoredData = (): ModeConfig =>
  JSON.parse(
    Storage.readItem(SessionStorageKeys.APP_DATA) ||
      JSON.stringify({ mode: getAPPMode(), data: dataMapper() }),
  );

export const storeAppModeData = (appModeData: ModeConfig) =>
  Storage.writeItem(SessionStorageKeys.APP_DATA, JSON.stringify(appModeData));

export const getIsTiedAgent = () => getAppStoredData().mode === AppMode.TIED_AGENT;

export const getIsErgoInternet = () => getAppStoredData().mode === AppMode.ERGO_INTERNET;

export const getOeNumber = () => getAppStoredData().data.oeNumber;

export const getAgentPhone = () => getAppStoredData().data.telephone;
