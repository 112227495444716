export const TIME_OF_ONE_MINUTE: number = 60 * 1000;
export const TIME_OF_ONE_DAY: number = 24 * 60 * TIME_OF_ONE_MINUTE;

// Only date part in ISO string e.g. yyyy-mm-dd
export const ISO_DATE_LENGTH = 10;

export type IsoDateString = string;

export interface DateObject {
  year: string;
  month: string;
  day: string;
}

export const mapDateToDateObject = (date: Date): DateObject => {
  return {
    day: getTwoDigitNumber(date.getDate()),
    month: getTwoDigitNumber(date.getMonth() + 1),
    year: getTwoDigitNumber(date.getFullYear()),
  };
};

export const mapDateToDateIsoString = (date: Date): string => {
  return date.toISOString().slice(0, ISO_DATE_LENGTH);
};

export const mapToGermanDate = (dateText: string | undefined): string | undefined => {
  if (dateText) {
    const date = new Date(dateText);

    return `${getTwoDigitNumber(date.getDate())}.${getTwoDigitNumber(
      date.getMonth() + 1,
    )}.${date.getFullYear()}`;
  }
  return undefined;
};

export const parseDate = (isoDate: IsoDateString | undefined): Date | undefined => {
  if (isoDate) {
    return new Date(isoDate);
  }
  return undefined;
};

const getTwoDigitNumber = (input: number | string): string => {
  const isOneDigitNumber = Number(input) < 10;
  return `${isOneDigitNumber ? '0' : ''}${Number(input)}`;
};

export function mapDateInputToIsoDateString(originalValue: unknown): string | undefined {
  const newDateObject = originalValue as DateObject;
  if (!newDateObject || !newDateObject.day || !newDateObject.month || !newDateObject.year) {
    return undefined;
  }
  const isoDate = `${newDateObject.year}-${getTwoDigitNumber(
    newDateObject.month,
  )}-${getTwoDigitNumber(newDateObject.day)}`;

  if (isValidIsoDateFormat(isoDate)) {
    return isoDate;
  } else {
    return undefined;
  }
}

export function isValidIsoDateFormat(value: IsoDateString): boolean {
  if (value) {
    const pattern = /(^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$)/;
    return pattern.test(value);
  } else {
    return false;
  }
}

export const mapIsoDateStringToDateInput = (isoDate: IsoDateString | undefined): DateObject => {
  if (isoDate && isValidIsoDateFormat(isoDate)) {
    const date = new Date(isoDate);
    return {
      year: getTwoDigitNumber(date.getFullYear()),
      month: getTwoDigitNumber(date.getMonth() + 1),
      day: getTwoDigitNumber(date.getDate()),
    } as DateObject;
  }
  return { year: '', month: '', day: '' };
};
