import { getServiceConfiguration } from '../configuration/serviceConfiguration';
// eslint-disable-next-line
const loadjs = require('load-js');

const serviceConfiguration = getServiceConfiguration();

function loadExternalScript(...urls: string[]): Promise<void> {
  return loadjs(urls.map(url => ({ url, async: true })));
}

export const duwLoaded = loadExternalScript(serviceConfiguration.duw.entrypointFile);
export const pdeLoaded = loadExternalScript(serviceConfiguration.pde.entrypointFile);
export const trackJs = loadExternalScript(
  `//cdn.trackjs.com/releases/current/tracker.js?randomParam${Math.random()}`,
);
