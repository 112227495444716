import * as React from 'react';
import { Beitragsvariante, Variante } from 'rlv-common';
import { duwLoaded } from '../../../helpers/externalScripts';
import { Storage } from '../../../helpers/Storage';
import { ViewName } from '../../../helpers/ViewName';
import { configuration } from '../../../index';
import DuwPageContainer, { DuwPageData } from '../../../pages/duw/DuwPageContainer';
import { getAngebot, updateDuwData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { SessionStorageKeys } from '../../../types/SessionStorage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const duwAssessment: StateDefinition<DuwPageData> = {
  name: StateName.DUW_ASSESSMENT_PAGE,
  percentage: PagePercentage.DUW_ASSESSMENT_PAGE,
  trackingViewName: ViewName.GESUNDHEITSFRAGEN_DUW1,
  validInboundStates: [
    StateName.DUW_QUESTIONNAIRE_PAGE,
    StateName.PERSONAL_DATA_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE
  ],
  transitions: [
    {
      test: (action, _) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.DUW_QUESTIONNAIRE_PAGE
    },
    {
      test: (action, _) => action === NavigationAction.NEXT,
      newState: StateName.PERSONAL_DATA_PAGE
    }
  ],
  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);
    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};

    let variante: Beitragsvariante;

    if (response.feeCalculationModel) {
      variante = response.feeCalculationModel.variante || '';
    }

    const variantenInformation = response.varianten.find(element => element.variante === variante);

    return {
      userInput: {
        ...response,
        priceNetto: variantenInformation && variantenInformation.nettoBeitragMitRisikozuschlag
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData
    };
  },
  onExit: async (_, inputData) => {
    await updateDuwData(inputData.businessId, inputData.userInput);
    Storage.writeItem(SessionStorageKeys.DUW_STATE, 'Completed');

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const userInput = inputData.userInput;
    const selectedVariant = userInput.varianten && userInput.varianten.find((variant: Variante) => variant.selected);

    return <DuwPageContainer
      configuration={configuration}
      businessId={inputData.businessId}
      storeState={{
        duwResult: userInput.duwResult,
        paymentMethod: userInput.paymentMethod,
        valueRanges: userInput.valueRanges,
        selectedVariant,
        update: values => updateApp(values)
      }}
      onError={onError}
      scriptLoaded={duwLoaded}
      handleAction={handleAction}
      updateDTMTracking={updateDTMTracking}
    />;
  }
};
