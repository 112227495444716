import { THEME } from '@eg/elements/Provider';
import { FormikProps, getIn } from 'formik';
import { getQueryValue } from 'rlv-common';
import { FEATURE_FLAG_LIST, FFlagList, featureFlag } from './FeatureFlag';
import { Key } from './Key';
import { aemAssets } from './configuration/serviceConfiguration';
import { isProduction } from './constants';
import { Storage } from './helpers/Storage';
import { CarbonParamName } from './types/AemCarbonEnv';

export const egElementsTheme = THEME['ergo-one'];

export const getErrorMessage = (
  form: FormikProps<object>,
  field: { name: string },
): string | undefined => {
  if ((getIn(form.touched, field.name) || form.isSubmitting) && getIn(form.errors, field.name)) {
    return getIn(form.errors, field.name);
  }
  return undefined;
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const limitMaxLength = (value: string, maxLength: number): string =>
  value.substr(0, maxLength);

export const isDivergingInsuredPerson = (): boolean =>
  Storage.readItem('divergingInsuredPerson') === 'true';

/**
 * Prevent the User from entering 'e', '+', '-', '.' and ','
 *
 * @param event The KeyboardEvent which should be filtered.
 */
export const filterNumberInputField = (event: React.KeyboardEvent) => {
  if (
    event.which === Key.E ||
    event.which === Key.ALPHA_PLUS ||
    event.which === Key.NUMPAD_PLUS ||
    event.which === Key.COMMA ||
    event.which === Key.NUMPAD_COMMA ||
    event.which === Key.PERIOD ||
    event.which === Key.NUMPAD_MINUS ||
    event.which === Key.ALPHA_MINUS
  ) {
    event.preventDefault();
  }
};

export const getOeNumberFromErgo = (): string | undefined => {
  const urlParam = getQueryValue('adh_oenr') || undefined;
  if (!!urlParam) {
    return urlParam;
  }

  if (!!aemAssets) {
    return aemAssets[CarbonParamName.OE_NUMBER];
  }
  return undefined;
};

// Get Feature flags from AEM
const featureList = FEATURE_FLAG_LIST?.filter(k => aemAssets[k] !== undefined).map(v => ({
  name: FFlagList[v],
  active: aemAssets[v] === 'true',
}));

const featureFlagApi = featureFlag(featureList, isProduction);

export const isFeatureActive = (name: FFlagList) => featureFlagApi.isActive(name);

export const shouldShowPublicView = (isPublicView: boolean) => isPublicView;
