import * as React from 'react';
import { duwLoaded } from '../../../helpers/externalScripts';
import { ViewName } from '../../../helpers/ViewName';
import { configuration } from '../../../index';
import DuwPageContainer, { DuwPageData } from '../../../pages/duw/DuwPageContainer';
import { getAngebot, updateDuwData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const duwQuestionnaire: StateDefinition<DuwPageData> = {
  name: StateName.DUW_QUESTIONNAIRE_PAGE,
  percentage: PagePercentage.DUW_QUESTIONAIRE,
  trackingViewName: ViewName.GESUNDHEITSFRAGEN_DUW1,
  validInboundStates: [
    StateName.DUW_DISCLOSURE_PAGE,
    StateName.DUW_ASSESSMENT_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],
  transitions: [
    {
      test: (action, data) =>
        action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.LIVING_CONDITIONS_INCEPTION_DATE,
    },
    {
      test: (action, data) => action === NavigationAction.NEXT,
      newState: StateName.DUW_ASSESSMENT_PAGE,
    },
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);
    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};
    const { employment: situation, professionKey } = response.livingConditionsModel || {};
    const profession: string | undefined = professionKey?.toString().padStart(6, '0');
    return {
      userInput: {
        ...response.duwDisplayModel,
        situation,
        profession,
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  onExit: async (_, inputData) => {
    await updateDuwData(inputData.businessId, inputData.userInput);

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const userInput = inputData.userInput;

    return (
      <DuwPageContainer
        configuration={configuration}
        businessId={inputData.businessId}
        storeState={{
          duwToken: userInput.duwToken,
          birthdate: userInput.birthdate,
          vorname: userInput.vorname,
          nachname: userInput.nachname,
          weight: userInput.weight,
          height: userInput.height,
          smoker: userInput.smoker,
          sumInsured: userInput.sumInsured,
          situation: userInput.situation,
          profession: userInput.profession,
          paymentMethod: userInput.paymentMethod,
          valueRanges: userInput.valueRanges,
          update: values => updateApp(values),
        }}
        onError={onError}
        scriptLoaded={duwLoaded}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
