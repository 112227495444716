import { DuwDisplayModel } from 'rlv-common';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { Configuration } from '../../../types/Configuration';
import { SharedSubmitCallBack } from '../../../types/callbacks/sharedSubmitCallBack';
import { DuwResult } from '../../../types/external/duw/DuwResult';

export interface DuwQuestionnaireProps extends DuwDisplayModel, SharedSubmitCallBack<DuwResult> {
  scriptLoaded: Promise<void>;
  configuration: Configuration;
  onError: (e: Error) => void;
  onProgress: (percentage: number) => void;
  onNotifyId: (token: string) => void;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: DuwResult, navigationRequest: NavigationAction) => void;
}

export const DUW_TITLE = 'Gesundheits-Check';
