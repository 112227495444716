import { useEffect, useState } from 'react';

interface CustomSizesInt {
  topSize: number;
  marginSize: number;
}

// Calculate header height to fit various environment menu bar implementations
export const useHeaderHeight = () => {
  const [customSizes, setCustomSizes] = useState<CustomSizesInt>({ topSize: 0, marginSize: 0 });

  const calculateSize = () => {
    if (document && !!document.getElementById('MainNavi')) {
      const faitHeaderHeight = document.getElementById('MainNavi')!.clientHeight || 0;
      setCustomSizes({ topSize: faitHeaderHeight, marginSize: 0 });
    }
  };

  useEffect(() => {
    calculateSize();

    window.matchMedia('(min-width: 912px)').addEventListener('change', calculateSize);
    window.matchMedia('(min-width: 1152px)').addEventListener('change', calculateSize);

    return () => {
      window.matchMedia('(min-width: 912px)').removeEventListener('change', calculateSize);
      window.matchMedia('(min-width: 1152px)').removeEventListener('change', calculateSize);
    };
  }, []);

  return { customSizes };
};
