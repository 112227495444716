import { ColorScheme, Price as EEPrice, Size } from '@eg/elements/Price';
import * as React from 'react';
import { convertToNumber } from '../../helpers/numberFormatter';
import { PaymentMethodAbbr } from '../../helpers/PaymentMethod';
import { PriceProps, PriceSize } from './Price.types';

const Price = ({ amount, paymentMethod, size = PriceSize.Large , unstyled, checked, hideFractionDigits}: PriceProps) => (
  <EEPrice
    value={convertToNumber(amount)}
    size={Size[PriceSize[size]]}
    suffix={paymentMethod ? PaymentMethodAbbr[paymentMethod] : ''}
    unstyled={unstyled}
    colorScheme={checked ? ColorScheme.Inverted : ColorScheme.Regular }
    hideFractionDigits={hideFractionDigits}
  />
);

export default Price;
