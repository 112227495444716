import * as React from 'react';
import { PaymentMethodText } from '../../helpers/PaymentMethod';
import {
  AfterSalesFeedbackContent as AfterSalesFeedbackContentType,
  AfterSalesFeedbackTypes,
} from './AfterSalesFeedback.types';

const cardTitle = 'Ihre Risikolebensversicherung';
export const paymentFrequency = (paymentMethod: string) => {
  if (paymentMethod === 'MONATLICH') {
    return PaymentMethodText.MONATLICH;
  }
  if (paymentMethod === 'VIERTELJAEHRLICH') {
    return PaymentMethodText.VIERTELJAEHRLICH;
  }
  if (paymentMethod === 'HALBJAEHRLICH') {
    return PaymentMethodText.HALBJAEHRLICH;
  }
  if (paymentMethod === 'JAEHRLICH') {
    return PaymentMethodText.JAEHRLICH;
  }
  return undefined;
};

export const basisVariant = 'Grundschutz';

const AfterSalesFeedbackContent: AfterSalesFeedbackContentType = {
  [AfterSalesFeedbackTypes.ONLINE_APPLICATION]: {
    header: {
      subtitle: 'Ihr Antrag ist angekommen. Sie müssen nichts weiter tun.',
    },
    body: {
      paragraph1: (
        <>
          ERGO kümmert sich um alles Weitere. Sie{' '}
          <b>erhalten in wenigen Tagen Ihre Vertragsunterlagen per Post</b>, sobald Ihr Antrag
          bearbeitet wurde.
          <br />
          <br />
          Wenn Sie Fragen haben, rufen Sie bitte an.
        </>
      ),
      cardTitle,
    },
  },
  [AfterSalesFeedbackTypes.OFFER_POSTAL]: {
    header: {
      subtitle: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
    },
    body: {
      paragraph1: (
        <>
          <b>In wenigen Tagen erhalten Sie Ihr persönliches Angebot per Post</b>. Dann können Sie
          alles in Ruhe prüfen und vergleichen.
          <br />
          <br />
          Wenn Sie Fragen haben, rufen Sie bitte an.
        </>
      ),
      cardTitle,
    },
  },
  [AfterSalesFeedbackTypes.OFFER_EMAIL]: {
    header: {
      subtitle: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
    },
    body: {
      paragraph1: (
        <>
          <b>In Kürze erhalten Sie Ihr persönliches Angebot an die angegebene E-Mail-Adresse</b>.
          Dann können Sie alles in Ruhe prüfen und vergleichen.
          <br />
          <br />
          Wenn Sie Fragen haben, rufen Sie bitte an.
        </>
      ),
      cardTitle,
    },
  },
  [AfterSalesFeedbackTypes.OFFER_PDF]: {
    header: {
      subtitle: 'Ihre Angebotsanforderung wurde erfolgreich gesendet.',
    },
    body: {
      paragraph1: (
        <>
          <b>Ihr persönliches Angebot wurde Ihnen per Download bereitgestellt</b>. Jetzt können Sie
          alles in Ruhe prüfen und vergleichen.
          <br />
          <br />
          Wenn Sie Fragen haben, rufen Sie bitte an.
        </>
      ),
      cardTitle,
    },
  },
};

export default AfterSalesFeedbackContent;
