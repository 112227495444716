import * as React from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import AemFragment from '../../../components/AemFragment/AemFragment';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { FormTemplateProps } from '../../../types/FormTemplate.types';
import Footer from '../../Footer/index';
import { Headline } from '../../Headline/Headline';
import './FormTemplate.css';

const FormTemplate = <T,>({
  templateLayout: { title, subline, footerInfoSection, virtualAssistantFragment },
  formValues,
  validationSchema,
  handleOnSubmitAction,
  handleNavigateNoSubmitAction,
  render,
}: FormTemplateProps<T>) => {
  const [navigationAction, setNavigationAction] = React.useState<NavigationAction>(
    NavigationAction.NEXT,
  );

  return (
    <Formik
      isInitialValid={validationSchema.isValidSync(formValues)}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(value: T) => handleOnSubmitAction(value, navigationAction)}
    >
      {(formProps: FormikProps<T>) => (
        <>
          <Form className="form-template__content">
            <Headline text={title} subline={subline} />

            {render.map(
              elem =>
                !elem.shouldBeHidden && (
                  <Field name={elem.name} key={elem.name}>
                    {({ form, field }: FieldProps<T>) => <>{elem.component(form, field)}</>}
                  </Field>
                ),
            )}
            {virtualAssistantFragment && (
              <AemFragment name={virtualAssistantFragment} useVA={true} />
            )}
            <Footer
              showNext={true}
              showPrevious={true}
              labelNext="weiter"
              labelPrevious="zurück"
              handleAction={(action: NavigationAction) => {
                setNavigationAction(action);
                if (action === NavigationAction.BACK && handleNavigateNoSubmitAction) {
                  handleNavigateNoSubmitAction(action);
                  return;
                }
                formProps.submitForm();
              }}
              infoSection={footerInfoSection && footerInfoSection()}
              disableNext={!formProps.isValid}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default FormTemplate;
