export enum BasisDatenFieldState {
  HIDDEN = 'hidden',
  READONLY = 'readonly'
}

export interface BasisDatenConfiguration {
  gewicht?: BasisDatenFieldState;
  groesse?: BasisDatenFieldState;
  geschlecht?: BasisDatenFieldState;
}

export default BasisDatenConfiguration;
