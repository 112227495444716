export interface AemCarbonEnv {
  name: string;
  url: string;
  [key: string]: string;
}

export enum CarbonParamName {
  OE_NUMBER = 'OENumber',
  PNR_NUMBER = 'PNRNumber',
  TELEPHONE = 'telephone',
  QUELLSYSTEM = 'quellsystem',
  VVA = 'vva', // vorvertraglicheAnzeigepflicht (eng: Pre-contractual duty to inform)
}
