import * as React from 'react';
import { Tracker } from './tracker';
import { TrackingConsumer } from './trackingContext';

// Taken from https://github.com/piotrwitek/utility-types/blob/master/src/mapped-types.ts#L162
type SetDifference<A, B> = A extends B ? never : A;
type SetComplement<T, T1 extends T> = SetDifference<T, T1>;
export type Subtract<T extends T1, T1 extends object> = Pick<T, SetComplement<keyof T, keyof T1>>;

export interface InjectedTrackerProps {
  tracker: Tracker;
}

export const withTracker = <P extends InjectedTrackerProps>(Component: React.ComponentType<P>) =>
  class ComponentWithTracker extends React.Component<Subtract<P, InjectedTrackerProps>> {
    public render(): React.ReactNode {
      return (
        <TrackingConsumer>
          {
          // @ts-ignore FIXME!!!
            context => context && <Component {...this.props} tracker={context}/>
          }
        </TrackingConsumer>
      );
    }
  };
