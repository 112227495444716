import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { ControlWithHint } from '@eg/elements/ControlWithHint';
import ChevronRightIcon from '@eg/elements/components/Icons/ChevronRightIcon.js';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioTileValue } from '../../../../components/RadioGroup/RadioTile/RadioTile.types';
import { Storage } from '../../../../helpers/Storage';
import { getAgentPhone, getIsTiedAgent } from '../../../../helpers/modeConfig';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementGWG } from '../../../../tracking/tracking.types';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { ConsentSelection, MoneyLaunderingText, RADIO_GROUP_OPTIONS } from './MoneyLaundering.data';
import { MoneyLaunderingProps } from './MoneyLaundering.types';
import './MoneyLaundering.css';

export const MoneyLaundering = ({
  trackClickEvent,
  onNavigateNoSubmit,
  onSubmit,
}: MoneyLaunderingProps) => {
  const [selectedOption, setSelectedOption] = React.useState<RadioTileValue>(
    Storage.readItem('consentSelfInitiative')
      ? Storage.readItem('consentSelfInitiative') === 'true'
        ? ConsentSelection.YES
        : ConsentSelection.NO
      : '',
  );

  const headerSubtext = (
    <>
      Erfolgen die Aufnahme der Geschäftsbeziehung und die damit verbundenen Zahlungen
      (Transaktionen) auf Ihre eigene Veranlassung und aufgrund eigener Entscheidungen?
      <br />
      <br />
      Und schließen Sie (als Versicherungsnehmer) den Vertrag für sich selbst ab? (D. h. Sie sind
      kein Stellvertreter, z. B. ein gesetzlicher Betreuer oder Bevollmächtigter?)
    </>
  );

  return (
    <div className="container__money-laundering">
      <Headline text={MoneyLaunderingText.title} />
      <section className="container__money-laundering--paragraph">{headerSubtext}</section>
      <div className="radio_groups--container">
        <div className="radio-groups--tiles">
          <RadioGroup
            options={RADIO_GROUP_OPTIONS}
            name="consent"
            selectedValue={selectedOption}
            onChangeSelection={(_, value) => {
              setSelectedOption(value);
              if (value === ConsentSelection.YES) {
                trackClickEvent(TrackingElementGWG.RB_GWG_YES);
                Storage.writeItem('consentSelfInitiative', JSON.stringify(true));
              } else {
                trackClickEvent(TrackingElementGWG.RB_GWG_NO);
                Storage.removeItem('consentSelfInitiative');
              }
            }}
          />
        </div>
        {selectedOption === ConsentSelection.NO && (
          <div className="radio-groups--errorSection">
            {getIsTiedAgent() && (
              <div className="radio-groups--errorCard">
                <Card
                  label={MoneyLaunderingText.cardTitle}
                  accentColor="#8e0038"
                  className="radio-groups--card"
                >
                  {`${MoneyLaunderingText.cardContent}${getAgentPhone()}`}
                </Card>
              </div>
            )}
            {!getIsTiedAgent() && (
              <>
                <div className="radio-groups--error">
                  <ControlWithHint
                    error={
                      selectedOption === ConsentSelection.NO && MoneyLaunderingText.errorMessage
                    }
                  />
                </div>
                <div className="radio-groups--link">
                  <Button
                    variant="text-link"
                    iconRight={ChevronRightIcon}
                    size="normal"
                    onClick={() => {
                      trackClickEvent(TrackingElementGWG.LINK_AGENT);
                      window.open('https://www.ergo.de/de/Service/Vermittlersuche', '_blank');
                    }}
                  >
                    <div className="radio-groups--linkText">{MoneyLaunderingText.link}</div>
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <AemFragment name={AemFragmentCatalog.STEP16VA} useVA={true} />
      <Footer
        showNext
        showPrevious
        labelNext="weiter"
        labelPrevious="zurück"
        disableNext={selectedOption === ConsentSelection.NO || !selectedOption}
        handleAction={(navigationAction: NavigationAction) => {
          if (navigationAction === NavigationAction.BACK && selectedOption === '') {
            onNavigateNoSubmit(navigationAction);
          }
          if (ConsentSelection.YES) {
            onSubmit(true, navigationAction);
          }
        }}
      />
    </div>
  );
};
