import * as React from 'react';
import Price from '../../../../components/Price/Price';
import { PriceSize } from '../../../../components/Price/Price.types';
import { isMobile } from '../../../../helpers/mediaMatcher';
import '../RadioTab.css';
import { RadioTabButtonProps } from '../RadioTab.types';

const RadioTabButton = ({
  checked,
  variant,
  icon: Icon,
  highlightText,
  title,
  subtitle,
  paymentMethod,
  price,
  setVariant
}: RadioTabButtonProps) => (
  <label
    className={`tab-button-container ${checked ? 'tab-button-container--checked' : ''}`}
    data-testid={variant}
  >
    {Icon && <Icon height="34px" width="34px" />}
    {highlightText && (
      <span className="tab-highlight">{highlightText}</span>
    )}
    <span className="tab-title">{title}</span>
    {subtitle && <span className="tab-subtitle">{subtitle}</span>}
    {price && paymentMethod && (
      <Price
        amount={String(price)}
        paymentMethod={paymentMethod}
        unstyled={true}
        size={isMobile() ? PriceSize.Small : PriceSize.Medium}
        checked={checked}
      />
    )}
    <input
      type="radio"
      name="tarif"
      className="tab-input"
      key={variant}
      checked={checked}
      onChange={() => {
        setVariant(variant);
      }}
    />
  </label>
);

export default RadioTabButton;
