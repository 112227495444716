import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingGender } from '../../../../tracking/tracking.types';
import { InjectedTrackerProps } from '../../../../tracking/withTracker';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';

export enum PhysicalAspectFormText {
  TITLE = 'Fragen zur Person',
  VIRTUAL_ASSISTANT = 'Diese Daten sind wichtig, um Ihr persönliches Risiko besser einzuschätzen. Keine Sorge, ERGO nimmt den Schutz Ihrer Daten sehr ernst und hält sich streng an die gesetzlichen Datenschutzvorschriften.',
  FOOTER = 'Wenn Sie jemand anders absichern möchten, geben Sie bitte dessen Daten an.',
}

export enum PhysicalAspectFormRadioTitle {
  MAN = 'Mann',
  WOMAN = 'Frau',
}

export enum PhysicalAspectFormNames {
  GENRE = 'genre',
  HEIGHT = 'height',
  WEIGHT = 'weight',
}

export interface PhysicalAspectFormValues {
  genre: string | undefined;
  height: number | undefined;
  weight: number | undefined;
}

export interface PhysicalAspectFormProps
  extends SharedSubmitCallBack<PhysicalAspectFormValues>,
    InjectedTrackerProps {
  height?: number;
  weight?: number;
  disabled: boolean;
  onSubmit: (value: PhysicalAspectFormValues, navAction: NavigationAction) => void;
  onNavigateNoSubmit?: (navigationRequest: NavigationAction) => void;
  trackClick: (gender: TrackingGender) => void;
  updateTrackingValues: (data: TrackingValues) => void;
}

export interface TrackingValues {
  height?: string;
  weight?: string;
}
