import { AxiosError } from 'axios';

export interface TrackingContent {
  event?: TrackingEvents;
  page: TrackingPage;
  product: TrackingProduct[];
  transaction: TrackingTransaction;
  eventdetails?: TrackingEventDetails;
  user?: TrackingUser[];
  miscellaneous?: TrackingMiscellaneous;
}
export interface TrackingWindow extends Window {
  appEventData: TrackingContent[];
}

export enum TrackingEvents {
  PAGE_LOAD = 'PageLoaded',
  GENERAL_CLICK = 'GeneralClick',
}

export enum TrackingEnvironments {
  DEVELOP = 'develop',
  PRODUCTION = 'prod',
}

export enum TrackingPagename {
  LEGAL_QUESTIONS_SUMMARY_OFFLINE_PAGE = 'LegalQuestionsSummaryOfflinePage',
}

export enum TrackingElementBasic {
  BUTTON_NEXT = 'Btn_Weiter',
  BUTTON_BACK = 'Btn_Zurueck',
}

export enum TrackingElementNeedSelection {
  RADIOBUTTON_FALLING_SUM = 'RB_FallendeVersicherungssumme',
  RADIOBUTTON_CONSTANT_SUM = 'RB_KonstanteVersicherungssumme',
}

export enum TrackingElementDivergingInsuredPerson {
  RADIOBUTTON_SELF = 'RB_MichSelbst',
  RADIOBUTTON_DIVERGING = 'RB_EineAnderePerson',
}

export enum TrackingElementSmoker {
  RADIOBUTTON_SMOKER = 'RB_RaucherJa',
  RADIOBUTTON_NONSMOKER = 'RB_RaucherNein',
}

export enum TrackingElementGender {
  RADIOBUTTOM_MALE = 'RB_Mann',
  RADIOBUTTION_FEMALE = 'RB_Frau',
}

export enum TrackingElementMotorbike {
  RADIOBUTTON_BIKER = 'RB_MotorradJa',
  RADIOBUTTON_NOT_BIKER = 'RB_MotorradNein',
}

export enum TrackingElementLivingCondition {
  RADIOBUTTON_HOMEOWNER = 'RB_WohneigentumJa',
  RADIOBUTTON_NOT_HOMEOWNER = 'RB_WohneigentumNein',
}

export enum TrackingElementInsuranceStart {
  RADIOBUTTON_DATE_TOP = 'RB_DateTop',
  RADIOBUTTON_DATE_MIDDLE = 'RB_DateMiddle',
  RADIOBUTTON_DATE_BOTTOM = 'RB_DateBottom',
}

export enum TrackingElementFeePage {
  RADIOBUTTON_PREMIUM = 'RB_Premium',
  RADIOBUTTON_KOMFORT = 'RB_Komfort',
  RADIOBUTTON_BASIC = 'RB_Basis',
  BUTTON_ALL_BENEFITS = 'Btn_AlleLeistungen',
  CHECKBOX_SECURITY_PLUS = 'Checkbox_SicherheitPlus',
  CHECKBOX_DYNAMIC_PAYMENT = 'Checkbox_Beitragsdynamik',
  DOWNLOAD_BENEFITS_PDF = 'Download_Leistungsübersicht',
  INFOICON_BASIC_AFTER_INSURANCE = 'InfoIcon_Nachversicherungsgarantie',
  INFOICON_AFTER_INSURANCE = 'InfoIcon_NachversicherungsgarantieErweitert',
  INFOICON_SECRUITY_PLUS = 'InfoIcon_SicherheitPlus',
  INFOICON_DYNAMIC_PAYMENT = 'InfoIcon_BeitragsDynamik',
  INFOICON_EARLY_PAYMENT = 'InfoIcon_VorgezogeneTodesfallleistung',
  INFOICON_MEDICAL_OPINION = 'InfoIcon_MedizinischeZweitmeinung',
  INFOICON_INSTANT_HELP = 'InfoIcon_SoforthilfeAngehörige',
  BUTTON_ONLINE_CONTRACT = 'Btn_OnlineAbschließen',
  BUTTON_REQUEST_OFFER = 'Btn_AngebotAnfordern',
  BUTTON_MORE_INFO = 'Link_MehrInfoZumBeitrag',
  INFOICON_DURATION = 'InfoIcon_Laufzeit',
  INFOICON_DEATH_BENEFIT = 'InfoIcon_Todesfallsumme',
}

export enum TrackingElementPersonalData {
  BUTTON_SAVE = 'Btn_Speichern',
  INFOICON_IBAN = 'InfoIcon_IBAN',
  BUTTON_SAVE_PERSON = 'Btn_PersonSpeichern',
}

export enum TrackingElementSubscriptions {
  RB_BEZUGSRECHT_JA = 'RB_BezugsrechtJa',
  RB_REZUGSRECHT_NEIN = 'RB_BezugsrechtNein',
  LNK_INFO_BEZUGSRECHT = 'Lnk_InfoBezugsrecht',
  LNK_ANSCHRIFT_VP_UEBERNEHMEN = 'Lnk_AnschriftVPÜbernehmen',
  INFO_ICON_LAND = 'InfoIcon_Land',
  BTN_PERSON_SPEICHERN = 'Btn_PersonSpeichern',
  LNK_PERSON_LOESCHEN = 'Lnk_PersonLöschen',
  LNK_PERSON_BEARBEITEN = 'Lnk_PersonBearbeiten',
  BTN_PERSON_HINZUFUEGEN = 'Btn_PersonHinzufügen',
}

export enum TrackingElementGWG {
  RB_GWG_YES = 'RB_GwgJa',
  RB_GWG_NO = 'RB_GwgNein',
  LINK_AGENT = 'Lnk_VermittlerFinden',
}

export enum TrackingElementLoanProtection {
  RB_LOAN_YES = 'RB_DarlehenJa',
  RB_LOAN_NO = 'RB_DarlehenNein',
}

export enum TrackingElementConsentDataProcessing {
  CHECKBOX_DATAPROCESSING = 'Checkbox_DatenverarbeitungJa',
  CHECKBOX_HEALTHDATA = 'Checkbox_WeitergabeGesundheitsdatenJa',
}

export enum TrackingElementConsentToReleasePrivateData {
  RADIOBUTTON_GENERAL = 'RB_AllgemeineSEE',
  RADIOBUTTON_SINGULAR = 'RB_EinzelfallSEE',
  CHECKBOX = 'Checkbox_SEE',
}

export enum TrackingElementSummary {
  ACCORDEON_TARIFF = 'Acc_TarifUndBeitrag',
  ACCORDEON_INSURANCE = 'Acc_Absicherungsbedarf',
  ACCORDEON_RISK = 'Acc_Risikoeinschätzung',
  ACCORDEON_HEALTH = 'Acc_Gesundheitsfragen',
  ACCORDEON_PERSONAL_DATA = 'Acc_PersönlicheDatenVersicherungsnehmer',
  ACCORDEON_INSURED_PERSON_DATA = 'Acc_PersönlicheDatenVersichertePerson',
  ACCORDEON_PAYMENT = 'Acc_Bankverbindung',
  ACCORDEON_INHERITANCE = 'Acc_BezugsberechtigtePersonen',
  BUTTON_DOWNLOAD = 'Btn_SofortDownload',
  BUTTON_POSTAL = 'Btn_AngebotPerPost',
  BUTTON_EMAIL = 'Btn_AngebotPerEmail',
}

export enum TrackingElementCreditCheck {
  CHECKBOX = 'Checkbox_BonitätJa',
}

export enum TrackingElementIDD {
  CHECKBOX_IDD = 'Checkbox_BeratungsverzichtJa',
  BUTTON_IDD_DOWNLOAD = 'Btn_IDDDownload',
  BUTTON_SEND = 'Btn_OnlineBeantragen',
}

export enum TrackingElementAfterSalesFeedback {
  BUTTON_DOWNLOAD_PDF = 'Btn_AngebotDownload',
}

export enum TrackingConversionTypes {
  SALE_FUNNEL_START = 'SaleFunnelStart',
  SALE_FUNNEL_PERSONAL_DATA = 'SaleFunnelPersonalData',
  SALE_FUNNEL_CALCULATION = 'SaleFunnelCalculation',
  SALE_FUNNEL_FINAL_CHECK = 'SaleFunnelFinalCheck',
  SALE = 'Sale',
  POSTAL = 'OfferPost',
  EMAIL = 'OfferEmail',
  PDF_DOWNLOAD = 'OfferPdfDownload',
}

export enum TrackingNeed {
  CONSTANT = 'KonstanteSumme',
  DECREASING = 'LinearFallendeSumme',
}

export type TrackingElement =
  | TrackingElementBasic
  | TrackingElementNeedSelection
  | TrackingElementDivergingInsuredPerson
  | TrackingElementSmoker
  | TrackingElementGender
  | TrackingElementMotorbike
  | TrackingElementLivingCondition
  | TrackingElementInsuranceStart
  | TrackingElementFeePage
  | TrackingElementPersonalData
  | TrackingElementSubscriptions
  | TrackingElementGWG
  | TrackingElementLoanProtection
  | TrackingElementConsentDataProcessing
  | TrackingElementConsentToReleasePrivateData
  | TrackingElementSummary
  | TrackingElementCreditCheck
  | TrackingElementDUWDisclosure
  | TrackingElementIDD
  | TrackingElementAfterSalesFeedback;

interface TrackingPage {
  pageInfo: TrackingPageInfo;
  attributes: TrackingPageAttributes;
  category: TrackingPageCategory;
}

export enum TrackingBoolean {
  YES = 'J',
  NO = 'N',
}

interface TrackingPageInfo {
  pageName?: string;
  sysEnv: TrackingEnvironments;
  issueDate: string;
}

interface TrackingAgency {
  oenrNumber?: string;
  pnrNumber?: string;
}

interface TrackingMiscellaneous {
  errors?: TrackingError;
  agency?: TrackingAgency;
}

export interface TrackingError {
  errorFlag: true;
  errorType?: number | string; // TODO: analyze to unify in the string type
  errorMessages: string;
  errorUrl: string;
}

interface TrackingPageAttributes {
  displayedBrand: TrackingBrand;
  brokerModus: TrackingBrokerModus;
}

export interface NetworkError extends AxiosError {}

export enum TrackingBrand {
  ERGO = 'ERGO',
}

export enum TrackingBrokerModus {
  ON = 'On',
  OFF = 'Off',
}
interface TrackingPageCategory {
  pagePurpose: TrackingPagePurpose;
  primaryCategory: TrackingCategory;
}

export enum TrackingPagePurpose {
  SALE = 'Sale',
}

export enum TrackingCategory {
  RLV = 'risikolv2022',
}

interface TrackingProduct {
  productInfo: TrackingProductInfo;
  category: TrackingProductCategory;
  attributes: TrackingProductAttributes;
}

interface TrackingProductInfo {
  productName: string;
}

export interface TrackingProductAttributes {
  tariffOptions?: TrackingTariffOptions;
  additionalTariffOptions?: TrackingAdditionalTariffOptions;
  insuranceStart?: string;
  productCombination?: TrackingProductCombination;
  tariffOptions1?: string;
  tariffOptions2?: string;
}

export enum TrackingProductCombination {
  basic = 'Basis',
  comfort = 'Komfort',
  premium = 'Premium',
}

export interface TrackingTariffOptions {
  Rechner: TrackingTarifName;
  Bedarfsanalyse?: TrackingNeed;
  VersSum?: number;
  VersLZ?: number;
  VersSumEJ?: number;
  VersSumLJ?: number;
  Dynamik?: TrackingBoolean;
  SiPlus?: TrackingBoolean;
  Zahlung?: TrackingPaymentInterval;
  BezugsrechtAend?: number;
}

export enum TrackingTarifName {
  RLV = 'risikolv',
}

export enum TrackingPaymentInterval {
  MONTHLY = '1/12',
  QUARTERLY = '1/4',
  BIANNUAL = '1/2',
  YEARLY = '1/1',
}

export interface TrackingAdditionalTariffOptions {
  Rechner: TrackingTarifName;
  RauchS?: TrackingSmokerValues;
  KGroesse?: string;
  KGewicht?: string;
  MotorradF?: TrackingBoolean;
  SWohneig?: TrackingBoolean;
  BezugrechtAend?: number;
}

export enum TrackingSmokerValues {
  smoker = 'R',
  nonSmokerOneYear = 'NRless10',
  nonSmokerTenYears = 'NRmin10',
}

interface TrackingProductCategory {
  primaryCategory: string;
}

interface TrackingTransaction {
  transactionID: string;
  attributes?: TrackingTransactionAttributes;
  total?: TransactionTotal;
}

interface TransactionTotal {
  transactionTotal?: number;
}

interface TrackingTransactionAttributes {
  conversionType?: TrackingConversionTypes;
  calculatedValue?: number;
}

interface TrackingEventDetails {
  clickType: 'other';
  clickedElement?: TrackingElement;
}

export enum TrackingClickType {
  OTHER = 'other',
}

export interface TrackingUser {
  profile: TrackingUserProfile;
}

interface TrackingUserProfile {
  attributes: TrackingUserAttributes;
  address?: TrackingUserAddress;
  profileInfo?: TrackingProfileInfo;
}

export interface TrackingUserContact {
  address: TrackingUserAddress;
  profileInfo: TrackingProfileInfo;
}

export interface TrackingUserAttributes {
  insuredIsInsuredPerson?: TrackingBoolean;
  birthday?: string;
  jobTitle?: string;
  occupationalGroup?: string;
  smokerNonSmoker?: TrackingSmoker;
  gender?: TrackingGender;
}

export type TrackingUserAddress = { [key in 'line1' | 'city' | 'country']?: string };
export type TrackingProfileInfo = {
  [key in 'email' | 'telephone' | 'telephoneE164' | 'firstName' | 'lastName']?: string;
};

export enum TrackingGender {
  MALE = 'Mann',
  FEMALE = 'Frau',
}

export enum TrackingSmoker {
  SMOKER = 'Raucher',
  NONSMOKER = 'Nichtraucher',
}

export interface TrackingPageLoadData {
  pageName?: string;
  conversionType?: TrackingConversionTypes;
}
export interface TrackingClickEventData {
  clickedElement: TrackingElement;
}

export enum TrackingElementDUWDisclosure {
  CHECKBOX_DUTY_OF_DISCLOSURE = 'Checkbox_Anzeigepflicht',
  LINK_INFORMATION_VIOLATION_OF_DUTY = 'Link_MitteilungVVG',
}
