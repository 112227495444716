import { Adresse, Geschlecht, Nationality, parseDate } from 'rlv-common';
import { PersonalPageData, SetStateHandlerArg } from '../pages/personalData/PersonalDataPage.types';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import { handleFormErrors } from '../tracking/trackingFormating';
import { VnInput } from '../types/external/personendatenerfassung/messaging/model/input/VnInput';

export const isValidPerson = (storeState: PersonalPageData): boolean =>
  !!storeState.adresse &&
  !!storeState.vorname &&
  !!storeState.nachname &&
  !!storeState.geburtsort &&
  !!storeState.staatsangehoerigkeit;

export const noActionSelected = () => undefined;

export const createVn = (
  userInput: PersonalPageData,
  duwGeschlecht: Geschlecht | undefined,
): VnInput => ({
  ...userInput,
  // TODO: workaround: remove tagDerAbbuchung after the backend calls the PDS to get initial data.
  tagDerAbbuchung: undefined,
  id: userInput.pdeId,
  geschlecht: duwGeschlecht ? duwGeschlecht : userInput.geschlecht,
  isGWGRequired: false,
  geburtsdatum: parseDate(userInput.birthdate),
  isIbanAlwaysVisible: true,
  showGeburtsort: true,
  showStaatsangehoerigkeit: true,
  hasInternationalIbanSupport: true,
  einverstaendnisEmailWerbung: false,
  isEMailOptional: false,
  showEmailConfirmationTooltip: false,
  showSepaBankInfo: false,
  isSexReadOnly: typeof duwGeschlecht !== 'undefined',
});

export const getAdresse = (adresse: Adresse | undefined) =>
  adresse
    ? `${adresse.strasse} ${adresse.hausnummer}, ${adresse.plz} ${adresse.ort}, Deutschland`
    : '';

export const getNationalityForKey = (
  nationalities: Nationality[] | undefined,
  staatsangehoerigkeit: string | undefined,
) => (nationalities ? nationalities.find(({ key }) => key === staatsangehoerigkeit) : undefined);

export const getNationalityText = (
  nationalityForKey: Nationality | undefined,
  staatsangehoerigkeit: string | undefined,
) => (nationalityForKey ? nationalityForKey.text : staatsangehoerigkeit);

export const actionAfterSaving = (
  action: NavigationAction,
  vpEditable: boolean,
  pdeValid: boolean,
  isEmailMandatoryValid: boolean | undefined,
  handleAction: HandleActionCallback,
  setStateHandler: (newState: SetStateHandlerArg) => void,
) => {
  if (
    getIsAllPdePageDataValid(vpEditable, pdeValid, isEmailMandatoryValid) ||
    NavigationAction.BACK === action
  ) {
    handleAction(action);
  } else {
    handleSavingErrors(setStateHandler);
  }
};

export const handleSavingErrors = (setStateHandler: (newState: SetStateHandlerArg) => void) => {
  setStateHandler({
    showGlobalErrorMessages: true,
    isLoading: false,
    actionAfterSaving: noActionSelected,
  });
};

export const getIsAllPdePageDataValid = (
  vpEditable: boolean,
  pdeValid: boolean,
  isEmailMandatoryValid: boolean | undefined,
): boolean => getErrorMessages(vpEditable, pdeValid, isEmailMandatoryValid).length === 0;

export const getErrorMessages = (
  vpEditable: boolean,
  pdeValid: boolean,
  isEmailMandatoryValid: boolean | undefined,
): string[] => {
  const errorMessages: string[] = [];
  if (!vpEditable && !pdeValid) {
    handleFormErrors('Die versicherte Person ist nicht valide');
    errorMessages.push('Die versicherte Person ist nicht valide');
  }
  if (!isEmailMandatoryValid && !pdeValid) {
    errorMessages.push('Bitte geben Sie ein gültige E-mail address ein.');
  }
  return errorMessages;
};
