import * as React from 'react';
import { DecreasingNeedModel } from 'rlv-common';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { NeedSubPageProps } from '../Needs.types';
import { yearsTermValidator } from '../../../validation/DecreasingNeedBlock';
import { SliderForm, SliderFormProps } from '../forms/SliderForm/SliderForm';
import {
  ComponentFieldNames,
  DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT,
  getPeriodInYears
} from '../NeedsPageContainer.data';

export const DecreasingPeriodInYearsPage = ({
  valueRanges,
  storeState,
  handleAction,
  tracker
}: NeedSubPageProps) => {
  const SETUP = {
    componentFieldName: ComponentFieldNames.PeriodInYears,
    sliderConfig: {
      min: valueRanges.insuranceDuration.min,
      max: valueRanges.insuranceDuration.max,
      step: valueRanges.insuranceDuration.increment,
      text: 'Jahre',
      trackerUpdate: (value: number) =>
        tracker.updateTariffOptions({
          VersLZ: value
        }),
      textInSingular: 'Jahr'
    },
    templateLayout: DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT.periodInYears,
    formValues: {
      periodInYears: getPeriodInYears(
        storeState.periodInYears,
        valueRanges.insuranceDuration.max
      )
    },
    validationSchema: yearsTermValidator(valueRanges),
    onHandleSliderFormActions: (
      { periodInYears }: { periodInYears: number },
      navigationAction: NavigationAction
    ) => {
      if (navigationAction === NavigationAction.NEXT) {
        storeState.update({ periodInYears });
      }

      handleAction(navigationAction);
    },
    handleNavigateNoSubmitAction: () => {
      handleAction(NavigationAction.BACK);
    }
  };

  // TODO: type aren't well defined to support some optional params not passed here, thus the unknown
  return <SliderForm<DecreasingNeedModel> {...SETUP as unknown as SliderFormProps<DecreasingNeedModel>} />;
};
