import * as React from 'react';
import { ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import LivingConditionsSwitcher, { LivingConditionsPageData } from '../../../pages/livingConditions/LivingConditionsPageContainer';
import { getAngebot, updateLivingConditionsPageData } from '../../../services/api';
import {
  getTrackingBirthdate,
  mapFetchedNeedData,
  mapFetchedPricingData,
  mapTariffOptionsSmokerValues,
  mapTrackingEmployment,
  mapTrackingInsuredPerson,
  mapUserAttributesSmokerValues
} from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createLivingConditionsPageSchema } from '../../../validation/LivingConditionsPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const livingConditionsHeightWeightState: StateDefinition<LivingConditionsPageData> = {
  name: StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT,
  percentage: PagePercentage.LIVING_CONDITIONS_HEIGHT_WEIGHT,
  trackingViewName: ViewName.BASISDATEN,
  createValidationSchema: (valueRanges: ValueRanges) => createLivingConditionsPageSchema(valueRanges),

  validInboundStates: [
    StateName.LIVING_CONDITIONS_SMOKER,
    StateName.LIVING_CONDITIONS_BIKER
  ],

  transitions: [
    {
      test: action => {
        const backAction =
            action === NavigationAction.BACK ||
            action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.LIVING_CONDITIONS_SMOKER
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.LIVING_CONDITIONS_BIKER
    }
  ],

  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
        ...getTrackingBirthdate(response.livingConditionsModel),
        ...mapTrackingEmployment(response.livingConditionsModel),
        ...mapUserAttributesSmokerValues(response.livingConditionsModel)
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
        ...mapFetchedPricingData(
          response.needSelectionModel,
          response.feeCalculationModel
        )
      },
      additionalTariffOptions: {
        ...mapTariffOptionsSmokerValues(response.livingConditionsModel)
      }
    };

    return {
      userInput: response.livingConditionsModel,
      valueRanges: response.valueRanges,
      fetchedTrackingData
    };

    return {};
  },

  onExit: async (_, inputData) => {
    const userInput = inputData.userInput;

    await updateLivingConditionsPageData(inputData.businessId, userInput);
    return {};
  },

  render: (
    inputData,
    handleAction,
    updateApp,
    _unused,
    updateDTMTracking
  ) => {
    const { userInput, valueRanges, businessId } = inputData;

    return (
      <LivingConditionsSwitcher
        storeState={{
          height: userInput.height,
          weight: userInput.weight,
          update: values => updateApp(values)
        }}
        businessId={businessId}
        valueRanges={valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  }
};
