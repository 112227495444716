import TooltipIcon from '@eg/elements/TooltipIcon';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Need } from 'rlv-common';
import { FieldNumberInput, FieldSelectRow } from '../../../components/FormComponents';
import { yearFormatter } from '../../../helpers/formattingHelper';
import { TrackingElementFeePage } from '../../../tracking/tracking.types';
import { constantSumInsuredValidation } from '../../../validation/ConstantNeedBlock';
import { decreasingSumInsuredValidation } from '../../../validation/DecreasingNeedBlock';
import { usePreviousAndTrackError } from '../../../hooks/usePreviousAndTrackError/usePreviousAndTrackError';
import { UpdateOfferValues, UpdatingOfferFromProps } from './UpdatingOfferForm.types';
import {
  paymentMethodOptions,
  UpdatingOfferInput,
  UpdatingOfferTooltips,
} from './UpdatingOfferFormData';

import './UpdatingOfferForm.css';

/**
 * The updating offer form renders four inputs:
 *
 * * paymentMethod
 * * sumInsured
 * * insuranceEndSum
 * * periodInYears
 *
 * The inputs will be renderd based on the Need selection param.
 *
 * return the filters selected by the user through the onSubmit callback.
 */
const UpdatingOfferForm = ({
  updateOfferValues,
  needSelection,
  valueRanges,
  onSubmit,
  setErrorCallback,
  trackClick,
}: UpdatingOfferFromProps) => {
  const [formError, setFormError] = React.useState<boolean>(false);
  const { compareErrors } = usePreviousAndTrackError({
    sumInsured: '',
    insuranceEndSum: '',
    periodInYears: '',
  });

  const inputData = UpdatingOfferInput[needSelection];

  return (
    <Formik
      initialValues={updateOfferValues}
      validationSchema={
        needSelection === Need.KONSTANT
          ? constantSumInsuredValidation(valueRanges)
          : decreasingSumInsuredValidation(valueRanges)
      }
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      isInitialValid={true}
      enableReinitialize
    >
      {(formikForm: FormikProps<UpdateOfferValues>) => {
        const hasError =
          needSelection === Need.KONSTANT
            ? !!formikForm.errors.sumInsured || !!formikForm.errors.periodInYears
            : !!formikForm.errors.sumInsured ||
              !!formikForm.errors.insuranceEndSum ||
              !!formikForm.errors.periodInYears;

        if (hasError !== formError) {
          setErrorCallback(hasError);
        }
        setFormError(hasError);

        const errorMessage = (message: string | undefined, field: string) => {
          compareErrors(field, message);
          return <p className="error-message">{message}</p>;
        };

        return (
          <Form>
            <div className="updating-offer-form__container first-child">
              <FieldSelectRow
                name={inputData?.paymentMethod.key}
                label={inputData?.paymentMethod.text}
                value={formikForm.values.paymentMethod}
                placeholder=""
                onChange={() => {
                  formikForm.submitForm();
                }}
                options={paymentMethodOptions}
              />
            </div>
            <div className="updating-offer-form__container">
              <FieldNumberInput
                className="updating-offer-form__input-field"
                name={inputData.sumInsured.key}
                label={inputData.sumInsured.text}
                tooltip={
                  <TooltipIcon
                    isModal
                    onToggledOpen={isOpen =>
                      isOpen && trackClick(TrackingElementFeePage.INFOICON_DEATH_BENEFIT)
                    }
                  >
                    {UpdatingOfferTooltips[needSelection].sumInsured}
                  </TooltipIcon>
                }
                adornmentRight="€"
                min={valueRanges.insuranceSum.min}
                max={valueRanges.insuranceSum.max}
                step={valueRanges.insuranceSum.increment}
                onBlur={() => formikForm.dirty && formikForm.submitForm()}
              />
            </div>
            {(formError || hasError) && errorMessage(formikForm.errors.sumInsured, 'sumInsured')}
            {needSelection === Need.DECREASING && (
              <>
                <div className="updating-offer-form__container">
                  <FieldNumberInput
                    className="updating-offer-form__input-field"
                    name={inputData.insuranceEndSum?.key || ''}
                    label={inputData.insuranceEndSum?.text}
                    adornmentRight="€"
                    min={valueRanges.insuranceEndSum.min}
                    max={valueRanges.insuranceEndSum.max}
                    step={valueRanges.insuranceEndSum.increment}
                    onBlur={() => formikForm.dirty && formikForm.submitForm()}
                  />
                </div>
                {(formError || hasError) &&
                  errorMessage(formikForm.errors.insuranceEndSum, 'insuranceEndSum')}
              </>
            )}
            <div className="updating-offer-form__container">
              <FieldNumberInput
                className="updating-offer-form__input-field"
                name={inputData.periodInYears.key}
                label={inputData.periodInYears.text}
                tooltip={
                  <TooltipIcon
                    isModal
                    onToggledOpen={isOpen =>
                      isOpen && trackClick(TrackingElementFeePage.INFOICON_DURATION)
                    }
                  >
                    {UpdatingOfferTooltips[needSelection].periodInYears}
                  </TooltipIcon>
                }
                adornmentRight={yearFormatter(formikForm.values.periodInYears)}
                min={valueRanges.insuranceDuration.min}
                max={valueRanges.insuranceDuration.max}
                step={valueRanges.insuranceDuration.increment}
                onBlur={() => formikForm.dirty && formikForm.submitForm()}
              />
            </div>
            {(formError || hasError) &&
              errorMessage(formikForm.errors.periodInYears, 'periodInYears')}
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdatingOfferForm;
