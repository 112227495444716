import * as React from 'react';
import { ProgressBarProps } from '../StatusRibbon.types';
import './ProgressBar.css';

const percentageRange = {
  min: 0,
  max: 100
};

const ProgressBar = ({ percentage }: ProgressBarProps): React.ReactElement => {
  if (percentage > percentageRange.max || percentage < percentageRange.min) {
    throw new Error('Percentage should be between 0 and 100');
  }

  return (
    <div data-testid="progress-bar" className="progress-bar">
      <div
        className="progress-bar__indicator"
        data-testid="progress-bar__indicator"
        style={{ width: `${percentage}%` }}>
      </div>
    </div>
  );
};

export default ProgressBar;
