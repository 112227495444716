import * as React from 'react';
import RadioTile from './RadioTile/RadioTile';

import './RadioGroup.css';
import { IRadiouGroup, RadioGroupOption } from './RadioGroup.types';
import { RadioTileValue } from './RadioTile/RadioTile.types';

const RadioGroup: IRadiouGroup = ({ options, name, selectedValue, disabled, maxRow = maxNumOfRowElements, onChangeSelection }) => {
  const [optionsHaveIcons, setOptionsHaveIcons] = React.useState<boolean>();
  const [hasMaxNumOfElementsInARow, setHasMaxNumOfElementsInARow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setOptionsHaveIcons(options.every(option => option.icon));
    setHasMaxNumOfElementsInARow(options.length > maxRow);
  }, [options]);

  const isChecked = (currentValue: RadioTileValue) => currentValue === selectedValue;
  const isColumn = !optionsHaveIcons && hasMaxNumOfElementsInARow;

  return (
    <div className={`radio-group ${isColumn ? 'radio-group--column' : ''}
        ${optionsHaveIcons ? 'radio-group--with-icons' : ''}`} data-testid="radio-group">
      {options.map(({ title, value, description, icon }: RadioGroupOption) =>
        <RadioTile key={`radio-tile-${title}`} title={title} subtitle={description} value={value} disabled={disabled}
          name={name} checked={isChecked(value)} icon={icon} inlineDisplay={!optionsHaveIcons} handleClick={onChangeSelection} />
      )}
    </div>
  );
};

const maxNumOfRowElements = 2;

export default RadioGroup;
