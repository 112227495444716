import Card from '@eg/elements/Card';
import * as React from 'react';
import { mapToGermanDate } from 'rlv-common';
import { numberWithDecimals } from '../../../helpers/numberFormatter';
import { basisVariant, paymentFrequency } from '../AfterSalesFeedback.data';
import { TariffInformationComponentProps } from '../AfterSalesFeedback.types';
import './TariffInformationComponent.css';

const TariffInformationComponent = ({
  variante,
  inceptionDate,
  priceNetto,
  paymentMethod,
  cardTitle,
  annotation
}: TariffInformationComponentProps): React.ReactElement => {
  const tariff = variante === 'basis' ? basisVariant : `${variante?.slice(0, 1).toUpperCase()}${variante?.slice(1)}`;
  const contribution = `${numberWithDecimals(priceNetto.toString())} €* ${paymentFrequency(paymentMethod)}`;
  const startDate = mapToGermanDate(inceptionDate);

  const cardContent = [
    { title: 'Ihr Tarif', value: tariff },
    { title: 'Versicherungsbeginn', value: startDate },
    { title: 'Beitrag', value: contribution }
  ];

  return (
    <div className="tariff-info__card">
      <h3 className="tariff-info__card-title">
        {cardTitle}
      </h3>
      <Card padding="normal" className="tariff-info__card-content">
        {cardContent.map(param => (
          <div className="tariff-info__card-content-param" key={`tariff-info__card-content-param-${param.title}`}>
            <b>{param.title}</b>
            <span>{param.value}</span>
          </div>
        ))}
      </Card>
      {annotation && <section className="tariff-info__paragraph2">
        <p className="tariff-info__paragraph2--asterisk" />
        <p className="tariff-info__paragraph2--literal">
          {annotation}
        </p>
      </section>}
    </div>
  );
};

export default TariffInformationComponent;
