import { differenceInYears } from 'date-fns';
import * as React from 'react';
import { parseDate } from 'rlv-common/types/IsoDateString';
import AppLoader from '../../../components/AppLoader';
import Footer from '../../../components/Footer';
import { Headline } from '../../../components/Headline/Headline';
import { getServiceConfiguration } from '../../../configuration/serviceConfiguration';
import { getDuwGenre } from '../../../helpers/genreHelper';
import { createScriptError } from '../../../helpers/loadingScriptErrorHelper';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { BasisDatenFieldState } from '../../../types/external/duw/BasisDatenConfiguration';
import DuwInput from '../../../types/external/duw/DuwInput';
import { DuwResult } from '../../../types/external/duw/DuwResult';
import {
  DUW_NEXT_CLICKED_EVENT,
  DUW_RENDER_EVENT,
  DUW_UNMOUNT_EVENT,
  DuwNumberingMode,
  DuwValues,
  DuwSituation,
  situationMap,
} from '../../../types/external/duw/DuwTypes';
import { DUW_TITLE, DuwQuestionnaireProps } from './DuwQuestionaire.types';

const DuwQuestionnaire = ({
  scriptLoaded,
  configuration,
  onError,
  onProgress,
  onNotifyId,
  onSubmit,
  onNavigateNoSubmit,
  duwToken,
  birthdate,
  smoker,
  sumInsured,
  height,
  weight,
  profession,
  situation,
}: DuwQuestionnaireProps): React.ReactElement => {
  const [tokenState, setTokenState] = React.useState<string | undefined>(duwToken);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    initDuw();
    return () => {
      finalizeDuw();
    };
  }, []);

  const dispatchDuwRenderEvent = () => {
    document.dispatchEvent(
      new CustomEvent(DUW_RENDER_EVENT, {
        detail: {
          theme: configuration.theme,
          token: tokenState,
          apiBaseUrl: getServiceConfiguration().duw.baseUrl,
          basisDatenConfiguration: {
            gewicht: BasisDatenFieldState.HIDDEN,
            groesse: BasisDatenFieldState.HIDDEN,
            geschlecht: BasisDatenFieldState.HIDDEN,
          },
          basisData: {
            groesse: height,
            gewicht: weight,
            geschlecht: getDuwGenre(),
          },
          numbering: {
            first: 1,
            mode: DuwNumberingMode.HIDE,
          },
          enableConsentCheckbox: false,
          formMode: {
            statischeDaten: {
              alter: differenceInYears(new Date(), parseDate(birthdate) as Date),
              berufsklasse: '1',
              anteilHandwerklicherArbeit: 60,
              raucher: smoker === DuwValues.MAGIC_OE_VALUE_SMOKER.valueOf(),
              versicherungssumme: sumInsured,
              occupation: profession,
              situation: situationMap.get(situation || '') || DuwSituation.Miscellaneous,
            },
            callbacks: {
              onError,
              onNotifyCaseId,
              onFinalize: (result: DuwResult) => onSubmit(result, NavigationAction.NEXT),
              onProgress,
              onRenderComplete,
              onCancel: () => onNavigateNoSubmit(NavigationAction.BACK),
            },
            tarif: DuwValues.MAGIC_DUW_VALUE_TARIF_RLV,
            mandant: DuwValues.MAGIC_DUW_VALUE_MANDANT_ERGO,
            isMultiPage: true,
          },
        },
      } as DuwInput),
    );
  };

  const initDuw = () => {
    scriptLoaded
      .then(() => {
        if (!configuration || !configuration.theme) {
          throw new Error('The DuwPage needs at least a theme! Check your configuration!');
        }
        dispatchDuwRenderEvent();
      })
      .catch((error: Event) => {
        setIsLoading(false);
        onError(createScriptError(error));
      });
  };

  const finalizeDuw = () => {
    document.dispatchEvent(new Event(DUW_UNMOUNT_EVENT));
  };

  const onNotifyCaseId = (token: string) => {
    setTokenState(token);
    onNotifyId(token);
  };

  const onRenderComplete = () => {
    // That is necesary to trigger initial action in the DUW
    setIsLoading(false);
    document.dispatchEvent(new Event(DUW_NEXT_CLICKED_EVENT));
  };

  return (
    <>
      <AppLoader show={isLoading} viewport="relative">
        <Headline text={DUW_TITLE} />
        <div id="root-duw"></div>
        <Footer showNext={false} showPrevious={false} handleAction={() => undefined} />
      </AppLoader>
    </>
  );
};

export default DuwQuestionnaire;
