import { Checkbox } from '@eg/elements/Checkbox';
import { Form, Formik } from 'formik';
import React from 'react';
import { getIsTiedAgent } from 'src/helpers/modeConfig';
import { aemAssets } from 'src/configuration/serviceConfiguration';
import { CarbonParamName } from 'src/types/AemCarbonEnv';
import AemFragment from '../../../components/AemFragment/AemFragment';
import Footer from '../../../components/Footer';
import { Headline } from '../../../components/Headline/Headline';
import Text from '../../../components/Text/Text';
import { NavigationAction } from '../../../routing/StateMachineTypes';
import { TrackingElementDUWDisclosure } from '../../../tracking/tracking.types';
import { handleFormErrors } from '../../../tracking/trackingFormating';
import { AemFragmentCatalog } from '../../../types/AemFragmentCatalog';
import { SharedSubmitCallBack } from '../../../types/callbacks/sharedSubmitCallBack';
import './DuwDisclosure.css';
export interface DuwDisclosureProps extends SharedSubmitCallBack<string> {
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: string, navigationRequest: NavigationAction) => void;
  trackClick: (clickEvent: TrackingElementDUWDisclosure) => void;
}

export const DuwDisclosure = ({
  onNavigateNoSubmit,
  trackClick,
}: DuwDisclosureProps): React.ReactElement => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.checked);
    setShowError(!event.target.checked);
    trackClick(TrackingElementDUWDisclosure.CHECKBOX_DUTY_OF_DISCLOSURE);
  };

  const [selectedOption, setSelectedOption] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const headline = 'Wichtige Zustimmung vor der Gesundheitsprüfung';
  const checkboxLabel =
    'Hiermit bestätige ich, dass ich die „Mitteilung nach § 19 Abs. 5 VVG über die Folgen einer Verletzung der vorvertraglichen Anzeigepflicht“ verstanden und gelesen habe.';
  const errorMessage =
    'Bitte bestätigen Sie, dass Sie die rechtlichen Hinweise gelesen und zur Kenntnis genommen haben.';

  const vvaLink =
    getIsTiedAgent() && aemAssets[CarbonParamName.VVA]?.length > 0
      ? aemAssets[CarbonParamName.VVA]
      : 'https://www.ergo.de/de/landingpages/vva'; // TODO: (Octavio) Review logic

  if (showError) {
    handleFormErrors(errorMessage);
  }
  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        if (!selectedOption) {
          setShowError(true);
        } else {
          setShowError(false);
          onNavigateNoSubmit(NavigationAction.NEXT);
        }
      }}
      validationSchema={undefined}
    >
      {form => (
        <Form className="container duw-disclosure">
          <Headline text={headline} />
          <div>
            <Text styles="duw-disclosure-intro">
              Bitte beachten Sie die Belehrung zur vorvertraglichen Anzeigepflicht (§19 Abs. 5 VVG).
            </Text>
            <Text>
              Um Ihren Versicherungsantrag ordnungsgemäß prüfen zu können, ist es notwendig, dass
              Sie die in Textform gestellten Fragen wahrheitsgemäß und vollständig beantworten.
            </Text>

            <Text>
              Eine Verletzung Ihrer vorvertraglichen Anzeigepflicht kann ERGO zur Anfechtung, zum
              Rücktritt, zur Kündigung oder zur Vertragsanpassung berechtigen.
            </Text>

            <Text>
              Unvollständige und unrichtige Angaben können - auch rückwirkend - zum vollständigen
              oder teilweisen Wegfall des Versicherungsschutzes führen.
            </Text>

            <Text>
              Bitte beachten Sie hierzu die{' '}
              <a
                onClick={() => {
                  trackClick(TrackingElementDUWDisclosure.LINK_INFORMATION_VIOLATION_OF_DUTY);
                }}
                href={vvaLink}
                target="_blank"
              >
                Mitteilung nach § 19 Abs. 5 VVG über die Folgen einer Verletzung der
                vorvertraglichen Anzeigepflicht
              </a>
            </Text>

            <Text styles="bold">
              Dies betrifft auch die wahrheitsgemäße Beantwortung der zuvor gestellten Fragen zu
              Ihrer Größe, zu Ihrem Gewicht und Ihrem Rauchverhalten (Raucher/Nichtraucher).
            </Text>
          </div>
          <div className="checkbox-container">
            <div className="checkbox-inline-wrapper">
              <Checkbox
                colorScheme="inverted"
                error={showError}
                label={checkboxLabel}
                checked={selectedOption}
                onChange={handleCheckboxChange}
                verticalAlign="top"
              />
            </div>
            {showError && <p className="checkbox-container__error-message">{errorMessage}</p>}
          </div>
          <AemFragment name={AemFragmentCatalog.STEP13VA} useVA={true} />
          <Footer
            showNext
            showPrevious
            labelNext="weiter"
            handleAction={(navigationAction: NavigationAction) => {
              if (navigationAction === NavigationAction.NEXT) {
                form.submitForm();
              } else {
                onNavigateNoSubmit(navigationAction);
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
