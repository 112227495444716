import FormRow from '@eg/elements/FormRow';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import {
  MAX_SIZE_ENTITELMENT_PERCENTAGE,
  MIN_SIZE_ENTITLEMENT_PERCENTAGE,
  Relationship,
} from 'rlv-common';
import {
  FieldInput,
  FieldInputRow,
  FieldSelectRow,
} from '../../../../../components/FormComponents';
import FieldDateInputRow from '../../../../../components/FormComponents/FieldDateInputRow/FieldDateInputRow'; // from './fields';
import { scrollToErrorByQuery } from '../../../../../components/ScrollToError';
import { isMobile } from '../../../../../helpers/mediaMatcher';
import { TrackingElementSubscriptions } from '../../../../../tracking/tracking.types';
import { createBeneficiarySchema } from '../../../../../validation/Subscriptions';
import { AddressFieldsContainer } from './AddressFieldsContainer';
import { FormLabels, FormPlaceholders, FormTexts } from './BeneficiaryDetailsForm.data';
import {
  AddressField,
  BeneficiaryDetailsProps,
  BeneficiaryFormOutput,
  PERCENTAGE_MAX_LENGTH,
} from './BeneficiaryDetailsForm.types';
import { AddressFieldsCopyLink, CustomButton, FieldRadioGroupRow } from './fields';

import './BeneficiaryDetailsForm.css';

export const BeneficiaryDetailsForm: React.FC<BeneficiaryDetailsProps> = ({
  addressFromInsuredPerson,
  beneficiary,
  entitledPersons,
  relationships,
  onSubmit,
  setShowForm,
  trackClickEvent,
}): React.ReactElement => {
  const propsCity = beneficiary?.person?.address?.city;
  const [cityMatcher, setCityMatcher] = useState<string>(propsCity);

  const handleOnClickAddressLink = (form: FormikProps<FormikValues>) => {
    if (addressFromInsuredPerson) {
      for (const key in addressFromInsuredPerson) {
        if (Object.prototype.hasOwnProperty.call(addressFromInsuredPerson, key)) {
          const value = addressFromInsuredPerson[key];
          form.setFieldValue(`person.address.${key}`, value);
          form.setFieldTouched(`person.address.${key}`, false);
          if (key === 'city') {
            setCityMatcher(value);
          }
        }
      }
    }
  };

  const setRelationshipFreeText = (form: FormikProps<FormikValues>, value: string) => {
    if (value !== Relationship.OTHER) {
      form.setFieldValue('relationshipFreeText', '');
    } else {
      form.setFieldValue('relationshipFreeText', '');
      form.setFieldTouched('relationshipFreeText', false);
    }
  };

  const mapPersonAddressToAddressField = (
    formikForm: FormikProps<BeneficiaryFormOutput>,
  ): AddressField => ({
    ...formikForm.values.person.address,
    streetNumber: formikForm.values.person.address.streetNumber,
    zip: +formikForm.values.person.address.zip,
  });

  const setCityError = (switcher: string) => {
    setCityMatcher(switcher);
  };

  return (
    <Formik
      initialValues={beneficiary}
      enableReinitialize
      onSubmit={values => onSubmit(values)}
      validationSchema={createBeneficiarySchema(!!cityMatcher)}
    >
      {(formikForm: FormikProps<BeneficiaryFormOutput>) => {
        const setCity = (city: string) => {
          formikForm.setFieldValue('person.address.city', city);
        };

        return (
          <Form data-component-id="edit-entitled-person-form" className="beneficiary-details__form">
            {entitledPersons && entitledPersons.length === 0 && (
              <hr className="beneficiary__separator" />
            )}
            <h3>{isMobile() ? FormTexts.mobileTitle : FormTexts.title}</h3>
            <FieldRadioGroupRow name="person.addresses" label={FormLabels.addresses} />
            <FormRow
              className="beneficiary-details__name-row"
              label={FormLabels.name ? FormLabels.name : ''}
              colspans={[1, 2]}
            >
              <FieldInput
                className="beneficiary-details__name-field"
                name="person.firstName"
                placeholder={FormPlaceholders.firstName}
                value={formikForm.values.person.firstName}
              />
              <FieldInput
                className="beneficiary-details__name-field"
                name="person.surname"
                placeholder={FormPlaceholders.surname}
                value={formikForm.values.person.surname}
              />
            </FormRow>
            <FieldDateInputRow
              name="person.birthdate"
              label={FormLabels.birthdate}
              formError={
                formikForm.touched.person?.birthdate && !!formikForm?.errors?.person?.birthdate
                  ? formikForm.errors.person.birthdate
                  : undefined
              }
              date={formikForm.values.person.birthdate}
            />
            <AddressFieldsCopyLink
              label={FormTexts.linkCopyAddress}
              onClick={() => {
                handleOnClickAddressLink(formikForm);
                trackClickEvent(TrackingElementSubscriptions.LNK_ANSCHRIFT_VP_UEBERNEHMEN);
              }}
            />
            <AddressFieldsContainer
              props={{ address: mapPersonAddressToAddressField(formikForm) }}
              trackClickEvent={clickedElement => {
                trackClickEvent(clickedElement);
              }}
              setCity={setCity}
              setCityError={setCityError}
            />
            <FieldSelectRow
              className="beneficiary-details__relationship-row"
              name="relationship"
              label={FormLabels.relationship}
              placeholder={FormPlaceholders.relationship || ''}
              options={relationships}
              onChange={setRelationshipFreeText}
              value={formikForm.values.relationship}
            />
            {formikForm.values.relationship === Relationship.OTHER && (
              <FieldInputRow
                name="relationshipFreeText"
                label={FormLabels.relationshipFreeText}
                value={formikForm.values.relationshipFreeText}
              />
            )}
            <FieldInputRow
              className="beneficiary-details__percentage-row"
              name="entitlementPercentage"
              label={FormLabels.entitlementPercentage}
              placeholder={FormPlaceholders.entitlementPercentage}
              type={'number'}
              adornmentRight="%"
              max={MAX_SIZE_ENTITELMENT_PERCENTAGE}
              min={MIN_SIZE_ENTITLEMENT_PERCENTAGE}
              maxLength={PERCENTAGE_MAX_LENGTH}
              value={formikForm.values.entitlementPercentage}
            />
            <CustomButton
              label={FormTexts.savePerson}
              variant="primary"
              loading={false}
              onClick={() => {
                formikForm.submitForm();
                scrollToErrorByQuery('[role="alert"]');
                trackClickEvent(TrackingElementSubscriptions.BTN_PERSON_SPEICHERN);
              }}
            />
            {entitledPersons && entitledPersons.length > 0 && (
              <CustomButton
                label={FormTexts.cancel}
                variant="text-link"
                loading={false}
                onClick={() => {
                  setShowForm(false);
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default BeneficiaryDetailsForm;
