import * as React from 'react';
import { Geschlecht, LivingConditionsModel, mapToGermanDate } from 'rlv-common';
import { scrollToTop } from '../../Helper';
import { Storage } from '../../helpers/Storage';
import { NavigationAction, StateName } from '../../routing/StateMachineTypes';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import {
  TrackingBoolean,
  TrackingClickEventData,
  TrackingElementGender,
  TrackingElementInsuranceStart,
  TrackingElementLivingCondition,
  TrackingElementMotorbike,
  TrackingGender,
  TrackingSmoker,
} from '../../tracking/tracking.types';
import { parseSmokerValue } from '../../tracking/trackingFormating';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { PageContainer } from '../../types/PageContainer';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { RlvProfession } from '../../types/RlvProfession';
import { SessionStorageKeys } from '../../types/SessionStorage';
import BirthdateForm from './forms/birthdateForm/birthdateForm';
import InsuranceStartDateForm from './forms/insuranceStartDateForm/insuranceStartDateForm';
import BikerForm from './forms/motorbikerForm/motorBikerForm';
import ProfessionForm, { ProfessionFormModel } from './forms/professionForm/professionForm';
import PropertyOwnerForm from './forms/propertyOwnerForm/propertyOwnerForm';
import SmokerForm from './forms/smokerForm/SmokerForm';

import { PhysicalAspectFormValues } from './forms/physicalAspectForm/PhysicalAspect.types';
import PhysicalAspectForm from './forms/physicalAspectForm/PhysicalAspectForm';
import { SmokerServerValues } from './forms/smokerForm/SmokerForm.types';

export interface LivingConditionsPageData
  extends LivingConditionsModel,
    StoreStateUpdater<LivingConditionsPageData> {
  geschlecht?: Geschlecht;
  birthdate?: string;
}

export type SetValueFunction = (field: string, value: string) => void;

interface LivingConditionsPageProps
  extends PagePropsWithValues<LivingConditionsPageData>,
    InjectedTrackerProps {
  businessId: string;
  getProfessions?: (term: string) => Promise<RlvProfession[]>;
}

interface LivingConditionsPageState {
  suggestions: RlvProfession[];
}

export class LivingConditionsPageContainer
  extends React.Component<LivingConditionsPageProps, LivingConditionsPageState>
  implements PageContainer
{
  public constructor(props: LivingConditionsPageProps) {
    super(props);
    this.state = {
      suggestions: [],
    };
  }

  public componentDidMount() {
    scrollToTop();
    // TODO, change this with new flow stages tracking names when available
    this.props.tracker.trackPageLoad({});
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.BASISDATEN });
  }

  public render() {
    const livingConditionsContent = () => {
      switch (Storage.readItem('currentState')) {
        case StateName.LIVING_CONDITIONS_BIRTHDATE:
          if (this.props.storeState.birthdate) {
            this.props.tracker.updateUserAttributes({
              birthday: this.props.storeState.birthdate.split('-')[0],
            });
          }
          return (
            <BirthdateForm
              disabled={this.disabled}
              birthdate={this.props.storeState.birthdate}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={async (birthdateSelection: string, navigationRequest: NavigationAction) => {
                await this.submitChild('birthdate', birthdateSelection, navigationRequest);
              }}
              valueRanges={this.props.valueRanges}
              updateTrackingBirthdate={date => {
                this.props.tracker.updateUserAttributes({
                  birthday: date,
                });
              }}
            />
          );

        case StateName.LIVING_CONDITIONS_EMPLOYMENT:
          if (this.props.storeState.employment && this.props.storeState.professionLabel) {
            this.props.tracker.updateUserAttributes({
              occupationalGroup: this.props.storeState.employment,
              jobTitle: this.props.storeState.professionLabel,
            });
          }
          return (
            <ProfessionForm
              employment={this.props.storeState.employment}
              professionLabel={this.props.storeState.professionLabel}
              professionKey={this.props.storeState.professionKey}
              getProfessions={this.props.getProfessions}
              valueRanges={this.props.valueRanges}
              updateTrackingValues={attributes => {
                this.props.tracker.updateUserAttributes(attributes);
              }}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) => {
                this.navigate(navigationRequest);
              }}
              onSubmit={(
                {
                  employmentSelection: employment,
                  professionSelection: professionLabel,
                  assignedProfessionKey: professionKey,
                }: ProfessionFormModel,
                navigationRequest: NavigationAction,
              ) => {
                if (navigationRequest === NavigationAction.BACK) {
                  return;
                }

                this.props.storeState.update({
                  employment,
                  professionLabel,
                  professionKey,
                });
                this.navigate(navigationRequest);
              }}
            />
          );
        case StateName.LIVING_CONDITIONS_SMOKER:
          if (this.props.storeState.smoker) {
            this.props.tracker.updateAdditionalTariffOptions({
              RauchS: parseSmokerValue(this.props.storeState.smoker as SmokerServerValues),
            });
            this.props.tracker.updateUserAttributes({
              smokerNonSmoker:
                this.props.storeState.smoker === SmokerServerValues.SMOKER
                  ? TrackingSmoker.SMOKER
                  : TrackingSmoker.NONSMOKER,
            });
          }
          return (
            <SmokerForm
              smoker={this.props.storeState.smoker}
              onSubmit={(smoker: string, navigationAction: NavigationAction) => {
                if (navigationAction === NavigationAction.NEXT) {
                  this.props.storeState.update({ smoker });
                }
                this.props.handleAction(navigationAction);
              }}
              onNavigateNoSubmit={(navigationAction: NavigationAction) => {
                this.props.handleAction(navigationAction);
              }}
              trackClick={(
                trackClickData: TrackingClickEventData,
                smoker: SmokerServerValues,
                isSmoker: boolean,
              ) => {
                this.props.tracker.updateAdditionalTariffOptions({
                  RauchS: parseSmokerValue(smoker),
                });
                this.props.tracker.updateUserAttributes({
                  smokerNonSmoker: isSmoker ? TrackingSmoker.SMOKER : TrackingSmoker.NONSMOKER,
                });
                this.props.tracker.trackClickEvent(trackClickData);
              }}
            />
          );
        case StateName.LIVING_CONDITIONS_HEIGHT_WEIGHT:
          const genderId = Storage.readItem(SessionStorageKeys.GENRE);
          if (genderId === '1') {
            this.props.tracker.updateUserAttributes({ gender: TrackingGender.FEMALE });
          } else if (genderId === '2') {
            this.props.tracker.updateUserAttributes({ gender: TrackingGender.MALE });
          }
          if (this.props.storeState.height) {
            this.props.tracker.updateAdditionalTariffOptions({
              KGroesse: String(this.props.storeState.height),
            });
          }
          if (this.props.storeState.weight) {
            this.props.tracker.updateAdditionalTariffOptions({
              KGewicht: String(this.props.storeState.weight),
            });
          }
          return (
            <PhysicalAspectForm
              disabled={this.disabled}
              height={this.props.storeState.height}
              weight={this.props.storeState.weight}
              onSubmit={(
                { height, weight }: PhysicalAspectFormValues,
                navigationAction: NavigationAction,
              ) => {
                if (navigationAction === NavigationAction.NEXT) {
                  this.props.storeState.update({ height, weight });
                }
                this.props.handleAction(navigationAction);
              }}
              onNavigateNoSubmit={(navigationAction: NavigationAction) => {
                this.props.handleAction(navigationAction);
              }}
              trackClick={gender => {
                if (gender === TrackingGender.FEMALE) {
                  this.props.tracker.updateUserAttributes({ gender });
                  this.props.tracker.trackClickEvent({
                    clickedElement: TrackingElementGender.RADIOBUTTION_FEMALE,
                  });
                } else if (gender === 'Mann') {
                  this.props.tracker.updateUserAttributes({ gender });
                  this.props.tracker.trackClickEvent({
                    clickedElement: TrackingElementGender.RADIOBUTTOM_MALE,
                  });
                }
              }}
              updateTrackingValues={({ height, weight }) => {
                if (height) {
                  this.props.tracker.updateAdditionalTariffOptions({
                    KGroesse: height,
                  });
                }
                if (weight) {
                  this.props.tracker.updateAdditionalTariffOptions({
                    KGewicht: weight,
                  });
                }
              }}
            />
          );
        case StateName.LIVING_CONDITIONS_BIKER:
          if (this.props.storeState.biker) {
            this.props.tracker.updateAdditionalTariffOptions({
              MotorradF: this.props.storeState.biker ? TrackingBoolean.YES : TrackingBoolean.NO,
            });
          }
          return (
            <BikerForm
              biker={this.props.storeState.biker}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={async (isBiker: boolean, navigationRequest: NavigationAction) =>
                this.submitChild('biker', isBiker, navigationRequest)
              }
              trackClick={biker => {
                this.props.tracker.updateAdditionalTariffOptions({
                  MotorradF: biker ? TrackingBoolean.YES : TrackingBoolean.NO,
                });
                this.props.tracker.trackClickEvent({
                  clickedElement: biker
                    ? TrackingElementMotorbike.RADIOBUTTON_BIKER
                    : TrackingElementMotorbike.RADIOBUTTON_NOT_BIKER,
                });
              }}
            />
          );
        case StateName.LIVING_CONDITIONS_PROPERTY_OWNER:
          if (this.props.storeState.propertyOwner) {
            this.props.tracker.updateAdditionalTariffOptions({
              SWohneig: this.props.storeState.propertyOwner
                ? TrackingBoolean.YES
                : TrackingBoolean.NO,
            });
          }
          return (
            <PropertyOwnerForm
              propertyOwner={this.props.storeState.propertyOwner}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={async (
                propertyOwnerSelection: boolean,
                navigationRequest: NavigationAction,
              ) => this.submitChild('propertyOwner', propertyOwnerSelection, navigationRequest)}
              trackClick={homeowner => {
                this.props.tracker.updateAdditionalTariffOptions({
                  SWohneig: homeowner ? TrackingBoolean.YES : TrackingBoolean.NO,
                });
                this.props.tracker.trackClickEvent({
                  clickedElement: homeowner
                    ? TrackingElementLivingCondition.RADIOBUTTON_HOMEOWNER
                    : TrackingElementLivingCondition.RADIOBUTTON_NOT_HOMEOWNER,
                });
              }}
            />
          );
        case StateName.LIVING_CONDITIONS_INCEPTION_DATE:
          if (this.props.storeState.inceptionDate) {
            this.props.tracker.updateInsuranceStartDate(
              mapToGermanDate(this.props.storeState.inceptionDate) || '',
            );
          }
          return (
            <InsuranceStartDateForm
              insuranceStartDate={this.props.storeState.inceptionDate}
              onNavigateNoSubmit={(navigationRequest: NavigationAction) =>
                this.navigate(navigationRequest)
              }
              onSubmit={async (
                insuranceStartDateSelection: string,
                navigationRequest: NavigationAction,
              ) =>
                this.submitChild('inceptionDate', insuranceStartDateSelection, navigationRequest)
              }
              valueRanges={this.props.valueRanges}
              trackClick={(date, position) => {
                this.props.tracker.updateInsuranceStartDate(date);
                let trackingElement;
                switch (position) {
                  case 0:
                    trackingElement = TrackingElementInsuranceStart.RADIOBUTTON_DATE_TOP;
                    break;
                  case 1:
                    trackingElement = TrackingElementInsuranceStart.RADIOBUTTON_DATE_MIDDLE;
                    break;
                  case 2:
                    trackingElement = TrackingElementInsuranceStart.RADIOBUTTON_DATE_BOTTOM;
                    break;
                  default:
                    break;
                }
                if (trackingElement) {
                  this.props.tracker.trackClickEvent({
                    clickedElement: trackingElement,
                  });
                }
              }}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    };

    return (
      <div data-component-id={Storage.readItem('currentState')}>{livingConditionsContent()}</div>
    );
  }

  private async updateStateMachine(field: string, value: string | boolean | number) {
    this.props.storeState.update({
      [field]: value,
    });
  }

  public readonly submitChild = async (
    field: string,
    value: string | boolean | number,
    navigationRequest: NavigationAction,
  ) => {
    await this.updateStateMachine(field, value).then(() => this.navigate(navigationRequest));
  };

  public navigate(navigationRequest: NavigationAction) {
    this.props.handleAction(navigationRequest);
  }

  private readonly disabled = !!Storage.readItem(SessionStorageKeys.DUW_STATE);
}

export default withTracker(LivingConditionsPageContainer);
