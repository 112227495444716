import { Address, Addresses, EntitledPerson, Person, TextValue, ValueList } from 'rlv-common';
import { TrackingElement } from '../../../../../tracking/tracking.types';

export const NEW_BENEFICIARY_REGISTRATION_KEY = 'NEW-ENTITLED-PERSON';
export const PERCENTAGE_MAX_LENGTH = 3;
export const ADDRESS_ZIP_MAX_LENGTH = 5;

export interface BeneficiaryDetailsProps {
  setShowForm: (_: boolean) => void;
  beneficiary: BeneficiaryFormOutput;
  entitledPersons: EntitledPerson[];
  addressFromInsuredPerson?: Address;
  relationships: ValueList<TextValue>;
  onSubmit: (beneficiary: BeneficiaryFormOutput) => void;
  trackClickEvent: (clickedElement: TrackingElement) => void;
}

export interface BeneficiaryDetailsFormTexts {
  title?: string;
  mobileTitle?: string;
  linkCopyAddress: string;
  errorMessage: string;
  savePerson: string;
  cancel: string;
}

export interface BeneficiaryDetails {
  addresses: string;
  name: string;
  firstName: string;
  surname: string;
  birthdate: string;
  address: string;
  street: string;
  streetNumber: string;
  zip: string;
  wrongZip: string;
  city: string;
  country: string;
  relationship: string;
  relationshipFreeText: string;
  entitlementPercentage: string;
  percentageMinMax: string;
}

export interface BeneficiaryFormOutput {
  entitlementPercentage: string;
  person: Person;
  relationship: string;
  relationshipFreeText?: string;
}

export interface AddressField {
  street: string;
  streetNumber: string;
  zip: number;
  city: string;
  country: string;
}

export interface AddressFieldsContainerProps {
  props: {
    address: AddressField;
  };
  trackClickEvent: (clickedElement: TrackingElement) => void;
  setCity: (city: string) => void;
  setCityError: (swticher: string) => void;
}

/* Mocks */

export const MOCK_BENEFICIARY: EntitledPerson = {
  person: {
    pdeId: undefined,
    addresses: Addresses.MR,
    firstName: '',
    surname: '',
    birthdate: '01-01-1991',
    address: {
      street: '',
      streetNumber: '',
      zip: '',
      city: '',
      country: 'Deutschland',
    },
  },
  entitlementPercentage: 0,
  relationship: '',
  relationshipFreeText: '',
};

export const mockRelationships: ValueList<TextValue> = {
  defaultValue: undefined,
  possibleValues: [
    { key: 'SONSTIGES_VERHAELTNIS', text: 'Sonstiges Verhältnis' },
    { key: 'EHEPARTNER_LEBENSPARTNER', text: 'Ehepartner / Lebenspartner' },
  ],
};
