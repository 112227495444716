export enum SubscriptionsPageContainerTexts {
  TITLE = 'Möchten Sie sofort das widerrufliche Bezugsrecht vergeben?',
  VA_TEXT_NEIN = '<b>Tipp:</b> Bezugsberechtigte bekommen im Todesfall die Versicherungssumme. Und zwar ohne Testament und ohne Erbschein – also deutlich schneller.',
  VA_TEXT_JA = 'Sehr gut. Sie können jetzt bis zu 3 Bezugsberechtigte angeben. Achtung: Vergeben Sie bei mehr als einer Person die Anteile so, dass die Summe 100 % ergibt.',
  VA_TEXT_JA_INCOMPLETE_PERCENTAGE = 'Sie haben nicht genug Anteile vergeben. Verteilen Sie die Anteile so, dass ihre Summe 100 % ergibt.',
  VA_TEXT_JA_FULL_PERCENTAGE = 'Perfekt. Sie haben genau 100 % der Anteile vergeben.',
  INFO_SECTION_BEFORE_LINK = 'Informieren Sie sich über das',
  INFO_SECTION_LINK_TEXT = 'Bezugsrecht und weitere wichtige Details.',
  NEXT_BUTTON = 'weiter',
  BACK_BUTTON = 'zurück'
}

export const LinkToLawPortal = 'https://www.ergo.de/de/rechtsportal/testament-und-erbrecht/todesfall-des-erblassers/lebensversicherung-teil-der-erbschaft';
