import { addMethod, number as yupNumber, NumberSchema, Ref } from 'yup';
import { EURO_WITHOUT_CENT, formatCurrency } from '../helpers/formattingHelper';

export interface CustomMixedSchema extends NumberSchema {
  isSmallerThanDarlehnssumme(maxSum: number): this;

  isSmallerThan(referenceValue: Ref, message: string, discrepancy?: number): this;

  isBiggerThan(referenceValue: Ref, message: string, discrepancy?: number): this;

  isNotNullWhenPeriodBiggerThanloanPeriod(
    period: Ref,
    repaymentFreePeriod: Ref,
    loanPeriod: Ref,
  ): this;

  validateNumber(): this;
}

// When the field gets emptied yup struggles to validate it properly because the
// empty value translates to NaN, which can not be validated as a number.
// See: https://github.com/jquense/yup/issues/66
addMethod(yupNumber, 'validateNumber', function () {
  return this.transform(value => (isNaN(value) ? 0 : value));
});

addMethod(
  yupNumber,
  'isSmallerThan',
  function (referenceValue: Ref, message: string, discrepancy?: number) {
    return this.test({
      name: 'isSmallerThan',
      exclusive: true,
      message,
      test(value: number) {
        let refValue = (this as any).resolve(referenceValue);

        if (discrepancy) {
          refValue = Number(refValue) - discrepancy;
        }
        return value <= refValue;
      },
    });
  },
);

addMethod(
  yupNumber,
  'isBiggerThan',
  function (referenceValue: Ref, message: string, discrepancy?: number) {
    return this.test({
      name: 'isBiggerThan',
      exclusive: true,
      message,
      test(value: number) {
        let refValue = (this as any).resolve(referenceValue);

        if (discrepancy) {
          refValue = Number(refValue) - discrepancy;
        }
        return value >= refValue;
      },
    });
  },
);

addMethod(yupNumber, 'isSmallerThanDarlehnssumme', function (maxSum: number) {
  return this.test({
    name: 'isSmallerThanDarlehnssumme',
    exclusive: true,
    message: `Die Grundsumme liegt zwischen 0,00 € und ${formatCurrency(EURO_WITHOUT_CENT).format(
      maxSum,
    )}. `,
    test(value: number) {
      return value > 1 ? value <= maxSum : true;
    },
  });
});

addMethod(
  yupNumber,
  'isNotNullWhenPeriodBiggerThanloanPeriod',
  function (period: Ref, repaymentFreePeriod: Ref, loanPeriod: Ref) {
    return this.test({
      name: 'isNotNullWhenPeriodBiggerThanloanPeriod',
      exclusive: true,
      message:
        'Ist die Gesamtlaufzeit länger als die Darlehenslaufzeit muss eine konstante Grundsumme gesetzt werden.',
      test(value: number) {
        const periodVal = (this as any).resolve(period);
        const loanPeriodVal = (this as any).resolve(loanPeriod);
        const repaymentFreePeriodVal = (this as any).resolve(repaymentFreePeriod);

        if (Number(periodVal) > Number(loanPeriodVal) + Number(repaymentFreePeriodVal)) {
          return value !== 0;
        }
        return true;
      },
    });
  },
);
