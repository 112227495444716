import { Button } from '@eg/elements/Button';
import { Modal } from '@eg/elements/Modal';
import { CheckColorIcon } from '@eg/elements/components/Icons/CheckColorIcon';
import { TooltipIcon } from '@eg/elements/components/TooltipIcon';
import * as React from 'react';
import { Need } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Price from '../../../../components/Price/Price';
import { PriceSize } from '../../../../components/Price/Price.types';
import { aemAssets } from '../../../../configuration/serviceConfiguration';
import { PaymentMethodSentenceText, PaymentMethodText } from '../../../../helpers/PaymentMethod';
import { yearFormatter } from '../../../../helpers/formattingHelper';
import { addThousandsSeparator } from '../../../../helpers/numberFormatter';
import {
  getBruttoBeitragForVariant,
  getNettoBeitragForVariant,
} from '../../../../helpers/variant.utils';
import { TrackingElementFeePage } from '../../../../tracking/tracking.types';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { RadioTabContentBenefitProps } from '../RadioTab.types';

const RadioTabBenefit = (props: RadioTabContentBenefitProps) => {
  const checkIconHeight = 24;
  const checkIconWidth = 24;
  const [showFragmentCatalogModal, setShowFragmentCatalogModal] = React.useState(false);
  const [showMoreInfoModal, setShowMoreInfoModal] = React.useState(false);
  const period = props.periodInYears && yearFormatter(props.periodInYears);

  const variante = props.varianten?.filter(variant => variant.variante === props.variant)[0];
  const nettobeitrag = getNettoBeitragForVariant(variante);
  const bruttobeitrag = getBruttoBeitragForVariant(variante);

  return (
    <div className="tab-content-body">
      <div className="tab-content-title">{props.title}</div>
      <div className="text-center tab-content-price-head">
        Ihr aktueller {props.paymentMethod && PaymentMethodSentenceText[props.paymentMethod]}{' '}
        Beitrag:
      </div>
      <div className="tab-content-price">
        <Price size={PriceSize.Small} amount={props.price} unstyled={true} />
      </div>
      <Button
        variant="text-link"
        className="tab-content-moreInfo-btn"
        onClick={() => {
          setShowMoreInfoModal(true);
          props.trackClick(TrackingElementFeePage.BUTTON_MORE_INFO);
        }}
      >
        Mehr Infos zum Beitrag
      </Button>
      <div className="tab-benefit-list">
        {props.insuranceTerm === Need.KONSTANT ? (
          <div className="tab-benefit-fixed-list">
            <li key="sumInsured">
              <div>
                <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
                <span>
                  Todesfallsumme:{' '}
                  {props.sumInsured && addThousandsSeparator(props.sumInsured.toString())} €
                </span>
              </div>
            </li>
            <li key="periodInYears">
              <div>
                <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
                <span>
                  Vertragslaufzeit: {props.periodInYears} {period}{' '}
                </span>
              </div>
            </li>
          </div>
        ) : (
          <div className="tab-benefit-fixed-list">
            <li key="sumInsured">
              <div>
                <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
                <span>
                  Todesfallsumme im 1. Jahr:{' '}
                  {props.sumInsured && addThousandsSeparator(props.sumInsured.toString())} €
                </span>
              </div>
            </li>
            <li key="periodInYears">
              <div>
                <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
                <span>
                  Vertragslaufzeit: {props.periodInYears} {period}
                </span>
              </div>
            </li>
            <li key="insuranceEndSum">
              <div>
                <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
                <span>
                  Todesfallsumme im letzten Jahr:{' '}
                  {props.insuranceEndSum && addThousandsSeparator(props.insuranceEndSum.toString())}{' '}
                  €
                </span>
              </div>
            </li>
          </div>
        )}

        {props.benefitsData.map(benefit => (
          <li key={benefit.title}>
            <div>
              <CheckColorIcon height={checkIconHeight} width={checkIconWidth} />
              <span className="tab-benefit-text">{benefit.title}</span>
              <TooltipIcon
                isModal
                className="tab-benefit-list-tooltip-icon"
                onToggledOpen={isOpen => {
                  if (isOpen && benefit.trackingElement) {
                    props.trackClick(benefit.trackingElement);
                  }
                }}
              >
                {benefit.tooltip}
              </TooltipIcon>
            </div>
          </li>
        ))}
      </div>

      {aemAssets && aemAssets[AemFragmentCatalog.PRODUCT_TABLE] && (
        <>
          <Button
            variant="secondary"
            className="overview-button"
            onClick={() => {
              setShowFragmentCatalogModal(true);
              props.trackClick(TrackingElementFeePage.BUTTON_ALL_BENEFITS);
            }}
          >
            Alle Leistungen im Vergleich
          </Button>
          <Modal
            open={showFragmentCatalogModal}
            dismissible
            onDismiss={() => setShowFragmentCatalogModal(false)}
            backdropDismissesModal
            extendedWidth
          >
            <AemFragment name={AemFragmentCatalog.PRODUCT_TABLE} />
          </Modal>
        </>
      )}
      {showMoreInfoModal && (
        <Modal
          open={showMoreInfoModal}
          dismissible
          onDismiss={() => setShowMoreInfoModal(false)}
          backdropDismissesModal
          extendedWidth
        >
          <section className="esc_generated-content">
            <h4>Informationen zu Ihrem Beitrag</h4>
            <h5>Ihr aktueller Beitrag ist garantiert für das erste Versicherungsjahr.</h5>
            <p>
              {'Der aktuelle Beitrag von '}
              <Price amount={String(nettobeitrag)} size={PriceSize.Small} unstyled />
              <span> {props.paymentMethod && PaymentMethodText[props.paymentMethod]}</span>
              {
                ' ist für das erste Versicherungsjahr garantiert (sog. Nettobeitrag), kann aber während der Vertragslaufzeit bis auf den maximalen Beitrag von '
              }
              <Price amount={String(bruttobeitrag)} size={PriceSize.Small} unstyled />
              <span> {props.paymentMethod && PaymentMethodText[props.paymentMethod]}</span>
              {' ansteigen (sog. Bruttobeitrag).'}
            </p>
            <br />
            <h5>Warum kann der Beitrag steigen?</h5>
            <p>
              ERGO beteiligt Sie mit einem Sofort-Rabatt (sog. Beitragsvorwegabzug) an Überschüssen
              aus der Kalkulation. Diese Überschüsse werden jährlich neu ermittelt. Der Beitrag
              gilt, solange die Überschüsse unverändert bleiben.
            </p>
            <p>
              Auf den folgenden Seiten wird deshalb jeweils Ihr <b>aktueller Beitrag</b> angegeben
              und nicht der maximale Beitrag.
            </p>
            <p>
              <b>Tipp:</b> Mit dem optionalen Baustein <b>„Sicherheit Plus“</b> bleibt Ihr Beitrag
              garantiert gleich!
            </p>
          </section>
        </Modal>
      )}
    </div>
  );
};

export default RadioTabBenefit;
