export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV || 'production';

export const CONFIG_BACKEND_BASE_URL =
  window.CONFIG_BACKEND_BASE_URL || process.env.REACT_APP_BACKEND_BASE_URL || '';

export const CONFIG_BACKEND_API_PREFIX =
  window.CONFIG_BACKEND_API_PREFIX || process.env.REACT_APP_BACKEND_API_PREFIX || '';

export const CONFIG_FRONTEND_BASE_URL =
  window.CONFIG_FRONTEND_BASE_URL || process.env.REACT_APP_FRONTEND_BASE_URL || '';

export const CONFIG_DUW_BASE_URL =
  window.CONFIG_DUW_BASE_URL || process.env.REACT_APP_DUW_BASE_URL || '';

export const CONFIG_DUW_ENTRYPOINT_FILE =
  window.CONFIG_DUW_ENTRYPOINT_FILE || process.env.REACT_APP_DUW_ENTRYPOINT_FILE || '';

export const CONFIG_PERSONENDATENERFASSUNG_BASE_URL =
  window.CONFIG_PERSONENDATENERFASSUNG_BASE_URL ||
  process.env.REACT_APP_PERSONENDATENERFASSUNG_BASE_URL ||
  '';

export const CONFIG_PERSONENDATENERFASSUNG_ENTRYPOINT_FILE =
  window.CONFIG_PERSONENDATENERFASSUNG_ENTRYPOINT_FILE ||
  process.env.REACT_APP_PERSONENDATENERFASSUNG_ENTRYPOINT_FILE ||
  '';
