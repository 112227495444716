import { RadioGroupOption } from '../../../../components/RadioGroup/RadioGroup.types';

export enum ConsentSelection {
  YES = 'Ja',
  NO = 'Nein',
}

export enum MoneyLaunderingText {
  title = 'Bitte geben Sie an, ob Sie auf eigene Veranlassung handeln',
  errorMessage = 'Wenn Sie auf Veranlassung einer anderen Person oder als Stellvertreter abschließen möchten, können Sie die Versicherung nicht online beantragen.', // tslint:disable-next-line:max-line-length
  link = 'ERGO Berater finden',
  cardTitle = 'Versicherung leider nicht online abschließbar',
  cardContent = 'Wenn Sie auf Veranlassung einer anderen Person oder als Stellvertreter abschließen möchten, wenden Sie sich bitte an Ihren ERGO Berater: ',
}

export const RADIO_GROUP_OPTIONS: RadioGroupOption[] = [
  {
    title: ConsentSelection.YES,
    value: ConsentSelection.YES,
  },
  {
    title: ConsentSelection.NO,
    value: ConsentSelection.NO,
  },
];
