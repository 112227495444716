import { ValueRanges } from 'rlv-common/types';
import { number as yupNumber, object, ObjectSchema, Schema } from 'yup';
import { getCommonValidationParameters } from './CommonValidations';

export function constantSumInsuredValidation(valueRanges: ValueRanges): Schema<any> {
  return createConstantNeedBlockSchema(valueRanges)
    .concat(constantSumInsuredValidator(valueRanges))
    .concat(constantYearsTermValidator(valueRanges));
}

export function createConstantNeedBlockSchema(valueRanges: ValueRanges): Schema<any> {
  return getCommonValidationParameters(valueRanges);
}

export const constantSumInsuredValidator = (valueRanges: ValueRanges): ObjectSchema => {
  const {
    insuranceSum: { increment, max, min },
  } = valueRanges;

  const US_INCREMENT = 5000;

  const OUT_OF_RANGE_MSG = `Die Todesfallleistung zu Beginn muss zwischen ${min.toLocaleString(
    'de-DE',
  )} und ${max.toLocaleString('de-DE')} Euro (in ${
    increment ? increment.toLocaleString('de-DE') : ''
  }er Schritten) liegen.`;

  return object().shape({
    sumInsured: yupNumber()
      .required('Darf nicht leer sein')
      .min(min, OUT_OF_RANGE_MSG)
      .max(max, OUT_OF_RANGE_MSG)
      .test('Multiple', 'Muss ein Vielfaches von 5.000 sein', value => {
        if (increment) {
          return value % US_INCREMENT === 0;
        }
        return true;
      }),
  });
};

export const constantYearsTermValidator = (valueRanges: ValueRanges) => {
  const {
    insuranceDuration: { max, min },
  } = valueRanges;

  const OUT_OF_RANGE_MSG = `Die Laufzeit beträgt mindestens ${min}, maximal ${max} Jahre. Bitte korrigieren Sie die Angabe.`;

  return object().shape({
    periodInYears: yupNumber()
      .required('Darf nicht leer sein.')
      .min(min, OUT_OF_RANGE_MSG)
      .max(max, OUT_OF_RANGE_MSG),
  });
};
