export enum Key {
  BACKSPACE = 8,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  DELETE = 46,
  E = 69,
  NUMPAD_PLUS = 107,
  NUMPAD_MINUS = 109,
  NUMPAD_COMMA = 110,
  ALPHA_PLUS = 187,
  COMMA = 188,
  ALPHA_MINUS = 189,
  PERIOD = 190,
}
