import { IsoDateString } from './IsoDateString';
import {
  AnnuityNeedModel,
  CheckoutModel,
  ConstantNeedModel,
  DecreasingNeedModel,
  DuwDisplayModel,
  FeeCalculationModel,
  FeedbackModel,
  LivingConditionsModel,
  NeedPageModel,
  NeedSelectionModel,
  PersonalDataModel,
  RequestOfferPersonalDataModel,
} from './UserInput';
import { ValueRanges } from './ValueRanges';
import { Variante } from './Variante';

export enum ResponseCodes {
  SUCCESS = 200,
  SUCESS_RESOURCE_CREATED = 201,
  BAD_REQUEST = 400,
  PRECONDITION_FAILED = 412,
  VALIDATION_FAILURE = 422,
  ERROR = 500,
}

export interface CreateResponse {
  businessId: string;
  valueRanges: ValueRanges;
  debugLogs?: boolean;
}

export interface OrderResponse {
  status: number;
  showMeldung?: boolean;
}

export interface AgencyResponse {
  adsId: string;
  telephone?: AgencyTelephone;
}
export interface AgencyTelephone {
  id: string;
  laenderkennzahl: string;
  vorwahl: string;
  rufnummer: string;
  typ: string;
}

export interface AngebotResponse {
  businessId: string;
  erstelldatum: string;
  paymentMethod?: string;
  varianten: Variante[];
  insurancePeriodAdjusted?: boolean;
  valueRanges?: ValueRanges;
  insuranceEndDate?: IsoDateString;
  // TODO needs to be broken down by endpoint
  needPageModel?: NeedPageModel;
  needSelectionModel?: NeedSelectionModel;
  constantNeedModel?: ConstantNeedModel;
  decreasingNeedModel?: DecreasingNeedModel;
  annuityNeedModel?: AnnuityNeedModel;
  livingConditionsModel?: LivingConditionsModel;
  feeCalculationModel?: FeeCalculationModel;
  personalDataModel?: PersonalDataModel;
  duwDisplayModel?: DuwDisplayModel;
  checkoutModel?: CheckoutModel;
  feedbackModel?: FeedbackModel;
  requestOfferPersonalDataModel?: RequestOfferPersonalDataModel;
  divergingInsuredPerson?: boolean;
  messages?: Message[];
}

export interface Message {
  level: string;
  code: string;
  message: string;
  fields: string[];
}

export interface IddPdfDownloadResponse {
  href: string;
}
