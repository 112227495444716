import { EntitledPerson, InsuranceSetting, PatientConfidentialityRelease } from './ApiRequest';
import { IsoDateString } from './IsoDateString';
import { Nationality } from './Nationality';
import { Need } from './ValueRanges';
import { Beitragsvariante } from './Variante';

export interface Adresse {
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
}

export enum Geschlecht {
  WEIBLICH = 1,
  MAENNLICH = 2,
}

export enum Honorific {
  FEMALE_ADULT = 'Frau',
  MALE_ADULT = 'Herr',
}

export interface NeedPageModel {
  need?: Need;
  sumInsured?: number;
  periodInYears?: number;
  insuranceEndSum?: number;

  paymentPeriodInYears?: NeedValuePerVariant[];
  loanSum?: number;
  loanPeriodInYears?: number;
  loanRate?: number;
  basisSum?: number;
  repaymentFreePeriodInYears?: number;
}

export interface NeedSelectionModel {
  need?: Need;
}

export interface ConstantNeedModel extends NeedSelectionModel {
  sumInsured?: number;
  periodInYears?: number;
}

export interface DecreasingNeedModel extends NeedSelectionModel {
  sumInsured?: number;
  periodInYears?: number;
  insuranceEndSum?: number;
  paymentPeriodInYears?: NeedValuePerVariant[];
}

export interface AnnuityNeedModel extends NeedSelectionModel {
  periodInYears?: number;
  loanSum?: number;
  loanPeriodInYears?: number;
  loanRate?: number;
  basisSum?: number;
  repaymentFreePeriodInYears?: number;
  paymentPeriodInYears?: NeedValuePerVariant[];
}

export interface NeedValuePerVariant {
  variant: string;
  value?: number;
}

export interface RequestOfferDivergingInsuredPersonModel
  extends NeedSelectionModel,
    RequestOfferPersonalDataModel {
  divergingInsuredPerson: boolean;
  birthdateVn: IsoDateString | undefined;
  birthdateVp: IsoDateString | undefined;
}

export interface LivingConditionsModel extends NeedSelectionModel {
  birthdate?: IsoDateString;
  employment?: string;
  professionKey?: number;
  professionLabel?: string;
  smoker?: string;
  height?: number;
  weight?: number;
  biker?: boolean;
  propertyOwner?: boolean;
  inceptionDate?: IsoDateString;
}

export interface FeeCalculationModel
  extends NeedSelectionModel,
    ConstantNeedModel,
    DecreasingNeedModel,
    AnnuityNeedModel {
  paymentMethod: string;
  variante: Beitragsvariante;
  smoker?: string;
  additionalInsuranceSettings: InsuranceSetting[];
  insurancePeriodAdjusted?: boolean;
  startedDUW?: boolean;
  additionalTimeWaiting: boolean;
}

export interface PersonalDataModel {
  role: string;
  // TODO should be put into a person model (list, because there can be more than one)
  pdeId?: string;
  geschlecht?: Geschlecht;
  duwGeschlecht?: Geschlecht;
  vorname?: string;
  nachname?: string;
  adresse?: Adresse;
  staatsangehoerigkeit?: string;
  staatsangehoerigkeitText?: string;
  geburtsort?: string;
  birthdate?: IsoDateString;
  email?: string;
  vorwahl?: string;
  rufnummer?: string;
  // end person model
  iban?: string;
  bic?: string;
  tagDerAbbuchung?: number;
  // TODO -> Wertebereiche?
  abbuchungsTage?: number[];
  entitledPersons: EntitledPerson[];
  nationalities?: Nationality[];
}

export interface RequestOfferPersonalDataModel {
  vn: RequestOfferPerson;
  vp: RequestOfferPerson;
}

export interface RequestOfferPerson {
  nachname: string;
  role: string;
  vorname: string;
  adresse?: Adresse;
  birthdate?: IsoDateString;
  email?: string;
  geburtsort?: string;
  geschlecht?: Geschlecht;
  nationalities?: Nationality[];
  pdeId?: string;
  rufnummer?: string;
  staatsangehoerigkeit?: string;
  vorwahl?: string;
}

export interface DuwDisplayModel {
  birthdate?: IsoDateString;
  duwGeschlecht?: Geschlecht;
  vorname?: string;
  nachname?: string;
  weight?: number;
  height?: number;
  smoker?: string;
  sumInsured?: number;
  duwToken?: string;
  situation?: string;
  profession?: string;
}

export interface CheckoutModel
  extends NeedSelectionModel,
    DecreasingNeedModel,
    ConstantNeedModel,
    AnnuityNeedModel,
    LivingConditionsModel,
    FeeCalculationModel,
    PersonalDataModel,
    Partial<RequestOfferPersonalDataModel> {
  patientConfidentialityRelease?: PatientConfidentialityRelease;
  patientConfidentialityReleaseAfterDeath?: boolean;
  duwToken?: string;
  loanProtection?: boolean;
  insuranceEndDate?: string;
  extraExamination?: string;
}

export interface FeedbackModel {
  geschlecht?: Geschlecht;
  vorname: string;
  nachname: string;
  additionalTimeWaiting: boolean;
  vnPdsId: string;
  businessId: string;
}
