import { ValueRanges } from 'rlv-common/types';
import { number as yupNumber, object } from 'yup';
import { EURO_WITHOUT_CENT, formatCurrency } from '../helpers/formattingHelper';
import { CustomMixedSchema } from './validation';
require('./validation.ts'); // required for CustomMixedSchema to work

export const getCommonValidationParameters = (valueRanges: ValueRanges) => {
  const STEP_RANGE_SUM_INSURED = 1000;
  const sum = valueRanges.insuranceSum;
  const duration = valueRanges.insuranceDuration;
  const currencyFormatter = formatCurrency(EURO_WITHOUT_CENT);

  const sumInsuredMinMaxError = `Die Versicherungssumme liegt online bei ${currencyFormatter.format(
    sum.min,
  )} bis ${currencyFormatter.format(sum.max)}.
  Bitte korrigieren Sie die Angabe.`;
  const periodInYearsMinMaxError = `Die Laufzeit beträgt mindestens ${duration.min}, maximal ${duration.max} Jahre.
  Bitte korrigieren Sie die Angabe.`;

  return object().shape({
    // Used on NeedPage and FeePage
    sumInsured: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie die Versicherungssumme an.')
      .min(sum.min, sumInsuredMinMaxError)
      .max(sum.max, sumInsuredMinMaxError)
      .test(
        'isFullThousandEuro',
        'Es können nur ganze Tausend Eurobeträge abgesichert werden.',
        value => value % STEP_RANGE_SUM_INSURED === 0,
      ),
    periodInYears: (yupNumber() as CustomMixedSchema)
      .validateNumber()
      .required('Bitte geben Sie die gewünschte Laufzeit an.')
      .min(duration.min, periodInYearsMinMaxError)
      .max(duration.max, periodInYearsMinMaxError),
  });
};
