import * as React from 'react';
import Checkbox from '@eg/elements/Checkbox';

import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import FormTemplate from '../../../../components/FormComponents/FormTemplate/FormTemplate';
import InfoText from '../../../../components/InfoText/infoText';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioGroupOption } from '../../../../components/RadioGroup/RadioGroup.types';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { TemplateLayout } from '../../../../types/FormTemplate.types';
import { createLivingConditionsSmokerSchema } from '../../../../validation/LivingConditionsPage';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { getServiceConfiguration } from '../../../../configuration/serviceConfiguration';
import { TrackingClickEventData, TrackingElementSmoker } from '../../../../tracking/tracking.types';
import NoCigarIcon from '../../../../images/NoCigarretteIcon.svg';
import CigarIcon from '../../../../images/CigarretteIcon.svg';
import './SmokerForm.css';
import { SmokerServerValues } from './SmokerForm.types';

export const smokerFormTemplateLayout: TemplateLayout<object> = {
  title: 'Haben Sie in den letzten 12 Monaten geraucht?',
  footerInfoSection: () => (
    <InfoText textBeforeLink="Sie möchten jemand anders absichern? Dann geben Sie bitte an, ob diese Person Raucher oder Nichtraucher ist." />
  ),
};

const RADIO_GROUP_OPTIONS: RadioGroupOption[] = [
  {
    title: 'Ja',
    value: true,
    icon: (
      <img
        className="smoker-form__icon"
        src={`${getServiceConfiguration().iconsPath}${CigarIcon}`}
        alt="cigarrette"
      />
    ),
  },
  {
    title: 'Nein',
    value: false,
    icon: (
      <img
        className="smoker-form__icon"
        src={`${getServiceConfiguration().iconsPath}${NoCigarIcon}`}
        alt="no cigarrette"
      />
    ),
  },
];

interface SmokerFormValues {
  isSmoker: boolean | undefined;
  moreThanTenYears: boolean;
}

interface SmokerProps extends SharedSubmitCallBack<string> {
  smoker?: string;
  onSubmit: (value: string, navAction: NavigationAction) => void;
  onNavigateNoSubmit?: (navigationRequest: NavigationAction) => void;
  trackClick: (
    trackingData: TrackingClickEventData,
    smoker: SmokerServerValues,
    isSmoker: boolean,
  ) => void;
}

const SmokerForm = ({
  smoker,
  onSubmit,
  onNavigateNoSubmit,
  trackClick,
}: SmokerProps): React.ReactElement => {
  const [hideCheckBox, setHideCheckbox] = React.useState<boolean>(true);
  const [moreThanTen, setMoreThanTen] = React.useState<boolean>(
    smoker === SmokerServerValues.NON_SMOKER_MORE_THAN_10,
  );
  const [virtualAssistantFragment, setVirtualAssistantFragment] = React.useState<
    AemFragmentCatalog | undefined
  >(AemFragmentCatalog.STEP7VA);

  const setInitialFormValues = React.useMemo((): SmokerFormValues => {
    if (!smoker) {
      return { isSmoker: undefined, moreThanTenYears: false };
    }
    if (smoker === SmokerServerValues.SMOKER) {
      setVirtualAssistantFragment(AemFragmentCatalog.STEP7VA1);
      return { isSmoker: true, moreThanTenYears: false };
    }
    // Set VirtualAssistantText for no smoker scenario
    setVirtualAssistantFragment(AemFragmentCatalog.STEP7VA2);
    setHideCheckbox(false);
    return {
      isSmoker: false,
      moreThanTenYears: smoker === SmokerServerValues.NON_SMOKER_MORE_THAN_10,
    };
  }, [smoker]);

  const templateLayout = {
    ...smokerFormTemplateLayout,
    virtualAssistantFragment,
  };

  return (
    <div className="container__smoker">
      <FormTemplate<SmokerFormValues>
        templateLayout={templateLayout}
        formValues={setInitialFormValues}
        validationSchema={createLivingConditionsSmokerSchema()}
        handleOnSubmitAction={(value: SmokerFormValues, navAction) => {
          if (value.isSmoker) {
            onSubmit(SmokerServerValues.SMOKER, navAction);
            return;
          }
          onSubmit(
            value.moreThanTenYears
              ? SmokerServerValues.NON_SMOKER_MORE_THAN_10
              : SmokerServerValues.NON_SMOKER_AT_LEAST_A_YEAR,
            navAction,
          );
          return;
        }}
        handleNavigateNoSubmitAction={onNavigateNoSubmit}
        render={[
          {
            name: 'isSmoker',
            component: (form, field) => (
              <RadioGroup
                options={RADIO_GROUP_OPTIONS}
                name="isSmoker"
                selectedValue={field.value}
                onChangeSelection={(_, isSmoker) => {
                  setHideCheckbox(isSmoker as boolean);

                  setVirtualAssistantFragment(
                    isSmoker ? AemFragmentCatalog.STEP7VA1 : AemFragmentCatalog.STEP7VA2,
                  );

                  const nonSmokerValue = moreThanTen
                    ? SmokerServerValues.NON_SMOKER_MORE_THAN_10
                    : SmokerServerValues.NON_SMOKER_AT_LEAST_A_YEAR;
                  const smokerValue = isSmoker ? SmokerServerValues.SMOKER : nonSmokerValue;

                  trackClick(
                    {
                      clickedElement: isSmoker
                        ? TrackingElementSmoker.RADIOBUTTON_SMOKER
                        : TrackingElementSmoker.RADIOBUTTON_NONSMOKER,
                    },
                    smokerValue,
                    isSmoker as boolean,
                  );
                  form.setFieldValue(field.name, isSmoker);
                }}
              />
            ),
          },
          {
            name: 'moreThanTenYears',
            shouldBeHidden: hideCheckBox,
            component: (form, field) => (
              <div className="smoker-form__checkbox">
                <Checkbox
                  verticalAlign="top"
                  checked={field.value}
                  onChange={({ target: { checked } }) => {
                    form.setFieldValue(field.name, checked);
                    setMoreThanTen(checked);
                  }}
                  label={<span>Nichtraucher seit mindestens 10 Jahren?</span>}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SmokerForm;
