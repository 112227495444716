import { Size } from '@eg/elements/Price';

export enum PriceSize {
  Large = Size.Large,
  Medium = Size.Medium,
  Small = Size.Small,
  Auto = Size.Large,
}

export interface PriceProps {
  /**
   * Quantity to show.
   */
  amount: string;
  /**
   * [optional] Suffix of the price. Payment frequency.
   */
  paymentMethod?: string;
  /**
   * [optional] Size of the label.
   */
  size?: PriceSize;
  /**
   * [optional] Removing style from price component.
   */
  unstyled?: boolean;
  /**
   * [optional] Invert the style color
   */
  checked?: boolean;
  /**
   * [optional] Hide the faction digits of the number
   */
  hideFractionDigits?: boolean;
}
