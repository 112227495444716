import * as React from 'react';
import { Beitragsvariante } from 'rlv-common';
import {
  getNettoBeitragForVariant,
  getVarianteFromPossibleValues,
} from '../../../helpers/variant.utils';
import { FeePageVariante } from '../FeePage.types';
import './RadioTab.css';
import { RadioTabProps } from './RadioTab.types';
import { RadioTabButtonData } from './RadioTabData';
import RadioTabButton from './components/RadioTabButton';
import RadioTabContent from './components/RadioTabContent';

const RadioTab = (props: RadioTabProps) => {
  const [variant, setVariant] = React.useState<Beitragsvariante>(props.variant);

  const changeVariant = (selection: Beitragsvariante) => {
    props.variantCallback(selection);
    setVariant(selection);
  };

  const getPriceFromVarianten = (varianten: FeePageVariante[], dataVariant: string): string => {
    const variante = varianten?.filter(elem => elem.variante === dataVariant)[0];
    const nettoBeitrag = getNettoBeitragForVariant(variante) ?? 0;

    return String(nettoBeitrag);
  };

  return (
    <div className="tab-container" data-testid="radio-tab">
      <div className="tab-list">
        {RadioTabButtonData.filter(data => data.unavailableForSmoker !== props.smoker).map(data => (
          <RadioTabButton
            {...data}
            title={getVarianteFromPossibleValues(
              data.variant,
              props.tariffTypeValues!.possibleValues,
            )}
            setVariant={changeVariant}
            price={getPriceFromVarianten(props.varianten || [], data.variant)}
            checked={data.variant === variant}
            key={data.variant}
            paymentMethod={props.paymentMethod}
          />
        ))}
      </div>
      <div className="tab-panel">
        <RadioTabContent {...props} variant={variant} />
      </div>
    </div>
  );
};

export default RadioTab;
