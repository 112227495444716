import { ConstantSumInsuredPage } from './ConstantSumInsuredPage';
import { ConstantPeriodInYearsPage } from './ConstantPeriodInYearsPage';
import { DecreasingSumInsuredPage } from './DecreasingSumInsuredPage';
import { DecreasingPeriodInYearsPage } from './DecreasingPeriodInYearsPage';
import { DecreasingEndSumInsuredPage } from './DecreasingEndSumInsuredPage';

export const SUB_PAGES = {
  ConstantSumInsuredPage,
  ConstantPeriodInYearsPage,
  DecreasingSumInsuredPage,
  DecreasingPeriodInYearsPage,
  DecreasingEndSumInsuredPage
};
