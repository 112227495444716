import FormRow from '@eg/elements/FormRow';
import React, { useState, useEffect } from 'react';
import { FieldInputRow, FieldSelectRow } from '../../../../../components/FormComponents';
import { TrackingElementSubscriptions } from '../../../../../tracking/tracking.types';
import { getCitiesByZipCode } from '../../../../../services/api';
import { FormLabels, FormPlaceholders, FormTooltips } from './BeneficiaryDetailsForm.data';
import {
  ADDRESS_ZIP_MAX_LENGTH,
  AddressFieldsContainerProps,
} from './BeneficiaryDetailsForm.types';
import { FieldInputWithTooltip } from './fields';

import './AddressFieldsContainer.css';

export const AddressFieldsContainer: React.FC<AddressFieldsContainerProps> = ({
  props,
  trackClickEvent,
  setCity,
  setCityError,
}) => {
  const { street, streetNumber, zip, city } = props.address;
  const [zipCodeCities, setZipCodeCities] = useState<string[]>([city]);

  const handleSetCity = async (zipCode: number) => {
    const cities = await getCitiesByZipCode(zipCode);
    setCityError(!!cities.length ? cities[0] : '');
    setZipCodeCities(cities);
  };

  useEffect(() => {
    if (zipCodeCities.length === 0) {
      setCity('');
      setCityError('');
    } else {
      setCity(zipCodeCities[0]);
    }
  }, [zipCodeCities]);

  return (
    <div className="beneficiary-details__address">
      <FormRow
        className="beneficiary-details__address-row"
        label={FormLabels.address ? FormLabels.address : ''}
        colspans={[3, 1]}
      >
        <FieldInputRow
          className="beneficiary-details__address-field"
          name="person.address.street"
          label={FormLabels.street}
          placeholder={FormPlaceholders.street}
          value={street}
        />
        <FieldInputRow
          className="beneficiary-details__address-field"
          name="person.address.streetNumber"
          label={FormLabels.streetNumber}
          placeholder={FormPlaceholders.streetNumber}
          value={streetNumber}
        />
      </FormRow>
      <div id="location">
        <FormRow
          className="beneficiary-details__address-row"
          label=""
          colspans={{ regular: [1, 2] }}
        >
          <FieldInputRow
            className="beneficiary-details__address-field"
            name="person.address.zip"
            type={'number'}
            label={FormLabels.zip}
            placeholder={FormPlaceholders.zip}
            maxLength={ADDRESS_ZIP_MAX_LENGTH}
            value={zip === 0 ? undefined : zip}
            onBlur={() => handleSetCity(zip)}
            min={1}
          />
          {zipCodeCities.length < 2 ? (
            <FieldInputRow
              className="beneficiary-details__address-readonly"
              name="person.address.city"
              label={FormLabels.city}
              placeholder={FormPlaceholders.city}
              readOnly
              value={city}
            />
          ) : (
            <FieldSelectRow
              className="beneficiary-details__address-readonly"
              name="person.address.city"
              label={FormLabels.city}
              value={city}
              options={{
                defaultValue: { key: '', text: '' },
                possibleValues: zipCodeCities.map(cityKey => ({ key: cityKey, text: cityKey })),
              }}
            />
          )}
        </FormRow>
      </div>
      <FieldInputWithTooltip
        name="person.address.country"
        label={FormLabels.country}
        tooltip={FormTooltips.country}
        readOnly
        trackClickEvent={() => trackClickEvent(TrackingElementSubscriptions.INFO_ICON_LAND)}
      />
    </div>
  );
};
