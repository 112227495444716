import ControlWithHint from '@eg/elements/ControlWithHint';
import SelectRow from '@eg/elements/SelectRow';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import { TextValue, ValueList } from 'rlv-common';
import { getErrorMessage } from '../../../Helper';
export interface FieldSelectRowProps {
  name: string;
  label: string | undefined;
  options: ValueList<TextValue>;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  onChange?: (form: FormikProps<FormikValues>, value: string) => void;
  onBlur?: () => void;
}

const FieldSelectRow = (props: FieldSelectRowProps) => (
  <Field
    name={props.name}
    render={({
      field,
      form
    }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
      const { setFieldValue, setFieldTouched } = form;
      return (
        <div className={props.className || ''}>
          <SelectRow
            id={field.name}
            name={field.name}
            label={props.label || ''}
            onBlur={() => {
              setFieldTouched(field.name);
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(field.name, event.target.value);
              if (props.onChange) {
                props.onChange(form, event.target.value);
              }
            }}
            disabled={props.disabled}
            defaultValue={field.value}
          >
            {props.placeholder && <option value="">{props.placeholder}</option>}
            {props.options.possibleValues.map(opt => (
              <option key={opt.key} value={opt.key}>
                {opt.text}
              </option>
            ))}
          </SelectRow>
          <ControlWithHint error={getErrorMessage(form, field)}>
            <></>
          </ControlWithHint>
        </div>
      );
    }}
  />
);

export default FieldSelectRow;
