import { CopyLinkIcon } from '@eg/elements/components/Icons';
import * as React from 'react';

import './AddressFieldsCopyLink.css';

export interface AddressFieldsCopyLinkProps {
  label: string;
  onClick: () => void;
}

const IMAGE_HEIGHT_WIDTH: number = 24;

export const AddressFieldsCopyLink: React.FC<AddressFieldsCopyLinkProps> = ({ label, onClick }) => (
  <div className="copy-address">
    <a className="copy-address__link" onClick={onClick}>
      <CopyLinkIcon
        width={IMAGE_HEIGHT_WIDTH}
        height={IMAGE_HEIGHT_WIDTH}
        title={label}
        className="copy-address__link-icon"
      />
      <span className="copy-address__link-label">{label}</span>
    </a>
  </div>
);
