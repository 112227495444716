class Debouncer {
  private timeout: undefined | ReturnType<typeof setTimeout> = undefined;

  public debounce(callback: () => void, timeout: number = 500) {
    if (this.timeout !== undefined) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.timeout = setTimeout(async () => {
      callback();
    }, timeout);
  }
}

export default Debouncer;
