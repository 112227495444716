import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { InjectedTrackerProps } from '../../../../tracking/withTracker';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';

export interface PropertyOwnerFormProps
  extends SharedSubmitCallBack<boolean>,
    InjectedTrackerProps {
  propertyOwner?: boolean;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: boolean, navigationRequest: NavigationAction) => void;
  trackClick: (homeowner: boolean) => void;
}

export enum PropertyOwnerSelection {
  YES = 'Ja',
  NO = 'Nein',
}

export enum PropertyOwnerText {
  title = 'Wohnen Sie in einer Eigentumswohnung bzw. im eigenen Haus?',
  footer = 'Sie möchten jemand anders absichern? Dann geben Sie bitte an, ob diese Person selbst genutztes Wohneigentum hat.',
}
