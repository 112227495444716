import Input from '@eg/elements/Input';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

import './FieldInputWithTooltip.css';

export interface FieldInputWithTooltipProps {
  label: string | undefined;
  name: string;
  readOnly: boolean;
  tooltip: string | undefined;
  trackClickEvent: () => void;
}

export const FieldInputWithTooltip: React.FC<FieldInputWithTooltipProps> = ({
  label,
  name,
  readOnly,
  tooltip,
  trackClickEvent,
}) => (
  <Field
    name={name}
    render={({ field }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => (
      <section className="beneficiary-details__country">
        <div className="beneficiary-details__country--labelWithIcon">
          <span className="beneficiary-details__country--labelWithIcon-label">{label}</span>
          <TooltipIcon onToggledOpen={trackClickEvent}>{tooltip}</TooltipIcon>
        </div>
        <Input
          className="beneficiary-details__country--value"
          value={field.value}
          readOnly={readOnly}
        />
      </section>
    )}
  />
);
