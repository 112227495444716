import * as React from 'react';
import { NumericRange } from 'rlv-common';
import { shouldShowPublicView } from '../../Helper';
import { getAgentPhone, getIsTiedAgent } from '../../helpers/modeConfig';
import InfoText from '../../components/InfoText/infoText';
import Price from '../../components/Price/Price';
import { AemFragmentCatalog } from '../../types/AemFragmentCatalog';
import { TemplateLayout } from '../../types/FormTemplate.types';
import { ConstantNeedBlockData, DecreasingNeedData } from './Needs.types';

export enum ComponentFieldNames {
  SumInsured = 'sumInsured',
  PeriodInYears = 'periodInYears',
  InsuranceEndSum = 'insuranceEndSum',
}

export enum SliderFormValues {
  DefaultPeriodInYears = 15,
  DefaultInsuranceEndSum = 5000,
  DefaultInsuranceSumInterval = 5000,
}

export const getPeriodInYears = (
  storedDuration: number | undefined,
  maxDuration: number,
): number => {
  if (storedDuration) {
    return storedDuration;
  }
  if (maxDuration < SliderFormValues.DefaultPeriodInYears) {
    return maxDuration;
  }

  return SliderFormValues.DefaultPeriodInYears;
};

export const DECREASING_SLIDER_FORM_TEMPLATES_LAYOUT: {
  [K in keyof Required<
    Pick<DecreasingNeedData, 'sumInsured' | 'periodInYears' | 'insuranceEndSum'>
  >]: TemplateLayout<NumericRange>;
} = {
  sumInsured: {
    title: 'Legen Sie Ihre Versicherungssumme für das erste Jahr fest',
    subline: '',
    footerInfoSection: sumInsured => (
      <InfoText
        textBeforeLink={
          <span>
            Sie möchten mehr als{' '}
            {<Price amount={String(sumInsured?.max)} unstyled hideFractionDigits />} absichern? Dann
            wenden Sie sich an Ihren
          </span>
        }
        linkUrl={
          shouldShowPublicView(getIsTiedAgent())
            ? ''
            : 'https://www.ergo.de/de/Service/Vermittlersuche'
        }
        linkText={shouldShowPublicView(getIsTiedAgent()) ? 'ERGO Berater:' : 'ERGO Berater'}
        textAfterLink={getAgentPhone() || ''}
      />
    ),
    virtualAssistantFragment: AemFragmentCatalog.STEP4VA1ALT,
  },
  periodInYears: {
    title: 'Für wie lange möchten Sie Ihr Darlehen oder Ihren Kredit absichern?',
    subline: '',
    footerInfoSection: () => (
      <InfoText textBeforeLink="Sie haben Fragen oder möchten sich beraten lassen? Dann rufen Sie einfach an." />
    ),
    virtualAssistantFragment: AemFragmentCatalog.STEP5VA1ALT,
  },

  insuranceEndSum: {
    title: 'Legen Sie Ihre Versicherungssumme für das letzte Jahr fest',
    subline: '',
    footerInfoSection: () => (
      <InfoText textBeforeLink="Sie haben Fragen oder möchten sich beraten lassen? Dann rufen Sie einfach an." />
    ),
    virtualAssistantFragment: AemFragmentCatalog.STEP6VAALT,
  },
};

export const CONSTANT_SLIDER_FORM_TEMPLATES_LAYOUT: {
  [K in keyof Required<
    Pick<ConstantNeedBlockData, 'sumInsured' | 'periodInYears'>
  >]: TemplateLayout<NumericRange>;
} = {
  sumInsured: {
    title: 'Legen Sie Ihre Versicherungssumme fest',
    subline: '',
    virtualAssistantFragment: AemFragmentCatalog.STEP4VA1,
    footerInfoSection: sumInsured => (
      <InfoText
        textBeforeLink={
          <span>
            Sie möchten mehr als{' '}
            {<Price amount={String(sumInsured?.max)} unstyled hideFractionDigits />} absichern? Dann
            wenden Sie sich an Ihren
          </span>
        }
        linkUrl={
          shouldShowPublicView(getIsTiedAgent())
            ? ''
            : 'https://www.ergo.de/de/Service/Vermittlersuche'
        }
        linkText={shouldShowPublicView(getIsTiedAgent()) ? 'ERGO Berater:' : 'ERGO Berater'}
        textAfterLink={getAgentPhone() || ''}
      />
    ),
  },
  periodInYears: {
    title: 'Für wie lange möchten Sie Ihre Hinterbliebenen absichern?',
    subline: '',
    virtualAssistantFragment: AemFragmentCatalog.STEP5VA1,
  },
};
