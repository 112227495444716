import * as React from 'react';
import { Beitragsvariante, ValueRanges } from 'rlv-common';
import { isOnline } from '../../../helpers/isOnline';
import {
  getBruttoBeitragForVariant,
  getNettoBeitragForVariant,
} from '../../../helpers/variant.utils';
import { ViewName } from '../../../helpers/ViewName';
import { FeePageData, FeePageVariante } from '../../../pages/fee/FeePage.types';
import { FeePageContainer } from '../../../pages/fee/feePageContainer/FeePageContainer';
import { getAngebot, updateFeePageData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createFeePageSchema } from '../../../validation/FeePage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const feeBeforeDuwPage: StateDefinition<FeePageData> = {
  name: StateName.FEE_BEFORE_DUW_PAGE,
  percentage: PagePercentage.FEE_BEFORE_DUW_PAGE,
  trackingViewName: ViewName.BERECHNUNGSERGEBNIS,
  createValidationSchema: (valueRanges: ValueRanges) => createFeePageSchema(valueRanges),
  validInboundStates: [
    StateName.LIVING_CONDITIONS_INCEPTION_DATE,
    StateName.PERSONAL_DATA_PAGE,
    StateName.DUW_DISCLOSURE_PAGE,
    StateName.REQUEST_OFFER_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],
  transitions: [
    {
      test: (action, data) =>
        action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.LIVING_CONDITIONS_INCEPTION_DATE,
    },
    {
      test: (action, data) => {
        const isValid = createFeePageSchema(data.valueRanges).isValidSync(data.userInput);
        return action === NavigationAction.NEXT && isValid;
      },
      newState: StateName.DUW_DISCLOSURE_PAGE,
    },
    {
      test: (action, data) => {
        const isValid = createFeePageSchema(data.valueRanges).isValidSync(data.userInput);
        return action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER && isValid;
      },
      newState: StateName.REQUEST_OFFER_PAGE,
    },
    {
      test: (action, data) => {
        const isValid = createFeePageSchema(data.valueRanges).isValidSync(data.userInput);
        return action === NavigationAction.DIRECT_JUMP_PERSONAL_DATA && isValid;
      },
      newState: StateName.PERSONAL_DATA_PAGE,
    },
    {
      test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER_PERSONAL_DATA,
      newState: StateName.REQUEST_OFFER_PAGE,
    },
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    let variante = Beitragsvariante.KOMFORT;

    if (response.feeCalculationModel) {
      variante = response.feeCalculationModel.variante
        ? response.feeCalculationModel.variante
        : Beitragsvariante.KOMFORT;
    }

    if (
      response.livingConditionsModel &&
      response.livingConditionsModel.smoker !== 'NICHTRAUCHER_SEIT_10_JAHREN' &&
      variante === Beitragsvariante.BASIS
    ) {
      variante = Beitragsvariante.KOMFORT;
    }

    const variantenInformation = response.varianten.find(element => element.variante === variante);

    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};

    return {
      userInput: {
        ...response.feeCalculationModel,
        ...response.livingConditionsModel,
        variante,
        requestOfferPersonalDataId:
          response.requestOfferPersonalDataModel?.vp.pdeId !==
          response.requestOfferPersonalDataModel?.vn.pdeId,
        priceNetto: getNettoBeitragForVariant(variantenInformation),
        priceBrutto: getBruttoBeitragForVariant(variantenInformation),
        paymentMethod: response.paymentMethod,
        insurancePeriodAdjusted: transitionDetails.payload
          ? transitionDetails.payload.insurancePeriodAdjusted
          : false,
      },
      varianten: response.varianten,
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  onExit: async (_, inputData) => {
    await updateFeePageData(inputData.businessId, inputData.userInput, isOnline());
    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    if (!inputData.varianten) {
      return undefined;
    }

    const varianten: FeePageVariante[] = inputData.varianten.map(value => ({
      ...value,
      nettobeitrag: isOnline()
        ? value.bruttoBeitragMitRisikozuschlag || value.nettobeitragNachZahlweise
        : value.nettobeitragNachZahlweise,
      bruttobeitrag: isOnline()
        ? value.bruttoBeitragMitRisikozuschlag || value.bruttobeitragNachZahlweise
        : value.bruttobeitragNachZahlweise,
    }));

    const userInput = inputData.userInput;
    return (
      <FeePageContainer
        businessId={inputData.businessId}
        storeState={{
          need: userInput.need,
          insuranceEndSum: userInput.insuranceEndSum,
          sumInsured: userInput.sumInsured,
          periodInYears: userInput.periodInYears,
          paymentMethod: userInput.paymentMethod,
          variante: userInput.variante,
          loanSum: userInput.loanSum,
          loanPeriodInYears: userInput.loanPeriodInYears,
          loanRate: userInput.loanRate,
          basisSum: userInput.basisSum,
          repaymentFreePeriodInYears: userInput.repaymentFreePeriodInYears,
          paymentPeriodInYears: userInput.paymentPeriodInYears,
          additionalInsuranceSettings: userInput.additionalInsuranceSettings,
          smoker: userInput.smoker,
          additionalTimeWaiting: false,
          startedDUW: userInput.startedDUW,
          valueRanges: inputData.valueRanges,
          update: values => updateApp(values),
        }}
        valueRanges={inputData.valueRanges}
        requestOfferPersonalData={userInput.requestOfferPersonalDataId}
        options={inputData.valueRanges.insuranceSettings}
        varianten={varianten}
        insurancePeriodAdjusted={userInput.insurancePeriodAdjusted}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
        baseCalculationDataEditable={true}
        onError={onError}
      />
    );
  },
};
