import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import * as React from 'react';
import { Beitragsvariante, ErrorLogKey } from 'rlv-common';
import { CLASS_NAME, LoadingScriptError } from '../helpers/loadingScriptErrorHelper';
import { Storage } from '../helpers/Storage';
import { getTrackingViewName } from '../helpers/ViewName';
import {
  HandleActionCallback,
  StateData,
  StateDefinition,
  UpdateDTMTrackingFunction,
  UpdateFunction,
} from '../routing/StateMachine';
import { NavigationAction, StateName } from '../routing/StateMachineTypes';
import { log } from '../services/api';
import { Tracker } from '../tracking/tracker';
import { TrackingConsumer } from '../tracking/trackingContext';
import { SessionStorageKeys } from '../types/SessionStorage';
import { ErrorModal } from './ErrorModal/ErrorModal';

export interface PageWrapperProps {
  currentState: StateDefinition<object>;
  inputData: StateData<object>;
  handleAction: HandleActionCallback;
  // TODO: Check if it is possible to add as unknown to remove this tslint.
  updateApp: UpdateFunction<any>;
  updateDTMTracking: UpdateDTMTrackingFunction;
  updateCalling: boolean;
  interceptedActionPending: boolean;
  openErrorModal: boolean;
}

interface PageWrapperState {
  isPageError: boolean;
  openErrorModal: boolean;
  variantError: boolean;
}

export class PageWrapper extends React.Component<PageWrapperProps, PageWrapperState> {
  private constructor(props: PageWrapperProps) {
    super(props);
    this.state = {
      isPageError: false,
      openErrorModal: props.openErrorModal,
      variantError: false,
    };
  }

  public componentDidMount() {
    this.getHeaderDetails();
  }

  public componentDidUpdate() {
    this.getHeaderDetails();
    if (this.props.openErrorModal !== this.state.openErrorModal) {
      this.setState({ openErrorModal: this.props.openErrorModal });
    }
  }

  private readonly onError = (e: Error) => {
    this.setState({
      isPageError: true,
    });
    let errorLogKey: ErrorLogKey;
    if (e.name === CLASS_NAME) {
      errorLogKey = (e as LoadingScriptError).errorKey;
    } else {
      // TODO: Is not a map, but can be added as a parameter of the function.
      switch (this.props.currentState.name) {
        case StateName.CHECKOUT_PAGE:
          errorLogKey = ErrorLogKey.CHECKOUT_PAGE_ERROR;
          break;
        case StateName.DUW_QUESTIONNAIRE_PAGE:
          errorLogKey = ErrorLogKey.DUW_PAGE_ERROR;
          break;
        case StateName.PERSONAL_DATA_PAGE:
          errorLogKey = ErrorLogKey.PDE_PAGE_ERROR;
          break;
        default:
          errorLogKey = ErrorLogKey.UNKNOWN;
      }
    }

    log(errorLogKey, e ? e.message : undefined);
  };

  private readonly handleErrorModalRepeatClick = (_: Tracker, __: string) => {
    this.setState({ openErrorModal: false });

    if (this.state.isPageError || this.state.variantError) {
      window.location.reload();
      return;
    }
    this.props.handleAction(NavigationAction.REPEAT_CALL);
  };

  private readonly getHeaderDetails = (): void => {
    const { priceNetto, priceBrutto, paymentMethod, variante } = this.props.inputData.userInput as {
      priceNetto?: number;
      priceBrutto?: number;
      paymentMethod: string;
      variante?: Beitragsvariante;
    };

    if (priceNetto) {
      Storage.writeItem(SessionStorageKeys.PRICE_NETTO, String(priceNetto));
    }
    if (priceBrutto) {
      Storage.writeItem(SessionStorageKeys.PRICE_BRUTTO, String(priceBrutto));
    }
    if (paymentMethod) {
      Storage.writeItem(SessionStorageKeys.PAYMENT_METHOD, paymentMethod);
    }
    if (variante) {
      Storage.writeItem(SessionStorageKeys.VARIANTE, variante);
    }
  };

  public render() {
    const pageExitHelperObject = this.props.inputData.userInput as {
      pageExitNotAllowed: boolean;
    };
    return (
      <>
        {(this.state.openErrorModal || this.state.isPageError || this.state.variantError) && (
          <TrackingConsumer>
            {context =>
              context && (
                <ErrorModal
                  onClick={() => {
                    this.handleErrorModalRepeatClick(
                      context,
                      getTrackingViewName(this.props.currentState.trackingViewName),
                    );
                  }}
                  open
                  businessId={this.props.inputData.businessId}
                />
              )
            }
          </TrackingConsumer>
        )}
        {!this.state.openErrorModal && !this.state.isPageError && (
          <>
            <div data-component-id="main-view">
              {this.props.currentState.render &&
                this.props.currentState.render(
                  this.props.inputData,
                  this.props.handleAction,
                  this.props.updateApp,
                  this.onError,
                  this.props.updateDTMTracking,
                )}
            </div>

            <TrackingConsumer>
              {context =>
                context &&
                !pageExitHelperObject.pageExitNotAllowed && (
                  <Modal
                    open={this.props.interceptedActionPending}
                    onDismiss={() => {
                      this.props.handleAction(NavigationAction.INTERCEPTION_MODAL_DISMISS);
                    }}
                    dismissible
                  >
                    <p>
                      Wenn Sie Ihre Angaben ändern, müssen Sie die Gesundheitsfragen möglicherweise
                      noch einmal beantworten.
                    </p>
                    <Button
                      style={{ float: 'right' }}
                      width={'auto'}
                      variant="primary"
                      onClick={() => {
                        this.props.handleAction(NavigationAction.DUW_MODAL_CONFIRM);
                      }}
                    >
                      Okay
                    </Button>
                  </Modal>
                )
              }
            </TrackingConsumer>
          </>
        )}
      </>
    );
  }
}
