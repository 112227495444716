import Checkbox from '@eg/elements/Checkbox';
import { LoaderIcon } from '@eg/elements/components/Icons';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import * as React from 'react';
import Footer, { AuxButton } from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import Text from '../../../../components/Text';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { DataIdFeePage } from '../../../../types/DataId';
import { CONFIG_BACKEND_BASE_URL, CONFIG_BACKEND_API_PREFIX } from '../../../../config';

import * as downloadPDFUtils from '../../../../helpers/downloadPDF.utils';
import { TrackingElementIDD } from '../../../../tracking/tracking.types';
import './IddDownloadPdfSubmit.css';

export interface IddDownloadPdfSubmitProps extends SharedSubmitCallBack<string> {
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: string, navigationRequest: NavigationAction) => void;
  businessId: string;
  onError: (e: Error) => void;
  pdfUrlDownloaded: string | undefined;
  trackClickEvent: (clickedElement: TrackingElementIDD) => void;
  onPdfUrl: (url: string | undefined) => void;
}

export const IddDownloadPdfSubmit = ({
  businessId,
  onError,
  onSubmit,
  onNavigateNoSubmit,
  trackClickEvent,
  onPdfUrl,
  pdfUrlDownloaded,
}: IddDownloadPdfSubmitProps) => {
  const [isSubmitOrderButtonDisabled, setIsSubmitOrderButtonDisabled] = React.useState(
    !pdfUrlDownloaded,
  );
  const [isDownloadPdfButtonDisabled, setIsDownloadPdfButtonDisabled] = React.useState(
    !pdfUrlDownloaded,
  );
  const [isWaiverAccepted, setIsWaiverAccepted] = React.useState(!!pdfUrlDownloaded);
  const [isPdfDownloaded, setIsPdfDownloaded] = React.useState(!!pdfUrlDownloaded);
  const [isLoadingPdf, setIsLoadingPdf] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState<string | undefined>();

  React.useEffect(() => {
    downloadPDFUtils.setPdfDownloaded(false);
    setPdfUrl(undefined);
  }, []);

  React.useEffect(() => {
    onPdfUrl(pdfUrl);

    if (pdfUrl) {
      forceDownload();
    }
  }, [pdfUrl]);

  const handleCheckboxChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    trackClickEvent(TrackingElementIDD.CHECKBOX_IDD);
    setIsWaiverAccepted(checked);
    setIsDownloadPdfButtonDisabled(!checked);
    setIsPdfDownloaded(false);
    setIsSubmitOrderButtonDisabled(true);
    if (!checked) {
      downloadPDFUtils.setPdfDownloaded(false);
    }
  };

  const onDownloadPdfClick = async (): Promise<void> => {
    trackClickEvent(TrackingElementIDD.BUTTON_IDD_DOWNLOAD);
    if (pdfUrl) {
      forceDownload();
      return;
    }
    try {
      setIsLoadingPdf(true);

      const dinamicPdfUrl = `${CONFIG_BACKEND_BASE_URL}${CONFIG_BACKEND_API_PREFIX}/documents/${businessId}/download`;

      const blob = await fetch(dinamicPdfUrl).then(r => r.blob());
      console.log(blob);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'Vertragsdokumente_RLV_DIVE.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setIsPdfDownloaded(true);
      setIsSubmitOrderButtonDisabled(false);
    } catch (err) {
      onError(err as Error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const forceDownload = (): void => {
    if (pdfUrl) {
      downloadPDFUtils.openPDF(pdfUrl, 'Vertragsdokumente_RLV_DIVE');

      setIsPdfDownloaded(true);
      setIsSubmitOrderButtonDisabled(false);
      downloadPDFUtils.setPdfDownloaded(downloadPDFUtils.canNavigateBackFromPdf());
    }
  };

  return (
    <div className="downloadpdf content-container">
      <Headline text={Copy.headline} subline={Copy.subline} />
      <div className="form-wrapper">
        <div className="form-row">
          <Text styles="bold">{Copy.formHeader}</Text>
          <Text>
            Für Online-Verträge gilt: ERGO muss Sie beraten und dies dokumentieren. Sie können aber
            auch ohne Beratung abschließen - stimmen Sie dem Beratungsverzicht einfach zu. Dann nur
            noch PDF herunterladen & Antrag absenden!
          </Text>
        </div>
        <div className="form-row">
          <Checkbox
            label={Copy.checkBox}
            checked={isWaiverAccepted}
            onChange={handleCheckboxChange}
            data-component-id={DataIdFeePage.INPUT_CHECKBOX}
            verticalAlign="top"
          />
        </div>
      </div>

      <Text styles="bold subheader">Ihre Vertragsunterlagen</Text>
      <Text>
        Bitte speichern Sie vor dem Online-Antrag die Vertragsunterlagen und Informationen als PDF
        und schließen Sie Ihren Online-Antrag gleich ab.
      </Text>
      <AuxButton
        key="1"
        step={STEP_1}
        label="Herunterladen"
        disabled={isDownloadPdfButtonDisabled}
        onClick={onDownloadPdfClick}
        // TODO: Do not combine ternary operators
        iconRight={isPdfDownloaded ? CheckIcon : isLoadingPdf ? LoaderIcon : undefined}
      />
      <Text styles="bold subheader">Zahlungspflichtiger Vertrag</Text>
      <Text>Bei Vertragsabschluss fallen für Sie keine weiteren Kosten an.</Text>
      <Footer
        labelNext="Online beantragen"
        handleNext={() => {
          trackClickEvent(TrackingElementIDD.BUTTON_SEND);
          onSubmit('', NavigationAction.NEXT);
        }}
        handleAction={(navigationAction: NavigationAction) => {
          if (navigationAction === NavigationAction.BACK) {
            downloadPDFUtils.setPdfDownloaded(false);
            onNavigateNoSubmit(NavigationAction.BACK);
          }
        }}
        showNext={true}
        showPrevious={true}
        stepNext={STEP_2}
        disableNext={isSubmitOrderButtonDisabled}
      />
    </div>
  );
};

const STEP_1 = 1;
const STEP_2 = 2;

export const Copy = {
  headline: 'Beantragen Sie Ihren Vertrag jetzt online',
  subline:
    'Mit dem Klick auf „Online beantragen“ werden Ihre Vertragsdaten verbindlich an die ERGO Vorsorge Lebensversicherung AG übertragen.',
  formHeader: 'EU-Vorgabe: Beratungsverzicht',
  checkBox: `Ich verzichte für diesen Online-Antrag auf eine persönliche Beratung sowie deren Dokumentation.
     Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.`,
};
