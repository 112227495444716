export enum SessionStorageKeys {
  GENRE = 'genre',
  IS_ONLINE = 'isOnline',
  DIVERGING_INSURED_PERSON = 'divergingInsuredPerson',
  CONSENT_TO_DATA_PROCESSING = 'consentToDataProcessing',
  CONSENT_TO_CREDIT_CHECK = 'consentToCreditCheck',
  AEM_WRAPPER = 'aem',
  OFFER_TYPE = 'offerType',
  DUW_STATE = 'duwState',
  DUW_STATUS = 'duwStatus',
  VARIANTE = 'variante',
  PAYMENT_METHOD = 'paymentMethod',
  PRICE_NETTO = 'priceNetto',
  PRICE_BRUTTO = 'priceBrutto',
  CURRENT_STATE = 'currentState',
  PDF_DOWNLOADED = 'pdfDownloaded',
  FEATURE_FLAGS = 'FeatureFlags',
  BUSINESS_ID = 'businessId',
  APP_DATA = 'AppData',
}
