import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { getErrorMessage } from '../../../Helper';

export interface FieldInputProps {
  name: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value: string;
}

const FieldInput = (props: FieldInputProps) => (
  <Field
    name={props.name}
    render={({ field, form }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
      const { setFieldValue, setFieldTouched } = form;
      return (
        <div className={props.className || ''}>
          <Input
            id={field.name}
            name={field.name}
            placeholder={props.placeholder}
            onBlur={() => {
              setFieldTouched(field.name);
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(field.name, event.target.value);
            }}
            value={field.value}
            disabled={props.disabled}
            readOnly={props.readOnly}
          />
          <ControlWithHint error={getErrorMessage(form, field)}><></></ControlWithHint>
        </div>
      );
    }}
  />
);

export default FieldInput;
