import * as React from 'react';

import { Need } from 'rlv-common';
import { scrollToTop } from '../../Helper';
import { Storage } from '../../helpers/Storage';
import { NavigationAction, StateName } from '../../routing/StateMachineTypes';
import { DTMAuswahl, DTMSeitenname } from '../../tracking/dtmTracking';
import { TrackingConversionTypes } from '../../tracking/tracking.types';
import { withTracker } from '../../tracking/withTracker';
import { PageContainer } from '../../types/PageContainer';
import NeedSelection from './radiogroups/NeedSelection/NeedSelection';
import { SUB_PAGES } from './subPages';
import { SliderFormValues } from './NeedsPageContainer.data';
import { NeedPageProps } from './Needs.types';

export class NeedsPageContainer extends React.Component<NeedPageProps> implements PageContainer {
  private sessionCurrentState: string | undefined;

  public componentDidMount() {
    scrollToTop();

    this.props.updateDTMTracking({
      auswahl: DTMAuswahl.KONSTANT,
      seitenName: DTMSeitenname.BEDARFSANALYSE_AUSWAHL,
    });
    this.sessionCurrentState = Storage.readItem('currentState');

    const { trackingNeed } = this.props.tracker.getTrackingNeed(
      this.props.storeState.need || Need.KONSTANT,
    );
    const periodInYears = this.props.storeState.periodInYears
      ? this.props.storeState.periodInYears
      : SliderFormValues.DefaultPeriodInYears;
    const insuranceEndSum = this.props.storeState.insuranceEndSum
      ? this.props.storeState.insuranceEndSum
      : SliderFormValues.DefaultInsuranceEndSum;
    let trackingBody = {};

    switch (this.sessionCurrentState) {
      case undefined:
      case 'NeedPage':
        trackingBody = {
          Bedarfsanalyse: trackingNeed,
        };
        break;
      case 'DecreasingPeriodInYearsPage':
      case 'ConstantPeriodInYearsPage':
        trackingBody = {
          VersLZ: periodInYears,
        };
        break;
      case 'DecreasingEndSumInsuredPage':
        trackingBody = {
          VersSumLJ: insuranceEndSum,
        };
        break;
      default:
        break;
    }

    this.props.tracker.updateTariffOptions(trackingBody);

    if (!this.sessionCurrentState || this.sessionCurrentState === 'NeedPage') {
      this.props.tracker.trackPageLoad({
        conversionType: TrackingConversionTypes.SALE_FUNNEL_START,
      });
    } else {
      this.props.tracker.trackPageLoad();
    }
  }

  public render() {
    const needsContent = () => {
      if (!this.sessionCurrentState || this.sessionCurrentState === StateName.NEED_PAGE) {
        return (
          <NeedSelection
            data-testid="NeedSelection"
            need={this.props.storeState.need || Need.KONSTANT}
            valueRanges={this.props.valueRanges}
            onSubmit={async (needSelection: Need, navigationRequest: NavigationAction) => {
              await this.submitChild('need', needSelection, navigationRequest);
            }}
            onSelect={(need: Need) => {
              const { trackingNeed, trackingName } = this.props.tracker.getTrackingNeed(need);
              this.props.tracker.updateTariffOptions({
                Bedarfsanalyse: trackingNeed,
              });

              this.props.tracker.trackClickEvent({
                clickedElement: trackingName,
              });
            }}
          />
        );
      } else {
        const SubPage = SUB_PAGES[this.sessionCurrentState];
        return (
          <SubPage
            valueRanges={this.props.valueRanges}
            storeState={this.props.storeState}
            handleAction={this.props.handleAction}
            tracker={this.props.tracker}
          />
        );
      }
    };
    return <div data-component-id={Storage.readItem('currentState')}>{needsContent()}</div>;
  }

  private async updateStateMachine(field: string, value: string | boolean | number) {
    this.props.storeState.update({
      [field]: value,
    });
  }

  public readonly submitChild = async (
    field: string,
    value: string | boolean | number,
    navigationRequest: NavigationAction,
  ) => {
    await this.updateStateMachine(field, value).then(() => this.navigate(navigationRequest));
  };

  public navigate(navigationRequest: NavigationAction) {
    this.props.handleAction(navigationRequest);
  }
}

export default withTracker(NeedsPageContainer);
