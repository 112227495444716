import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { FieldNumberInputProps } from './FieldNumberInput.types';

const FieldNumberInput = ({
  className,
  name,
  label,
  tooltip,
  placeholder,
  min,
  max,
  step,
  adornmentRight,
  onBlur,
  onChange
}: FieldNumberInputProps) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
      const { setFieldValue, setFieldTouched } = form;
      return (
        <div className={className || ''}>
          <InputRow
            type="number"
            pattern="\d*"
            id={field.name}
            name={name}
            label={label}
            tooltip={tooltip}
            placeholder={placeholder}
            onBlur={() => {
              setFieldTouched(field.name);
              if (onBlur) { onBlur(); }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(field.name, event.target.value);
              if (onChange) { onChange(event); }
            }}
            defaultValue={field.value}
            min={min}
            max={max}
            step={step}
            adornmentRight={adornmentRight}
          />
        </div>
      );
    }}
  />
);

export default FieldNumberInput;
