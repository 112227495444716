import axios from 'axios';
import {
  AgencyResponse,
  AngebotResponse,
  AnnuityNeedModel,
  BUSINESS_ID_KEY,
  ConstantNeedModel,
  CreateRequest,
  CreateResponse,
  DecreasingNeedModel,
  ErrorLogKey,
  ErrorRequest,
  InsuranceSetting,
  MESSAGE_MAX_STRING_SIZE,
  Nationality,
  Need,
  OrderRequest,
  OrderResponse,
  PDERequest,
  PersonRolle,
  PersonRollenTyp,
  ResponseCodes,
  UpdateDivergingInsuredPersonalDataRequest,
  UpdateRequest,
  OFFER_TYPE,
  OfferType,
  OE_NUMBER_KEY,
} from 'rlv-common';

import { CONFIG_BACKEND_BASE_URL, CONFIG_BACKEND_API_PREFIX } from '../config';
import { isDivergingInsuredPerson } from '../Helper';
import { handleMessages } from '../helpers/consoleLog';
import { DuwPageData } from '../pages/duw/DuwPageContainer';
import { FeePageData } from '../pages/fee/FeePage.types';
import { CheckOutPageData } from '../pages/legalQuestions/legalQuestionsPageContainer';
import { LivingConditionsPageData } from '../pages/livingConditions/LivingConditionsPageContainer';
import { NeedPageData } from '../pages/needs/Needs.types';
import { PersonalPageData } from '../pages/personalData/PersonalDataPage.types';
import { RequestOfferDivergingInsuredPersonPageData } from '../pages/requestOfferDiverging/RequestOfferDivergingInsuredPersonPage';
import { RequestOfferPageData } from '../pages/requestOfferPersonalData/RequestOfferPersonalDataPage';
import { InsuranceDocument } from '../types/InsuranceDocument';
import { RlvProfession } from '../types/RlvProfession';

const headers = { headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' } };

const baseApiUrl = `${CONFIG_BACKEND_BASE_URL}${CONFIG_BACKEND_API_PREFIX}`;

export const getAngebot = async (businessId: string): Promise<AngebotResponse> => {
  const endpoint = `${baseApiUrl}/getAngebot`;

  try {
    const response = await axios.get(endpoint, {
      params: {
        businessId,
      },
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
    });
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei getAngebot: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOfferPageData = async (businessId: string): Promise<AngebotResponse> => {
  const endpoint = `${baseApiUrl}/getDivergingInsuredPerson`;

  try {
    const response = await axios.get(endpoint, {
      params: {
        businessId,
      },
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
    });
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei getDivergingInsuredPerson: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAngebot = async (req: CreateRequest): Promise<CreateResponse> => {
  const endpoint = `${baseApiUrl}/createAngebot`;

  try {
    const response = await axios.post(endpoint, req, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei createAngebot: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNeedPageData = async (
  businessId: string,
  values: Partial<NeedPageData>,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    need: values.need,
  };

  return callEndpoint(body, 'needSelectionData');
};

export const updateLivingConditionsPageData = async (
  businessId: string,
  values: Partial<LivingConditionsPageData>,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    birthdate: values.birthdate,
    employment: values.employment,
    professionKey: values.professionKey,
    professionLabel: values.professionLabel,
    smoker: values.smoker,
    height: values.height,
    weight: values.weight,
    biker: values.biker,
    propertyOwner: values.propertyOwner,
    inceptionDate: values.inceptionDate,
  };

  return callEndpoint(body, 'livingConditionData');
};

export const updateConstantNeedBlockData = async (
  businessId: string,
  values: Partial<ConstantNeedModel>,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    ...mapConstantData(values),
  };

  return callEndpoint(body, 'constantNeedData');
};

export const updateDivergingInsuredPersonPageData = async (
  businessId: string,
  values: RequestOfferDivergingInsuredPersonPageData,
): Promise<AngebotResponse> => {
  // Das Geburtsdatum des VN setzen
  // TODO THIS IS UPDATING BIRTHDATE BEFORE THE BIRTHDATE HAS BEEN ASKED FOR
  values.vn.birthdate = values.birthdateVn;
  values.vp.birthdate = values.birthdateVp;
  const body: UpdateDivergingInsuredPersonalDataRequest = {
    businessId,
    divergingInsuredPerson: values.divergingInsuredPerson,
    requestOfferPersonalDataModel: { vn: values.vn, vp: values.vp },
  };

  return callEndpoint(body, 'divergingInsuredPerson');
};

const mapConstantData = (values: Partial<ConstantNeedModel>) => ({
  need: Need.KONSTANT,
  sumInsured: values.sumInsured,
  periodInYears: values.periodInYears,
});

export const updateDecreasingNeedBlockData = async (
  businessId: string,
  values: Partial<DecreasingNeedModel>,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    ...mapDecreasingData(values),
  };

  return callEndpoint(body, 'decreasingNeedData');
};

const mapDecreasingData = (values: Partial<DecreasingNeedModel>) => ({
  need: Need.DECREASING,
  sumInsured: values.sumInsured,
  periodInYears: values.periodInYears,
  insuranceEndSum: values.insuranceEndSum,
});

export const updateAnnuityNeedBlockData = async (
  businessId: string,
  values: Partial<AnnuityNeedModel>,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    ...mapAnnuityData(values),
  };

  return callEndpoint(body, 'annuityNeedData');
};

export const updateOeNumber = async (businessId: string, oeNumber?: string): Promise<void> => {
  const body: UpdateRequest = {
    businessId,
  };

  if (oeNumber) {
    body.oeNumber = oeNumber;
  }
  try {
    await callEndpoint(body, 'oeNumber');
  } catch (error) {
    return Promise.reject(error);
  }
};

const mapAnnuityData = (values: Partial<AnnuityNeedModel>) => ({
  need: Need.ANNUITY,
  periodInYears: values.periodInYears,
  loanSum: values.loanSum,
  loanPeriodInYears: values.loanPeriodInYears,
  loanRate: values.loanRate,
  basisSum: values.basisSum,
  repaymentFreePeriodInYears: values.repaymentFreePeriodInYears,
});

export const updateFeePageData = async (
  businessId: string,
  values: Partial<FeePageData>,
  isOnline?: boolean,
): Promise<AngebotResponse> => {
  if (!values.additionalInsuranceSettings) {
    return Promise.reject();
  }

  let needData = {};
  switch (values.need) {
    case Need.KONSTANT:
      needData = mapConstantData(values);
      break;
    case Need.DECREASING:
      needData = mapDecreasingData(values);
      break;
    case Need.ANNUITY:
      needData = mapAnnuityData(values);
      break;
    default:
      needData = {};
  }
  const body: UpdateRequest = {
    businessId,
    ...needData,
    paymentMethod: values.paymentMethod,
    additionalInsuranceSettings: Object.keys(values.additionalInsuranceSettings).map(
      (element: string): InsuranceSetting => {
        if (!values.additionalInsuranceSettings) {
          return {} as InsuranceSetting;
        }
        const setting: InsuranceSetting = values.additionalInsuranceSettings[element];
        return {
          insuranceSettingKey: setting.insuranceSettingKey,
          insuranceSettingValue: setting.insuranceSettingValue,
        };
      },
    ),
    isOnline,
  };
  if (values.variante) {
    body.variante = values.variante;
  }
  return callEndpoint(body, 'feeData');
};

export const updatePersonalPageData = async (
  businessId: string,
  values: Partial<PersonalPageData>,
): Promise<AngebotResponse> => {
  const body: PDERequest = {
    businessId,
    iban: values.iban,
    bic: values.bic,
    persons: [
      {
        pdeId: values.pdeId ? values.pdeId : 'pdeIdVna',
        rollentyp: PersonRollenTyp.VERSICHERUNGSNEHMER,
      },
    ],
    entitledPersons: values.entitledPersons,
  };

  return callEndpoint(body, 'personalData');
};

export const updateDuwData = async (
  businessId: string,
  values: DuwPageData,
): Promise<AngebotResponse> => {
  const body: UpdateRequest = {
    businessId,
    duwToken: values.duwToken,
  };

  return callEndpoint(body, 'duwData');
};

export const updateCheckOutPageData = async (
  businessId: string,
  values: Partial<CheckOutPageData>,
): Promise<AngebotResponse> => {
  let body: UpdateRequest = {
    businessId,
    patientConfidentialityRelease: values.patientConfidentialityRelease,
    patientConfidentialityReleaseAfterDeath: values.patientConfidentialityReleaseAfterDeath,
  };
  if (typeof values.loanProtection === 'boolean') {
    body = { ...body, loanProtection: values.loanProtection };
  }
  return callEndpoint(body, 'checkOutData');
};

export const updateRequestOfferPageData = async (
  businessId: string,
  values: Partial<RequestOfferPageData>,
): Promise<AngebotResponse> => {
  const persons: PersonRolle[] = [];

  if (values.vp && values.vp.pdeId) {
    persons.push({
      pdeId: values.vp.pdeId,
      rollentyp: PersonRollenTyp.VERSICHERTE_PERSON,
    });
  }

  persons.push({
    pdeId: values.vn!.pdeId ? values.vn!.pdeId : 'pdeIdVna',
    rollentyp: PersonRollenTyp.VERSICHERUNGSNEHMER,
  });

  const body: UpdateRequest = {
    businessId,
    persons,
  };

  return callEndpoint(body, 'requestOfferPersonalData');
};

export const getCitiesByZipCode = async (zipCode: number): Promise<string[]> => {
  if (isNaN(zipCode) || zipCode.toString().length < 5) {
    return [];
  }
  const endpoint = `${baseApiUrl}/cities?zipCode=${zipCode}`;
  try {
    const response = await axios.get(endpoint, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei getCitiesByZipCode: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProfessions = async (term: string): Promise<RlvProfession[]> => {
  const endpoint = `${baseApiUrl}/professions`;
  const professionBody = { term };
  try {
    const response = await axios.post(endpoint, professionBody, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei getProfessions: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNationalities = async (): Promise<Nationality[]> => {
  const endpoint = `${baseApiUrl}/nationalities`;

  try {
    const response = await axios.get(endpoint, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei getNationalities: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDocumentList = async (businessId: string): Promise<InsuranceDocument[]> => {
  const endpoint = `${baseApiUrl}/documents/${businessId}`;
  try {
    const response = await axios.get(endpoint, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei List Documents: ${response.status}`);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const makeOrder = async (businessId: string, oeNumber?: string): Promise<OrderResponse> => {
  const endpoint = `${baseApiUrl}/order`;

  const reqBody: OrderRequest = {
    [BUSINESS_ID_KEY]: businessId,
    [OE_NUMBER_KEY]: oeNumber,
  };

  // Should be typed with AxiosError
  try {
    const response = await axios.post(endpoint, reqBody, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei makeOrder: ${response.status}`);
    }
    return Promise.resolve({ status: response.status });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const makeOffer = async (
  businessId: string,
  oeNumber?: string,
  pdeIdVn?: string,
  pdeIdVp?: string,
  offerType?: OfferType,
): Promise<OrderResponse> => {
  const endpoint = `${baseApiUrl}/order`;

  // Todo Fehlerhandling für PdeId explizit auslagern
  if (!pdeIdVn) {
    return Promise.reject();
  }

  if (isDivergingInsuredPerson() && !pdeIdVp) {
    return Promise.reject();
  }

  const reqBody: OrderRequest = {
    [BUSINESS_ID_KEY]: businessId,
    [OE_NUMBER_KEY]: oeNumber,
    [OFFER_TYPE]: offerType,
  };

  try {
    const response = await axios.post(endpoint, reqBody, headers);
    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei makeOffer: ${response.status}`);
    }
    return Promise.resolve({ status: response.status });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const log = (errorLogKey: ErrorLogKey, msg?: string): void => {
  const endpoint = `${baseApiUrl}/log`;
  const maxMessageSize = MESSAGE_MAX_STRING_SIZE - 1;
  let message = msg;
  if (message && message.length > maxMessageSize) {
    message = message.substring(0, maxMessageSize); // bissel was ist besser als nichts
  }
  const reqBody: ErrorRequest = {
    errorLogKey,
    message,
  };
  axios.put(endpoint, reqBody, headers).catch(e => {
    // TODO: Errors form send log information are dull
  });
};

export const isSuccessfulResponseCode = (statusCode: number): boolean =>
  statusCode === ResponseCodes.SUCCESS || statusCode === ResponseCodes.VALIDATION_FAILURE;

export const callEndpoint = async (body: object, endpoint: string): Promise<AngebotResponse> => {
  const serviceEndpoint = `${baseApiUrl}/${endpoint}`;

  try {
    const response = await axios.put(serviceEndpoint, body, headers);

    if (!isSuccessfulResponseCode(response.status)) {
      throw new Error(`Error bei ${endpoint}: ${response.status}`);
    }

    if (response.data.messages) {
      handleMessages(response.data.messages);
    }

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
