import * as React from 'react';
import { duwLoaded } from '../../../helpers/externalScripts';
import { ViewName } from '../../../helpers/ViewName';
import { configuration } from '../../../index';
import DuwPageContainer, { DuwPageData } from '../../../pages/duw/DuwPageContainer';
import { getAngebot, updateDuwData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const duwDisclosure: StateDefinition<DuwPageData> = {
  name: StateName.DUW_DISCLOSURE_PAGE,
  percentage: PagePercentage.DUW_DISCLOSURE_PAGE,
  trackingViewName: ViewName.GESUNDHEITSFRAGEN_DUW1,
  validInboundStates: [
    StateName.FEE_BEFORE_DUW_PAGE,
    StateName.PERSONAL_DATA_PAGE,
    StateName.CHECKOUT_PAGE,
    StateName.DUW_QUESTIONNAIRE_PAGE,
    StateName.LIVING_CONDITIONS_PROPERTY_OWNER
  ],
  transitions: [
    {
      test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.FEE_BEFORE_DUW_PAGE
    },
    {
      test: (action, data) => action === NavigationAction.NEXT,
      newState: StateName.DUW_QUESTIONNAIRE_PAGE
    }
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};

    return {
      userInput: response.duwDisplayModel,
      valueRanges: response.valueRanges,
      fetchedTrackingData
    };
  },
  onExit: async (transitionDetails, inputData) => {
    await updateDuwData(inputData.businessId, inputData.userInput);

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const userInput = inputData.userInput;

    return <DuwPageContainer
      configuration={configuration}
      businessId={inputData.businessId}
      storeState={{
        duwToken: userInput.duwToken,
        birthdate: userInput.birthdate,
        duwGeschlecht: userInput.duwGeschlecht,
        vorname: userInput.vorname,
        nachname: userInput.nachname,
        weight: userInput.weight,
        height: userInput.height,
        smoker: userInput.smoker,
        sumInsured: userInput.sumInsured,
        paymentMethod: userInput.paymentMethod,
        valueRanges: userInput.valueRanges,
        update: values => updateApp(values)
      }}
      onError={onError}
      scriptLoaded={duwLoaded}
      handleAction={handleAction}
      updateDTMTracking={updateDTMTracking}
    />;
  }
};
