import * as React from 'react';

export const BASIC_AFTER_INSURANCE_TOOLTIP = (
  <div className="tooltip">
    <p><b>Nachversicherungsgarantie</b></p>

    <p>
      Bei bestimmten Ereignissen können Sie die Todesfallleistung ohne neue
      Gesundheitsprüfung erhöhen. Sie werden nur gefragt, ob Sie noch
      Nichtraucher sind. Ereignisse sind z. B.:
    </p>
    <ul>
      <li>Heirat bzw. Lebenspartnerschaft</li>
      <li>Geburt bzw. Adoption eines Kindes</li>
      <li>
        Erwerb, Bau oder Renovierung einer Immobilie, wenn Sie dafür ein
        Darlehen von mind. 50.000 € aufnehmen
      </li>
    </ul>
  </div>
);

export const AFTER_INSURANCE_TOOLTIP = (
  <div className="tooltip">
    <p><b>Erweiterte Nachversicherungsgarantie</b></p>

    <p>
      Bei bestimmten Ereignissen können Sie die Todesfallleistung ohne neue
      Gesundheitsprüfung erhöhen. Sie werden nur gefragt, ob Sie noch
      Nichtraucher sind. Ereignisse sind z. B.:
    </p>
    <ul>
      <li>Heirat bzw. Lebenspartnerschaft</li>
      <li>Geburt bzw. Adoption eines Kindes</li>
      <li>
        Erwerb, Bau oder Renovierung einer Immobilie, wenn Sie dafür ein
        Darlehen von mind. 50.000 € aufnehmen
      </li>
      <li>Volljährigkeit</li>
      <li>Scheidung</li>
      <li>Gehaltserhöhung um mehr als 10 % bei Arbeitnehmer</li>
    </ul>
  </div>
);

export const EARLY_PAYMENT_TOOLTIP = (
  <div className="tooltip">
    <p><b>Vorgezogene Todesfallleistung</b></p>
    <p>
      Sie bekommen die volle Versicherungssumme bereits zu Lebzeiten.
      Vorausgesetzt, Sie haben wegen einer schweren Erkrankung nur noch max. 12
      Monate zu leben.
    </p>
  </div>
);

export const MEDICAL_OPINION_TOOLTIP = (
  <div className="tooltip">
    <p><b>Service: Medizinische Zweitmeinung</b></p>
    <p>
      Bei Fragen zu einer Diagnose oder Behandlung berät Sie das weltweite
      Netzwerk Best Doctors. Dort bekommen Sie eine unabhängige zweite Meinung
      und wertvolle Unterstützung.
    </p>
  </div>
);

export const INSTANT_HELP_TOOLTIP = (
  <div className="tooltip">
    <p><b>Soforthilfe für Angehörige</b></p>
    <p>
      Direkt nach Ihrem Tod bekommen Ihre Angehörigen 10 % der
      Versicherungssumme - insgesamt bis zu 10.000 €.
    </p>
  </div>
);

export const SECURITY_PLUS_TOOLTIP = (
  <div className="tooltip">
    <p><b>Garantiert gleichbleibender Beitrag</b></p>
    <p>
      Mit <b>Sicherheit Plus</b> bleibt Ihr Beitrag über die gesamte Laufzeit gleich.
      Garantiert ohne Beitragserhöhungen!
    </p>
  </div>
);

export const DYNAMIC_PAYMENT_TOOLTIP = (
  <div className="tooltip">
    <p><b>Beitragsdynamik</b></p>
    <p>
    Wenn Sie die konstante Versicherungssumme vereinbart haben, können Sie eine
    sogenannte <b>Beitragsdynamik</b> einschließen. Dann steigt Ihr Beitrag jedes Jahr um 3 %.
    Damit passt sich Ihre Versicherungssumme an die steigenden Kosten und Inflation an.
    Wenn Sie das einmal nicht möchten, widersprechen Sie einfach.
    </p>
  </div>
);
