import * as React from 'react';
import { EntitledPerson } from 'rlv-common';
import { BeneficiaryDetailsProps } from './BeneficiaryDetails.types';
import BeneficiaryDetailsForm from './BeneficiaryDetailsForm/BeneficiaryDetailsForm';
import {
  BeneficiaryFormOutput,
  MOCK_BENEFICIARY,
} from './BeneficiaryDetailsForm/BeneficiaryDetailsForm.types';
import BeneficiaryDetailsStatus from './BeneficiaryDetailsStatus/BeneficiaryDetailsStatus';
import { MAX_ENTITLEMENT_PERCENTAGE } from './BeneficiaryDetailsStatus/BeneficiaryDetailsStatus.data';
import { BeneficiaryDetailsSummary } from './BeneficiaryDetailsSummary/BeneficiaryDetailsSummary';

const BeneficiaryDetails = ({
  entitledPersons,
  addressFromInsuredPerson,
  onChangeBeneficiariesVisualization,
  onChangePercentage,
  onUpdateEntitledPersons,
  valueRanges,
  isTotalPercentageCompleted,
  trackClickEvent,
}: BeneficiaryDetailsProps) => {
  const [showForm, setShowForm] = React.useState(false);
  const [totals, setTotals] = React.useState(0);
  const [selectedBeneficiary, setSelectedBeneficiary] = React.useState(MOCK_BENEFICIARY);

  React.useEffect(() => {
    if (!entitledPersons.length) {
      setShowForm(true);
    }
    totalPercentage();
  }, [entitledPersons]);

  const totalPercentage = (): void => {
    const newTotal = entitledPersons.reduce(
      (sum: number, el: EntitledPerson) => sum + el.entitlementPercentage,
      0,
    );

    if (newTotal === MAX_ENTITLEMENT_PERCENTAGE) {
      onChangePercentage(true);
    } else {
      onChangePercentage(false);
    }
    setTotals(newTotal);
  };

  const handleBeneficiary = (beneficiary: BeneficiaryFormOutput): void => {
    setShowForm(false);
    const data: EntitledPerson = mapFormToServerData(beneficiary);

    const shouldEdit = !!data.person.pdeId;

    if (shouldEdit) {
      editBeneficiary(data);
      return;
    }
    addNewBeneficiary(data);
  };

  const enableCreateMode = () => setShowForm(true);

  const enableEditMode = (index: string | undefined) => {
    const res = entitledPersons.find(el => el.person.pdeId === index);
    if (res) {
      setSelectedBeneficiary(res);
      setShowForm(true);
    }
    return;
  };

  const addNewBeneficiary = (beneficiary: EntitledPerson): void => {
    const maxNumOfBeneficiaries = 3;
    if (entitledPersons.length > maxNumOfBeneficiaries) {
      return;
    }

    const newBeneficiaries = entitledPersons.slice();
    newBeneficiaries.push(beneficiary);
    onUpdateEntitledPersons(newBeneficiaries);
  };

  const editBeneficiary = (newBeneficiary: EntitledPerson) => {
    const {
      person: { pdeId },
    } = newBeneficiary;

    const newBeneficiaries = entitledPersons.map((entitledPerson: EntitledPerson) =>
      entitledPerson.person.pdeId === pdeId ? newBeneficiary : entitledPerson,
    );

    onUpdateEntitledPersons(newBeneficiaries);

    setSelectedBeneficiary(MOCK_BENEFICIARY);
    return;
  };

  const deleteBeneficiary = (index: string | undefined) => {
    if (!index) {
      return;
    }
    const newBeneficiaries = entitledPersons.filter(
      (entitledPerson: EntitledPerson) => entitledPerson.person.pdeId !== index,
    );

    onUpdateEntitledPersons(newBeneficiaries);
  };

  return (
    <section>
      {!entitledPersons && <hr className="beneficiary__separator" />}
      {!!entitledPersons.length && (
        <BeneficiaryDetailsSummary
          beneficiaries={entitledPersons}
          onChangeBeneficiariesVisualization={onChangeBeneficiariesVisualization}
          isTotalPercentageCompleted={isTotalPercentageCompleted}
          createNewBeneficiaryCallback={enableCreateMode}
          editBeneficiaryCallback={id => enableEditMode(id)}
          deleteBeneficiaryCallback={id => deleteBeneficiary(id)}
          trackClickEvent={clickedElement => {
            trackClickEvent(clickedElement);
          }}
        />
      )}
      {showForm && (
        <BeneficiaryDetailsForm
          entitledPersons={entitledPersons}
          beneficiary={mapEntitledPersonToForm(selectedBeneficiary)}
          setShowForm={setShowForm}
          addressFromInsuredPerson={addressFromInsuredPerson}
          relationships={valueRanges.relationships}
          onSubmit={handleBeneficiary}
          trackClickEvent={clickedElement => {
            trackClickEvent(clickedElement);
          }}
        />
      )}
      <BeneficiaryDetailsStatus
        numBeneficiaries={entitledPersons.length}
        currentPercentage={totals}
      />
    </section>
  );
};

export const mapFormToServerData = (formOutput: BeneficiaryFormOutput): EntitledPerson => {
  const dataToTheServer = {
    ...formOutput,
    entitlementPercentage: Number(formOutput.entitlementPercentage),
  };
  return dataToTheServer;
};

export const mapEntitledPersonToForm = (entitledPerson: EntitledPerson): BeneficiaryFormOutput => ({
  ...entitledPerson,
  entitlementPercentage: String(entitledPerson.entitlementPercentage),
});

export default BeneficiaryDetails;
