import * as React from 'react';
import { Geschlecht } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import RequestOfferFeedbackPage, {
  RequestOfferFeedbackPageData,
} from '../../../pages/RequestOfferFeedbackPage';
import { getAngebot } from '../../../services/api';
import { fetchTrackingDataFeedbackPage } from '../../../tracking/trackingFormating';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const requestOfferFeedbackPage: StateDefinition<RequestOfferFeedbackPageData> = {
  name: StateName.REQUEST_OFFER_FEEDBACK_PAGE,
  trackingViewName: ViewName.ANGEBOTFEEDBACK,
  validInboundStates: [StateName.REQUEST_OFFER_PAGE],
  transitions: [],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData = fetchTrackingDataFeedbackPage(response);

    return {
      userInput: {
        ...response.feedbackModel,
        shouldDownloadPdf: transitionDetails.action === NavigationAction.OFFER_DIRECT_ONLINE,
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  render: (inputData, handleAction, _unused, _unused2, updateDTMTracking) => {
    const userInput = inputData.userInput;
    return (
      <RequestOfferFeedbackPage
        businessId={inputData.businessId}
        valueRanges={inputData.valueRanges}
        storeState={{
          geschlecht: userInput.geschlecht ? userInput.geschlecht : Geschlecht.MAENNLICH,
          vorname: userInput.vorname ? userInput.vorname : '',
          nachname: userInput.nachname ? userInput.nachname : '',
          variante: userInput.variante,
          inceptionDate: userInput.inceptionDate,
          priceNetto: userInput.priceNetto,
          paymentMethod: userInput.paymentMethod,
          additionalTimeWaiting: false,
          shouldDownloadPdf: inputData.userInput.shouldDownloadPdf,
          update: values => undefined,
          vnPdsId: userInput.vnPdsId,
          businessId: inputData.businessId,
        }}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
