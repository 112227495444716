import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { mapToGermanDate } from 'rlv-common';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import './insuranceStartDateForm.css';
import {
  InsuranceStartDateFormProps,
  InsuranceStartDateText,
} from './insuranceStartDateForm.types';

const InsuranceStartDateForm = ({
  insuranceStartDate,
  onNavigateNoSubmit,
  onSubmit,
  valueRanges,
  trackClick,
}: InsuranceStartDateFormProps): React.ReactElement => {
  const insuranceStartDateOptions = valueRanges.insuranceStart.possibleValues.map(
    (date: string) => ({
      title: mapToGermanDate(date)!,
      value: date,
    }),
  );
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);
  const [selectedOption, setSelectedOption] = React.useState('');

  React.useEffect(() => {
    if (insuranceStartDate) {
      setSelectedOption(insuranceStartDate);
    }
  }, []);

  return (
    <div className="container">
      <Formik
        initialValues={{ insuranceStartDate }}
        onSubmit={() => onSubmit(selectedOption, navigationRequest)}
      >
        {form => (
          <Form>
            <Headline text={InsuranceStartDateText.title} />
            <Field
              name="insuranceStartDateField"
              render={() => (
                <>
                  <div className="radio_groups--container">
                    <RadioGroup
                      options={insuranceStartDateOptions}
                      name="insuranceStart"
                      selectedValue={selectedOption}
                      onChangeSelection={(_, value) => {
                        setSelectedOption(value as string);
                        const position = insuranceStartDateOptions
                          .map(option => option.value)
                          .indexOf(value as string);
                        trackClick(mapToGermanDate(value as string) || '', position);
                      }}
                    />
                  </div>
                  <AemFragment name={AemFragmentCatalog.STEP11VA} useVA={true} />
                  <Footer
                    showNext={true}
                    showPrevious={true}
                    labelNext={'Jetzt berechnen'}
                    labelPrevious={'zurück'}
                    disableNext={!selectedOption && !insuranceStartDate}
                    handleAction={(navigationAction: NavigationAction) => {
                      setNavigationRequest(navigationAction);
                      if (navigationAction === NavigationAction.BACK && !insuranceStartDate) {
                        onNavigateNoSubmit(navigationAction);
                      }
                      form.submitForm();
                    }}
                  />
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InsuranceStartDateForm;
