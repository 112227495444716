import { Button } from '@eg/elements/Button';
import { SaveCheckIcon } from '@eg/elements/components/Icons';
import React, { FC } from 'react';
import { SetStateHandlerArg } from '../../pages/personalData/PersonalDataPage.types';
import { TrackingClickEventData, TrackingElementPersonalData } from '../../tracking/tracking.types';
import { AnfrageTyp } from '../../types/external/personendatenerfassung/messaging/model/input/AnfrageTyp';
import { scrollToErrorByQuery } from '../ScrollToError';

interface EditableFullFormProps {
  trackClickEvent: (data: TrackingClickEventData) => void;
  setStateHandler: (newState: SetStateHandlerArg, callback?: () => void) => void;
  anfrageTyp: AnfrageTyp;
  updateTracking?: () => void;
}

export const SaveButtonPersonalForm: FC<EditableFullFormProps> = ({
  trackClickEvent,
  setStateHandler,
  anfrageTyp,
  updateTracking,
}) => {
  const onClickSave = () => {
    document.dispatchEvent(new CustomEvent('nextClicked', { detail: anfrageTyp }));
    if (updateTracking) {
      updateTracking();
    }
    trackClickEvent({ clickedElement: TrackingElementPersonalData.BUTTON_SAVE });
    setStateHandler({ pdeEventRunning: true });
    scrollToErrorByQuery('[role="alert"]');
  };

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12" style={{ textAlign: 'right' }}>
          <Button variant="secondary" iconLeft={SaveCheckIcon} onClick={onClickSave}>
            Speichern
          </Button>
        </div>
      </div>
    </div>
  );
};
