const AKTIONSNUMMER_COOKIENAME: string = 'aktionNr=';

export const extractAktionsNummerFromCookie = (): string => {
  const decodedCookieString = decodeURIComponent(document.cookie);
  const cookieList: string[] = decodedCookieString.split(';');
  const aktionsNummerCookie: string | undefined = cookieList.find(element => element.includes(AKTIONSNUMMER_COOKIENAME));
  if (aktionsNummerCookie) {
    const aktionsNummernListe: string | undefined = aktionsNummerCookie.split('=')[1];
    if (aktionsNummernListe) {
      return aktionsNummernListe.split(',')[0];
    }
  }
  return '';
};
