import { CarbonParamName } from 'src/types/AemCarbonEnv';
import { Need, RequestOfferPerson } from 'rlv-common';
import { PHONE_NUMBER_PREFIXES, preparePhoneNumberForHashing, hashData } from '@b2c-commons/utils';
import { aemAssets } from '../configuration/serviceConfiguration';
import { Storage } from '../helpers/Storage';
import { getOeNumber, getIsTiedAgent } from '../helpers/modeConfig';
import { TRACKING_VERSION } from '../releaseVersion';
import { isDevelopment } from '../constants';
import {
  TrackingAdditionalTariffOptions,
  TrackingBrand,
  TrackingBrokerModus,
  TrackingCategory,
  TrackingClickEventData,
  TrackingContent,
  TrackingElementNeedSelection,
  TrackingEnvironments,
  TrackingError,
  TrackingEvents,
  TrackingNeed,
  TrackingPageLoadData,
  TrackingPagePurpose,
  TrackingProductCombination,
  TrackingProfileInfo,
  TrackingTariffOptions,
  TrackingTarifName,
  TrackingUserAddress,
  TrackingUserAttributes,
  TrackingWindow,
} from './tracking.types';
import { createObjectDeepCopy } from './trackingFormating';

export class Tracker {
  private readonly trackingData: TrackingContent;

  private static instance: Tracker;

  public constructor() {
    const shortName = aemAssets ? aemAssets['ShortName'] : 'risikolv';

    const productGroup = aemAssets ? aemAssets['ProductGroup'] : 'RisikoLeben';

    // constant Tracking Data Values
    this.trackingData = {
      page: {
        pageInfo: {
          sysEnv: isDevelopment ? TrackingEnvironments.DEVELOP : TrackingEnvironments.PRODUCTION,
          issueDate: `risikolvOTR2022|${TRACKING_VERSION}`,
        },
        attributes: {
          displayedBrand: TrackingBrand.ERGO,
          brokerModus: TrackingBrokerModus.OFF,
        },
        category: {
          pagePurpose: TrackingPagePurpose.SALE,
          primaryCategory: TrackingCategory.RLV,
        },
      },
      product: [
        {
          productInfo: {
            productName: shortName,
          },
          category: {
            primaryCategory: productGroup,
          },
          attributes: {
            tariffOptions: {
              Rechner: TrackingTarifName.RLV,
            },
          },
        },
      ],
      transaction: {
        transactionID: '',
      },
    };
  }

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public trackPageLoad = (data?: TrackingPageLoadData) => {
    const currentPage = Storage.readItem('currentState') || 'NeedPage';

    const trackingObject = {
      ...createObjectDeepCopy(this.trackingData),
      event: TrackingEvents.PAGE_LOAD,
    };

    trackingObject.page.pageInfo.pageName = `${
      this.trackingData.product[0].productInfo.productName
    }:${aemAssets[data && data.pageName ? data.pageName : currentPage]}`;

    if (data && data.conversionType) {
      trackingObject.transaction = {
        ...trackingObject.transaction,
        attributes: {
          ...trackingObject.transaction.attributes,
          conversionType: data.conversionType,
        },
      };
    }

    if (getIsTiedAgent()) {
      const oenrNumber = getOeNumber();
      const pnrNumber = aemAssets[CarbonParamName.PNR_NUMBER]; // TODO: perhaps move to Mode config?

      trackingObject.miscellaneous = {
        agency: {
          oenrNumber,
          pnrNumber,
        },
      };
    }

    if (isDevelopment) {
      console.log(trackingObject);
    }
    this.pushTrackingData(trackingObject);
  };

  private readonly pushTrackingData = (data: TrackingContent) => {
    // init carbon data layer array if not already present
    const trackingWindow = window as unknown as TrackingWindow;
    trackingWindow.appEventData = trackingWindow.appEventData || [];

    // push tracking call

    const { tariffOptions, additionalTariffOptions, ...attributes } = data.product[0].attributes;
    if (tariffOptions) {
      const tariffOptions1 = this.parseTariffOptionsData(tariffOptions);
      attributes.tariffOptions1 = tariffOptions1;
    }
    if (additionalTariffOptions) {
      const tariffOptions2 = this.parseTariffOptionsData(additionalTariffOptions);
      attributes.tariffOptions2 = tariffOptions2;
    }
    data.product = [
      {
        attributes,
        productInfo: data.product[0].productInfo,
        category: data.product[0].category,
      },
    ];
    if (data.user) {
      data.user = [data.user[0]];
    }

    trackingWindow.appEventData.push(data);
  };

  private readonly parseTariffOptionsData = (
    data: TrackingTariffOptions | TrackingAdditionalTariffOptions,
  ) =>
    Array.from(
      Object.keys(data).filter(key => data[key] !== undefined),
      key => `${key}=${data[key]}`,
    ).join('|');

  public trackClickEvent = (data: TrackingClickEventData) => {
    const trackingObject: TrackingContent = {
      ...createObjectDeepCopy(this.trackingData),
      event: TrackingEvents.GENERAL_CLICK,
      eventdetails: { clickType: 'other', clickedElement: data.clickedElement },
    };

    if (process.env.NODE_ENV === 'development') {
      console.log(trackingObject);
    }
    this.pushTrackingData(trackingObject);
  };

  public trackErrorEvent = (error: Omit<TrackingError, 'errorFlag'>) => {
    const trackingObject: TrackingContent = {
      ...createObjectDeepCopy(this.trackingData),
      event: TrackingEvents.GENERAL_CLICK,
      eventdetails: { clickType: 'other' },
      miscellaneous: {
        errors: {
          errorFlag: true,
          ...error,
        },
      },
    };

    if (process.env.NODE_ENV === 'development') {
      console.log(trackingObject);
    }
    this.pushTrackingData(trackingObject);
  };

  public updateTariffOptions = (data: Partial<TrackingTariffOptions>) => {
    if (!this.trackingData.product[0].attributes.tariffOptions) {
      this.trackingData.product[0].attributes.tariffOptions = {
        Rechner: TrackingTarifName.RLV,
      };
    }

    this.trackingData.product[0].attributes.tariffOptions = {
      ...this.trackingData.product[0].attributes.tariffOptions,
      ...data,
    };
  };

  public updateAdditionalTariffOptions = (data: Partial<TrackingAdditionalTariffOptions>) => {
    if (!this.trackingData.product[0].attributes.additionalTariffOptions) {
      this.trackingData.product[0].attributes.additionalTariffOptions = {
        Rechner: TrackingTarifName.RLV,
      };
    }

    this.trackingData.product[0].attributes.additionalTariffOptions = {
      ...this.trackingData.product[0].attributes.additionalTariffOptions,
      ...data,
    };
  };

  public updateUserAttributes = (data: Partial<TrackingUserAttributes>) => {
    if (!this.trackingData.user) {
      this.trackingData.user = [
        {
          profile: {
            attributes: {},
          },
        },
      ];
    }
    this.trackingData.user[0].profile.attributes = {
      ...this.trackingData.user[0].profile.attributes,
      ...data,
    };
  };

  public readonly setUserContactInfo = (
    addressData: Partial<TrackingUserAddress>,
    profileInfo: Partial<TrackingProfileInfo>,
  ) => {
    if (this.trackingData.user) {
      this.trackingData.user[0].profile.address = {
        ...this.trackingData.user?.[0].profile.address,
        ...addressData,
      };
      this.trackingData.user[0].profile.profileInfo = {
        ...this.trackingData.user?.[0].profile.profileInfo,
        ...profileInfo,
      };
    } else {
      this.trackingData.user = [
        {
          profile: {
            attributes: {},
            address: {
              ...addressData,
            },
            profileInfo: {
              ...profileInfo,
            },
          },
        },
      ];
    }
  };

  public prepareUserContactInfoData(vn: Partial<RequestOfferPerson>) {
    const postalCode = vn.adresse?.plz;
    const trackingAddressData = {
      ...(Boolean(postalCode) && { postalCode }),
      line1: hashData(`${vn.adresse?.strasse}${vn.adresse?.hausnummer}`),
      city: hashData(`${vn.adresse?.ort}`),
      ...(vn.staatsangehoerigkeit && { country: hashData(vn.staatsangehoerigkeit) }),
    };

    const fullPhoneNumber =
      vn.vorwahl && vn.rufnummer && preparePhoneNumberForHashing(`${vn.vorwahl}${vn.rufnummer}`);

    const profileInfoData: TrackingProfileInfo = {
      email: hashData(`${vn.email}`),
      ...(fullPhoneNumber && {
        telephone: hashData(`${PHONE_NUMBER_PREFIXES.german}${fullPhoneNumber}`),
        telephoneE164: hashData(`${PHONE_NUMBER_PREFIXES.e164}${fullPhoneNumber}`),
      }),
      firstName: hashData(`${vn.vorname}`),
      lastName: hashData(`${vn.nachname}`),
    };

    return { trackingAddressData, profileInfoData };
  }

  public updateUserContactInfo(vn: Partial<RequestOfferPerson>) {
    const { trackingAddressData, profileInfoData } = this.prepareUserContactInfoData(vn);
    this.setUserContactInfo(trackingAddressData, profileInfoData);
  }

  public updateProductCombination = (data: TrackingProductCombination) => {
    this.trackingData.product[0].attributes.productCombination = data;
  };

  public updateCalculatedValue = (price: number) => {
    if (!this.trackingData.transaction.attributes) {
      this.trackingData.transaction.attributes = {};
    }

    this.trackingData.transaction.attributes.calculatedValue = price;
  };

  public updateTransactionTotal = (price: number) => {
    if (!this.trackingData.transaction.total) {
      this.trackingData.transaction.total = {};
    }

    this.trackingData.transaction.total.transactionTotal = price;
  };

  public updateInsuranceStartDate = (date: string) => {
    this.trackingData.product[0].attributes.insuranceStart = date;
  };

  public getTrackingNeed(need: Need) {
    let trackingNeed;
    let trackingName = TrackingElementNeedSelection.RADIOBUTTON_CONSTANT_SUM;
    switch (need) {
      case Need.KONSTANT:
        trackingNeed = TrackingNeed.CONSTANT;
        trackingName = TrackingElementNeedSelection.RADIOBUTTON_CONSTANT_SUM;
        break;
      case Need.DECREASING:
        trackingNeed = TrackingNeed.DECREASING;
        trackingName = TrackingElementNeedSelection.RADIOBUTTON_FALLING_SUM;
        break;
      default:
        break;
    }
    return { trackingNeed, trackingName };
  }

  public init = (businessId: string) => {
    this.trackingData.transaction.transactionID = businessId;
  };
}
