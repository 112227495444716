import {
  AngebotResponse,
  Beitragsvariante,
  FeeCalculationModel,
  InsuranceSettingKey,
  LivingConditionsModel,
  mapZahlweise,
  Need,
  NeedSelectionModel,
} from 'rlv-common';
import { getNettoBeitragPerYearForVariant } from '../helpers/getPaymentPerYear';
import { Storage } from '../helpers/Storage';
import { paymentIntervalData, trackingTabData } from '../pages/fee/FeePageData';
import { SmokerServerValues } from '../pages/livingConditions/forms/smokerForm/SmokerForm.types';
import { FetchedTrackingData } from '../routing/StateMachine';
import { SessionStorageKeys } from '../types/SessionStorage';
import { Tracker } from './tracker';
import {
  NetworkError,
  TrackingAdditionalTariffOptions,
  TrackingBoolean,
  TrackingContent,
  TrackingGender,
  TrackingNeed,
  TrackingSmoker,
  TrackingSmokerValues,
  TrackingTariffOptions,
  TrackingUserAttributes,
} from './tracking.types';

export const getTrackingNeed = (need?: Need) => {
  let trackingNeed;
  switch (need) {
    case Need.KONSTANT:
      trackingNeed = TrackingNeed.CONSTANT;
      break;
    case Need.DECREASING:
      trackingNeed = TrackingNeed.DECREASING;
      break;
    default:
      break;
  }
  return trackingNeed;
};

export const getTrackingBirthdate = (livingConditionsModel?: LivingConditionsModel) => {
  const userAttributes: Partial<TrackingUserAttributes> = {};

  if (!livingConditionsModel || !livingConditionsModel.birthdate) {
    return undefined;
  }

  userAttributes.birthday = livingConditionsModel.birthdate.split('-')[0];

  return userAttributes;
};

export const parseSmokerValue = (value: SmokerServerValues) => {
  const dataMapping = [
    {
      name: SmokerServerValues.SMOKER,
      value: TrackingSmokerValues.smoker,
    },
    {
      name: SmokerServerValues.NON_SMOKER_MORE_THAN_10,
      value: TrackingSmokerValues.nonSmokerTenYears,
    },
    {
      name: SmokerServerValues.NON_SMOKER_AT_LEAST_A_YEAR,
      value: TrackingSmokerValues.nonSmokerOneYear,
    },
  ];
  const parsedData = dataMapping.find(data => data.name === value);
  if (parsedData) {
    return parsedData.value;
  }
  return undefined;
};

export const mapFetchedPricingData = (
  needSelectionModel?: NeedSelectionModel,
  feeCalculationModel?: FeeCalculationModel,
) => {
  const tariffOptions: Partial<TrackingTariffOptions> = {};
  if (!needSelectionModel) {
    return undefined;
  }
  if (feeCalculationModel) {
    switch (needSelectionModel.need) {
      case Need.KONSTANT:
        tariffOptions.VersSum = feeCalculationModel.sumInsured;
        break;
      case Need.DECREASING:
        tariffOptions.VersSumEJ = feeCalculationModel.sumInsured;
        tariffOptions.VersSumLJ = feeCalculationModel.insuranceEndSum;
        break;
      default:
        break;
    }
    tariffOptions.VersLZ = feeCalculationModel.periodInYears;
  }
  return tariffOptions;
};

export const mapFetchedNeedData = (needSelectionModel?: NeedSelectionModel) => {
  const tariffOptions: Partial<TrackingTariffOptions> = {};
  if (needSelectionModel) {
    tariffOptions.Bedarfsanalyse = getTrackingNeed(needSelectionModel.need);
  }
  return tariffOptions;
};

export const mapTrackingInsuredPerson = () => {
  const userAttributes: TrackingUserAttributes = {
    insuredIsInsuredPerson:
      Storage.readItem('divergingInsuredPerson') === 'true'
        ? TrackingBoolean.NO
        : TrackingBoolean.YES,
  };

  return userAttributes;
};

export const mapTrackingEmployment = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const userAttributes: Partial<TrackingUserAttributes> = {
    jobTitle: livingConditionsModel.professionLabel,
    occupationalGroup: livingConditionsModel.employment,
  };

  return userAttributes;
};

export const mapUserAttributesSmokerValues = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const userAttributes: Partial<TrackingUserAttributes> = {
    smokerNonSmoker:
      livingConditionsModel.smoker === 'RAUCHER' ? TrackingSmoker.SMOKER : TrackingSmoker.NONSMOKER,
  };

  return userAttributes;
};

export const mapTariffOptionsSmokerValues = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const additionalTariffOptions: Partial<TrackingAdditionalTariffOptions> = {
    RauchS: parseSmokerValue(livingConditionsModel.smoker as SmokerServerValues),
  };

  return additionalTariffOptions;
};

export const mapTrackingGender = () => {
  const genderId = Storage.readItem(SessionStorageKeys.GENRE);

  const userAttributes: Partial<TrackingUserAttributes> = {};

  if (genderId === '1') {
    userAttributes.gender = TrackingGender.FEMALE;
  } else if (genderId === '2') {
    userAttributes.gender = TrackingGender.MALE;
  }

  return userAttributes;
};

export const mapTrackingHeightAndWeight = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const additionalTariffOptions: Partial<TrackingAdditionalTariffOptions> = {
    KGewicht: String(livingConditionsModel.weight),
    KGroesse: String(livingConditionsModel.height),
  };

  return additionalTariffOptions;
};

export const mapTrackingBiker = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const additionalTariffOptions: Partial<TrackingAdditionalTariffOptions> = {
    MotorradF: livingConditionsModel.biker ? TrackingBoolean.YES : TrackingBoolean.NO,
  };

  return additionalTariffOptions;
};

export const mapTrackingPropertyOwner = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  const additionalTariffOptions: Partial<TrackingAdditionalTariffOptions> = {
    SWohneig: livingConditionsModel.propertyOwner ? TrackingBoolean.YES : TrackingBoolean.NO,
  };

  return additionalTariffOptions;
};

export const mapTrackingInceptionDate = (livingConditionsModel?: LivingConditionsModel) => {
  if (!livingConditionsModel) {
    return undefined;
  }

  return livingConditionsModel.inceptionDate;
};

export const mapFeePageTrackingData = (feeCalculationModel?: FeeCalculationModel) => {
  if (!feeCalculationModel) {
    return undefined;
  }
  const paymentInterval = paymentIntervalData.find(
    interval => interval.name === feeCalculationModel.paymentMethod,
  );
  if (!paymentInterval) {
    return undefined;
  }

  const dynamic = feeCalculationModel.additionalInsuranceSettings.find(
    setting => setting.insuranceSettingKey === InsuranceSettingKey.BEITRAGSDYNAMIK,
  );

  const security = feeCalculationModel.additionalInsuranceSettings.find(
    setting => setting.insuranceSettingKey === InsuranceSettingKey.SICHERHEIT_PLUS,
  );

  const tariffOptions: Partial<TrackingTariffOptions> = {
    Zahlung: paymentInterval.trackingValue,
    Dynamik: dynamic && dynamic.insuranceSettingValue ? TrackingBoolean.YES : TrackingBoolean.NO,
    SiPlus: security && security.insuranceSettingValue ? TrackingBoolean.YES : TrackingBoolean.NO,
  };

  return tariffOptions;
};

export const fetchTrackingDataAfterFeePage = (response: AngebotResponse) => {
  if (!response.feeCalculationModel) {
    return undefined;
  }

  const variant = response.feeCalculationModel.variante
    ? response.feeCalculationModel.variante
    : Beitragsvariante.KOMFORT;

  const trackingVariant = trackingTabData.find(data => data.name === variant);
  const variantenInformation = response.varianten.find(element => element.variante === variant);

  const fetchedTrackingData: FetchedTrackingData = {
    userAttributes: {
      ...mapTrackingInsuredPerson(),
      ...getTrackingBirthdate(response.livingConditionsModel),
      ...mapTrackingGender(),
      ...mapTrackingEmployment(response.livingConditionsModel),
      ...mapUserAttributesSmokerValues(response.livingConditionsModel),
    },
    tariffOptions: {
      ...mapFetchedNeedData(response.needSelectionModel),
      ...mapFetchedPricingData(response.needSelectionModel, response.feeCalculationModel),
      ...mapFeePageTrackingData(response.feeCalculationModel),
    },
    additionalTariffOptions: {
      ...mapTariffOptionsSmokerValues(response.livingConditionsModel),
      ...mapTrackingHeightAndWeight(response.livingConditionsModel),
      ...mapTrackingBiker(response.livingConditionsModel),
      ...mapTrackingPropertyOwner(response.livingConditionsModel),
    },
    insuranceStart: mapTrackingInceptionDate(response.livingConditionsModel),
    productCombination: trackingVariant ? trackingVariant.productCombination : undefined,
    calculatedValue: variantenInformation
      ? getNettoBeitragPerYearForVariant(
          variantenInformation,
          mapZahlweise(response.feeCalculationModel.paymentMethod),
        )
      : undefined,
  };

  return fetchedTrackingData;
};

export const fetchTrackingDataAfterPersonalDataPage = (response: AngebotResponse) => {
  const fetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};
  if (!fetchedTrackingData.tariffOptions || !response.personalDataModel) {
    return fetchedTrackingData;
  }

  fetchedTrackingData.tariffOptions.BezugsrechtAend =
    response.personalDataModel.entitledPersons.length;

  const personalDataModel = response.requestOfferPersonalDataModel;
  if (personalDataModel) {
    const { profileInfoData, trackingAddressData } =
      Tracker.getInstance().prepareUserContactInfoData(personalDataModel?.vn);
    fetchedTrackingData.userContactInfo = {
      address: trackingAddressData,
      profileInfo: profileInfoData,
    };
  }

  return fetchedTrackingData;
};

export const fetchTrackingDataFeedbackPage = (response: AngebotResponse) => {
  const fetchedTrackingData = fetchTrackingDataAfterPersonalDataPage(response) || {};

  if (!response.feeCalculationModel) {
    return fetchedTrackingData;
  }

  const variant = response.feeCalculationModel.variante
    ? response.feeCalculationModel.variante
    : Beitragsvariante.KOMFORT;
  const variantenInformation = response.varianten.find(element => element.variante === variant);
  fetchedTrackingData.transactionTotal = variantenInformation
    ? getNettoBeitragPerYearForVariant(
        variantenInformation,
        mapZahlweise(response.feeCalculationModel.paymentMethod),
      )
    : undefined;

  return fetchedTrackingData;
};

export const createObjectDeepCopy = (original: TrackingContent): TrackingContent => {
  if (!original) {
    return original;
  }

  const copy = {};
  // TODO: (Octavio) this converts all to object.
  Object.keys(original).forEach(key => {
    const value = original[key];
    copy[key] = typeof value === 'object' ? createObjectDeepCopy(value) : value;
  });

  return copy as TrackingContent;
};

export const handleNetworkErrors = (error: NetworkError, tracker: Tracker, url: string): void => {
  if (error.response && error.request) {
    tracker.trackErrorEvent({
      errorMessages: ` ${error.response.statusText}: ${error.request.responseURL}:`,
      errorType: error.response.status,
      errorUrl: url,
    });
  } else {
    tracker.trackErrorEvent({
      errorMessages: error.message || '',
      errorUrl: url,
    });
  }
};

export const handleFormErrors = (error: string): void => {
  if (!error) {
    return;
  }
  Tracker.getInstance().trackErrorEvent({
    errorMessages: error,
    errorUrl: window.location.href,
    errorType: 'FormFieldError',
  });
};
