import * as React from 'react';
import { ConsentToDataProcessingData } from './ConsentToDataProcessing.types';

export const consentToDataProcessingData: ConsentToDataProcessingData = {
  titleText: (
    <>
      Information zur Verwendung Ihrer Daten und Einwilligungs- und
      Schweigepflichtent&shy;bindungs&shy;erklärung
    </>
  ),
  sections: [
    {
      subtitle:
        'Bitte lesen Sie auch die weiteren Informationen zur Datenverarbeitung bzw. Schweigepflichtentbindungserklärung',
      pdfLabel:
        'Information zur Verwendung Ihrer Daten + Einwilligung in die Verwendung von Gesundheitsdaten und Schweigepflichtentbindungserklärungen',
    },
    {
      subtitle: 'Erklärung zur Datenverarbeitung',
      checkboxIntro:
        'Ich habe die „Informationen zur Verwendung Ihrer Daten“ und zu den „Schweigepflichtentbindungserklärungen“ gelesen und',
      checkboxes: [
        {
          label: (
            <div className="consent-to-data-processing__label-group">
              <p className="consent-to-data-processing__checkbox-label">
                <b>willige ein</b>, dass die ERGO Vorsorge Lebensversicherung AG die von mir hier
                und künftig mitgeteilten Gesundheitsdaten erhebt, speichert und nutzt. Und zwar,
                soweit dies zur Anfrageprüfung zur Begründung, Durchführung oder Beendigung dieses
                Versicherungsvertrags erforderlich ist.
              </p>
              <p className="consent-to-data-processing__checkbox-label additional__sentence">
                Die Einwilligung gilt auch für die zeitlich befristete Verarbeitung Ihrer
                Gesundheitsdaten zu den genannten Zwecken, wenn der Vertrag nicht zustande kommt.
              </p>
            </div>
          ),
        },
        {
          label: (
            <>
              <p className="consent-to-data-processing__checkbox-label">
                <b>willige</b> in die <b>Weitergabe</b> meiner Gesundheitsdaten und nach § 203 StGB
                geschützten Daten an die genannten Stellen außerhalb der ERGO Vorsorge
                Lebensversicherung AG <b>ein</b> und entbinde die für die ERGO Vorsorge
                Lebensversicherung AG tätigen Personen von ihrer Schweigepflicht.
              </p>
            </>
          ),
        },
      ],
      errorMessage:
        'Bitte willigen Sie für den Fall Ihres Todes in die Erhebung Ihrer Gesundheitsdaten bei Dritten zur Leistungsprüfung bzw. einer erforderlichen erneuten Antragsprüfung ein. Ohne diese Einwilligung ist ein Antrag leider nicht möglich!',
    },
  ],
};
