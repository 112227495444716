import * as React from 'react';

import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioTileValue } from '../../../../components/RadioGroup/RadioTile/RadioTile.types';

import { RadioGroupOption } from '../../../../components/RadioGroup/RadioGroup.types';

import { SubscriptionOptionsFormDescription } from './SubscriptionOptionsForm.data';
import { SubscriptionOptionsFormProps } from './SubscriptionOptionsForm.types';

import './SubscriptionOptionsForm.css';

const SubscriptionOptionsForm = ({
  hasBeneficiaries,
  onChangeBeneficiariesVisualization
}: SubscriptionOptionsFormProps): React.ReactElement => {
  const RADIO_GROUP_OPTIONS: RadioGroupOption[] = [
    {
      title: SubscriptionOptionsFormDescription.YES,
      value: true,
      description: SubscriptionOptionsFormDescription.DESC_YES
    },
    {
      title: SubscriptionOptionsFormDescription.NO,
      value: false,
      description: SubscriptionOptionsFormDescription.DESC_NO
    }
  ];
  return (
    <div className="subscription-options-form__body__radio_tiles">
      <RadioGroup
        options={RADIO_GROUP_OPTIONS}
        name="subscriptionOption"
        selectedValue={hasBeneficiaries}
        onChangeSelection={(_, value: RadioTileValue) => {
          onChangeBeneficiariesVisualization(value as boolean);
        }}
      />
    </div>
  );
};

export default SubscriptionOptionsForm;
