import { AlertIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import { EntitledPerson, MAX_ENTITLED_PERSONS, MAX_SIZE_ENTITELMENT_PERCENTAGE } from 'rlv-common';
import AemFragment from '../../components/AemFragment/AemFragment';
import AppLoader from '../../components/AppLoader';
import Footer from '../../components/Footer';
import { Headline } from '../../components/Headline/Headline';
import InfoText from '../../components/InfoText/infoText';
import { isOnline } from '../../helpers/isOnline';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { updatePersonalPageData } from '../../services/api';
import { TrackingElementBasic, TrackingElementSubscriptions } from '../../tracking/tracking.types';
import { withTracker } from '../../tracking/withTracker';
import { AemFragmentCatalog } from '../../types/AemFragmentCatalog';
import './SubscriptionsPageContainer.css';
import {
  LinkToLawPortal,
  SubscriptionsPageContainerTexts,
} from './SubscriptionsPageContainer.data';
import {
  SubscriptionsPageContainerProps,
  SubscriptionsPageContainerState,
} from './SubscriptionsPageContainer.types';
import BeneficiaryDetails from './components/BeneficiaryDetails/BeneficiaryDetails';
import SubscriptionOptionsForm from './components/SubscriptionOptionsForm/SubscriptionOptionsForm';

class SubscriptionsPageContainer extends React.Component<
  SubscriptionsPageContainerProps,
  SubscriptionsPageContainerState
> {
  public constructor(props: SubscriptionsPageContainerProps) {
    super(props);
    this.state = {
      displaySubscriptionsForm: false,
      isTotalPercentageCompleted: false,
      isLoading: false,
    };
  }

  public componentDidMount(): void {
    this.handleInitialStateOfBeneficiaries();
    this.props.tracker.trackPageLoad({});
  }

  private handleInitialStateOfBeneficiaries(): void {
    const hasBeneficiaries = !!this.props.storeState.entitledPersons.length;

    this.setState({ displaySubscriptionsForm: hasBeneficiaries });
  }

  private handleFragment(): AemFragmentCatalog {
    if (this.state.displaySubscriptionsForm && !this.state.isTotalPercentageCompleted) {
      return AemFragmentCatalog.STEP16VAALTA;
    }
    return this.state.isTotalPercentageCompleted
      ? AemFragmentCatalog.STEP16VAALTB
      : AemFragmentCatalog.STEP16VAALTC;
  }

  private trackClickEvent(eventName: TrackingElementSubscriptions): void {
    this.props.tracker.trackClickEvent({ clickedElement: eventName });
  }

  private readonly updateEntitledPersons = async (
    entitledPersons: EntitledPerson[],
  ): Promise<void> => {
    this.setState({ isLoading: true });
    const { personalDataModel } = await updatePersonalPageData(this.props.businessId, {
      ...this.props.storeState,
      entitledPersons,
    });

    this.props.storeState.update({ entitledPersons: personalDataModel?.entitledPersons }, () =>
      this.setState({ isLoading: false }),
    );
  };

  private isAllBeneficiaryDataValid(): boolean {
    return this.getErrorMessages().length === 0;
  }

  private calculateCurrentPercentageSum() {
    return this.props.storeState.entitledPersons.reduce(
      (sum: number, b: EntitledPerson) => Number(sum + b.entitlementPercentage),
      0,
    );
  }

  private getErrorMessages(): string[] {
    const errorMessages: string[] = [];
    if (this.props.storeState.entitledPersons.length > MAX_ENTITLED_PERSONS) {
      const errorMsg = `Es dürfen maximal ${MAX_ENTITLED_PERSONS} bezugsberechtigte Personen angegeben werden`;
      errorMessages.push(errorMsg);
    }
    if (this.calculateCurrentPercentageSum() !== MAX_SIZE_ENTITELMENT_PERCENTAGE) {
      const errorMsg = 'Die Summe der vergebenen Bezugsrechte entspricht nicht 100%';
      errorMessages.push(errorMsg);
    }
    if (
      this.props.storeState.entitledPersons.find(
        (entitledPerson: EntitledPerson) =>
          !!(entitledPerson.addressValidation && entitledPerson.addressValidation.isInvalid),
      )
    ) {
      const errorMsg =
        'Bitte korrigieren Sie die Adresse der zuletzt bearbeiteten, bezugsberechtigten Person.';
      errorMessages.push(errorMsg);
    }
    return errorMessages;
  }
  public render(): React.ReactNode {
    return (
      <div className="subscriptions-page__container">
        <AppLoader show={this.state.isLoading} viewport="relative">
          <Headline text={SubscriptionsPageContainerTexts.TITLE} />
          <SubscriptionOptionsForm
            hasBeneficiaries={this.state.displaySubscriptionsForm}
            onChangeBeneficiariesVisualization={(newVal: boolean) => {
              const trackingValue = newVal
                ? TrackingElementSubscriptions.RB_BEZUGSRECHT_JA
                : TrackingElementSubscriptions.RB_REZUGSRECHT_NEIN;
              this.trackClickEvent(trackingValue);
              this.handleBeneficiariesVisualization(newVal);
            }}
          />

          {this.state.displaySubscriptionsForm && (
            <>
              {!this.props.storeState.entitledPersons && <hr className="beneficiary__separator" />}
              <BeneficiaryDetails
                entitledPersons={this.props.storeState.entitledPersons}
                addressFromInsuredPerson={{
                  street: this.props.storeState.adresse?.strasse
                    ? this.props.storeState.adresse?.strasse
                    : '',
                  streetNumber: this.props.storeState.adresse?.hausnummer
                    ? this.props.storeState.adresse?.hausnummer
                    : '',
                  zip: this.props.storeState.adresse?.plz ? this.props.storeState.adresse?.plz : '',
                  city: this.props.storeState.adresse?.ort
                    ? this.props.storeState.adresse?.ort
                    : '',
                  country: 'Deutschland',
                }}
                onUpdateEntitledPersons={this.updateEntitledPersons}
                onChangeBeneficiariesVisualization={this.handleBeneficiariesVisualization}
                onChangePercentage={this.handlePercentage}
                valueRanges={this.props.valueRanges}
                isTotalPercentageCompleted={this.state.isTotalPercentageCompleted}
                trackClickEvent={trackingElement =>
                  this.props.tracker.trackClickEvent({
                    clickedElement: trackingElement,
                  })
                }
              />
            </>
          )}
          <AemFragment name={this.handleFragment()} useVA={true} />
          {this.state.displaySubscriptionsForm && !this.isAllBeneficiaryDataValid() && (
            <div className="subscriptions-page__error-container">
              <AlertIcon width={24} height={24} className="subscriptions-page__error-message" />
              Bitte beheben Sie die Fehler bei der Erfassung der Personendaten:
              <ul>
                {this.getErrorMessages().map((element: string) => (
                  <li key={element}>{element}</li>
                ))}
              </ul>
              <br />
            </div>
          )}
          <Footer
            showNext
            showPrevious
            labelNext={SubscriptionsPageContainerTexts.NEXT_BUTTON}
            labelPrevious={SubscriptionsPageContainerTexts.BACK_BUTTON}
            disableNext={this.isNextButtonDisabled()}
            handleNext={() => {
              this.props.tracker.trackClickEvent({
                clickedElement: TrackingElementBasic.BUTTON_NEXT,
              });
              this.props.tracker.updateAdditionalTariffOptions({
                BezugrechtAend: this.props.storeState.entitledPersons.length,
              });

              if (!this.state.displaySubscriptionsForm) {
                this.props.storeState.update({ entitledPersons: [] }, () =>
                  this.props.handleAction(NavigationAction.NEXT),
                );
                return;
              }
              this.props.handleAction(NavigationAction.NAVIGATE_NO_UPDATE);
            }}
            handleAction={(navigationAction: NavigationAction) => {
              if (navigationAction === NavigationAction.BACK) {
                this.props.handleAction(NavigationAction.BACK);
                return;
              }

              if (isOnline()) {
                this.props.handleAction(NavigationAction.DIRECT_JUMP_SUMMARY);
                return;
              }

              this.props.tracker.trackClickEvent({
                clickedElement: TrackingElementBasic.BUTTON_BACK,
              });
            }}
            infoSection={
              <InfoText
                textBeforeLink={SubscriptionsPageContainerTexts.INFO_SECTION_BEFORE_LINK}
                linkText={SubscriptionsPageContainerTexts.INFO_SECTION_LINK_TEXT}
                onClick={() =>
                  this.trackClickEvent(TrackingElementSubscriptions.LNK_INFO_BEZUGSRECHT)
                }
                linkUrl={LinkToLawPortal}
              />
            }
          />
        </AppLoader>
      </div>
    );
  }

  private readonly handleBeneficiariesVisualization = (displaySubscriptionsForm: boolean): void => {
    this.setState({ displaySubscriptionsForm });
  };

  private readonly handlePercentage = (complete: boolean): void => {
    this.setState({ isTotalPercentageCompleted: complete });
  };

  private readonly isNextButtonDisabled = (): boolean => {
    if (this.state.displaySubscriptionsForm && !this.state.isTotalPercentageCompleted) {
      return true;
    }
    return false;
  };
}

export default withTracker(SubscriptionsPageContainer);
