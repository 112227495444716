import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';

export interface BikerFormProps extends SharedSubmitCallBack<boolean> {
  biker?: boolean;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (value: boolean, navigationRequest: NavigationAction) => void;
  trackClick: (biker: boolean) => void;
}

export enum BikerSelection {
  YES = 'Ja',
  NO = 'Nein',
}

export enum BikerText {
  title = 'Fahren Sie Motorrad?',
}

export enum BikerInfoText {
  title = 'Relevant sind:',
  bullet1 = 'Zweiräder, Quads oder Trikes, jeweils ab 80 ccm Hubraum',
  bullet2 = 'Halter, Fahrer, Beifahrer oder Saisonfahrer dieser Fahrzeuge',
}
