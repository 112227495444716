import { aemAssets } from '../configuration/serviceConfiguration';

export enum ViewName {
  BEDARFSANALYSE = 'NeedPage',
  BEDARFSANALYSE_KONSTANT = 'BedarfsanalyseKonstant',
  BEDARFSANALYSE_LINEAR_FALLEND = 'BedarfsanalyseLinearFallend',
  BEDARFSANALYSE_ANNUITAETISCH_FALLEND = 'BedarfsanalyseAnnuitaetischFallend',
  BASISDATEN = 'Basisdaten',
  DIVERGING_INSURED_PERSON = 'BasisdatenVpAuswahl',
  BERECHNUNGSERGEBNIS = 'Berechnungsergebnis',
  GESUNDHEITSFRAGEN_DUW1 = 'GesundheitsfragenDUW1',
  GESUNDHEITSFRAGEN_DUW2 = 'GesundheitsfragenDUW2',
  BERECHNUNGSERGEBNISNACHDUW = 'BerechnungsergebnisNachDUW',
  PERSONENDATEN = 'Personendaten',
  PRUEFENUNDSENDEN = 'PruefenUndSenden',
  ABSCHLUSSFEEDBACK = 'AbschlussFeedback',
  PERSONENDATEN_ANGEBOT = 'PersonendatenAngebot',
  ANGEBOTFEEDBACK = 'AngebotFeedback',
  SUBSCRIPTIONS = 'Bezugsrecht',
}

export function getTrackingViewName(viewName: ViewName | string | undefined): string {
  return aemAssets && viewName
    ? `${aemAssets.ShortName}:${aemAssets[viewName]}`
    : `risikolv:${viewName}`;
}
