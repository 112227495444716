import * as React from 'react';

import './RadioTile.css';
import { IRadioTile } from './RadioTile.types';

const RadioTile: IRadioTile = ({
  title,
  subtitle,
  icon,
  name,
  value,
  disabled,
  checked,
  inlineDisplay,
  handleClick
}) => {
  const hasIconNoSubtitle = icon && !subtitle;

  return (
    <label htmlFor={title}
      data-testid="radio-tile"
      className={`radio-tile__container
      ${checked ? 'radio-tile__container--checked' : ''}
      ${inlineDisplay ? 'radio-tile__container--inline-display' : ''}
      ${hasIconNoSubtitle ? 'radio-tile__container--variable-width' : ''}
      ${checked && disabled ? 'radio-tile__container--checked__disabled' : ''}
      ${!checked && disabled ? 'radio-tile__container--unchecked__disabled' : ''}
      `}
    >
      <div className="info-content">
        {icon && <span className={`info-content__icon${disabled ? ' info-content__icon--disabled' : ''}`}>{icon}</span>}

        <div className={`info-content__text${disabled ? ' info-content__text--disabled' : ''}`}>
          <span className={`info-content__text__title ${!!subtitle ? 'info-content__text__title-bold' : ''}`}>{title}</span>
          {subtitle && <span className="info-content__text__subtitle">{subtitle}</span>}
        </div>
      </div>

      <input className={`radio-tile__input${disabled ? ' radio-tile__input--disabled' : ''}`}
        id={title} name={name} type="radio" disabled={disabled} onChange={() => {
          handleClick(name, value);
        }} checked={checked} />
    </label>

  );
};

export default RadioTile;
