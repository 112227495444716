import { Message } from 'rlv-common/types';
import { Storage } from './Storage';

const styles = [
  'font-size: 12px',
  'font-family: monospace',
  'background: white',
  'display: inline-block',
  'color: blue',
  'padding: 8px 19px',
  'border: 1px dashed;',
].join(';');

export const handleMessages = (messages: Message[]): void => {
  const debugLogs = Storage.readItem('debugLogs') === 'true';

  if (debugLogs) {
    const tableMessage = messages.map(message => {
      const { level, code, message: text } = message;
      const codeName = code.split('.').pop() || '';

      return { level, code: codeName, text };
    });

    if (tableMessage) {
      console.info('%c Messages', styles);
      console.table(tableMessage);
    }
  }
};
