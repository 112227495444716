import * as React from 'react';
import { AngebotResponse, Need, ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import LivingConditionsSwitcher, {
  LivingConditionsPageData,
} from '../../../pages/livingConditions/LivingConditionsPageContainer';
import { getAngebot, updateLivingConditionsPageData } from '../../../services/api';
import { mapFetchedNeedData, mapTrackingInsuredPerson } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { createLivingConditionsPageSchema } from '../../../validation/LivingConditionsPage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const livingConditionsBirthdateState: StateDefinition<LivingConditionsPageData> = {
  name: StateName.LIVING_CONDITIONS_BIRTHDATE,
  percentage: PagePercentage.LIVING_CONDITIONS_BIRTHDATE,
  trackingViewName: ViewName.BASISDATEN,
  createValidationSchema: (valueRanges: ValueRanges) =>
    createLivingConditionsPageSchema(valueRanges),

  validInboundStates: [
    StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
    StateName.CONSTANT_SUM_INSURED,
    StateName.DECREASING_SUM_INSURED,
  ],

  transitions: [
    {
      test: action => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
    },
    {
      test: (action, data) =>
        action === NavigationAction.NEXT && data.userInput.need === Need.KONSTANT,
      newState: StateName.CONSTANT_SUM_INSURED,
    },
    {
      test: (action, data) =>
        action === NavigationAction.NEXT && data.userInput.need === Need.DECREASING,
      newState: StateName.DECREASING_SUM_INSURED,
    },
  ],

  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
      },
    };

    return {
      userInput: {
        ...response.needSelectionModel,
        ...response.livingConditionsModel,
        professionKey: response.livingConditionsModel!.professionKey
          ? response.livingConditionsModel!.professionKey
          : undefined,
        professionLabel: response.livingConditionsModel!.professionLabel
          ? response.livingConditionsModel!.professionLabel
          : '',
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },

  onExit: async (transitionDetails, inputData) => {
    let userInput = inputData.userInput;
    if (!userInput.professionKey && transitionDetails.action === NavigationAction.BACK) {
      userInput = {
        ...userInput,
        professionLabel: '',
      };
    }

    const response: AngebotResponse = await updateLivingConditionsPageData(
      inputData.businessId,
      userInput,
    );
    return { payload: { insurancePeriodAdjusted: response.insurancePeriodAdjusted } };
  },

  render: (inputData, handleAction, updateApp, _unused, updateDTMTracking) => {
    const { userInput, valueRanges, businessId } = inputData;
    return (
      <LivingConditionsSwitcher
        storeState={{
          need: userInput.need,
          birthdate: userInput.birthdate,
          update: values => updateApp(values),
        }}
        businessId={businessId}
        valueRanges={valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
