import * as React from 'react';
import './Slider.css';

import ERGOLayoutBox from '@eg/elements/LayoutBox';
import ERGONaturalLanguageForm from '@eg/elements/NaturalLanguageForm';
import ERGONaturalLanguageFormSelect from '@eg/elements/NaturalLanguageFormSelect';
import ERGOSlider from '@eg/elements/Slider';
import { addThousandsSeparator, removeThousandsSeparator } from '../../helpers/numberFormatter';

interface SliderProps {
  value: number;
  text: string;
  textInSingular?: string;
  min?: number;
  max?: number;
  step?: number;
  onChangeValue: (newValue: number) => void;
}

const range = {
  min: 0,
  max: 100,
  step: 1
};

/**
 * Slider component
 *
 * @param value Current value that should be displayed in the input and the slider
 * @param text Name of the elements that are managed by the slider
 * @param textInSingular [optional] Singular form of the elements managed by the slider
 * @param min [optional] Min range of the slider. Default is 0
 * @param max [optional] Max range of the slider. Default is 100
 * @param step [optional] Defines the amount the value should increment on dragging the slider. Default is 1
 * @param onChangeValue (event) Callback function that receives a number as a parameter
 */
const Slider = ({ value, text, textInSingular, min = range.min, max = range.max, step = range.step, onChangeValue }: SliderProps) => {
  const changeValue = (newValue: number) => {
    if (isNaN(newValue)) {
      return;
    }
    onChangeValue(newValue);
  };

  const getCustomText = (currentValue: number): string => currentValue === 1 && textInSingular ? textInSingular : text;

  if (min > max) {
    throw new Error('You cannot set a min value bigger that the max one [default = 100]');
  }

  return (
    <div className="slider__container" data-testid="slider">
      <ERGONaturalLanguageForm>
        <ERGOLayoutBox flex={[1, 0]} direction="column">
          <>
            <ERGONaturalLanguageFormSelect
              label={text}
              aria-hidden
              value={addThousandsSeparator(value.toString())}
              onChange={(newValue: string) => changeValue(removeThousandsSeparator(newValue))}
            />
            <span className="slider__input-text" data-testid="input-text">{getCustomText(value)}</span>
          </>
          <ERGOSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(newValue: number) => changeValue(newValue)}
            className="slider__bar"
          />
        </ERGOLayoutBox>
        <div className="slider__footer">
          <span className="slider__edge-text" data-testid="slider__edge-text-1">{addThousandsSeparator(min.toString())} {getCustomText(min)}</span>
          <span className="slider__edge-text" data-testid="slider__edge-text-2">{addThousandsSeparator(max.toString())} {text}</span>
        </div>
      </ERGONaturalLanguageForm>
    </div>
  );
};

export default Slider;
