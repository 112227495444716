import * as React from 'react';

import FormTemplate from '../../../../components/FormComponents/FormTemplate/FormTemplate';
import Slider from '../../../../components/Slider/Slider';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { usePreviousAndTrackError } from '../../../../hooks/usePreviousAndTrackError/usePreviousAndTrackError';
import { FormInfo } from '../../../../types/FormTemplate.types';

import './SliderForm.css';

interface SliderConfig {
  min: number | undefined;
  max: number | undefined;
  step: number | undefined;
  text: string;
  trackerUpdate?: (value: number) => void;
  textInSingular?: string;
}

export interface SliderFormProps<T> extends FormInfo<T> {
  componentFieldName: string;
  sliderConfig: SliderConfig;
  onHandleSliderFormActions: (value: T, navAction: NavigationAction) => void;
  handleNavigateNoSubmitAction: (action: NavigationAction) => void;
}

export const SliderForm = <T,>({
  componentFieldName,
  sliderConfig,
  templateLayout,
  formValues,
  validationSchema,
  onHandleSliderFormActions,
  handleNavigateNoSubmitAction,
}: SliderFormProps<T>): React.ReactElement => {
  const { compareErrors } = usePreviousAndTrackError({ sliderError: '' });

  return (
    <div className="container">
      <FormTemplate<T>
        templateLayout={templateLayout}
        formValues={formValues}
        validationSchema={validationSchema}
        handleOnSubmitAction={onHandleSliderFormActions}
        handleNavigateNoSubmitAction={handleNavigateNoSubmitAction}
        render={[
          {
            name: componentFieldName,
            component: (form, field) => {
              compareErrors('sliderError', form.errors[field.name]);
              return (
                <>
                  <Slider
                    value={field.value}
                    text={sliderConfig.text}
                    textInSingular={sliderConfig.textInSingular}
                    min={sliderConfig.min}
                    max={sliderConfig.max}
                    step={sliderConfig.step}
                    onChangeValue={newValue => {
                      if (sliderConfig.trackerUpdate) {
                        sliderConfig.trackerUpdate(newValue);
                      }
                      form.setFieldValue(field.name, newValue);
                    }}
                  />
                  {form.errors[field.name] && (
                    <p className="error-message">{form.errors[field.name]}</p>
                  )}
                </>
              );
            },
          },
        ]}
      />
    </div>
  );
};
