import { object, string as yupString } from 'yup';
import { TextValue, ValueRanges } from 'rlv-common/types';

export const createFeePageSchema = (valueRanges: ValueRanges) =>
  object().shape({
    variante: yupString().required('Bitte wählen Sie eine Variante aus'),
    paymentMethod: yupString()
      .required('Bitte wählen Sie eien Zahlweise aus.')
      .test(
        'isValid',
        'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
        value =>
          !!valueRanges.paymentMethod.possibleValues.find(
            (entry: TextValue) => entry.key === value,
          ),
      ),
  });
