import { DiamondIcon } from '@eg/elements/components/Icons';
import { Beitragsvariante } from 'rlv-common';
import { TrackingElementFeePage } from '../../../tracking/tracking.types';
import {
  BenefitProps,
  RadioTabButtonDataProps,
  RadioTabContentDataProps
} from './RadioTab.types';
import {
  AFTER_INSURANCE_TOOLTIP,
  BASIC_AFTER_INSURANCE_TOOLTIP,
  EARLY_PAYMENT_TOOLTIP,
  INSTANT_HELP_TOOLTIP,
  MEDICAL_OPINION_TOOLTIP
} from './RadoTabTooltips';

export const RadioTabButtonData: RadioTabButtonDataProps[] = [
  {
    variant: Beitragsvariante.PREMIUM,
    icon: DiamondIcon
  },
  {
    variant: Beitragsvariante.KOMFORT,
    highlightText: 'Bestseller'
  },
  {
    variant: Beitragsvariante.BASIS,
    unavailableForSmoker: true
  }
];

const basicBenefitData: BenefitProps[] = [
  {
    title: 'Erhöhung der Summe ohne erneute Gesundheitsprüfung',
    tooltip: BASIC_AFTER_INSURANCE_TOOLTIP,
    trackingElement: TrackingElementFeePage.INFOICON_BASIC_AFTER_INSURANCE
  }
];

const comfortBenefitData: BenefitProps[] = [
  {
    title: 'Erweiterte Erhöhung der Summe ohne erneute Gesundheitsprüfung',
    tooltip: AFTER_INSURANCE_TOOLTIP,
    trackingElement: TrackingElementFeePage.INFOICON_AFTER_INSURANCE
  }
];

const premiumBenefitData: BenefitProps[] = [
  {
    title: 'Vorgezogene Todesfallleistung',
    tooltip: EARLY_PAYMENT_TOOLTIP,
    trackingElement: TrackingElementFeePage.INFOICON_EARLY_PAYMENT
  },
  {
    title: 'Service: Medizinische Zweitmeinung',
    tooltip: MEDICAL_OPINION_TOOLTIP,
    trackingElement: TrackingElementFeePage.INFOICON_MEDICAL_OPINION
  },
  {
    title: 'Soforthilfe für Angehörige',
    tooltip: INSTANT_HELP_TOOLTIP,
    trackingElement: TrackingElementFeePage.INFOICON_INSTANT_HELP
  }
];

export const RadioTabContentData: RadioTabContentDataProps[] = [
  {
    variant: Beitragsvariante.PREMIUM,
    title: ' - maximale Absicherung mit vielen Extras',
    benefitsData: comfortBenefitData.concat(premiumBenefitData)
  },
  {
    variant: Beitragsvariante.KOMFORT,
    title: ' - leistungsstarker Bestseller',
    benefitsData: comfortBenefitData
  },
  {
    variant: Beitragsvariante.BASIS,
    title: ' – preisgünstige Absicherung',
    benefitsData: basicBenefitData
  }
];
