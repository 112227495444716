import { Card } from '@eg/elements/Card';
import { MessageBox } from '@eg/elements/MessageBox';
import { AlertIcon } from '@eg/elements/components/Icons';
import React from 'react';
import { Nationality, RequestOfferPersonalDataModel, parseDate } from 'rlv-common';
import { CorrectedAddress } from 'rlv-common/types/CorrectedAddress';
import { isDivergingInsuredPerson, scrollToTop } from '../../Helper';
import AppLoader from '../../components/AppLoader';
import Footer from '../../components/Footer';
import { DataSummary } from '../../components/PersonalDataPage/DataSummary';
import { SaveButtonPersonalForm } from '../../components/PersonalDataPage/SaveButtonPersonalForm';
import { scrollToErrorByQuery } from '../../components/ScrollToError';
import { getServiceConfiguration } from '../../configuration/serviceConfiguration';
import { createScriptError } from '../../helpers/loadingScriptErrorHelper';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import { TrackingConversionTypes, TrackingElementBasic } from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { Configuration } from '../../types/Configuration';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { AnfrageTyp } from '../../types/external/personendatenerfassung/messaging/model/input/AnfrageTyp';
import { PersonendatenInput } from '../../types/external/personendatenerfassung/messaging/model/input/PersonendatenInput';
import { VnInput } from '../../types/external/personendatenerfassung/messaging/model/input/VnInput';
import { VpInput } from '../../types/external/personendatenerfassung/messaging/model/input/VpInput';
import {
  FinishedPersonendatenOutput,
  PersonendatenOutput,
} from '../../types/external/personendatenerfassung/messaging/model/output/PersonendatenOutput';
import './RequestOfferPersonalDataPage.css';

export interface RequestOfferPageData
  extends RequestOfferPersonalDataModel,
    StoreStateUpdater<RequestOfferPageData> {
  oeNumber?: string;
  pageExitNotAllowed?: boolean;
  divergingInsuredPerson: boolean;
  nationalities?: Nationality[];
  showMeldung: boolean;
}

interface RequestOfferPersonalDataPageProps
  extends PagePropsWithValues<RequestOfferPageData>,
    InjectedTrackerProps {
  scriptLoaded: Promise<void>;
  configuration: Configuration;
  businessId: string;
  onError: (e: Error) => void;
}

interface RequestOfferPersonalDataPageState {
  isLoading: boolean;
  isEmailOptionalValid?: boolean;
  isEmailMandatoryValid?: boolean;
  showGlobalErrorMessages: boolean;
  isAddressInvalidInPds?: boolean;
  isAddressCorrectedInPds?: boolean;
  pdeEventRunning: boolean;
  pdeValid: boolean;
  isEditable: boolean;
}

export class RequestOfferPersonalDataPage extends React.Component<
  RequestOfferPersonalDataPageProps,
  RequestOfferPersonalDataPageState
> {
  public constructor(props: RequestOfferPersonalDataPageProps) {
    super(props);
    this.state = {
      isLoading: false,
      showGlobalErrorMessages: false,
      isAddressInvalidInPds: false,
      pdeEventRunning: false,
      pdeValid: !!props.storeState.vn.pdeId,
      isEditable: true, // comment MF: When calling (also reloading) always go into editable mode,
      // so that the overview page is not displayed even though the address is still wrong
    };
    this.setStateHandler = this.setStateHandler.bind(this);
  }

  public setIsEditable = () => {
    this.setState(
      {
        isEditable: true,
      },
      () => this.renderPDE(),
    );
  };

  public setStateHandler(
    newState: Partial<RequestOfferPersonalDataPageState>,
    callback?: () => void,
  ) {
    this.setState({ ...this.state, ...newState }, callback ? () => callback() : undefined);
  }

  public onError = (e: Error) => {
    this.props.onError(e);
  };

  public onInvalidAddress = (isAddressInvalid: boolean) => {
    this.setState({ isAddressInvalidInPds: isAddressInvalid });
    this.props.storeState.update({ pageExitNotAllowed: isAddressInvalid });
    scrollToErrorByQuery('[role="alert"]');
  };

  public onCorrectedAddress = (correctedAddresses: CorrectedAddress[]) => {
    correctedAddresses.forEach(correctedAddress => {
      if (correctedAddress.pdeId === this.props.storeState.vn.pdeId) {
        this.props.storeState.update({
          vn: {
            ...this.props.storeState.vn,
            adresse: {
              strasse: correctedAddress.strasse,
              hausnummer: correctedAddress.hausnummer,
              plz: correctedAddress.plz,
              ort: correctedAddress.ort,
            },
          },
        });
      }
      if (
        this.props.storeState.vp.pdeId &&
        correctedAddress.pdeId === this.props.storeState.vp.pdeId
      ) {
        this.props.storeState.update({
          vp: {
            ...this.props.storeState.vp,
            adresse: {
              strasse: correctedAddress.strasse,
              hausnummer: correctedAddress.hausnummer,
              plz: correctedAddress.plz,
              ort: correctedAddress.ort,
            },
          },
        });
      }
    });
  };

  public onChangedPersonendaten = (output: PersonendatenOutput) => {
    this.setState({
      pdeValid: output.isAbschlussMoeglich,
      pdeEventRunning: false,
    });
    this.props.storeState.update({
      vn: {
        ...output.vn,
        birthdate: this.props.storeState.vn.birthdate
          ? this.props.storeState.vn.birthdate
          : this.props.storeState.vp.birthdate,
        role: output.vn.role ? output.vn.role : '',
        pdeId: output.vn.id,
      },
    });
    if (this.props.storeState.divergingInsuredPerson && output.vps && output.vps.length > 0) {
      this.props.storeState.update({
        vp: {
          ...output.vps[0],
          birthdate: this.props.storeState.vp.birthdate,
          role: output.vps[0].role ? output.vps[0].role : '',
          pdeId: output.vps[0].id,
        },
      });
    }
  };

  public onFinishedPersonendaten = async (output: FinishedPersonendatenOutput) => {
    this.setState({
      isEmailOptionalValid: output.isEmailOptionalValid,
      isEmailMandatoryValid: output.isEmailMandatoryValid,
      isAddressCorrectedInPds: output.isAddressCorrected || false,
    });

    this.closePde();
  };

  public async componentDidMount() {
    scrollToTop();
    await this.initPde();
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.PERSONENDATEN_ANGEBOT });
    this.props.tracker.trackPageLoad({
      conversionType: TrackingConversionTypes.SALE_FUNNEL_PERSONAL_DATA,
    });
  }

  public componentWillUnmount() {
    // Wenn die PDE an dieser Stelle noch da ist, dann unmounten
    if (document.getElementById('root-personendaten')) {
      this.closePde();
    }
  }

  public render() {
    const cardLabel = isDivergingInsuredPerson() ? '' : 'Versicherte Person';

    return (
      <div className="personal-data-page" data-component-id="RequestOfferPage">
        <AppLoader show={this.state.isLoading} viewport="relative">
          <h3>Personendatenerfassung</h3>
          <br />
          <div>
            {this.state.isEditable && (
              <Card label={cardLabel} accentColor="#737373">
                <div id="root-personendaten" />

                <div className="esc_grid">
                  <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-12" style={{ textAlign: 'right' }}>
                      <SaveButtonPersonalForm
                        trackClickEvent={this.props.tracker.trackClickEvent}
                        setStateHandler={this.setStateHandler}
                        anfrageTyp={AnfrageTyp.ANGEBOT}
                        updateTracking={() =>
                          this.props.tracker.updateUserContactInfo(this.props.storeState.vn)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Card>
            )}
            {this.renderPersonendatenOverview()}
            {!this.state.isEditable && scrollToTop()}
            <div id="annotation-text" />
            {this.renderShowGlobalErrorMessages()}
            {this.renderFooter()}
          </div>
        </AppLoader>
      </div>
    );
  }

  private async initPde() {
    await this.props.scriptLoaded
      .then(() => {
        if (!this.props.configuration || !this.props.configuration.theme) {
          throw new Error(
            'The RequestOfferPersonalDataPage needs at least a theme! Check your configuration!',
          );
        }
        this.renderPDE();
      })
      .catch((error: Event) => {
        this.props.onError(createScriptError(error));
      });
  }

  public renderPDE() {
    document.dispatchEvent(
      new CustomEvent('renderPersonendaten', {
        detail: {
          callbacks: {
            onChangedPersonendaten: this.onChangedPersonendaten,
            onError: this.onError,
            onFinishedPersonendaten: this.onFinishedPersonendaten,
            onCorrectedAddress: this.onCorrectedAddress,
            onInvalidAddress: this.onInvalidAddress,
          },
          theme: this.props.configuration.theme,
          apiRootUrl: getServiceConfiguration().pde.baseUrl,
          creditor: {
            name: '',
          },
          angebotsId: this.props.businessId,
          vn: this.createVn(this.props.storeState),
          vps: this.createVPs(this.props.storeState),

          hinweisBezugsrecht: false,
          disableEwe: true,
          addressValidation: true,
        } as PersonendatenInput,
      }),
    );
  }

  private closePde() {
    document.dispatchEvent(new CustomEvent('unmountPersonendaten', {}));
    this.setState({
      showGlobalErrorMessages: false,
      pdeEventRunning: false,
      isEditable: false,
    });
    scrollToErrorByQuery('[data-component-id="vp-view-card"]');
  }

  private createVn(userInput: RequestOfferPageData): VnInput {
    return {
      ...userInput.vn,
      id: userInput.vn.pdeId,
      geburtsdatum: this.props.storeState.divergingInsuredPerson
        ? parseDate(userInput.vn.birthdate)
        : parseDate(userInput.vp.birthdate),
      einverstaendnisEmailWerbung: false,
      role: userInput.vn.role,
      isEMailOptional: false,
      showGeburtsort: true,
      showEmailConfirmationTooltip: false,
      showStaatsangehoerigkeit: true,
      geschlecht: userInput.vn.geschlecht,
      isSexReadOnly: !!userInput.vn.geschlecht,
    };
  }

  private createVPs(userInput: RequestOfferPageData): VpInput[] {
    const result: VpInput[] = [];
    if (this.props.storeState.divergingInsuredPerson) {
      result.push({
        ...userInput.vp,
        id: userInput.vp.pdeId,
        role: userInput.vp.role,
        isGWGRequired: true,
        geburtsdatum: parseDate(userInput.vp.birthdate),
      });
    }
    return result;
  }

  private getErrorMessages(): string[] {
    const errorMessages: string[] = [];
    // if (!this.state.isEmailMandatoryValid) {
    //   errorMessages.push('Bitte geben Sie ein gültige E-mail address ein.');
    // }
    return errorMessages;
  }

  private renderPersonendatenOverview() {
    return (
      <>
        {!this.state.isEditable && (
          <Card
            label={isDivergingInsuredPerson() ? 'Versicherungsnehmer' : 'Versicherte Person'}
            accentColor="#737373"
          >
            <DataSummary
              isAddressCorrectedInPds={!!this.state.isAddressCorrectedInPds}
              storeState={{
                ...this.props.storeState.vn,
                nationalities: this.props.storeState.nationalities,
              }}
              renderPDE={this.renderPDE}
              setStateHandler={this.setStateHandler}
            />
          </Card>
        )}
        {!this.state.isEditable && isDivergingInsuredPerson() && (
          <Card label="Versicherte Person" accentColor="#737373">
            <DataSummary
              isAddressCorrectedInPds={!!this.state.isAddressCorrectedInPds}
              storeState={{
                ...this.props.storeState.vp,
                nationalities: this.props.storeState.nationalities,
              }}
              renderPDE={this.renderPDE}
              setStateHandler={this.setStateHandler}
            />
          </Card>
        )}
      </>
    );
  }

  private renderShowGlobalErrorMessages() {
    // not in use, evaluate to remove it, analyze this ticket: KL-99291
    return (
      this.state.showGlobalErrorMessages &&
      !!this.getErrorMessages().length && (
        <div style={{ color: '#8e0038', fontSize: '14px' }}>
          <AlertIcon width={24} height={24} style={{ position: 'relative', top: '5px' }} /> Bitte
          beheben Sie die Fehler beim Absenden des Angebots:
          <ul>
            {this.getErrorMessages().map(element => (
              <li key={element}>{element}</li>
            ))}
          </ul>
          <br />
        </div>
      )
    );
  }

  private isAllPdePageDataValid(): boolean {
    return this.getErrorMessages().length === 0;
  }

  private renderFooter() {
    return this.state.isEditable ? (
      <Footer
        showNext={true}
        showPrevious={true}
        handleAction={this.props.handleAction}
        handleBack={() => {
          if (!this.state.isAddressInvalidInPds && this.isAllPdePageDataValid()) {
            document.dispatchEvent(
              new CustomEvent('nextClicked', {
                detail: 'ANGEBOT',
              }),
            );
            this.setState({
              pdeEventRunning: true,
              isLoading: true,
            });
            this.props.handleAction(NavigationAction.BACK);
          }
        }}
        handleNext={() => {
          if (!this.isAllPdePageDataValid()) {
            return;
          }
          const { vn } = this.props.storeState;
          this.props.tracker.updateUserContactInfo(vn);
          this.props.tracker.trackClickEvent({
            clickedElement: TrackingElementBasic.BUTTON_NEXT,
          });
          document.dispatchEvent(
            new CustomEvent('nextClicked', {
              detail: 'ANGEBOT',
            }),
          );
          this.setState({
            pdeEventRunning: true,
            showGlobalErrorMessages: true,
          });
          scrollToErrorByQuery('[role="alert"]');
        }}
      />
    ) : (
      <>
        {this.props.storeState.showMeldung && (
          <MessageBox type="error">
            Online können wir Ihnen den Tarif leider nicht anbieten. Bitte rufen Sie uns an -
            natürlich gebührenfrei!
          </MessageBox>
        )}
        <Footer
          showPrevious={true}
          showNext={true}
          handleAction={this.props.handleAction}
          handleBack={() => {
            this.setState(
              {
                isEditable: true,
              },
              () => this.renderPDE(),
            );
            this.props.storeState.update({ showMeldung: false });
          }}
          handleNext={() => {
            if (this.isAllPdePageDataValid()) {
              this.props.handleAction(NavigationAction.NEXT);
            }
          }}
        />
      </>
    );
  }
}

export default withTracker(RequestOfferPersonalDataPage);
