import { ValueRanges } from 'rlv-common/types';
import { number as yupNumber, object, ObjectSchema, ref, Schema } from 'yup';
import { EURO_WITHOUT_CENT, formatCurrency } from '../helpers/formattingHelper';
import { getCommonValidationParameters } from './CommonValidations';
require('./validation.ts'); // required for CustomMixedSchema to work

export function decreasingSumInsuredValidation(valueRanges: ValueRanges): Schema<any> {
  return createDecreasingNeedSchema(valueRanges)
    .concat(sumInsuredValidator(valueRanges))
    .concat(insuranceEndSumValidator(valueRanges))
    .concat(yearsTermValidator(valueRanges));
}

const validateIncrementBy = (value: number, increment: number | undefined) => {
  if (increment) {
    return value % increment === 0;
  }
  return true;
};

export const createDecreasingNeedSchema = (valueRanges: ValueRanges): Schema<any> => {
  const STEP_RANGE_SUM_INSURED = 1000;
  const insuranceEndSum = valueRanges.insuranceEndSum;
  const currencyFormatter = formatCurrency(EURO_WITHOUT_CENT);
  const insuranceEndSumMinMaxError = `Die Versicherungssumme zu Beginn des letzten Jahres muss mindestens
  ${currencyFormatter.format(
    insuranceEndSum.min,
  )} und darf maximal die Versicherungssumme zu Beginn des ersten Jahres sein.`;

  return getCommonValidationParameters(valueRanges).concat(
    object().shape({
      insuranceEndSum: (yupNumber() as any)
        .validateNumber()
        .required('Bitte geben Sie die Versicherungssumme zum Ende des letzten Vertragsjahres ein.')
        .isSmallerThan(
          ref('sumInsured'),
          'Bitte beachten Sie, dass die Summe zum Versicherungsende kleiner sein muss, als die Summe zum Versicherungsbeginn.',
          STEP_RANGE_SUM_INSURED,
        )
        .min(insuranceEndSum.min, insuranceEndSumMinMaxError)
        .test(
          'isFullThousandEuro',
          'Es können nur ganze Tausend Eurobeträge als Versicherungssumme zum Ende des letzten Vertragsjahres eingegeben werden.',
          (value: number) => value % STEP_RANGE_SUM_INSURED === 0,
        ),
    }),
  );
};

export const sumInsuredValidator = (valueRanges: ValueRanges): ObjectSchema => {
  const {
    insuranceSum: { increment, max, min },
  } = valueRanges;

  // TODO: Should be checked because there is a conflict between the User Story and the values that come from the server
  const OUT_OF_RANGE_MSG = `Die Todesfallleistung zu Beginn muss zwischen ${min.toLocaleString(
    'de-DE',
  )} und ${max.toLocaleString('de-DE')} Euro (in ${
    increment ? increment.toLocaleString('de-DE') : ''
  }er Schritten) liegen.`;

  return object().shape({
    sumInsured: yupNumber()
      .required('Darf nicht leer sein')
      .min(min, OUT_OF_RANGE_MSG)
      .max(max, OUT_OF_RANGE_MSG)
      .test('Multiple', 'Muss ein Vielfaches von 5.000 sein', value =>
        validateIncrementBy(value, increment),
      ),
  });
};

export const yearsTermValidator = (valueRanges: ValueRanges) => {
  const {
    insuranceDuration: { max, min },
  } = valueRanges;

  const OUT_OF_RANGE_MSG = `Die Laufzeit beträgt mindestens ${min}, maximal ${max} Jahre. Bitte korrigieren Sie die Angabe.`;

  return object().shape({
    periodInYears: yupNumber()
      .required('Darf nicht leer sein.')
      .min(min, OUT_OF_RANGE_MSG)
      .max(max, OUT_OF_RANGE_MSG),
  });
};

export const insuranceEndSumValidator = (valueRanges: ValueRanges) => {
  const {
    insuranceEndSum: { increment, max, min },
  } = valueRanges;

  const OUT_OF_RANGE_MSG = `Die Versicherungssumme zu Beginn des letzten Jahres muss mindestens ${min.toLocaleString(
    'de-DE',
  )} € und darf maximal die Versicherungssumme zu Beginn des ersten Jahres sein ( ${max.toLocaleString(
    'de-DE',
  )} € ).`;

  return object().shape({
    insuranceEndSum: yupNumber()
      .required('Darf nicht leer sein.')
      .min(min, OUT_OF_RANGE_MSG)
      .max(max, OUT_OF_RANGE_MSG)
      .test('Multiple', 'Muss ein Vielfaches von 5.000 sein', value =>
        validateIncrementBy(value, increment),
      ),
  });
};
