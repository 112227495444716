import TooltipIcon from '@eg/elements/TooltipIcon';
import * as React from 'react';
import './Headline.css';
import { HeadlineProps } from './Headline.types';

export const Headline = ({ text, subline, tooltip, tracking }: HeadlineProps) => <div className="intro">
  <div className={`intro__headline ${subline ? 'intro__headline--with__subline' : 'intro__headline'}`}>{text}</div>
  {subline && <div className="intro__subline">{subline}</div>}
  {tooltip &&
      <TooltipIcon
        isModal
        className="subline-tooltip-icon"
        onToggledOpen={isOpen => {
          if (isOpen && tracking) {
            tracking();
          }
        }}
      >
        {tooltip}
      </TooltipIcon>
  }
</div>;
