import * as React from 'react';
import { Need, ValueRanges } from 'rlv-common';
import { ViewName } from '../../../helpers/ViewName';
import NeedsPageContainer from '../../../pages/needs/NeedsPageContainer';
import { NeedPageData } from '../../../pages/needs/Needs.types';
import { PagePercentage } from '../../../types/PagePercentage';

import {
  getAngebot,
  updateConstantNeedBlockData,
  updateDecreasingNeedBlockData,
  updateNeedPageData,
} from '../../../services/api';
import { createNeedPageSchema } from '../../../validation/NeedPage';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const needPageState: StateDefinition<NeedPageData> = {
  name: StateName.NEED_PAGE,
  percentage: PagePercentage.NEED_PAGE,
  trackingViewName: ViewName.BEDARFSANALYSE,
  createValidationSchema: (valueRanges: ValueRanges) => createNeedPageSchema(valueRanges),
  validInboundStates: [
    StateName.LIVING_CONDITIONS_PAGE,
    StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],
  transitions: [
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
    },
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);

    return {
      userInput: response.needSelectionModel,
      valueRanges: response.valueRanges,
    };
  },
  onExit: async (transitionDetails, inputData) => {
    await updateNeedPageData(inputData.businessId, inputData.userInput);
    if (inputData.userInput.need === Need.KONSTANT) {
      await updateConstantNeedBlockData(inputData.businessId, inputData.userInput);
    } else if (inputData.userInput.need === Need.DECREASING) {
      await updateDecreasingNeedBlockData(inputData.businessId, inputData.userInput);
    } else {
      throw new Error(`Error in NeedPage, illegal value for need: ${inputData.userInput.need}`);
    }

    return {};
  },
  render: (inputData, handleAction, updateApp, _unused, updateDTMTracking) => {
    const userInput: NeedPageData = inputData.userInput;

    return (
      <NeedsPageContainer
        businessId={inputData.businessId}
        inputData={inputData}
        updateApp={updateApp}
        storeState={{
          need: userInput.need,
          update: values => updateApp(values),
        }}
        valueRanges={inputData.valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
