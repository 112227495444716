import { InsuranceSettingKey } from './ApiRequest';
import { IsoDateString } from './IsoDateString';
import { Beitragsvariante } from './Variante';

export const MAX_ENTITLED_PERSONS = 3;
export const MAX_SIZE_ENTITELMENT_PERCENTAGE = 100;
export const MIN_SIZE_ENTITLEMENT_PERCENTAGE = 1;

export enum Need {
  KONSTANT = 'KONSTANT',
  DECREASING = 'LINEAR_FALLEND',
  ANNUITY = 'ANNUITAETISCH_FALLEND',
}

export enum Relationship {
  OTHER = 'SONSTIGES_VERHAELTNIS',
}

export interface ValueRanges {
  ageAtEntry: NumericRange;
  ageAtExpiry: NumericRange;
  employments: ValueList<TextValue>;
  fatalityProgression: ValueList<string>;
  insuranceDuration: NumericRange;
  insuranceEndSum: NumericRange;
  insuranceSettings: InsuranceSettingsOption[];
  insuranceStart: ValueList<IsoDateString>;
  insuranceSum: NumericRange;
  loanRate: NumericRange;
  paymentMethod: ValueList<TextValue>;
  relationships: ValueList<TextValue>;
  repaymentFreePeriodInYears: NumericRange;
  smoker: ValueList<TextValue>;
  tariffType: ValueList<TextValue>;
}

export interface ValueList<T> {
  defaultValue: T | undefined;
  possibleValues: T[];
}

export interface TextValue {
  key: string;
  text: string;
}

export interface NumericRange {
  defaultValue: number | undefined;
  increment: number | undefined;
  max: number;
  min: number;
}

export interface InsuranceSettingsOption {
  description: string;
  key: InsuranceSettingKey;
  variantSettings: InsuranceSettingsOptionVariantSetting[];
}

export interface InsuranceSettingsOptionVariantSetting {
  available: boolean;
  included: boolean;
  variant: Beitragsvariante;
}
