const defLocale = 'de-DE';
const defOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const convertToNumber = (numStr: string): number | undefined => {
  const errorNumber = undefined;

  const numberHandling = () => {
    // case is not a number
    if (!numStr.includes('.') && !numStr.includes(',')) {
      return errorNumber;
    }

    // case european format
    const decimals = 2;
    const isEuroFormat = numStr.includes('.') && (numStr.split('.')[1].length > decimals);
    if (isEuroFormat) {
      const numberFormat = numStr.replace('.', '').replace(',', '.');
      return Number.isNaN(+numberFormat) ? errorNumber : +numberFormat;
    }

    // case english format
    const numberFormatElse = numStr.replace(',', '');
    return Number.isNaN(+numberFormatElse) ? errorNumber : +numberFormatElse;
  };

  return Number.isNaN(+numStr) ? numberHandling() : +numStr;
};

// european format (12.345,67)
export const numberWithDecimals = (numStr: string, locale: string = defLocale): string | undefined => {
  const value = convertToNumber(numStr);
  return value && value.toLocaleString(locale, { ...defOptions }) || undefined;
};

export const addThousandsSeparator = (numStr: string): string => numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const removeThousandsSeparator = (numStr: string): number => Number(numStr.replace(/[,.]/g, ''));
