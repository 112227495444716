import Button from '@eg/elements/Button';
import Modal from '@eg/elements/components/Modal';
import * as React from 'react';
import {
  Beitragsvariante,
  DuwDisplayModel,
  FeeCalculationModel,
  ValueRanges,
  Variante,
} from 'rlv-common';
import { scrollToTop } from '../../Helper';
import { Storage } from '../../helpers/Storage';
import {
  getNettoBeitragForVariant,
  getVarianteFromPossibleValues,
} from '../../helpers/variant.utils';
import { NavigationAction, StateName } from '../../routing/StateMachineTypes';
import { updateDuwData } from '../../services/api';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { Configuration } from '../../types/Configuration';
import { DuwResult } from '../../types/external/duw/DuwResult';
import { PageContainer } from '../../types/PageContainer';
import { PageProps, StoreStateUpdater } from '../../types/PageProps';
import { FeePageData } from '../fee/FeePage.types';
import DuwAssessment from './DuwAssessment/DuwAssessment';
import { DuwStatus } from './DuwAssessment/DuwAssessment.types';
import { DuwDisclosure } from './duwDisclosure/DuwDisclosure';
import DuwQuestionnaire from './DuwQuestionnaire/DuwQuestionnaire';

export interface DuwState {
  showModal: boolean;
}

type DuwPageType = DuwDisplayModel &
  Pick<FeeCalculationModel, 'paymentMethod'> &
  Pick<FeePageData, 'valueRanges'>;
export interface DuwPageData extends DuwPageType, StoreStateUpdater<DuwPageData> {
  varianten?: Variante[];
  duwProgress?: number;
  duwResult?: DuwStatus;
  paymentMethod: string;
  selectedVariant?: Variante;
  valueRanges: ValueRanges;
}

interface DuwPageProps extends PageProps<DuwPageData>, InjectedTrackerProps {
  scriptLoaded: Promise<void>;
  configuration: Configuration;
  businessId: string;
  onError: (e: Error) => void;
}
export class DuwPageContainer
  extends React.Component<DuwPageProps, DuwState>
  implements PageContainer
{
  public constructor(props: DuwPageProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  public componentDidMount() {
    scrollToTop();
    this.props.updateDTMTracking({
      seitenName: DTMSeitenname.GESUNDHEITSFRAGEN,
    });
    this.props.tracker.trackPageLoad({});
  }

  private readonly checkDuwState = () => {
    this.setState({ showModal: true });
  };
  private readonly tariff = getVarianteFromPossibleValues(
    this.props.storeState.selectedVariant?.variante || Beitragsvariante.KOMFORT,
    this.props.storeState.valueRanges.tariffType.possibleValues,
  );

  public render() {
    const duwContent = () => {
      switch (Storage.readItem('currentState')) {
        case StateName.DUW_DISCLOSURE_PAGE:
          return (
            <DuwDisclosure
              onNavigateNoSubmit={this.props.handleAction}
              onSubmit={() => true}
              trackClick={clickEvent =>
                this.props.tracker.trackClickEvent({
                  clickedElement: clickEvent,
                })
              }
            />
          );
        case StateName.DUW_QUESTIONNAIRE_PAGE:
          return (
            <DuwQuestionnaire
              scriptLoaded={this.props.scriptLoaded}
              configuration={this.props.configuration}
              onError={this.props.onError}
              onProgress={this.onDuwProgress.bind(this)}
              onNotifyId={this.onDuwToken.bind(this)}
              onNavigateNoSubmit={action => this.navigate(action)}
              onSubmit={({ result }: DuwResult, action) => {
                this.submitChild('duwResult', result, action).catch(error =>
                  this.props.onError(error),
                );
              }}
              {...this.props.storeState}
            />
          );
        case StateName.DUW_ASSESSMENT_PAGE:
          return (
            <DuwAssessment
              duwStatus={this.props.storeState.duwResult}
              onNavigateNoSubmit={this.props.handleAction}
              paymentMethod={this.props.storeState.paymentMethod}
              nettoRiskSurcharge={this.props.storeState.selectedVariant?.nettoRiskSurcharge}
              price={getNettoBeitragForVariant(this.props.storeState.selectedVariant)}
              tariff={this.tariff}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    };

    const duwQuestionnaireModal = () => (
      <Modal open={this.state.showModal}>
        {this.modalText()}
        <Button onClick={this.handleClick}>{this.modalButtonText}</Button>
      </Modal>
    );

    return (
      <div data-component-id={Storage.readItem('currentState')}>
        {duwContent()}
        {duwQuestionnaireModal()}
      </div>
    );
  }

  public readonly submitChild = async (
    field: string,
    value: string | boolean | number,
    navigationRequest: NavigationAction,
  ) => {
    await this.updateStateMachine(field, value).then(() => this.navigate(navigationRequest));
  };

  public navigate = (navigationRequest: NavigationAction) => {
    if (
      !!Storage.readItem('duwState') &&
      navigationRequest === NavigationAction.BACK &&
      StateName.DUW_QUESTIONNAIRE_PAGE
    ) {
      this.checkDuwState();
      return;
    }
    this.props.handleAction(navigationRequest);
  };

  private async updateStateMachine(field: string, value: string | boolean | number) {
    this.props.storeState.update({
      [field]: value,
    });
  }

  private async onDuwToken(token: string) {
    const updatedDuwToken: DuwPageData = {
      ...this.props.storeState,
      duwToken: token,
    } as DuwPageData;

    this.props.storeState.update(updatedDuwToken);
    await updateDuwData(this.props.businessId, updatedDuwToken);
  }

  private onDuwProgress(duwProgress: number) {
    this.props.storeState.update({ duwProgress });
  }

  public handleClick = () => {
    this.setState({ showModal: false });
    this.props.handleAction(NavigationAction.BACK);
  };

  private readonly modalText = () => (
    <>
      <b>Bitte beachten Sie:</b>
      <br />
      <br />
      Sie können zurück gehen und Ihre Angaben anpassen. Das Geschlecht und das Geburtsdatum können
      jedoch nicht mehr verändert werden.
      <br />
      Bei Fragen rufen Sie bitte an.
      <br />
      <br />
    </>
  );

  private readonly modalButtonText = 'schließen';
}

export default withTracker(DuwPageContainer);
