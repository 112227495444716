import LoadingSpinner from '@eg/elements/LoadingSpinner';
import * as React from 'react';
import './AppLoader.css';
export interface AppLoader {
  /**
   * [optional] Aditional viewport
   */
  viewport?: 'fixed' | 'relative';
  /**
   * [optional] Aditional show : boolean to show loader or not
   */
  show?: boolean;
}

const AppLoader: React.FC<AppLoader> = props => (
  <div className={props.show ? 'app-loader' : ''}>
    <LoadingSpinner show={props.show} viewport={props.viewport} />
    {props.children}
  </div>
);

export default AppLoader;
