import PDFMerger from 'pdf-merger-js/browser';
import { SessionStorageKeys } from '../types/SessionStorage';
import { Storage } from './Storage';

export const buildPdfs = async (URLs: string[]): Promise<string | undefined> => {
  try {
    const merger = new PDFMerger();

    for (const file of URLs) {
      await merger.add(file);
    }

    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  } catch (error) {
    console.error(error);
    throw new Error('We cannot merge all PDFs');
  }
};

const downloadByAnchorTag = (href = '#', docName = 'no_name') => {
  const anchorTag = document.createElement('a');
  anchorTag.target = '_blank';
  anchorTag.href = href;
  anchorTag.download = docName;
  anchorTag.rel = 'noopener noreferrer';

  anchorTag.click();

  anchorTag.remove();
};

const isOpenInNewWindow: boolean = navigator.userAgent.includes('Firefox');
export const canNavigateBackFromPdf = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent);

export const openPDF = (pdfUrl: string, docName: string): void => {
  if (isOpenInNewWindow) {
    window.open(pdfUrl, '_blank');
    return;
  }

  downloadByAnchorTag(pdfUrl, docName);
};

export const isPdfDownloaded = (): boolean =>
  Storage.readItem(SessionStorageKeys.PDF_DOWNLOADED) === 'true';

export const setPdfDownloaded = (downloaded: boolean) => {
  Storage.writeItem(SessionStorageKeys.PDF_DOWNLOADED, downloaded.toString());
};
