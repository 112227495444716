import { DEFAULT_AEM_PARAMS } from '../tracking/trackingData';
import {
  CONFIG_FRONTEND_BASE_URL,
  CONFIG_DUW_BASE_URL,
  CONFIG_DUW_ENTRYPOINT_FILE,
  CONFIG_PERSONENDATENERFASSUNG_BASE_URL,
  CONFIG_PERSONENDATENERFASSUNG_ENTRYPOINT_FILE,
} from '../config';
import { AemCarbonEnv } from '../types/AemCarbonEnv';

interface ServiceConfiguration {
  duw: Service;
  pde: Service;
  iconsPath: string;
  fragmentPath: string;
}

interface Service {
  baseUrl: string;
  entrypointFile: string;
}

export const { params: carbonParams } = window as Window;
// eslint-disable-next-line
export const aemContent = document.querySelector("[id^='rlv-otr']");
export const aemAssets = aemContent ? carbonParams[aemContent.id] : DEFAULT_AEM_PARAMS;

export const rlvKey: string | false =
  !!carbonParams && Object.keys(carbonParams).filter(k => k.includes('rlv-otr'))[0];
export const carbonEnv =
  !!carbonParams &&
  !!rlvKey &&
  carbonParams[rlvKey].envs.find((env: AemCarbonEnv) => env.name === 'otrUrl');

const getServiceConfiguration = (): ServiceConfiguration => {
  const iconsPath: string = !!carbonEnv ? carbonEnv.url : '';
  const fragmentPath: string = !!carbonEnv
    ? `${window.location.protocol}//${window.location.hostname}`
    : '';

  return {
    duw: {
      baseUrl: CONFIG_DUW_BASE_URL,
      entrypointFile: CONFIG_DUW_ENTRYPOINT_FILE,
    },
    pde: {
      baseUrl: CONFIG_PERSONENDATENERFASSUNG_BASE_URL,
      entrypointFile: CONFIG_PERSONENDATENERFASSUNG_ENTRYPOINT_FILE,
    },
    iconsPath,
    fragmentPath,
  };
};

const getLocalDocRoutes = (offlineDocs: string[]) =>
  offlineDocs.map((doc: string) => `${CONFIG_FRONTEND_BASE_URL}/documents/${doc}`);

export { ServiceConfiguration, Service, getServiceConfiguration, getLocalDocRoutes };
