import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import { RadioGroupOption } from '../../../../components/RadioGroup/RadioGroup.types';
import { RadioTileValue } from '../../../../components/RadioGroup/RadioTile/RadioTile.types';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingElementLoanProtection } from '../../../../tracking/tracking.types';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import './LoanProtection.css';
import { ConsentSelection, LoanProtectionText } from './LoanProtection.data';
import { LoanProtectionProps } from './LoanProtection.types';

export const LoanProtection = ({
  loanProtection,
  trackClickEvent,
  onNavigateNoSubmit,
  onSubmit,
}: LoanProtectionProps) => {
  const RADIO_GROUP_OPTIONS: RadioGroupOption[] = [
    {
      title: ConsentSelection.YES,
      value: ConsentSelection.YES,
    },
    {
      title: ConsentSelection.NO,
      value: ConsentSelection.NO,
    },
  ];
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);
  const [selectedOption, setSelectedOption] = React.useState<RadioTileValue>('');

  React.useEffect(() => {
    if (loanProtection === true) {
      setSelectedOption(ConsentSelection.YES);
    }
    if (loanProtection === false) {
      setSelectedOption(ConsentSelection.NO);
    }
  }, []);

  return (
    <Formik
      initialValues=""
      onSubmit={() => onSubmit(selectedOption === ConsentSelection.YES, navigationRequest)}
    >
      {form => (
        <Form className="container__loan-protection">
          <Headline text={LoanProtectionText.title} />
          <section className="container__loan-protection--paragraph">
            {LoanProtectionText.subtitle}
          </section>
          <Field
            name="LoanProtectionField"
            render={() => (
              <>
                <div className="radio_groups--container">
                  <div className="radio-groups--tiles">
                    <RadioGroup
                      options={RADIO_GROUP_OPTIONS}
                      name="consent"
                      selectedValue={selectedOption}
                      onChangeSelection={(_, value) => {
                        setSelectedOption(value);
                        trackClickEvent(
                          selectedOption === ConsentSelection.YES
                            ? TrackingElementLoanProtection.RB_LOAN_NO
                            : TrackingElementLoanProtection.RB_LOAN_YES,
                        );
                      }}
                    />
                  </div>
                </div>
                <AemFragment name={AemFragmentCatalog.STEP17VA} useVA={true} />
                <Footer
                  showNext
                  showPrevious
                  labelNext="weiter"
                  labelPrevious="zurück"
                  disableNext={!selectedOption}
                  handleAction={(navigationAction: NavigationAction) => {
                    setNavigationRequest(navigationAction);
                    if (navigationAction === NavigationAction.BACK && selectedOption === '') {
                      onNavigateNoSubmit(navigationAction);
                    }
                    form.submitForm();
                  }}
                />
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
