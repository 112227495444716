import * as React from 'react';
import { Storage } from '../../../helpers/Storage';
import { SessionStorageKeys } from '../../../types/SessionStorage';
import FeePage from '../FeePage';
import { FeePageProps } from '../FeePage.types';

export class FeePageContainer extends React.Component<FeePageProps> {
  public render(): React.ReactNode {
    const currentState = Storage.readItem(SessionStorageKeys.CURRENT_STATE);

    return (
      <div className="container">
        <div data-component-id={currentState}>
          <FeePage
            {...this.props}
            feeText={'Ihr persönlicher Zahlbeitrag'}
            feeTextAnnotation={
              'Durch die Gewinn-Beteiligung reduziert sich Ihr Brutto-Beitrag, ' +
              'so dass Sie nur den genannten Netto-Betrag zahlen. Dieser Netto-Betrag kann nicht ' +
              'für die gesamte Vertragsdauer garantiert werden. Er gilt, solange die Gewinn-Anteile ' +
              'unverändert bleiben.'
            }
            showMessageBox
          />
        </div>
      </div>
    );
  }
}
