import * as React from 'react';
import { ValueRanges } from 'rlv-common';
import { ViewName } from '../../../../helpers/ViewName';
import NeedsPageContainer from '../../../../pages/needs/NeedsPageContainer';
import { ConstantNeedBlockData } from '../../../../pages/needs/Needs.types';
import { getAngebot, updateConstantNeedBlockData } from '../../../../services/api';
import {
  getTrackingBirthdate,
  mapFetchedNeedData,
  mapTrackingInsuredPerson,
} from '../../../../tracking/trackingFormating';
import { PagePercentage } from '../../../../types/PagePercentage';
import { createNeedPageSchema } from '../../../../validation/NeedPage';
import { FetchedTrackingData, StateDefinition } from '../../../StateMachine';
import { NavigationAction, StateName } from '../../../StateMachineTypes';

export const constantSumInsuredState: StateDefinition<ConstantNeedBlockData> = {
  name: StateName.CONSTANT_SUM_INSURED,
  percentage: PagePercentage.CONSTANT_SUM_INSURED,
  trackingViewName: ViewName.BEDARFSANALYSE,
  createValidationSchema: (valueRanges: ValueRanges) => createNeedPageSchema(valueRanges),
  validInboundStates: [StateName.LIVING_CONDITIONS_BIRTHDATE, StateName.CONSTANT_YEARS_TERM],
  transitions: [
    {
      test: action => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.LIVING_CONDITIONS_BIRTHDATE,
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.CONSTANT_YEARS_TERM,
    },
  ],
  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData: FetchedTrackingData = {
      userAttributes: {
        ...mapTrackingInsuredPerson(),
        ...getTrackingBirthdate(response.livingConditionsModel),
      },
      tariffOptions: {
        ...mapFetchedNeedData(response.needSelectionModel),
      },
    };

    if (response.feeCalculationModel) {
      fetchedTrackingData.tariffOptions!.VersSum = response.feeCalculationModel.sumInsured;
    }

    return {
      userInput: response.constantNeedModel,
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  onExit: async (_, inputData) => {
    await updateConstantNeedBlockData(inputData.businessId, inputData.userInput);
    return {};
  },

  render: (inputData, handleAction, updateApp, _unused, updateDTMTracking) => {
    const userInput: ConstantNeedBlockData = inputData.userInput;
    return (
      <NeedsPageContainer
        businessId={inputData.businessId}
        inputData={inputData}
        updateApp={updateApp}
        storeState={{
          sumInsured: userInput.sumInsured,
          // We need to send this values to the tracker
          periodInYears: userInput.periodInYears,
          update: values => updateApp(values),
        }}
        valueRanges={inputData.valueRanges}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
